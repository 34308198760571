import { useQuery } from "@tanstack/react-query";
import { getMessageHistory } from "../../../services/apiMessages";
import useToken from "./hooks/useToken";

export function useMessageHistory(id) {
  const token = useToken();
  const { isLoading, data, error } = useQuery({
    queryKey: ["messages-history", id],
    queryFn: () => getMessageHistory(token, id),
  });

  return { isLoading, data, error };
}
