import styled from "styled-components";

const StyledAppLayoutNavbar = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  font-size: 0.8rem;
  justify-content: space-between;

  border-bottom: 1px solid #cb9e52;
  & .tabs-me {
    display: flex;
    flex-direction: row-reverse;

    & .active {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 202, 64, 0.297) 100%
      );
    }
    & a:hover {
      color: var(--color-grey-400);
    }

    & p,
    & .tab {
      cursor: pointer;
      padding: 0.4rem 1rem;
      position: relative;
      @media (max-width: 800px) {
        padding: 0.4rem 0.6rem;
      }

      & .chat-count {
        margin-left: 0.4rem;
        border-bottom: 1px solid;
      }

      &:hover {
        color: var(--color-grey-400);
      }
    }
  }

  .operations {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    /* gap: 2rem; */

    & .user-count-mobile {
      display: none;
    }
  }
  & p {
    margin-bottom: 0 !important;
  }

  @media (max-width: 800px) {
    font-size: 0.6rem;
  }

  @media (max-width: 600px) {
    .user-count-desktop {
      display: none;
    }

    .user-count-mobile {
      display: block !important;
      margin-right: 0.6rem;
    }
  }
`;

// eslint-disable-next-line react/prop-types
function AppLayoutNavbar({ children }) {
  return <StyledAppLayoutNavbar>{children}</StyledAppLayoutNavbar>;
}

export default AppLayoutNavbar;
