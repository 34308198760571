import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { io } from "socket.io-client";
import { userHasPermission } from "../../../../utils/helper";
import { SOCKET } from "../../../../utils/constants";
import useToken from "../hooks/useToken";
import { pendingChatsTab } from "../slices/messageSlice";
import { useDispatch } from "react-redux";
import newChat from "../../../../sounds/newChat.mp3";
import { useNotification } from "../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../UserContext/UserContext";

const SocketContext = createContext();

// eslint-disable-next-line react/prop-types
const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const { user } = useContext(UserContext);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null); // State to track errors
  // eslint-disable-next-line no-unused-vars
  const [isReconnecting, setIsReconnecting] = useState(false); // State to manage reconnections
  const reconnectAttempts = useRef(0); //
  const dispatch = useDispatch();
  const token = useToken();
  const { requestPermission, sendNotification } = useNotification();
  const navigate = useNavigate();

  const newChatAudio = useMemo(() => new Audio(newChat), []);
  const userPermission = useMemo(() => userHasPermission(user, "chat"), [user]);

  // Function to handle reconnection
  const attemptReconnection = () => {
    if (reconnectAttempts.current >= 5) {
      setError("Failed to reconnect to the server after multiple attempts.");
      return;
    }

    setIsReconnecting(true);
    reconnectAttempts.current += 1;

    setTimeout(() => {
      const newSocket = io(SOCKET, {
        path: "/socket.io",
        extraHeaders: {
          reconnection: true,
        },
      });

      setSocket(newSocket);
      setIsReconnecting(false);
    }, reconnectAttempts.current * 2000); // Exponential backoff for reconnection
  };

  // Effect to handle socket connection
  useEffect(() => {
    if (!userPermission || socket) return;

    const newSocket = io(SOCKET, {
      // path: "/backend/socket.io",
      path: "/socket.io",
      // path: "/socket.io",
      extraHeaders: {
        // Authorization: `bearer ${token}`,
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 300,
      },
    });
    setSocket(() => newSocket); // Set the socket instance

    newSocket.emit("joinSupportRoom", {
      token,
      nickname: user.nickname,
      username: user.username,
    });

    // Event listeners for errors and disconnections
    newSocket.on("connect_error", (err) => {
      console.error("Socket connection error:", err);
      setError("Unable to connect to the server. Please try again later.");
    });

    newSocket.on("disconnect", (reason) => {
      console.warn("Socket disconnected:", reason);
      if (reason === "io server disconnect" || reason === "transport close") {
        attemptReconnection(); // Attempt to reconnect
      }
    });

    newSocket.on("reconnect_failed", () => {
      console.error("Reconnection failed");
      setError("Reconnection failed. Please check your connection.");
    });

    newSocket.on("reconnect_attempt", () => {
      console.log("Attempting to reconnect...");
      setError(null); // Clear previous error while trying to reconnect
    });

    newSocket.on("userJoined", (data) => {
      console.log(data);
    });
    newSocket.on("userLeft", (data) => {
      console.log(data);
    });

    const handleNewChat = async () => {
      console.log("newChat");

      newChatAudio.play();
      const permission = await requestPermission();

      if (permission === "granted") {
        sendNotification("FeneFx", {
          body: "You have new chat.",
          icon: "/Asset 2.svg", // Optional: Add your image path
          onClick: () => {
            window.focus();
            dispatch(pendingChatsTab());
          },
        });
      }
    };

    newSocket.on("newChat", handleNewChat);

    return () => {
      newSocket.off("newChat", handleNewChat);
      newSocket.off("userJoined");
      newSocket.off("userLeft");
      console.log("Socket disconnected");
      newSocket.disconnect();
      setSocket(null);
    };
  }, [user]); // Only runs when user changes

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

const useSocket = () => {
  const context = React.useContext(SocketContext);
  if (context === undefined) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};

export { SocketProvider, useSocket };
