/* eslint-disable react/prop-types */
import { useState } from "react";

import styled from "styled-components";
import { detectDirection } from "../../../utils/helper";
import { useBan } from "../messages/useBan";
import toast from "react-hot-toast";

const Textarea = styled.textarea`
  padding: 0.8rem 1.2rem;
  border: 1px solid var(--color-grey-300);
  border-radius: 5px;
  background-color: rgba(0, 128, 126, 0.297);
  box-shadow: var(--shadow-sm);
  color: var(--color-grey-100);
  resize: none;
  width: 40rem;
  height: 6rem;

  &::placeholder {
    color: var(--color-grey-700);
  }
`;

function BanUser({ onCloseModal, userId }) {
  const [days, setDays] = useState(1);
  const [description, setDescription] = useState("");

  // eslint-disable-next-line no-unused-vars
  const { banUser, isBanning } = useBan();

  function handleBan() {
    const data = {
      duration: days,
      reason: description,
    };
    banUser(
      { data, userId },
      {
        onSuccess: (res) => {
          onCloseModal();
          toast.success(res.message || "The user has been banned");
        },
        onError: (err) => {
          onCloseModal();
          toast.error(
            err.response.data.message || err.message || "Failed to ban the user"
          );
        },
      }
    );
  }

  function handleInput(e) {
    const value = e.target.value;
    setDescription(value);
  }
  return (
    <div
      style={{
        padding: "1.2rem 2.8rem",
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <h3>Select the days </h3>
      <select
        defaultValue={days}
        onChange={(e) => setDays(+e.target.value)}
        className="searchIn"
        style={{
          width: "100%",
        }}
      >
        <option value={1}>1 day</option>
        <option value={3}>3 days</option>
        <option value={7}>1 week</option>
        <option value={"permanent"}>always</option>
      </select>
      <Textarea
        dir={detectDirection(description)}
        value={description}
        onChange={handleInput}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1.6rem",
        }}
      >
        <button className="btn btn-primary" onClick={onCloseModal}>
          Cancel
        </button>

        <button className="btn btn-primary" onClick={handleBan}>
          Ban
        </button>
      </div>
    </div>
  );
}

export default BanUser;
