import React, { useEffect, useState, useContext } from "react";
import axios from "axios";

import UserContext from "../../UserContext/UserContext";
import "../competition.css";
import Cookies from "js-cookie";
import "./profile.css";

// const cardBaseStyle = {
//   color: "white",
//   borderRadius: "12px",
//   padding: "1.5rem",
//   flex: 1,
//   textAlign: "center",
//   boxShadow: "0 4px 15px rgba(212, 192, 76, 0.4)",
//   background: "linear-gradient(135deg, #1b4438, #145c4a)",
//   transition: "transform 0.3s ease, box-shadow 0.3s ease",
//   cursor: "pointer",
// };
function ProfileCard({ children }) {
  return <div className="card-base"> {children} </div>;
}

function RowSection({ title, data }) {
  return (
    <div className="rowSectionStyle">
      <div className="sectionTitleStyle"> {title} </div>{" "}
      {Object.entries(data).map(([key, value], index) => (
        <div key={index} className="rowItemStyle">
          <span> {key} </span> <span className="highlightStyle"> {value} </span>{" "}
        </div>
      ))}{" "}
    </div>
  );
}

function Profile({ competition, toggleLoading, changePage }) {
  // console.log("myyy commmm::", competition);
  console.log("func in profile", changePage);
  const [isAuth, setIsAuth] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = `${url}api/v1/panel/competitions/me`; // آدرس API
      const token = Cookies.get("token"); // توکن شما
      load(true);

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`, // افزودن توکن به هدر
          },
        });

        // داده‌های دریافتی از API
        console.log("Response data:", response.data);
        setIsAuth(response.data.auth_status);
        load(false);
      } catch (error) {
        // مدیریت خطا
        const errorMessage = error.response?.data?.message || "خطایی رخ داد.";
        console.error("Error fetching data:", errorMessage);
        load(false);
      }
    };

    fetchData(); // فراخوانی تابع
  }, []);

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      let targetTime; // زمان هدف (شروع یا پایان)

      if (competition.status === 1) {
        targetTime = new Date(competition.start); // زمان شروع مسابقه
      } else if (competition.status === 0) {
        targetTime = new Date(competition.end); // زمان پایان مسابقه
      }

      const difference = targetTime - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        setTimeLeft(null); // وقتی زمان به پایان برسد
      }
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer); // پاک‌سازی تایمر
  }, [competition.status, competition.start, competition.end]);

  const tradingOverviewData = [
    { label: "Current Equity", value: "$100,000.00" },
    { label: "Current Balance", value: "$100,000.00" },
    { label: "Return", value: "0.00%" },
    { label: "Daily Loss Limit", value: "Ongoing" },
    { label: "Max Loss Limit", value: "$5,000.00" },
    { label: "Today's Permitted Loss", value: "$0.00" },
    { label: "Trading Days", value: "Ongoing" },
    { label: "Minimum", value: "5 Days" },
    { label: "Current Result", value: "0 Days" },
    { label: "Overall Loss Limit", value: "Ongoing" },
    { label: "Max Loss Limit", value: "$10,000.00" },
    { label: "Max Permitted Loss", value: "$0.00" },
  ];

  const detailStatusData = {
    Trades: "N/A",
    Lots: "N/A",
    "Win Rate (%)": "N/A",
    "Lose Rate (%)": "N/A",
    "Long (%)": "N/A",
    "Short (%)": "N/A",
    "Most Traded Pair": "N/A",
  };
  const { user, load, url } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  console.log("user", user);

  return (
    <div className="profileContainerStyle">
      <div className="headerRowStyle1">
        {" "}
        {/* ردیف اول */}{" "}
        <ProfileCard>
          <p style={{ fontSize: "1.5rem", fontWeight: "bold", color: "white" }}>
            سلام {user.dataes.first_name}
          </p>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0.5rem 0",
            }}
          >
            رتبه فعلی: <span className="highlightStyle"> N / A </span>{" "}
          </div>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0.5rem 0",
            }}
          >
            موجودی فعلی: <span className="highlightStyle"> $1,000,000 </span>{" "}
          </div>{" "}
          <div
            onClick={() => {
              if (!isAuth) {
                changePage("احراز هویت");
              }
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0.5rem 0",
              }}
            >
              وضعیت احراز هویت :{" "}
              <span
                style={{
                  color: isAuth ? "#00c874" : "red",
                  fontWeight: "bold",
                }}
              >
                {isAuth ? "تکمیل شده" : "تکمیل نشده"}
                <br />
              </span>
            </div>
            {!isAuth && <span>برای احراز هویت خود کلیک نمایید</span>}
          </div>
        </ProfileCard>{" "}
        <ProfileCard>
          <h4
            style={{
              fontSize: "1.4rem",
              fontWeight: "bold",
              marginBottom: "1rem",
            }}
          >
            جزئیات حساب{" "}
          </h4>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0.5rem 0",
            }}
          >
            سرور: <span className="highlightStyle">Mondtrades-Server </span>{" "}
          </div>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0.5rem 0",
            }}
          >
            پلتفرم: <span className="highlightStyle"> MT5 </span>{" "}
          </div>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0.5rem 0",
            }}
          >
            ورود: <span className="highlightStyle">- - - </span>{" "}
          </div>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0.5rem 0",
            }}
          >
            رمز عبور: <span className="highlightStyle"> ** ** ** ** </span>{" "}
          </div>{" "}
        </ProfileCard>{" "}
        <ProfileCard>
          <h4
            style={{
              fontSize: "1.4rem",
              fontWeight: "bold",
              marginBottom: "1rem",
            }}
          >
            {competition.status === 1
              ? "زمان باقی‌مانده تا شروع:"
              : competition.status === 0
              ? "زمان باقی‌مانده تا پایان:"
              : ""}{" "}
          </h4>{" "}
          {timeLeft ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0.5rem 0",
                }}
              >
                روز: <span className="highlightStyle"> {timeLeft.days} </span>{" "}
              </div>{" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0.5rem 0",
                }}
              >
                ساعت: <span className="highlightStyle"> {timeLeft.hours} </span>{" "}
              </div>{" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0.5rem 0",
                }}
              >
                دقیقه:{" "}
                <span className="highlightStyle"> {timeLeft.minutes} </span>{" "}
              </div>{" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0.5rem 0",
                }}
              >
                ثانیه:{" "}
                <span className="highlightStyle"> {timeLeft.seconds} </span>{" "}
              </div>{" "}
            </>
          ) : (
            <div style={{ color: "red", fontWeight: "bold" }}>
              {" "}
              زمان به پایان رسید!{" "}
            </div>
          )}{" "}
        </ProfileCard>
      </div>{" "}
      {/* ردیف دوم */}{" "}
      <div
        className="headerRowStyle"
        style={{ marginTop: "2rem", flex: "wrap" }}
      >
        <div className="profileContainer">
          <div className="rowContainer">
            <div className="infoCard">
              <div className="infoRow">
                <p> اکویتی فعلی </p>
                <p className="highlightedValue"> 1,000,000 $ </p>
              </div>
              <div className="infoRow">
                <p> موجودی فعلی </p>
                <p className="highlightedValue"> 1,000,000 $ </p>
              </div>
              <div className="infoRow">
                <p> بازدهی </p>
                <p className="highlightedValue"> 0.00 % </p>
              </div>
            </div>
            <div className="infoCard">
              <div className="infoRow">
                <p> حد ضرر روزانه </p>
                <p className="highlightedValue"> 50,000 $ </p>
              </div>
              <div className="infoRow">
                <p> حداکثر ضرر </p>
                <p className="highlightedValue"> 50,000 $ </p>
              </div>
              <div className="infoRow">
                <p> ضرر مجاز امروز </p>
                <p className="highlightedValue"> 50,000 $ </p>
              </div>
            </div>
          </div>
          <div className="rowContainer">
            <div className="infoCard">
              <div className="infoRow">
                <p> روزهای معاملاتی </p>
                <p className="highlightedText"> تکمیل نشده </p>
              </div>
              <div className="infoRow">
                <p> حداقل </p>
                <p className="highlightedValue"> 5 روز </p>
              </div>
              <div className="infoRow">
                <p> اکنون </p>
                <p className="highlightedValue"> 0 روز </p>
              </div>
            </div>
            <div className="infoCard">
              <div className="infoRow">
                <p> حد ضرر کلی </p>
                <p className="highlightedValue"> - </p>
              </div>
              <div className="infoRow">
                <p> حداکثر ضرر </p>
                <p className="highlightedValue"> 120,000 $ </p>
              </div>
              <div className="infoRow">
                <p> حداکثر ضرر مجاز </p>
                <p className="highlightedValue"> 120,000 $ </p>
              </div>
            </div>
          </div>
        </div>
        <RowSection title="جزئیات وضعیت" data={detailStatusData} />{" "}
      </div>{" "}
    </div>
  );
}

export default Profile;
