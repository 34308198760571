/* eslint-disable react/prop-types */
import { useState } from "react";
import Empty from "./Empty";
import Conversation from "./Conversation";
import Search from "./Search";
import { useSelector } from "react-redux";
import SearchInHistory from "./SearchInHistory";
import Spinner from "./Spinner";
import SelectUser from "./SelectUser";

function ConversationList({ conversations, socket, loadingOldChats }) {
  const [filteredConversations, setFilteredConversations] = useState([]); // Local state to manage chats
  const { activeTab } = useSelector((state) => state.message);

  return (
    <>
      {activeTab !== "all" && activeTab !== "explore" && (
        <Search
          conversations={conversations}
          setFilteredConversations={setFilteredConversations}
        />
      )}
      {activeTab === "all" && <SearchInHistory />}
      {activeTab === "explore" && <SelectUser />}
      {loadingOldChats && (
        <div className="center-abs">
          <Spinner />
        </div>
      )}
      {filteredConversations.length > 0 && (
        <>
          <ul>
            {filteredConversations?.map((con, index) => (
              <Conversation
                key={`${con.id}-${index}`}
                conversation={con}
                socket={socket}
              />
            ))}
          </ul>
          <hr />
        </>
      )}

      <ul>
        {!conversations?.length && !loadingOldChats && (
          <Empty>
            <span style={{ fontSize: "0.6rem" }}>No conversations yet.</span>
          </Empty>
        )}
        {conversations?.length > 0 &&
          conversations?.map((con, index) => (
            <Conversation
              key={`${con?.id}-${index}`}
              conversation={con}
              socket={socket}
            />
          ))}
      </ul>
    </>
  );
}

export default ConversationList;
