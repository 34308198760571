import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import {
  IoChevronBackCircle,
  IoArrowForwardCircleSharp,
} from "react-icons/io5";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Paper,
} from "@mui/material";
import { MdAdsClick } from "react-icons/md";
import UserContext from "../UserContext/UserContext";
import Swal from "sweetalert2";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Success from "../Success/Success";

export default function AdminUsers() {
  const [users, setUsers] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // for managing page number
  const { load, url } = useContext(UserContext);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [inputAmount, setInputAmount] = useState(""); // Store the amount entered by the user
  const [currentMonthName, setCurrentMonthName] = useState(""); // Store current month name
  const [showDateInput, setShowDateInput] = useState(false); // To toggle date input visibility
  const [salaries, setsalaries] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(""); // متن مودال
  const [hovered, setHovered] = useState(false);

  /////////newww code ////////////////

  const handleFieldChange = (index, field, value) => {
    const updatedSalaries = [...salaries];
    updatedSalaries[index][field] = value;
    setsalaries(updatedSalaries);
  };

  const handleSaveChanges = (updatedSalaries) => {
    console.log("Updated Salaries:", updatedSalaries);
    NotificationManager.success("تغییرات با موفقیت ذخیره شد.");
  };

  //////////

  const handleRedirectToTronscan = (transactionId) => {
    if (!transactionId) {
      console.warn("Transaction ID is missing!");
      return;
    }

    // لینک سایت Tronscan با شناسه تراکنش
    const tronscanUrl = `https://tronscan.org/#/transaction/${transactionId}`;

    // هدایت به لینک
    window.open(tronscanUrl, "_blank");
  };

  // const ConfirmationModal = ({ isOpen, content, onConfirm, onCancel }) => {
  //   if (!isOpen) return null;

  //   return (
  //     <div
  //       style={{
  //         position: "absolute",
  //         top: 0,
  //         left: 0,
  //         width: "100%",
  //         height: "100vh",
  //         backgroundColor: "rgba(0, 0, 0, 0.5)",
  //         display: "flex",
  //         justifyContent: "center",

  //         zIndex: 1000,
  //       }}
  //     >
  //       <div
  //         style={{
  //           backgroundColor: "white",
  //           padding: "20px",
  //           borderRadius: "8px",
  //           boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  //           textAlign: "center",
  //         }}
  //       >
  //         <p style={{ marginBottom: "20px", color: "black" }}>{content}</p>
  //         <div
  //           style={{ display: "flex", justifyContent: "center", gap: "10px" }}
  //         >
  //           <button
  //             onClick={onConfirm}
  //             style={{
  //               backgroundColor: "#007bff",
  //               color: "white",
  //               border: "none",
  //               padding: "10px 15px",
  //               borderRadius: "5px",
  //               cursor: "pointer",
  //             }}
  //           >
  //             تایید
  //           </button>
  //           <button
  //             onClick={onCancel}
  //             style={{
  //               backgroundColor: "#dc3545",
  //               color: "white",
  //               border: "none",
  //               padding: "10px 15px",
  //               borderRadius: "5px",
  //               cursor: "pointer",
  //             }}
  //           >
  //             انصراف
  //           </button>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // Array of month names in Persian
  const monthNames = [
    "ژانویه",
    "فوریه",
    "مارس",
    "آوریل",
    "مه",
    "ژوئن",
    "جولای",
    "آگوست",
    "سپتامبر",
    "اکتبر",
    "نوامبر",
    "دسامبر",
  ];
  const getMonthName = (monthNumber) => {
    // آرایه‌ای از نام ماه‌های میلادی
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // ایندکس آرایه از 0 شروع می‌شود، بنابراین نیاز به monthNumber - 1 داریم
    return monthNames[monthNumber - 1] || "Invalid month"; // بازگشت نام ماه یا پیغام خطا
  };

  const confirmChange = async (lastMonthName) => {
    return Swal.fire({
      title: "توجه",
      text: `برای ماه ${lastMonthName} حقوق ثبت شده است. آیا مایل به تغییر هستید؟`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "بله، تغییر بده",
      cancelButtonText: "خیر",
    }).then((result) => result.isConfirmed);
  };

  const handleSalarySubmission = (userId, todayDate, lastMonthName) => {
    // نمایش لودینگ
    load(true);

    // ارسال درخواست POST به سرور
    axios
      .post(
        `${url}api/v1/admin/salary/${userId}`,
        {
          amount: inputAmount, // مبلغ وارد شده توسط کاربر
          date: todayDate, // تاریخ امروز
        },
        {
          headers: {
            Authorization: `bearer ${Cookies.get("token")}`, // ارسال توکن برای احراز هویت
          },
        }
      )
      .then((res) => {
        // درخواست با موفقیت انجام شد
        load(false); // پنهان کردن لودینگ
        NotificationManager.success("با موفقیت ");
        setInputAmount(""); // ریست کردن فیلد ورودی مبلغ

        // به‌روزرسانی حقوق‌ها با درخواست GET
        axios
          .get(`${url}api/v1/admin/salary/user/${userId}/get`, {
            headers: {
              Authorization: `bearer ${Cookies.get("token")}`,
            },
          })
          .then((res) => {
            setsalaries(res.data.data); // به‌روزرسانی جدول حقوق‌ها
          })
          .catch((err) => {
            console.error("خطا در دریافت اطلاعات به‌روزرسانی شده:", err);
          });
      })
      .catch((err) => {
        // خطا در ارسال درخواست
        load(false); // پنهان کردن لودینگ
        NotificationManager.error("خطایی در ثبت حقوق رخ داده است.", "خطا");
        console.error("خطا در ارسال حقوق:", err);
      });
  };

  // Function to get the current month name
  const getCurrentMonthName = (date) => {
    const monthIndex = new Date(date).getMonth();
    return monthNames[monthIndex];
  };

  // Get today's date and format it
  const getTodayDate = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1; // Month is zero-based
    const year = today.getFullYear();
    return `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`; // YYYY-MM-DD format
  };

  // Effect to display current month name when expanded row is clicked
  useEffect(() => {
    const currentDate = new Date();
    const monthName = getCurrentMonthName(currentDate); // Get current month name
    setCurrentMonthName(monthName); // Update state with the current month name
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm) {
      sendSearchRequest(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 2000);
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const sendSearchRequest = (term) => {
    load(true);
    const searchUrl = `${url}api/v1/panel/users/search?type=admin&query=${term}`;
    axios
      .get(searchUrl, {
        headers: { Authorization: `bearer ${Cookies.get("token")}` },
      })
      .then((res) => {
        load(false);
        setUsers(res.data.data);
      })
      .catch((err) => {
        load(false);
        console.error("Search request failed:", err);
      });
  };

  const handlePageChange = (page) => {
    if (page === currentPage) return; // Prevent redundant requests for the same page
    setCurrentPage(page);
    load(true);
    const url = `${
      users.links.find((link) => link.label === page.toString()).url
    }&page=${page}&type=admin`;
    axios
      .get(url, {
        headers: { Authorization: `bearer ${Cookies.get("token")}` },
      })
      .then((res) => {
        load(false);
        setUsers(res.data.data);
      })
      .catch((err) => {
        load(false);
        console.error("Page change request failed:", err);
      });
  };

  const usersShow = () => {
    console.log("my users", users);
    return (
      <>
        {users.map((d) => {
          return (
            <React.Fragment key={d.id}>
              <tr>
                <th className="lastTh">{d.id}</th>
                <th>{d.username}</th>
                <th>
                  {d.first_name !== null
                    ? d.first_name + " " + d.last_name
                    : "ثبت نشده"}
                </th>
                <th>{d.salary[0].fenefx_user ? "fenefx" : "mondfx"}</th>
                <th
                  className="lastTh"
                  onClick={() => {
                    setExpandedRow(expandedRow === d.id ? null : d.id);
                    setsalaries(d.salary[0]);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <MdAdsClick />
                </th>
              </tr>
              {expandedRow === d.id && (
                <tr>
                  <td colSpan="5">
                    {console.log("salarys :", salaries)}
                    <Box>
                      <Typography variant="h6" gutterBottom>
                        مدیریت حقوق
                      </Typography>
                      <Paper
                        elevation={3}
                        style={{
                          padding: "16px",
                          marginTop: "16px",
                          backgroundColor: "transparent", // پس‌زمینه شفاف
                          backdropFilter: "blur(10px)", // محو کردن پس‌زمینه
                          border: "1px solid rgba(255, 255, 255, 0.3)", // حاشیه برای وضوح فرم
                          borderRadius: "8px",
                          color: "white", // سفید کردن تمام متن‌ها
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="شناسه کاربر"
                              value={salaries.user_id || ""}
                              disabled
                              fullWidth
                              InputProps={{
                                style: { color: "white" }, // متن ورودی سفید
                              }}
                              InputLabelProps={{
                                style: { color: "white" }, // متن لیبل سفید
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              placeholder="مبلغ را وارد کنید"
                              value={salaries.amount || ""}
                              onChange={(e) =>
                                handleFieldChange("amount", e.target.value)
                              }
                              fullWidth
                              InputProps={{
                                style: {
                                  border: "white 1px solid",
                                  color: "white", // متن ورودی سفید
                                  textAlign: "right", // متن داخل فیلد راست‌چین
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  border: "white 1px solid",
                                  color: "white", // رنگ لیبل
                                  textAlign: "right", // تنظیم موقعیت لیبل به راست (برای زبان فارسی)
                                },
                                shrink: true, // اطمینان از جابجایی لیبل به حالت فشرده
                              }}
                              FormHelperTextProps={{
                                style: {
                                  textAlign: "right", // اگر متنی کمکی دارید، راست‌چین خواهد شد
                                },
                              }}
                              variant="outlined" // حالت Outlined برای نمایش بهتر
                              style={{
                                marginBottom: "16px", // فاصله بین فیلدها
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="واحد محاسبه"
                              value={salaries.calculator_base || ""}
                              onChange={(e) =>
                                handleFieldChange(
                                  "calculator_base",
                                  e.target.value
                                )
                              }
                              select
                              fullWidth
                              InputProps={{
                                style: { color: "white" ,border: "white 1px solid", }, // متن ورودی سفید
                              }}
                              InputLabelProps={{
                                style: { color: "white",fontSize:'large' }, // متن لیبل سفید
                              }}
                            >
                              <MenuItem value="toman">تومان</MenuItem>
                              <MenuItem value="usdt">تتر</MenuItem>
                            </TextField>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="وضعیت"
                              value={salaries.status || ""}
                              onChange={(e) =>
                                handleFieldChange("status", e.target.value)
                              }
                              select
                              fullWidth
                              InputProps={{
                                style: { color: "white" }, // متن ورودی سفید
                              }}
                              InputLabelProps={{
                                style: { color: "white" }, // متن لیبل سفید
                              }}
                            >
                              <MenuItem value="pending">در انتظار</MenuItem>
                              <MenuItem value="paid">پرداخت شده</MenuItem>
                              <MenuItem value="canceled">لغو شده</MenuItem>
                            </TextField>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="تاریخ پرداخت"
                              type="date"
                              value={salaries.payment_date || ""}
                              onChange={(e) =>
                                handleFieldChange(
                                  "payment_date",
                                  e.target.value
                                )
                              }
                              fullWidth
                              InputProps={{
                                style: { color: "white" }, // متن ورودی سفید
                              }}
                              InputLabelProps={{
                                style: { color: "white" }, // متن لیبل سفید
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="ماه"
                              value={salaries.month || ""}
                              onChange={(e) =>
                                handleFieldChange("month", e.target.value)
                              }
                              fullWidth
                              InputProps={{
                                style: { color: "white" }, // متن ورودی سفید
                              }}
                              InputLabelProps={{
                                style: { color: "white" }, // متن لیبل سفید
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="سال"
                              value={salaries.year || ""}
                              onChange={(e) =>
                                handleFieldChange("year", e.target.value)
                              }
                              fullWidth
                              InputProps={{
                                style: { color: "white" }, // متن ورودی سفید
                              }}
                              InputLabelProps={{
                                style: { color: "white" }, // متن لیبل سفید
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="حقوق پایه"
                              value={salaries.base_salary || ""}
                              onChange={(e) =>
                                handleFieldChange("base_salary", e.target.value)
                              }
                              fullWidth
                              InputProps={{
                                style: { color: "white" }, // متن ورودی سفید
                              }}
                              InputLabelProps={{
                                style: { color: "white" }, // متن لیبل سفید
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="اضافه‌کاری"
                              value={salaries.overtime || ""}
                              onChange={(e) =>
                                handleFieldChange("overtime", e.target.value)
                              }
                              fullWidth
                              InputProps={{
                                style: { color: "white" }, // متن ورودی سفید
                              }}
                              InputLabelProps={{
                                style: { color: "white" }, // متن لیبل سفید
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="هزینه حمل‌ونقل"
                              value={salaries.transport || ""}
                              onChange={(e) =>
                                handleFieldChange("transport", e.target.value)
                              }
                              fullWidth
                              InputProps={{
                                style: { color: "white" }, // متن ورودی سفید
                              }}
                              InputLabelProps={{
                                style: { color: "white" }, // متن لیبل سفید
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="شیفت شب"
                              value={salaries.night_shift || ""}
                              onChange={(e) =>
                                handleFieldChange("night_shift", e.target.value)
                              }
                              fullWidth
                              InputProps={{
                                style: { color: "white" }, // متن ورودی سفید
                              }}
                              InputLabelProps={{
                                style: { color: "white" }, // متن لیبل سفید
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="کمک‌ها"
                              value={salaries.help || ""}
                              onChange={(e) =>
                                handleFieldChange("help", e.target.value)
                              }
                              fullWidth
                              InputProps={{
                                style: { color: "white" }, // متن ورودی سفید
                              }}
                              InputLabelProps={{
                                style: { color: "white" }, // متن لیبل سفید
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="افزایش حقوق"
                              value={salaries.salary_increase || ""}
                              onChange={(e) =>
                                handleFieldChange(
                                  "salary_increase",
                                  e.target.value
                                )
                              }
                              fullWidth
                              InputProps={{
                                style: { color: "white" }, // متن ورودی سفید
                              }}
                              InputLabelProps={{
                                style: { color: "white" }, // متن لیبل سفید
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="توضیحات"
                              value={salaries.description || ""}
                              onChange={(e) =>
                                handleFieldChange("description", e.target.value)
                              }
                              fullWidth
                              InputProps={{
                                style: { color: "white" }, // متن ورودی سفید
                              }}
                              InputLabelProps={{
                                style: { color: "white" }, // متن لیبل سفید
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="وام"
                              value={salaries.loan || ""}
                              onChange={(e) =>
                                handleFieldChange("loan", e.target.value)
                              }
                              fullWidth
                              InputProps={{
                                style: { color: "white" }, // متن ورودی سفید
                              }}
                              InputLabelProps={{
                                style: { color: "white" }, // متن لیبل سفید
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="کیف پول"
                              value={salaries.wallet || ""}
                              onChange={(e) =>
                                handleFieldChange("wallet", e.target.value)
                              }
                              fullWidth
                              InputProps={{
                                style: { color: "white" }, // متن ورودی سفید
                              }}
                              InputLabelProps={{
                                style: { color: "white" }, // متن لیبل سفید
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="تشویقی (مثبت)"
                              value={salaries.cheer_up || ""}
                              onChange={(e) =>
                                handleFieldChange("cheer_up", e.target.value)
                              }
                              fullWidth
                              InputProps={{
                                style: { color: "white" }, // متن ورودی سفید
                              }}
                              InputLabelProps={{
                                style: { color: "white" }, // متن لیبل سفید
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="تشویقی (منفی)"
                              value={salaries.cheer_down || ""}
                              onChange={(e) =>
                                handleFieldChange("cheer_down", e.target.value)
                              }
                              fullWidth
                              InputProps={{
                                style: { color: "white" }, // متن ورودی سفید
                              }}
                              InputLabelProps={{
                                style: { color: "white" }, // متن لیبل سفید
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              label="نام مسئول"
                              value={salaries.did_name || ""}
                              onChange={(e) =>
                                handleFieldChange("did_name", e.target.value)
                              }
                              fullWidth
                              InputProps={{
                                style: { color: "white" }, // متن ورودی سفید
                              }}
                              InputLabelProps={{
                                style: { color: "white" }, // متن لیبل سفید
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Box mt={3}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleSaveChanges(d)}
                          >
                            ذخیره تغییرات
                          </Button>
                        </Box>
                      </Paper>
                    </Box>
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  useEffect(() => {
    load(true);
    const requestUrl = `${url}api/v1/admin/salary/pending`;
    axios
      .get(requestUrl, {
        headers: { Authorization: `bearer ${Cookies.get("token")}` },
      })
      .then((res) => {
        load(false);
        console.log(" my data :", res.data.users);
        setUsers(res.data.users);
      })
      .catch((err) => {
        load(false);
        console.error("Initial request failed:", err);
      });
  }, []);

  return (
    <div className="transAction">
      <Success
        show={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
        }}
      >
        <div style={{ textAlign: "center", color: "white" }}>
          <p>{modalContent}</p>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <button
              onClick={() => {
                setIsModalOpen(false);
                const todayDate = getTodayDate();
                handleSalarySubmission(expandedRow, todayDate, modalContent); // ارسال درخواست
              }}
              style={{
                backgroundColor: "#007bff",
                color: "white",
                border: "none",
                padding: "10px 15px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              تایید
            </button>
            <button
              onClick={() => setIsModalOpen(false)}
              style={{
                backgroundColor: "#dc3545",
                color: "white",
                border: "none",
                padding: "10px 15px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              انصراف
            </button>
          </div>
        </div>
      </Success>
      <NotificationContainer></NotificationContainer>
      <div className="eachTicket">
        <div className="table-responsive-md">
          <table border={1} className="table table-hover">
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>ID</th>
                <th className="dfjc">
                  نام کاربری
                  <input
                    className="searchIn"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    type="text"
                    placeholder="جستجو نام کاربری"
                  />
                </th>
                <th>نام کامل</th>
                <th>کارمند</th>
                <th className="lastTh"></th>
              </tr>
            </thead>
            <tbody>{usersShow()}</tbody>
          </table>
          {/* 
          <ul className="page">
            <li
              onClick={() => {
                if (users.prev_page_url !== null) {
                  handlePageChange(currentPage - 1);
                }
              }}
              className={`page__btn ${currentPage === 1 ? "disabled" : ""}`}
            >
              <IoChevronBackCircle />
            </li>
            {users.links.map((dd) => {
              if (
                dd.label !== "&laquo; Previous" &&
                dd.label !== "Next &raquo;"
              ) {
                return (
                  <li
                    key={dd.label}
                    className={`page__numbers ${dd.active ? "ac" : ""}`}
                    onClick={() => handlePageChange(dd.label)}
                  >
                    {dd.label}
                  </li>
                );
              }
            })}
            <li
              onClick={() => {
                if (users.next_page_url !== null) {
                  handlePageChange(Number(currentPage) + 1);
                }
              }}
              className={`page__btn ${
                users.next_page_url === null ? "disabled" : ""
              }`}
            >
              <IoArrowForwardCircleSharp />
            </li>
          </ul> */}
        </div>
      </div>
    </div>
  );
}
