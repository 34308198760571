import React, { useState, useContext } from "react";
import Profile from "./competition/Profile";
import TradeAnalytics from "./competition/TradeAnalytics";
import { FaBackward } from "react-icons/fa6";
import UserContext from "../UserContext/UserContext";
import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import axios from "axios";
import CompetitionRules from "./competition/CompetitionRules";
import LeaderBoard from "./competition/LeaderBoard";
import AOS from "aos";
import "aos/dist/aos.css";

function CompetitionProfile({ competition, toggleLoading, togglelist ,changePage }) {
  console.log("your com:::", competition);
  const [activeTab, setActiveTab] = useState("yourProfile"); // مدیریت تب فعال
  const { user, load, url } = useContext(UserContext);

  // تعریف تابع برای درخواست داده از API
  const fetchCompetitionData = async () => {
    const token = Cookies.get("token");
    const response = await axios.get(
      "http://192.168.8.167:8000/api/v1/panel/accounts/prop/account/6",
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
        timeout: 10000, // تایم‌اوت درخواست
      }
    );
    return response.data;
  };

  // محتوای مربوط به هر تب
  const renderTabContent = (competition) => {
    switch (activeTab) {
      case "yourProfile":
        return (
          <Profile competition={competition} toggleLoading={toggleLoading} changePage={changePage} />
        );
      case "tradeAnalytics":
        // return <TradeAnalytics />;
        return (
          <div>
            <h2>انالیز حساب</h2>
            <p> اینجا اطلاعات انالیز حساب نمایش داده می‌شود. </p>
          </div>
        );

      case "leaderboard":
        return <LeaderBoard competition={competition} />;
      case "rules":
        return <CompetitionRules competition={competition} />;
      // case "mt5":
      //   return (
      //     <div>
      //       <h2> ترمینال MT5 </h2>
      //       <p> اینجا اطلاعات مربوط به ترمینال MT5 نمایش داده می‌شود. </p>
      //     </div>
      //   );
      default:
        return null;
    }
  };

  load(false); // پایان بارگذاری

  return (
    <div className="containerStyle2" data-aos="zoom-in">
      {/* نوار بالایی */}
      <div className="headerStyle2">
        <div className="tabsContainerStyle2">
          <button
            className={
              activeTab === "yourProfile" ? "activeTabStyle2" : "tabStyle2"
            }
            onClick={() => setActiveTab("yourProfile")}
          >
            پروفایل شما
          </button>
          <button
            className={
              activeTab === "tradeAnalytics" ? "activeTabStyle2" : "tabStyle2"
            }
            onClick={() => setActiveTab("tradeAnalytics")}
          >
            تحلیل معاملات
          </button>
          <button
            className={
              activeTab === "leaderboard" ? "activeTabStyle2" : "tabStyle2"
            }
            onClick={() => setActiveTab("leaderboard")}
          >
            جدول برترین‌ها
          </button>
          <button
            className={activeTab === "rules" ? "activeTabStyle2" : "tabStyle2"}
            onClick={() => setActiveTab("rules")}
          >
            قوانین
          </button>
          {/* <button
            style={activeTab === "mt5" ? activeTabStyle : tabStyle}
            onClick={() => setActiveTab("mt5")}
          >
            ترمینال MT5
          </button> */}
        </div>
      </div>
      {/* محتوای تب */}
      <div
        style={{
          display: "flex",
          alignItems: "left",
          justifyContent: "left",
          marginLeft: "2rem",
        }}
      >
        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "Arial, sans-serif",
          }}
        >
          <FaBackward
            onClick={() => togglelist()}
            style={{
              width: "40px",
              height: "40px",
              padding: "10px",
              backgroundColor: "white",
              color: "#556B2F",
              border: "2px solid #ffffff",
              borderRadius: "50%",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "all 0.3s ease",
              cursor: "pointer",
            }}
            className="icon-button"
          />
        </div>
        <style>
          {`
    .icon-button:hover {
      background-color: #ffffff;
      color: #8FBC8F;
      transform: scale(1.2);
      box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);
    }
  `}
        </style>
      </div>
      <div className="contentStyle">{renderTabContent(competition)}</div>
    </div>
  );
}

// استایل‌ها

export default CompetitionProfile;
