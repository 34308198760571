/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-useless-concat */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "./Acc.css";
import UserContext from "../UserContext/UserContext";
import { useContext, useRef, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Success from "../Success/Success";
import SucImg from "../../photoes/148767.png";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import validator from "validator";
import { TiTickOutline } from "react-icons/ti";
import { DateInput } from "react-hichestan-datetimepicker";
import moment from "moment-jalaali";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { IoIosCopy } from "react-icons/io";
import QRCode from "react-qr-code";
const Acc = () => {
  const { user, load, url } = useContext(UserContext);

  const filteredRecharges = user.dataes.wallet_recharges.filter(
    (w) => w.link === "Salary"
  );

  const uploadRef = useRef();
  const [show, setShow] = useState({ show: false, with: "" });
  const [taid, setTaid] = useState(0);
  const [taid1, setTaid1] = useState(0);
  const [google, setGoogle] = useState("");
  const [activ, setActive] = useState({ a1: "active", a2: "", a3: "", a4: "" });
  const activer = (x) => {
    if (x === 1) {
      setActive({ a1: "active", a2: "", a3: "", a4: "" });
    } else if (x === 2) {
      setActive({ a1: "", a2: "active", a3: "", a4: "" });
    } else if (x === 3) {
      setActive({ a1: "", a2: "", a3: "active", a4: "" });
    } else if (x === 4) {
      setActive({ a1: "", a2: "", a3: "", a4: "active" });
    }
  };
  const ref = useRef();

  const [name, setName] = useState(user.dataes.first_name);
  const [last_name, setLast] = useState(user.dataes.last_name);
  const [email, setEmail] = useState(user.dataes.email);
  const [national_code, setNat] = useState(user.dataes.id_number);
  const [birth_day, setBir] = useState(user.dataes.birth_date);
  const [number, setNum] = useState(user.dataes.phone_number);
  const [gender, setGender] = useState(user.dataes.gender);
  const [old_password, setOp] = useState("");
  const [new_password, setNp] = useState("");
  const [new_passwordT, setNpt] = useState("");
  const [googleToken, setGoogleTok] = useState("");
  const [wallet, setWallet] = useState(filteredRecharges[0]?.wallet_address);

  const dis = () => {
    if (user.dataes.authenticated === 0) {
      return false;
    } else {
      return true;
    }
  };
  const addresslar = () => {
    if (user.dataes.address === null) {
      return {
        postal_code: "",
        address: "",
        city: "",
        province: "",
        country: "",
      };
    } else {
      return user.dataes.address;
    }
  };
  const [ig, setIg] = useState(url + "storage/" + user.dataes.image);
  const [zip, setZip] = useState(addresslar().postal_code);
  const [address, setAdd] = useState(addresslar().address);
  const [city, setCity] = useState(addresslar().city);
  const [province, setPro] = useState(addresslar().province);
  const [country, setCont] = useState(addresslar().country);
  const [taidPlace, setPlace] = useState(
    "شماره همراه خود را وارد نمایید (الزامی)"
  );
  const [taidPlace1, setPlace1] = useState(
    "آدرس ایمیل خود را وارد نمایید (الزامی)"
  );
  const [saveNum, setSave] = useState("");
  const [saveEmail, setSaveEmail] = useState("");
  const [time, setTime] = useState(120);
  const [time1, setTime1] = useState(120);
  const [nickName, setNickName] = useState(user.dataes.nickname);
  const [g, setG] = useState(user.dataes.two_factor_enabled);
  const barrasiShow = (time, type) => {
    const count = () => {
      if (window.innerWidth < 1200) {
        return { size: 25, strokeWidth: 1 };
      } else {
        return { size: 35, strokeWidth: 3.5 };
      }
    };
    return (
      <div className="flex barr mf8">
        <CountdownCircleTimer
          isPlaying
          duration={time}
          colors={["#fff", "#003e23", "#f05c03", "#f00"]}
          colorsTime={[100, 55, 30, 0]}
          size={count().size}
          strokeWidth={count().strokeWidth}
          onComplete={() => {
            if (type === "email") {
              setTaid1(0);
              setEmail("");
              setTime1(120);
              setPlace1("آدرس ایمیل خود را وارد نمایید (الزامی)");
            } else {
              setPlace("شماره همراه خود را وارد نمایید (الزامی)");
              setTaid(0);
              setNum("");
              setTime(120);
            }
          }}
          children={({ remainingTime }) => {
            const minutes = Math.floor(remainingTime / 60);
            const seconds = remainingTime % 60;

            return `${minutes}:${seconds}`;
          }}
        ></CountdownCircleTimer>
        <div className="barrasi"> بررسی </div>
      </div>
    );
  };
  const taidBtn = () => {
    if (taid === 1 && taid1 !== 2) {
      return (
        <button
          onClick={() => {
            load(true);
            axios
              .post(
                url + "api/v1/panel/verify_phone/send",
                { phone: number },
                { headers: { Authorization: `bearer ${Cookies.get("token")}` } }
              )
              .then((res) => {
                setTime(res.data.time);
                setSave(number);
                load(false);
                NotificationManager.success(
                  "کد تایید به شماره همراه شما ارسال شد",
                  "کد تایید ارسال شد",
                  5000
                );
                setPlace("کد تایید را وارد نمایید");
                setNum("");
                setTaid(2);
                console.log(res.data);
              })
              .catch((err) => {
                load(false);
                if (
                  err.response.data.message === "Phone number already exists"
                ) {
                  NotificationManager.error(
                    " شماره همراه وارد شده قبلا ثبت شده  ",
                    "کد تایید ارسال نشد",
                    3000
                  );
                } else {
                  NotificationManager.error(
                    " مشکل در سرور مجدد تلاش نمایید ",
                    "کد تایید ارسال نشد",
                    3000
                  );
                }
                console.log(err);
              });
          }}
        >
          {" "}
          دریافت کد{" "}
        </button>
      );
    } else if (taid === 2) {
      return (
        <button
          onClick={() => {
            if (number !== "") {
              load(true);
              axios
                .post(
                  url + "api/v1/panel/verify_phone/verify",
                  { code: number, phone: saveNum },
                  {
                    headers: {
                      Authorization: `bearer ${Cookies.get("token")}`,
                    },
                  }
                )
                .then((res) => {
                  load(false);
                  NotificationManager.success(
                    "شماره همراه شما با موفقیت تایید شد",
                    "تایید انجام شد",
                    5000
                  );
                  setPlace(saveNum);
                  setNum(saveNum);
                  setTaid(3);
                  console.log(res.data);
                })
                .catch((err) => {
                  load(false);
                  NotificationManager.error(
                    " کد تایید وارد شده صحیح نمی باشد",
                    "کد تایید ارسال نشد",
                    3000
                  );
                  console.log(err);
                });
            } else {
              NotificationManager.warning(
                "کد تایید وارد نشده است",
                " انجام نشد",
                3000
              );
            }
          }}
        >
          {" "}
          {barrasiShow(time, "number")}
        </button>
      );
    } else if (taid === 3) {
      return (
        <button>
          {" "}
          <TiTickOutline fontSize="35px" />{" "}
        </button>
      );
    }
  };
  const taidBtn1 = () => {
    if (taid1 === 1 && taid !== 2) {
      return (
        <button
          onClick={() => {
            load(true);
            axios
              .post(
                url + "api/v1/panel/verify_email/send",
                { email: email },
                { headers: { Authorization: `bearer ${Cookies.get("token")}` } }
              )
              .then((res) => {
                setTime1(res.data.time);
                setSaveEmail(email);
                load(false);
                NotificationManager.success(
                  "کد تایید به ایمیل شما ارسال شد",
                  "کد تایید ارسال شد",
                  5000
                );
                setPlace1("کد تایید را وارد نمایید");
                setEmail("");
                setTaid1(2);
              })
              .catch((err) => {
                load(false);
                if (err.response.data.message === "Email already exists") {
                  NotificationManager.error(
                    " ایمیل وارد شده قبلا ثبت شده  ",
                    "کد تایید ارسال نشد",
                    3000
                  );
                } else {
                  NotificationManager.error(
                    " مشکل در سرور مجدد تلاش نمایید ",
                    "کد تایید ارسال نشد",
                    3000
                  );
                }

                console.log(err);
              });
          }}
        >
          {" "}
          دریافت کد{" "}
        </button>
      );
    } else if (taid1 === 2) {
      return (
        <button
          onClick={() => {
            if (email !== "") {
              load(true);
              axios
                .post(
                  url + "api/v1/panel/verify_email/verify",
                  { code: email, email: saveEmail },
                  {
                    headers: {
                      Authorization: `bearer ${Cookies.get("token")}`,
                    },
                  }
                )
                .then((res) => {
                  load(false);
                  NotificationManager.success(
                    "ایمیل شما با موفقیت تایید شد",
                    "تایید ایمیل انجام شد",
                    5000
                  );
                  setPlace1(saveEmail);
                  setEmail(saveEmail);
                  setTaid1(3);
                })
                .catch((err) => {
                  load(false);
                  NotificationManager.error(
                    " کد تایید وارد شده صحیح نمی باشد",
                    "تایید ایمیل انجام نشد",
                    3000
                  );
                  console.log(err);
                });
            } else {
              NotificationManager.warning(
                "کد تایید وارد نشده است",
                " انجام نشد",
                3000
              );
            }
          }}
        >
          {" "}
          {barrasiShow(time1, "email")}
        </button>
      );
    } else if (taid1 === 3) {
      return (
        <button>
          {" "}
          <TiTickOutline fontSize="35px" />{" "}
        </button>
      );
    }
  };
  const mainCon = () => {
    if (activ.a1 === "active") {
      return (
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="row">
            <div className="col-xl-4 pl0">
              <div className="card mb-4 mb-xl-0">
                <div className="card-header"> تصویر پروفایل </div>
                {/* <div className="card-body text-center">
                  <img
                    className="img-account-profile rounded-circle mb-2"
                    src={ig}
                    alt=""
                  />
                  <div className="small font-italic text-muted mb-4">
                    {" "}
                    فرمت فایل ها JPG یا PNG باشد و کمتر از 5 مگابایت{" "}
                  </div>
                  <input
                    name="image"
                    onChange={(e) => {
                      setIg(URL.createObjectURL(e.currentTarget.files[0]));
                    }}
                    multiple={false}
                    ref={uploadRef}
                    type="file"
                    hidden
                  />
                  <button
                    onClick={() => uploadRef.current.click()}
                    className="btn btn-primary"
                    type="button"
                  >
                    {" "}
                    آپلود تصویر{" "}
                  </button>
                  {dis() ? <button className="btn btn-primary" style={{marginTop:'1rem'}}>به روز رسانی تصویر</button> : null}
                  
                </div> */}
                <div className="card-body text-center">
                  <img
                    className="img-account-profile rounded-circle mb-2"
                    src={ig}
                    alt=""
                  />
                  <div className="small font-italic text-muted mb-4">
                    فرمت فایل ها JPG یا PNG باشد و کمتر از 5 مگابایت
                  </div>
                  <input
                    name="image"
                    onChange={(e) => {
                      setIg(URL.createObjectURL(e.currentTarget.files[0]));
                    }}
                    multiple={false}
                    ref={uploadRef}
                    type="file"
                    hidden
                  />
                  <button
                    onClick={() => uploadRef.current.click()}
                    className="btn btn-primary"
                    type="button"
                  >
                    آپلود تصویر
                  </button>
                  {dis() ? (
                    <button
                      className="btn btn-primary"
                      style={{ marginTop: "1rem" }}
                      onClick={async () => {
                        try {
                          const imgData = { image: uploadRef.current.files[0] }; // ایجاد فرم دیتا
                          // افزودن فایل

                          const response = await axios.post(
                            url + "api/v1/panel/edit/auth",
                            imgData,
                            {
                              headers: {
                                Authorization: `Bearer ${Cookies.get("token")}`,
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          );

                          console.log("Response data:", response.data);
                          alert("تصویر با موفقیت به روز رسانی شد");
                        } catch (error) {
                          console.error("خطا در به روز رسانی تصویر:", error);
                          alert("خطایی رخ داد");
                        }
                      }}
                    >
                      به روز رسانی تصویر
                    </button>
                  ) : null}
                </div>
              </div>
              {user.dataes.roles[0].name === "user" && (
                <div
                  style={{ justifyContent: "start" }}
                  className="card mb-4 mb-xl-0"
                >
                  <div className="card-header"> کد های تخفیف شما </div>
                  <div className="mob p10 bb">
                    {" "}
                    *برای کپی کردن کد تخفیف روی کد کلیک نمایید{" "}
                  </div>
                  <div className="authUl mob">
                    {user.dataes.my_coupons.map((d) => {
                      return (
                        <div key={d.id} className="yesOrNo">
                          <div>
                            {" "}
                            {d.percent +
                              " درصد" +
                              " تا  " +
                              moment(d.expire_date).format("jYYYY/jM/jD")}{" "}
                          </div>
                          <div
                            onClick={() => {
                              navigator.clipboard.writeText(d.code);
                              NotificationManager.success("کپی شد");
                            }}
                            className="green taxf"
                          >
                            {d.code} <span></span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>

            <div className="col-xl-8 pr0">
              <div className="card mb-4">
                <div className="card-header"> جزئیات اکانت </div>
                <div className="card-body">
                  <div className="mb-3">
                    <label className="small mb-1" for="inputUsername">
                      {" "}
                      نام کاربری :{" "}
                    </label>
                    <input
                      name="username"
                      value={user.dataes.username}
                      disabled
                      className="form-control"
                      id="inputUsername"
                      type="text"
                      placeholder={
                        user.dataes.username + " " + "(غیر قابل تغییر)"
                      }
                    />
                  </div>

                  <div className="row gx-3 mb-3">
                    <div className="col-md-6 ">
                      <label className="small mb-1" for="inputFirstName">
                        {" "}
                        نام :{" "}
                      </label>
                      <input
                        value={name}
                        onChange={(e) => {
                          setName(e.currentTarget.value);
                        }}
                        disabled={dis()}
                        name="name"
                        className="form-control"
                        id="inputFirstName"
                        type="text"
                        placeholder="نام خود را وارد نمایید (الزامی)"
                      />
                    </div>

                    <div className="col-md-6">
                      <label className="small mb-1" for="inputLastName">
                        {" "}
                        نام خانوادگی :{" "}
                      </label>
                      <input
                        value={last_name}
                        onChange={(e) => {
                          setLast(e.currentTarget.value);
                        }}
                        disabled={dis()}
                        name="last_name"
                        className="form-control"
                        id="inputLastName"
                        type="text"
                        placeholder="نام خانوادگی خود را وارد نمایید (الزامی)"
                      />
                    </div>
                  </div>

                  {user.dataes.roles[0].name !== "user" && (
                    <div className="row gx-3 mb-3">
                      <div className="col-md-6">
                        <label className="small mb-1" for="wallet">
                          {" "}
                          آدرس ولت TRC2 :{" "}
                        </label>
                        <input
                          onChange={(e) => setWallet(e.target.value)}
                          value={wallet}
                          name="wallet"
                          style={{ height: "auto" }}
                          className="form-control"
                          id="wallet"
                          type="text"
                          placeholder="آدرس ولت TRC20 خود را وارد کنید"
                        />
                      </div>

                      <div className="col-md-6">
                        <label for="nickNameInput" className="small mb-1">
                          نام مستعار :
                        </label>
                        <input
                          type="text"
                          placeholder="نام مستعار خود را وارد کنید ( اختیاری )"
                          id="nickNameInput"
                          className="form-control"
                          value={nickName}
                          onChange={(e) => {
                            setNickName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {user.dataes.roles[0].name === "user" && (
                    <>
                      <div className="row gx-3 mb-3">
                        <div className="col-md-6">
                          <label className="small mb-1" for="inputOrgName">
                            {" "}
                            کد ملی :{" "}
                          </label>
                          <input
                            value={national_code}
                            onChange={(e) => {
                              setNat(e.currentTarget.value);
                              if (isNaN(+e.currentTarget.value)) {
                                setNat("");
                              }
                            }}
                            disabled={dis()}
                            name="national_code"
                            className="form-control"
                            id="inputOrgName"
                            type="text"
                            placeholder="کد ملی خود را وارد نمایید (الزامی)"
                          />
                        </div>

                        <div className="col-md-6">
                          <label for="nickNameInput" className="small mb-1">
                            نام مستعار :
                          </label>
                          <div className="inputWithButton">
                            <input
                              type="text"
                              placeholder="نام مستعار خود را وارد کنید ( اختیاری )"
                              id="nickNameInput"
                              className="form-control"
                              value={nickName}
                              onChange={(e) => {
                                setNickName(e.target.value);
                              }}
                            />
                            {
                              dis() ? (
                                <button
                                  onClick={async () => {
                                    try {
                                      const data5 = { nickName: nickName }; // داده‌هایی که باید ارسال شود

                                      const response = await axios.post(
                                        url + "api/v1/panel/edit/auth",
                                        data5,
                                        {
                                          headers: {
                                            Authorization: `Bearer ${Cookies.get(
                                              "token"
                                            )}`,
                                            "Content-Type":
                                              "multipart/form-data",
                                          },
                                        }
                                      );

                                      console.log(
                                        "Response data:",
                                        response.data
                                      );
                                      alert("عملیات با موفقیت انجام شد");
                                    } catch (error) {
                                      console.error("خطا در عملیات:", error);
                                      alert("خطایی رخ داد");
                                    }
                                  }}
                                >
                                  به‌روزرسانی
                                </button>
                              ) : null // یا هر چیزی که در شاخه‌ی else می‌خواهید
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row gx-3 mb-3">
                        <div className="col-md-6">
                          <label className="small mb-1" for="inputEmailAddress">
                            {" "}
                            آدرس ایمیل :{" "}
                          </label>
                          <div class="inputWithButton">
                            <input
                              value={email}
                              onChange={(e) => {
                                setEmail(e.currentTarget.value);
                                if (taid1 !== 2) {
                                  if (
                                    validator.isEmail(e.currentTarget.value)
                                  ) {
                                    setTaid1(1);
                                  } else {
                                    setTaid1(0);
                                  }
                                }
                              }}
                              disabled={
                                taid1 === 3 ||
                                user.dataes.confirmed_authentications.find(
                                  (e) => e.type === "Email_auth"
                                ) ||
                                taid === 2
                                  ? true
                                  : false
                              }
                              name="email"
                              className="form-control"
                              id="inputEmailAddress"
                              type="text"
                              placeholder={taidPlace1}
                            />
                            {taidBtn1()}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <label className="small mb-1" for="inputLocation">
                            {" "}
                            جنسیت :{" "}
                          </label>
                          <select
                            ref={ref}
                            value={gender}
                            onChange={(e) => {
                              setGender(e.currentTarget.value);
                            }}
                            disabled={dis()}
                            name="gender"
                            className="form-control"
                            id="inputLocation"
                            type="select"
                            placeholder={user.dataes.gender}
                          >
                            <option value="">
                              {" "}
                              جنسیت خود را انتخاب نمایید{" "}
                            </option>
                            <option value="male"> مرد </option>
                            <option value="female"> زن </option>
                          </select>
                        </div>
                      </div>

                      <div className="row gx-3 mb-3">
                        <div className="col-md-6">
                          <label className="small mb-1" for="inputPhone">
                            {" "}
                            شماره تلفن همراه :{" "}
                          </label>
                          <div class="inputWithButton">
                            <input
                              value={number}
                              onChange={(e) => {
                                setNum(e.currentTarget.value);
                                if (isNaN(+e.currentTarget.value)) {
                                  setNum("");
                                }
                                if (taid !== 2) {
                                  if (
                                    validator.isMobilePhone(
                                      e.currentTarget.value,
                                      ["fa-IR"]
                                    )
                                  ) {
                                    setTaid(1);
                                  } else {
                                    setTaid(0);
                                  }
                                }
                              }}
                              disabled={
                                taid === 3 ||
                                user.dataes.confirmed_authentications.find(
                                  (e) => e.type === "Phone_auth"
                                ) ||
                                taid1 === 2
                                  ? true
                                  : false
                              }
                              name="number"
                              className="form-control"
                              id="inputPhone"
                              type="text"
                              placeholder={taidPlace}
                            />
                            {taidBtn()}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <label className="small mb-1" for="inputBirthday">
                            {" "}
                            تاریخ تولد :{" "}
                          </label>
                          <DateInput
                            value={birth_day}
                            onChange={(e) => {
                              setBir(e.target.value.split("T")[0]);
                            }}
                            disabled
                            name="birth_day"
                            className="form-control"
                            id="inputBirthday"
                            placeholder="انتخاب از تقویم"
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <button
                    disabled={dis()}
                    onClick={() => {
                      if (user.dataes.roles[0].name === "user") {
                        let data1 = {
                          name: name,
                          last_name: last_name,
                          national_code: national_code,
                          gender: ref.current.value,
                          email: email,
                          number: number,
                          birth_day: birth_day,
                          image: uploadRef.current.files[0],
                          nickName: nickName,
                        };
                        if (
                          name === "" ||
                          last_name === "" ||
                          national_code === "" ||
                          gender === "" ||
                          email === "" ||
                          number === "" ||
                          birth_day === "" ||
                          name === null ||
                          last_name === null ||
                          national_code === null ||
                          gender === null ||
                          email === null ||
                          number === null ||
                          birth_day === null
                        ) {
                          NotificationManager.error(
                            "تمام فیلد های الزامی را پر نمایید",
                            "اطلاعات ثبت نشد",
                            5000
                          );
                        } else {
                          if (
                            (taid === 3 && taid1 === 3) ||
                            (user.dataes.email !== null &&
                              user.dataes.number !== null) ||
                            (user.dataes.email !== null && taid === 3) ||
                            (taid1 === 3 && user.dataes.number !== null)
                          ) {
                            console.log("my data ", data1);
                            load(true);
                            axios
                              .post(url + "api/v1/panel/edit", data1, {
                                headers: {
                                  Authorization: `bearer ${Cookies.get(
                                    "token"
                                  )}`,
                                  "Content-Type": "multipart/form-data",
                                },
                              })
                              .then(() => {
                                load(false);
                                setShow({ show: true, with: "acc" });
                              })
                              .catch((err) => {
                                load(false);
                                NotificationManager.error(
                                  "بروزرسانی انجام نشد دقایقی دیگر دوباره امتحان نمایید",
                                  "بروزرسانی انجام نشد",
                                  3000
                                );
                                console.log(err);
                              });
                          } else {
                            if (taid !== 3) {
                              NotificationManager.error(
                                "احراز هویت شماره همراه انجام نشده",
                                "تغییرات ذخیره نشد",
                                5000
                              );
                            }
                            if (taid1 !== 3) {
                              NotificationManager.error(
                                "احراز هویت ایمیل انجام نشده",
                                "تغییرات ذخیره نشد",
                                5000
                              );
                            }
                          }
                        }
                      } else {
                        let data = {
                          name: name,
                          last_name: last_name,
                          image: uploadRef.current.files[0],
                          WalletAddress: wallet,
                          nickName: nickName,
                        };
                        console.log("mydata", data);

                        load(true);
                        axios
                          .post(url + "api/v1/panel/edit", data, {
                            headers: {
                              Authorization: `bearer ${Cookies.get("token")}`,
                              "Content-Type": "multipart/form-data",
                            },
                          })
                          .then(() => {
                            load(false);
                            setShow({ show: true, with: "acc" });
                          })
                          .catch((err) => {
                            load(false);
                            NotificationManager.error(
                              "بروزرسانی انجام نشد دقایقی دیگر دوباره امتحان نمایید",
                              "بروزرسانی انجام نشد",
                              3000
                            );
                            console.log(err);
                          });
                      }
                    }}
                    className="btn btn-primary"
                    type="submit"
                  >
                    {" "}
                    ذخیره تغییرات{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      );
    } else if (activ.a2) {
      return (
        <>
          <div className="mm">
            <div className="card mb-4">
              <div className="card-header"> تنظیمات آدرس </div>
              <div className="card-body">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="row gx-3 mb-3">
                    <div className="col-md-6">
                      <label className="small mb-1" for="inputFirstName">
                        {" "}
                        کشور :{" "}
                      </label>
                      <input
                        onChange={(e) => {
                          setCont(e.target.value);
                        }}
                        value={country}
                        name="country"
                        className="form-control"
                        id="inputFirstName"
                        type="text"
                        placeholder=" نام کشور محل اقامت خود را وارد نمایید (الزامی) "
                      />
                    </div>

                    <div className="col-md-6">
                      <label className="small mb-1" for="inputLastName">
                        {" "}
                        استان :{" "}
                      </label>
                      <input
                        onChange={(e) => {
                          setPro(e.target.value);
                        }}
                        value={province}
                        name="state"
                        className="form-control"
                        id="inputLastName"
                        type="text"
                        placeholder=" نام استان محل اقامت خود را وارد نمایید (الزامی) "
                      />
                    </div>
                  </div>

                  <div className="row gx-3 mb-3">
                    <div className="col-md-6">
                      <label className="small mb-1" for="inputOrgName">
                        {" "}
                        شهر :{" "}
                      </label>
                      <input
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                        value={city}
                        name="city"
                        className="form-control"
                        id="inputOrgName"
                        type="text"
                        placeholder="  شهر محل اقامت خود را وارد نمایید (الزامی)"
                      />
                    </div>

                    <div className="col-md-6">
                      <label className="small mb-1" for="inputLocation">
                        {" "}
                        کد پستی :{" "}
                      </label>
                      <input
                        onChange={(e) => {
                          setZip(e.target.value);
                        }}
                        value={zip}
                        name="zip"
                        className="form-control"
                        id="inputLocation"
                        type="text"
                        placeholder="  کد پستی محل اقامت خود را وارد نمایید (اختیاری)"
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="small mb-1" for="inputEmailAddress">
                      {" "}
                      آدرس کامل محل اقامت :{" "}
                    </label>
                    <textarea
                      onChange={(e) => {
                        setAdd(e.target.value);
                      }}
                      value={address}
                      name="address"
                      style={{ height: "120px" }}
                      className="form-control"
                      id="inputEmailAddress"
                      type="email"
                      placeholder=" آدرس دقیق محل اقامت خود را به همراه پلاک وارد نمایید (الزامی) "
                    />
                  </div>

                  <button
                    onClick={() => {
                      let data2 = {
                        country: country,
                        state: province,
                        zip: zip,
                        city: city,
                        address: address,
                      };
                      if (
                        country === "" ||
                        province === "" ||
                        city === "" ||
                        address === ""
                      ) {
                        NotificationManager.error(
                          "تمام فیلد های الزامی را پر نمایید",
                          "اطلاعات ثبت نشد",
                          5000
                        );
                      } else {
                        load(true);
                        axios
                          .post(url + "api/v1/panel/edit/address", data2, {
                            headers: {
                              Authorization: `bearer ${Cookies.get("token")}`,
                            },
                          })
                          .then((res) => {
                            load(false);
                            console.log(res);
                            setShow({ show: true, with: "add" });
                          })
                          .catch((err) => {
                            load(false);
                            NotificationManager.error(
                              "بروزرسانی انجام نشد دقایقی دیگر دوباره امتحان نمایید",
                              "بروزرسانی انجام نشد",
                              3000
                            );
                          });
                      }
                    }}
                    className="btn btn-primary"
                    type="submit"
                  >
                    {" "}
                    ذخیره تغییرات{" "}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </>
      );
    } else if (activ.a3) {
      return (
        <>
          <div className="mm">
            <div className="card mb-4">
              <div className="card-header"> تغییر رمز عبور </div>
              <div className="card-body">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="col gx-3 mb-3">
                    <div className="col-md-6">
                      <label className="small mb-1" for="inputFirstName">
                        {" "}
                        گذرواژه فعلی :{" "}
                      </label>
                      <input
                        onChange={(e) => {
                          setOp(e.target.value);
                        }}
                        value={old_password}
                        name="old_password"
                        className="form-control"
                        id="inputFirstName"
                        type="password"
                        placeholder=" گذرواژه فعلی را وارد نمایید "
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="small mb-1" for="inputLastName">
                        {" "}
                        گذرواژه جدید :{" "}
                      </label>
                      <input
                        onChange={(e) => {
                          setNp(e.target.value);
                        }}
                        value={new_password}
                        name="new_password"
                        className="form-control"
                        id="inputLastName"
                        type="password"
                        placeholder=" گذرواژه جدید را وارد نمایید "
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="small mb-1" for="inputLastName">
                        {" "}
                        تکرار گذر واژه :{" "}
                      </label>
                      <input
                        onChange={(e) => {
                          setNpt(e.target.value);
                        }}
                        value={new_passwordT}
                        name="new_passwordT"
                        className="form-control"
                        id="inputLastName"
                        type="password"
                        placeholder=" گذرواژه جدید را وارد نمایید "
                      />
                    </div>
                    <div className="col-md-6">
                      <button
                        onClick={() => {
                          let p = new_password.length;
                          let data3 = {
                            old_password: old_password,
                            new_password: new_password,
                            new_passwordT: new_passwordT,
                          };
                          console.log(p);
                          if (
                            old_password === "" ||
                            new_password === "" ||
                            new_passwordT === ""
                          ) {
                            NotificationManager.error(
                              "گذرواژه وارد نشده",
                              "نا موفق",
                              4000
                            );
                          } else if (new_password !== new_passwordT) {
                            NotificationManager.error(
                              "گذرواژه جدید و تکرار آن برابر نیست ",
                              "اخطار",
                              4000
                            );
                          } else if (p < 8) {
                            NotificationManager.error(
                              " تعداد گذرواژه باید بیش تر از 8 حرف باشد ",
                              "اخطار",
                              4000
                            );
                          } else {
                            load(true);
                            axios
                              .post(url + "api/v1/auth/reset_password", data3, {
                                headers: {
                                  Authorization: `bearer ${Cookies.get(
                                    "token"
                                  )}`,
                                },
                              })
                              .then((res) => {
                                load(false);
                                console.log(res);
                                setShow({ show: true, with: "pass" });
                              })
                              .catch((err) => {
                                load(false);
                                if (err.response.status === 401) {
                                  NotificationManager.error(
                                    " گذرواژه فعلی اشتباه می باشد ",
                                    "تغییر رمز عبور انجام نشد"
                                  );
                                }
                              });
                          }
                        }}
                        className="btn btn-primary mt"
                        type="submit"
                      >
                        {" "}
                        ذخیره تغییرات{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      );
    } else if (activ.a4) {
      if (g) {
        return (
          <>
            <div className="mm">
              <div className="card mb-6">
                <div className="card-header"> تنظیمات Google2Fa </div>
                <div className="card-body">
                  <div className=" gx-6 mb-6 ">
                    <button
                      style={{ margin: "10px 0px", width: "100%" }}
                      onClick={() => {
                        load(true);
                        axios
                          .get(url + "api/v1/auth/2fa/disable", {
                            headers: {
                              Authorization: `bearer ${Cookies.get("token")}`,
                            },
                          })
                          .then((res) => {
                            load(false);
                            console.log(res.data);
                            setG(false);
                            NotificationManager.success(
                              "Google2Fa با موفقیت غیر فعال شد"
                            );
                          })
                          .catch((err) => {
                            load(false);
                            NotificationManager.error(
                              "Google2Fa  غیر فعال نشد"
                            );
                            console.log(err);
                          });
                      }}
                      className="btn btn-primary "
                    >
                      {" "}
                      غیر فعال‌سازی Google2Fa{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="mm">
              <div className="card mb-6">
                <div className="card-header"> تنظیمات Google2Fa </div>
                <div className="card-body">
                  <div> راهنمای مرحله به مرحله فعال‌سازی Google2Fa : </div>

                  <ul className="f12" style={{ marginRight: "10px" }}>
                    <li className="f12">
                      برای فعال‌سازی Google2Fa باید اپلیکیشن مربوطه را دانلود
                      نمایید
                      <a
                        style={{ color: "greenyellow", marginRight: "5px" }}
                        target="_blank"
                        href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DiOS&oco=0"
                      >
                        (جهت دانلود کلیک نمایید){" "}
                      </a>
                    </li>
                    <li> ابتدا برنامه Google2fa را نصب نمایید </li>
                    <li> دکمه " فعال سازی Google2Fa " را بزنید </li>
                    <li>
                      {" "}
                      در اپلیکیشن Google2fa ، در قسمت پایین و سمت راست آیکون +
                      را انتخاب کرده سپس از طریق Qr code موجود یا Secret key
                      اقدام به ثبت شناسه خود نمایید{" "}
                    </li>
                    <li>
                      {" "}
                      کد تایید ایجاد شده در اپلیکیشن را در کادر
                      <span style={{ color: "greenyellow" }}>
                        {" "}
                        " کد تایید Google2Fa "{" "}
                      </span>
                      وارد نمایید ، و سپس تایید کد را بزنید{" "}
                    </li>
                    <li>
                      {" "}
                      فعال سازی Google2Fa انجام شده و از این پس در قسمت ورود
                      امکان ورود از طریق Google2Fa برایتان فعال می باشد{" "}
                    </li>
                  </ul>
                  {google !== "" ? (
                    <>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <div style={{ width: "50%" }} className="varizCon">
                          <div className="add"> QR-Code : </div>
                          <div className="backQr">
                            <QRCode
                              className="qrcode"
                              style={{
                                width: "70%",
                                height: "70%",
                                padding: "20px",
                              }}
                              value={google.qrCodeUrl}
                            />
                          </div>
                        </div>

                        <div style={{ width: "50%" }} className="varizCon">
                          <div className="add"> کد Secret : </div>
                          <div className="wallet">
                            {" "}
                            {google.secret}{" "}
                            <IoIosCopy
                              onClick={() => {
                                navigator.clipboard.writeText(google.secret);
                                NotificationManager.success(
                                  "آدرس ولت کپی شد",
                                  "",
                                  3000
                                );
                              }}
                            />{" "}
                          </div>
                          <div className=" gx-6 mb-6 ">
                            <input
                              value={googleToken}
                              onChange={(e) => {
                                setGoogleTok(e.target.value);
                              }}
                              className="inputGo mt"
                              placeholder="کد تایید Google2Fa"
                            />
                            <button
                              style={{ margin: "10px 0px", width: "100%" }}
                              onClick={() => {
                                if (googleToken !== "") {
                                  load(true);
                                  axios
                                    .post(
                                      url + "api/v1/auth/2fa/verify",
                                      { token: googleToken },
                                      {
                                        headers: {
                                          Authorization: `bearer ${Cookies.get(
                                            "token"
                                          )}`,
                                        },
                                      }
                                    )
                                    .then((res) => {
                                      load(false);
                                      setG(true);
                                      console.log(res.data);
                                      NotificationManager.success(
                                        "Google2Fa با موفقیت برای شما فعال شد"
                                      );
                                    })
                                    .catch((err) => {
                                      load(false);
                                      console.log(err);
                                      NotificationManager.error(
                                        "کد وارد شده صحیح نمی باشد"
                                      );
                                    });
                                } else {
                                  NotificationManager.warning(
                                    "کد تایید خالی می باشد"
                                  );
                                }
                              }}
                              className="btn btn-primary "
                            >
                              {" "}
                              تایید کد Google2Fa{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className=" gx-6 mb-6 ">
                      <button
                        style={{ margin: "10px 0px", width: "100%" }}
                        onClick={() => {
                          load(true);
                          axios
                            .get(url + "api/v1/auth/2fa/enable", {
                              headers: {
                                Authorization: `bearer ${Cookies.get("token")}`,
                              },
                            })
                            .then((res) => {
                              load(false);
                              console.log(res.data);
                              setGoogle(res.data);
                            })
                            .catch((err) => {
                              load(false);
                              console.log(err);
                            });
                        }}
                        className="btn btn-primary "
                      >
                        {" "}
                        فعال‌سازی Google2Fa{" "}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      }
    }
  };
  const withModal = () => {
    if (show.with === "acc") {
      return (
        <div style={{ padding: "10px" }}>
          <div>
            <img className="sucImg" src={SucImg} alt="" />
            <div>اطلاعات اکانت شما با موفقیت بروزرسانی شد</div>
            <button
              onClick={() => {
                window.location.reload(false);
              }}
              style={{ marginTop: "20px" }}
              className="btn btn-success"
            >
              تایید بروز رسانی
            </button>
          </div>
        </div>
      );
    } else if (show.with === "add") {
      return (
        <div style={{ padding: "10px" }}>
          <div>
            <img className="sucImg" src={SucImg} alt="" />
            <div>اطلاعات آدرس شما با موفقیت بروزرسانی شد</div>
            <button
              onClick={() => {
                window.location.reload(false);
              }}
              style={{ marginTop: "20px" }}
              className="btn btn-success"
            >
              تایید بروز رسانی
            </button>
          </div>
        </div>
      );
    } else if (show.with === "pass") {
      return (
        <div style={{ padding: "10px" }}>
          <div>
            <img className="sucImg" src={SucImg} alt="" />
            <div>رمز عبور اکانت شما با موفقیت بروزرسانی شد</div>
            <button
              onClick={() => {
                window.location.reload(false);
              }}
              style={{ marginTop: "20px" }}
              className="btn btn-success"
            >
              تایید بروز رسانی
            </button>
          </div>
        </div>
      );
    }
  };
  return (
    <div>
      <NotificationContainer />
      <Success
        show={show.show}
        closeModal={() => {
          setShow({ show: false });
        }}
      >
        {withModal()}
      </Success>
      <div className="app-header-navigation">
        <div className="tabs">
          <a
            onClick={() => {
              activer(1);
            }}
            className={activ.a1}
          >
            اطلاعات شخصی
          </a>
          {user.dataes.roles[0].name === "user" && (
            <a
              onClick={() => {
                activer(2);
              }}
              className={activ.a2}
            >
              آدرس
            </a>
          )}
          <a
            onClick={() => {
              activer(3);
            }}
            className={activ.a3}
          >
            تغییر رمز عبور
          </a>
          <a
            onClick={() => {
              activer(4);
            }}
            className={activ.a4}
          >
            Google2FA
          </a>
        </div>
      </div>
      {mainCon()}
    </div>
  );
};

export default Acc;
