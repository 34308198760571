import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeTab: "my", // Default tab  userLocation: "",
  activeChatId: null,
  historyActiveChatId: null,
  searchQuery: "",
  userSearchedChats: [],
  supportIdForChats: null,
  startDateSupportChatHistory: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)
    .toISOString()
    .split("T")[0], // 7 days ago, formatted as YYYY-MM-DD
  endDateSupportChatHistory: new Date().toISOString().split("T")[0], // Today, formatted as YYYY-MM-DD
  pageOfPaginate: 1,
};

const messagesSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    myChatsTab: (state) => {
      state.activeTab = "my";
      state.activeChatId = null;
    },
    otherChatsTab: (state) => {
      state.activeTab = "other";
      state.activeChatId = null;
    },
    pendingChatsTab: (state) => {
      state.activeTab = "pending";
      state.activeChatId = null;
    },
    allTab: (state) => {
      state.activeTab = "all";
      state.activeChatId = null;
    },
    exploreTab: (state) => {
      state.activeTab = "explore";
      state.activeChatId = null;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setActiveChatId: (state, action) => {
      state.activeChatId = action.payload;
    },
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    updateUserSearchedChats: (state, action) => {
      state.userSearchedChats = action.payload;
    },

    setSupportIdForChats: (state, action) => {
      state.supportIdForChats = action.payload;
    },

    setChatCounts: (state, action) => {
      state.chatCounts = action.payload;
    },
    updateUserLocation: (state, action) => {
      state.userLocation = action.payload;
    },
    updateHistoryActiveChatId: (state, action) => {
      state.historyActiveChatId = action.payload;
    },

    setStartDateSupportChatHistory: (state, action) => {
      state.startDateSupportChatHistory = action.payload;
    },
    setEndDateSupportChatHistory: (state, action) => {
      state.endDateSupportChatHistory = action.payload;
    },
    setPageOfChatPaginate: (state, action) => {
      state.pageOfPaginate = action.payload;
    },
  },
});

export const {
  myChatsTab,
  otherChatsTab,
  pendingChatsTab,
  allTab,
  exploreTab,
  setActiveTab,
  setActiveChatId,
  updateUserLocation,
  updateHistoryActiveChatId,
  updateUserSearchedChats,
  setSearchQuery,
  setSupportIdForChats,
  setStartDateSupportChatHistory,
  setEndDateSupportChatHistory,
  setPageOfChatPaginate,
  setActiveChats,
  setChatCounts,
} = messagesSlice.actions;

export default messagesSlice.reducer;
