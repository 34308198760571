import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subCatName: "",
  question: "",
  answer: "",
  isImportant: false,
  formIsShown: false,
  formName: "",
  isEditingQuestion: false,
  editQuestionId: null,
  isEditingCategory: false,
  editCategoryId: null,
};

const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    clearInputs: (state) => {
      state.question = "";
      state.answer = "";
      state.isImportant = false;
      state.isEditingQuestion = false;
      state.editQuestionId = null;
      state.showForm = false;
      state.formName = "";
      state.isEditingCategory = false;
      state.editCategoryId = null;
      state.subCatName = "";
    },
    updateQuestionAll: (state, action) => {
      state.question = action.payload.question;
      state.answer = action.payload.answer;
      state.isImportant = action.payload.isImportant;
    },
    readyInputsToEdit: (state, action) => {
      state.formIsShown = true;
      state.formName = "question";
      state.question = action.payload.question;
      state.answer = action.payload.answer;
      state.isImportant = action.payload.isImportant;
      state.isEditingQuestion = true;
      state.editQuestionId = action.payload.id;
    },
    readyInputsToEditCat: (state, action) => {
      state.formIsShown = true;
      state.formName = "subCat";
      state.isEditingCategory = true;
      state.editCategoryId = action.payload.editCategoryId;
      state.subCatName = action.payload.subCatName;
    },

    updateSubCategory: (state, action) => {
      state.subCatName = action.payload;
    },
    updateQuestion: (state, action) => {
      state.question = action.payload;
    },
    updateIsImportant: (state, action) => {
      state.isImportant = action.payload;
    },
    updateAnswer: (state, action) => {
      state.answer = action.payload;
    },
    showForm: (state, action) => {
      state.formIsShown = true;
      state.formName = action.payload;
    },
    hideForm: (state) => {
      state.formIsShown = false;
      state.formName = "";
    },
  },
});

export const {
  clearInputs,
  updateQuestion,
  updateIsImportant,
  updateAnswer,
  updateSubCategory,
  showForm,
  hideForm,
  readyInputsToEdit,
  readyInputsToEditCat,
} = faqSlice.actions;

export default faqSlice.reducer;
