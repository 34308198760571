import { useQuery } from "@tanstack/react-query";
import useToken from "./hooks/useToken";
import { getSupportChatsPaginate } from "../../../services/apiMessages";
import { useSelector } from "react-redux";

export function useSpecificSupportChats({ supportId }) {
  const {
    startDateSupportChatHistory: from,
    endDateSupportChatHistory: to,
    pageOfPaginate,
  } = useSelector((state) => state.message);
  const token = useToken();
  const { isLoading, data, error } = useQuery({
    queryKey: ["support-chats-paginate", supportId, from, to, pageOfPaginate],
    queryFn: () =>
      getSupportChatsPaginate({ token, supportId, from, to, pageOfPaginate }),
  });

  return { isLoading, data, error };
}
