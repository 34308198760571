import { useQuery } from "@tanstack/react-query";

import useToken from "./hooks/useToken";
import { request } from "../../../utils/helper";
import { MAIN_URL } from "../../../utils/constants";

async function getResponses(token) {
  try {
    const data = await request({
      url: `${MAIN_URL}/admin/quick`,
      // url: `http://192.168.8.208:3006/quicks`,
      token: token,
    });

    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export function useResponses() {
  const token = useToken();
  const { isLoading, data, error } = useQuery({
    queryKey: ["responses"],
    queryFn: () => getResponses(token),
    staleTime: 300000, // 5 minutes in milliseconds
  });

  return { isLoading, data, error };
}
