import styled from "styled-components";

const LeftSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;

  & .btn-chat-menu {
    position: absolute;
    top: 2.2rem;
    left: 0%;
    background: var(--color-brand-600);
    outline: none;
    border: 1px solid var(--color-green-800);
    padding: 0.4rem;
    border-radius: 10px;
    cursor: pointer;
    z-index: 500;
    display: none;
  }

  & .container-me {
    display: grid;
    /* grid-template-columns: ${(props) => props.$leftWidth}fr 5px ${(props) =>
      props.$rightWidth}fr; */
    grid-template-columns: 1.2fr 0.4fr;
    flex-grow: 1;
    overflow-y: hidden;
    position: relative;

    & .container-part {
      scrollbar-width: none;
      flex-grow: 1;
      /* height: 85vh; */

      &.chat-pane {
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
        backface-visibility: hidden;
        transition: all 0.3s;
        will-change: filter;
      }

      &.chat-list-pane {
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-width: thin;
        border-right: 1px solid #cb9e52;
        position: relative;
      }
    }
  }

  @media (max-width: 1600px) {
    .container-me {
      grid-template-columns: 1.1fr 0.4fr;
    }
  }

  @media (max-width: 1400px) {
    .container-me {
      grid-template-columns: 1fr 0.4fr;
    }
  }

  @media (max-width: 1000px) {
    .container-me {
      grid-template-columns: 1fr 0.6fr;
    }
  }

  @media (max-width: 1000px) {
    .btn-chat-menu {
      display: block;
    }
    .container-me {
      grid-template-columns: 1fr;
      overflow-x: hidden;
      /* align-self: ${(props) =>
        props.isOpenChatList !== "user" ? "flex-end" : "flex-start"}; */
    }
    .chat-pane {
      /* filter: blur(5px); */
      filter: ${(props) => (props.isOpenChatList ? "blur(5px)" : "blur(0px)")};
    }
    .chat-list-pane {
      background-color: var(--color-brand-500);
      position: absolute !important;
      /* display: ${(props) => (props.isOpenChatList ? "block" : "none")}; */
      opacity: ${(props) => (props.isOpenChatList ? "1" : "0")};
      transform: ${(props) =>
        props.isOpenChatList ? "translateX(0)" : "translateX(-100%)"};
      width: ${(props) => (props.isOpenChatList ? "inherit" : "0")};
      transition: opacity 0.3s ease, transform 0.3s ease;
      top: 0;
      left: 0;
      height: 100%; /* Ensure it occupies full height */
      overflow-y: auto; /* Enable scrolling */
      z-index: 10;
    }
  }
`;
export default LeftSideWrapper;
