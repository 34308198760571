/* eslint-disable react/prop-types */
import {
  useRef,
  useState,
  useEffect,
  useCallback,
  memo,
  useContext,
} from "react";
import Message from "./Message";

import { useSendMessage } from "./useSendMessage";

import Empty from "./Empty";
import { usePick } from "./usePick";
import { useKey } from "./hooks/useKey";
import { useEditMessage } from "./useEditMessage";
import useToken from "./hooks/useToken";
import TopActions from "./TopActions";
import inChat from "../../../sounds/inChat.mp3";
import otherChat from "../../../sounds/otherChat.mp3";
import { detectDirection, userHasPermission } from "../../../utils/helper";
import Spinner from "./Spinner";
import { useResponses } from "./useResponses";
import MessagesWrapper from "./MessagesWrapper";
import Container from "./Container";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  clearInputs,
  closeEdit,
  closeReply,
  openEdit,
  openReply,
} from "./slices/inputSlice";
import InputArea from "./InputArea";
import { updateUserLocation } from "./slices/messageSlice";
import UserContext from "../../UserContext/UserContext";

function groupMessagesByDate(messages) {
  const groupedMessages = {};

  messages.forEach((msg) => {
    const messageDate = new Date(msg.created_at); // Assuming msg.updated_at is the timestamp
    const dateKey = format(messageDate, "yyyy-MM-dd"); // Format as "2024-11-18"

    if (!groupedMessages[dateKey]) {
      groupedMessages[dateKey] = [];
    }

    groupedMessages[dateKey].push(msg);
  });

  // Reverse the order of grouped messages
  const reversedGroupedMessages = Object.fromEntries(
    Object.entries(groupedMessages).sort(
      ([a], [b]) => new Date(b) - new Date(a)
    )
  );

  return reversedGroupedMessages;
}

const Chat = memo(
  function Chat({ socket, activeChat, handleFormat }) {
    const dispatch = useDispatch();
    const { activeChatId } = useSelector((state) => state.message);
    const { replyId, editId, isEditing, messageType } = useSelector(
      (state) => state.input
    );
    // eslint-disable-next-line no-unused-vars
    const [isLoadingMessages, setLoadingMessages] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState(null);

    const token = useToken();
    const { sendMessage } = useSendMessage(socket); // Custom hook
    const { editMessage } = useEditMessage(socket);
    const { user, url } = useContext(UserContext);
    const { pickMessage, isPicking } = usePick();

    const { data: allQuickRes } = useResponses();

    const [selectedFile, setSelectedFile] = useState(null); // Store selected file

    const fileInputRef = useRef(null); // Create a reference for the file input
    const textareaRef = useRef(null);
    const typingTimerRef = useRef(null); // Ref to hold the timeout

    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState("");

    const [quickResponse, setQuickResponse] = useState([]);
    const [quickResponseSearch, setQuickResponseSearch] = useState("");
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
    const [selectedQuickResponseIndex, setSelectedQuickResponseIndex] =
      useState(0);
    const quickResponseRefs = useRef([]); // Store references to each quick response item
    const groupedMessages = groupMessagesByDate(messages);

    useKey(
      "ArrowDown",
      () => {
        if (quickResponse.length > 0) {
          const currentCategory = quickResponse[selectedCategoryIndex];
          const maxQuickResponseIndex = currentCategory?.texts?.length - 1;

          if (selectedQuickResponseIndex < maxQuickResponseIndex) {
            setSelectedQuickResponseIndex(selectedQuickResponseIndex + 1);
          } else if (selectedCategoryIndex < quickResponse.length - 1) {
            setSelectedCategoryIndex(selectedCategoryIndex + 1);
            setSelectedQuickResponseIndex(0);
          }
        }
      },
      [quickResponse, selectedQuickResponseIndex, selectedCategoryIndex]
    );
    // Handle ArrowUp key
    useKey(
      "ArrowUp",
      () => {
        if (quickResponse.length > 0) {
          if (selectedQuickResponseIndex > 0) {
            setSelectedQuickResponseIndex(selectedQuickResponseIndex - 1);
          } else if (selectedCategoryIndex > 0) {
            setSelectedCategoryIndex(selectedCategoryIndex - 1);
            setSelectedQuickResponseIndex(
              quickResponse[selectedCategoryIndex - 1].texts.length - 1
            );
          }
        }
      },
      [quickResponse, selectedQuickResponseIndex, selectedCategoryIndex]
    );

    // Handle Enter key to select the active quick response
    useKey(
      "Enter",
      () => {
        if (quickResponse.length > 0) {
          const selectedText =
            quickResponse[selectedCategoryIndex].texts[
              selectedQuickResponseIndex
            ].text;
          handleQuickResponseSelect(selectedText);
        }
      },
      [quickResponse, selectedCategoryIndex, selectedQuickResponseIndex]
    );

    function handleQuickResponseSelect(selectedText) {
      const filteredText = selectedText
        .substring(selectedText.indexOf("#") + 1)
        .trim();

      setInputValue((prev) => {
        const quickResponseRegex = /(\/\+\+|\/\+|\/)(\S+)?/g;
        const match = prev.match(quickResponseRegex); // Find all quick response patterns

        if (match) {
          const lastMatch = match[match.length - 1]; // Get the last match
          const matchStartIndex = prev.lastIndexOf(lastMatch); // Find its start index
          const beforeMatch = prev.slice(0, matchStartIndex); // Text before the match
          const afterMatch = prev.slice(matchStartIndex + lastMatch.length); // Text after the match

          return `${beforeMatch}${filteredText} ${afterMatch}`.trim(); // Replace query with selected response
        }

        return prev; // Default case
      });

      setQuickResponse([]); // Clear quick response suggestions
      textareaRef.current?.focus();
      handleInput(); // Adjust textarea height
    }
    // Scroll selected quick response into view whenever it changes
    useEffect(() => {
      const currentRef =
        quickResponseRefs.current[selectedCategoryIndex]?.[
          selectedQuickResponseIndex
        ];
      if (currentRef) {
        currentRef.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, [selectedCategoryIndex, selectedQuickResponseIndex]);

    useEffect(() => {
      dispatch(clearInputs());
      if (activeChatId && socket) {
        setLoadingMessages(true); // Set loading to true before joining the chat room
        socket.emit("joinChat", { chatId: activeChatId, token });
        socket.on("chatHistorySupport", (chatMessages) => {
          if (chatMessages.find((e) => e.chat_id == activeChatId)) {
            setMessages(chatMessages);
            console.log(chatMessages[chatMessages.length - 1]);

            // socket.emit("markMessagesSeen", activeChatId, user.id);
            // socket.emit("readMessage", activeChatId);
            setLoadingMessages(false); // Set loading to false once  messages are received
          }
        });

        socket.on("error", (err) => {
          setLoadingMessages(false); // Stop loading if an error occurs
          setError(err.message || "Something went wrong.");
        });

        socket.on("userLocation", (loc) => {
          dispatch(updateUserLocation(loc.url));
        });
        console.log("chat component re rendered");

        socket?.on("newMessage", (newMessage) => {
          console.log(newMessage);

          socket.emit("getOpenChats");
          if (newMessage.chat_id == activeChatId) {
            setMessages((prevMessages) => [...prevMessages, newMessage]);

            const audio = new Audio(inChat); // Path to your sound file
            audio.play();

            socket.emit("markMessagesSeen", {
              chatId: activeChatId,
              userId: user.dataes.id,
            });

            socket.emit("readMessage", activeChatId);
          } else {
            const audio = new Audio(otherChat); // Path to your sound file
            audio.play();
          }
        });
        socket?.on("newComment", (vesper) => {
          socket.emit("getOpenChats");
          if (vesper.chat_id == activeChatId) {
            setMessages((prevMessages) => [...prevMessages, vesper]);
            const audio = new Audio(inChat); // Path to your sound file
            audio.play();
            socket.emit("markMessagesSeen", {
              chatId: activeChatId,
              userId: user.dataes.id,
            });
            socket.emit("readMessage", activeChatId);
          } else {
            const audio = new Audio(otherChat); // Path to your sound file
            audio.play();
          }
        });
        textareaRef.current?.focus();
        return () => {
          socket.off("chatHistorySupport");
          socket.off("newMessage");
          socket.off("newComment");
          socket.off("userLocation");
          socket.off("error");
        };
      }
    }, [activeChatId, socket, user.dataes.id, , activeChat, token, dispatch]);

    useKey("Enter", (e) => {
      // Check if Shift+Enter is pressed (to prevent sending on multiline input)
      if (!e.shiftKey) {
        e.preventDefault(); // Prevent default behavior (new line in textarea)
        handleSendMessage(); // Send the message
        setSelectedQuickResponseIndex(0);
      }
    });

    // Function to handle dynamic resizing of textarea
    const handleInput = () => {
      const textarea = textareaRef.current;
      if (textarea) {
        textarea.style.height = "auto"; // Reset height to auto to recalculate
        textarea.style.height = `${textarea.scrollHeight}px`; // Set height to match content

        // Enable scrolling if content exceeds max height
        if (textarea.scrollHeight > textarea.offsetHeight) {
          textarea.style.overflowY = "auto";
        } else {
          textarea.style.overflowY = "hidden";
        }
      }
    };

    function handleSendMessage() {
      setQuickResponseSearch("");
      if (inputValue.trim()) {
        if (isEditing && editId) {
          editMessage(editId, inputValue);
          dispatch(closeEdit());
        } else {
          sendMessage({
            chatId: activeChatId,
            message: inputValue,
            senderId: user.dataes.id,
            senderType: messageType,
            messageType: "message", // You can adjust based on message type
            replyId: `${replyId ? replyId : null}`,
          });
        }
        setInputValue(""); // Clear the input

        textareaRef.current?.focus();
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.direction = "ltr";
        if (replyId) {
          handleCloseReply();
        }
      }
    }

    function handleEditMessage(id, message) {
      dispatch(openEdit({ editId: id }));
      setInputValue(message);
      textareaRef.current?.focus();
    }

    function highlightText(text, query) {
      if (!query) return text; // If no query, return the text as-is

      // Escape special characters in the query for regex
      const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

      // Create a regular expression to find the query in the text
      const regex = new RegExp(`(${escapedQuery})`, "gi");

      // Split the text into parts and wrap the matched query
      const parts = text.split(regex);

      return parts.map((part, index) =>
        regex.test(part) ? (
          <span key={index} className="highlight">
            {part}
          </span>
        ) : (
          part
        )
      );
    }
    const handleInputChange = (e) => {
      if (activeChatId && messageType === "support") {
        // Clear the previous typing timer to reset the countdown
        if (typingTimerRef.current) {
          clearTimeout(typingTimerRef.current);
        }

        // Emit typing event
        socket.emit("supportTyping", {
          chatId: activeChatId,
          userId: user.dataes.id,
        });

        // Set a timeout to emit stopTyping after 1 second of inactivity
        typingTimerRef.current = setTimeout(() => {
          socket.emit("supportStopTyping", {
            chatId: activeChatId,
            userId: user.dataes.id,
          });
        }, 1500); // 1.5 seconds of inactivity
      }

      const value = e.target.value;
      const quickResponseRegex = /(\/\+\+|\/\+|\/)(\S+)?/g; // Detect quick response patterns

      // Reset quick responses when input changes
      setQuickResponse([]);
      setQuickResponseSearch("");

      const match = value.match(quickResponseRegex); // Find all matches
      if (match) {
        const lastCommand = match[match.length - 1]; // Get the last quick response command
        // eslint-disable-next-line no-unused-vars
        const [fullMatch, commandType, query] =
          lastCommand.match(/(\/\+\+|\/\+|\/)(\S+)?/) || [];

        if (commandType && query) {
          setQuickResponseSearch(query);

          let filtered;
          if (commandType === "/++") {
            // Search messages across all categories
            const allMatchingMessages = [];
            allQuickRes.forEach((item) => {
              const matchingMessages = item.texts.filter((message) =>
                message.text.toLowerCase().includes(query.toLowerCase())
              );
              allMatchingMessages.push(...matchingMessages);
            });
            filtered = [
              { title: "Search Results", id: 0, texts: allMatchingMessages },
            ];
          } else if (commandType === "/+") {
            // Search for categories
            filtered = allQuickRes.filter((item) =>
              item.title.toLowerCase().includes(query.toLowerCase())
            );
          } else {
            // Shortcut messages
            const allMatchingMessagesShortcut = [];
            allQuickRes.forEach((item) => {
              const matchingMessages = item.texts.filter((message) =>
                message.text.toLowerCase().startsWith(query.toLowerCase())
              );
              allMatchingMessagesShortcut.push(...matchingMessages);
            });
            filtered = [
              { title: "Shortcuts", id: 0, texts: allMatchingMessagesShortcut },
            ];
          }

          if (filtered.length > 0) {
            setQuickResponse(filtered);
          }
        }
      }

      setInputValue(value);
      const direction = detectDirection(value);
      textareaRef.current.style.direction = direction;
    };

    const handleButtonClick = () => {
      fileInputRef.current.click(); // Trigger the file input when the button is clicked
    };

    const handleFileChange = (e) => {
      const file = e.target.files[0];
      setSelectedFile(file); // Store the file in state when user selects one
    };

    const handlePasteOrDrag = (e) => {
      let items;

      // Check for clipboardData or dataTransfer items
      if (e.type === "paste") {
        items = e.clipboardData.items;
      } else if (e.type === "drop") {
        items = e.dataTransfer.items;
      }

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.type.startsWith("image/")) {
          const file = item.getAsFile();
          if (file) {
            setSelectedFile(file); // Use existing state to store the file
          }
          e.preventDefault(); // Prevent default behavior for both paste and drop
          break;
        }
      }
    };

    // Refactor to memoized functions
    const handleDragOver = useCallback((e) => {
      e.preventDefault();
    }, []);

    function handleCloseReply() {
      dispatch(closeReply());
    }

    if (isPicking) {
      return (
        <Container>
          <div className="center-abs">
            <Spinner />
          </div>
        </Container>
      );
    }

    if (!activeChatId)
      return (
        <Container>
          <MessagesWrapper>
            <Empty>Select a chat to start messaging</Empty>
          </MessagesWrapper>
        </Container>
      );

    return (
      <Container>
        {/* Messages area */}
        <TopActions
          userId={activeChat?.[0]?.user_id}
          socket={socket}
          activeChat={activeChat?.[0]}
        />
        <MessagesWrapper onDrop={handlePasteOrDrag} onDragOver={handleDragOver}>
          {!messages.length && <Empty>Select a chat to start messaging</Empty>}
          {Object.keys(groupedMessages).map((dateKey) => (
            <div key={dateKey}>
              <div style={{ textAlign: "center", margin: "1rem 0" }}>
                {format(new Date(dateKey), "EEEE dd MMM yyyy")}{" "}
              </div>
              <div className="day">
                {groupedMessages[dateKey].map((msg, index) => (
                  <Message
                    key={index}
                    message={msg}
                    // width={width}
                    onEdit={handleEditMessage}
                    onReply={() => {
                      if (!userHasPermission(user, "create chats")) return;
                      dispatch(
                        openReply({
                          replyId: msg.id,
                          replyMessage: msg.message,
                        })
                      );
                      textareaRef.current?.focus();
                    }}
                  />
                ))}
              </div>
            </div>
          ))}
        </MessagesWrapper>

        {/* Input area */}
        <InputArea
          activeChat={activeChat}
          socket={socket}
          handleFormat={handleFormat}
          inputValue={inputValue}
          setInputValue={setInputValue}
          handleInput={handleInput}
          handleInputChange={handleInputChange}
          handleSendMessage={handleSendMessage}
          handlePasteOrDrag={handlePasteOrDrag}
          fileInputRef={fileInputRef}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          messages={messages}
          handleCloseReply={handleCloseReply}
          handleButtonClick={handleButtonClick}
          handleFileChange={handleFileChange}
          textareaRef={textareaRef}
          pickMessage={pickMessage}
          quickResponse={quickResponse}
          quickResponseRefs={quickResponseRefs}
          selectedCategoryIndex={selectedCategoryIndex}
          selectedQuickResponseIndex={selectedQuickResponseIndex}
          handleQuickResponseSelect={handleQuickResponseSelect}
          highlightText={highlightText}
          quickResponseSearch={quickResponseSearch}
        />
      </Container>
    );
  },
  (prevProps, nextProps) => {
    // Custom comparison function to determine when to re-render
    return (
      prevProps.activeChatId === nextProps.activeChatId &&
      prevProps.socket === nextProps.socket &&
      prevProps.handleFormat === nextProps.handleFormat
    );
  }
);

export default Chat;
