/* eslint-disable no-useless-concat */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FaRegQuestionCircle } from "react-icons/fa";
import React, { Component } from "react";
import "../Dashbord/Dashbord.css";
import logo from "../../photoes/logo.png";
import { FaListUl } from "react-icons/fa";
import Ticket from "./Ticket";
import Acc from "./Acc";
import UserContext from "../UserContext/UserContext";
import { MdAccountBox, MdOutlineExitToApp, MdRuleFolder } from "react-icons/md";
import { GiCoins, GiWallet } from "react-icons/gi";
import { FaTradeFederation } from "react-icons/fa";
import { BsFillTicketDetailedFill } from "react-icons/bs";
import { SiAuthelia } from "react-icons/si";
import { TbCertificate } from "react-icons/tb";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import Auth from "./Auth";
import Order from "./Order";
import Safir from "./Safir";
import TranActions from "./TranActions";
import AllOrders from "./AllOrders";
import Wallet from "../Forgot/Wallet";
import NotificationDropdown from "../NotificationDropdown/NotificationDropdown";
import { IoMdAnalytics } from "react-icons/io";
import Rules from "./Rules";
import UserDashboard from "./UserDashboard";
import Competition from "./Competition"

class Dashbord extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      dashmenu: "داشبورد",
      inAcc: "فعال",
      mobileNav: true,
      nav: false,
    };
  }
  changePage = (d) => {
    this.setState({ dashmenu: d });
  };
  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const transactionValue = queryParams.get("transaction");

    if (transactionValue === "true") {
      this.setState({ dashmenu: "سرمایه های دریافت شده" });
    } else if (transactionValue === "false") {
      this.setState({ dashmenu: "تاریخچه تراکنش ها" });
    }
  }

  render() {
    const navclassName = () => {
      if (this.state.mobileNav) {
        return "notAc";
      } else {
        return "navAc";
      }
    };
    const { user, logout, url } = this.context;

    // const showMainCon = () => {
    //   if (this.state.dashmenu === "داشبورد") {
    //     return <UserDashboard/>;
    //   }else if (this.state.dashmenu === "سرمایه های دریافت شده") {
    //     return <AllOrders />;
    //   }
    //    else if (this.state.dashmenu === "دریافت سرمایه") {
    //     return <Order changePage={this.changePage} />;
    //   } else if (this.state.dashmenu === "ارسال تیکت") {
    //     return <Ticket />;
    //   } else if (this.state.dashmenu === "احراز هویت") {
    //     return (
    //       <>
    //         <Auth />
    //       </>
    //     );
    //   } else if (this.state.dashmenu === "حساب کاربری") {
    //     return <Acc />;
    //   } else if (this.state.dashmenu === "سفیر فنفیکس") {
    //     return <Safir />;
    //   } else if (this.state.dashmenu === "تاریخچه تراکنش ها") {
    //     return <TranActions changePage={this.changePage} />;
    //   } else if (this.state.dashmenu === "کیف پول") {
    //     return <Wallet />;
    //   } else if (this.state.dashmenu === "قوانین"){
    // return <Rules></Rules>
    // }
    // };

    const showMainCon = () => {
      const dashmenuTrimmed = this.state.dashmenu.trim(); // پاک کردن فاصله‌ها و newline‌ها

      if (dashmenuTrimmed === "داشبورد") {
        return <UserDashboard changePage={this.changePage}  />;
      } else if (dashmenuTrimmed === "سرمایه های دریافت شده") {
        return <AllOrders />;
      } else if (dashmenuTrimmed === "دریافت سرمایه") {
        return <Order changePage={this.changePage} />;
      } else if (dashmenuTrimmed === "ارسال تیکت") {
        return <Ticket />;
      }else if (dashmenuTrimmed === "مسابقه بزرگ") {
        return <Competition changePage={this.changePage} />;
      } else if (dashmenuTrimmed === "احراز هویت") {
        return <Auth />;
      } else if (dashmenuTrimmed === "حساب کاربری") {
        return <Acc />;
      } else if (dashmenuTrimmed === "سفیر فنفیکس") {
        return <Safir />;
      } else if (dashmenuTrimmed === "تاریخچه تراکنش ها") {
        return <TranActions changePage={this.changePage} />;
      } else if (dashmenuTrimmed === "کیف پول") {
        return <Wallet />;
      } else if (dashmenuTrimmed === "قوانین") {
        return <Rules />;
      }
    };

    const clickOnDashMen = (e) => {
      this.setState({ dashmenu: e.currentTarget.innerText });
      this.setState({ mobileNav: !this.state.mobileNav });
      // if(e.currentTarget.innerText === "حساب کاربری" ||
      // 	e.currentTarget.innerText === "احراز هویت" ||
      // 	e.currentTarget.innerText === "سفیر فنفیکس"
      // ){
      // 	load(true)

      // 	axios.post(url+"api/v1/auth/me",{},
      // 		{ headers: { "Authorization": `bearer ${Cookies.get('token')}` } }
      // 		).then(res=>{
      // 		login({dataes : res.data , at : Cookies.get('token') })
      // 		load(false)

      // 	}).catch(err=>{
      // 		load(false)
      // 		this.props.navigate("/")
      // 	})
      // }
    };
    // const classActive = (m) => {
    //   if (m === this.state.dashmenu) {
    //     return "active";
    //   } else {
    //     return "";
    //   }
    // };
    const classActive = (m) => {
      // حذف فاصله‌ها و newline‌های اضافی از ابتدای و انتهای رشته‌ها
      // console.log('m:',m,' this.state.dashmenue',this.state.dashmenu);
      if (m.trim() === this.state.dashmenu.trim()) {
        return "active";
      } else {
        return "";
      }
    };

    const allScreen = () => {
      if (!this.state.mobileNav) {
        return (
          <div
            onClick={() => {
              this.setState({ mobileNav: !this.state.mobileNav });
            }}
            className="allScreen"
          ></div>
        );
      }
    };

    const allContent = () => {
      if (user.dataes !== null) {
        return (
          <div>
            <div className="background"></div>
            <div className="app">
              <header className="app-header">
                <div className="app-header-logo">
                  <div className="dashlogo">
                    <img src={logo} alt="" />
                  </div>
                </div>
                <div className="app-header-actions">
                  <button className="user-profile">
                    <span>
                      {" "}
                      {user.dataes.first_name !== null
                        ? user.dataes.first_name + " " + user.dataes.last_name
                        : user.dataes.username}{" "}
                    </span>
                    <span>
                      <img
                        className="w50"
                        src={url + "storage/" + user.dataes.image}
                        alt=""
                      />
                    </span>
                  </button>
                  <NotificationDropdown changePage={this.changePage} />
                  <FaRegQuestionCircle
                    style={{
                      verticalAlign: "middle",
                      fontSize: "35px",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.open("https://fenefx.net/document/", "_blank");
                    }}
                  />
                </div>
                <div className="app-header-mobile">
                  {/* <span className="userSpan"> {user.dataes.first_name !== null ?
				 user.dataes.first_name + " " + user.dataes.last_name: user.dataes.username} </span> */}

                  <FaRegQuestionCircle
                    style={{
                      verticalAlign: "middle",
                      fontSize: "32px",
                      marginLeft: "5px",
                    }}
                  />
                  <NotificationDropdown changePage={this.changePage} />
                  <div className="icon-button large">
                    <img
                      className="w50"
                      src={url + "storage/" + user.dataes.image}
                      alt=""
                    />
                  </div>
                  <button
                    onClick={() => {
                      this.setState({ mobileNav: !this.state.mobileNav });
                    }}
                    className="icon-button large"
                  >
                    <FaListUl />
                  </button>
                </div>
              </header>
              <div className="app-body">
                {allScreen()}
                <div className="app-body-navigation">
                  <nav className="navigation">
                    <a
                      onClick={clickOnDashMen}
                      className={classActive("داشبورد")}
                    >
                      <MdAccountBox />
                      <span>داشبورد</span>
                    </a>
                    <a
                      onClick={clickOnDashMen}
                      className={classActive("حساب کاربری")}
                    >
                      <MdAccountBox />
                      <span>حساب کاربری</span>
                    </a>
                    <a
                      onClick={clickOnDashMen}
                      className={classActive("کیف پول")}
                    >
                      <GiWallet />
                      <span>کیف پول</span>
                    </a>
                    <a
                      onClick={clickOnDashMen}
                      className={classActive("سرمایه های دریافت شده")}
                    >
                      <GiCoins />
                      <span>سرمایه های دریافت شده</span>
                    </a>
                    <a
                      onClick={clickOnDashMen}
                      className={classActive("دریافت سرمایه")}
                    >
                      <FaTradeFederation />
                      <span>دریافت سرمایه</span>
                    </a>
                    <a
                      href="https://fenefx.co/login-panel-fenefx/view/index.php"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IoMdAnalytics />
                      <span>پنل آنالیز</span>
                    </a>
                    {/* <a href='https://fenefx.net/terms-and-conditions/'
				 target='_blank' rel="noreferrer">
				    <MdRuleFolder/>
					<span >قوانین</span>
				</a> */}
                    <a
                      onClick={clickOnDashMen}
                      className={classActive("مسابقه بزرگ")}
                    >
                      <MdRuleFolder />
                      <span>مسابقه بزرگ</span>
                    </a>
                    <a
                      onClick={clickOnDashMen}
                      className={classActive("قوانین")}
                    >
                      <MdRuleFolder />
                      <span>قوانین</span>
                    </a>

                    <a
                      onClick={clickOnDashMen}
                      className={classActive("ارسال تیکت")}
                    >
                      <BsFillTicketDetailedFill />
                      <span>ارسال تیکت</span>
                    </a>
                    <a
                      onClick={clickOnDashMen}
                      className={classActive("احراز هویت")}
                    >
                      <SiAuthelia />
                      <span>احراز هویت</span>
                    </a>
                    <a
                      onClick={clickOnDashMen}
                      className={classActive("تاریخچه تراکنش ها")}
                    >
                      <FaMoneyCheckDollar />
                      <span>تاریخچه تراکنش ها</span>
                    </a>
                    <a
                      onClick={clickOnDashMen}
                      className={classActive("سفیر فنفیکس")}
                    >
                      <TbCertificate />
                      <span> سفیر فنفیکس </span>
                    </a>

                    <a
                      onClick={() => {
                        logout();
                        this.props.navigate("/");
                      }}
                    >
                      <MdOutlineExitToApp />
                      <span>خروج</span>
                    </a>
                  </nav>
                </div>
                <div className={"app-mobile-navigation" + " " + navclassName()}>
                  <nav className="navigation">
                    <a
                      onClick={() => {
                        this.setState({ dashmenu: "داشبورد" });
                        this.setState({ mobileNav: !this.state.mobileNav });
                      }}
                      className={classActive("داشبورد")}
                    >
                      <MdAccountBox />
                      <span>داشبورد</span>
                    </a>

                    <a
                      onClick={() => {
                        this.setState({ dashmenu: "حساب کاربری" });
                        this.setState({ mobileNav: !this.state.mobileNav });
                      }}
                      className={classActive("حساب کاربری")}
                    >
                      <MdAccountBox />
                      <span>حساب کاربری</span>
                    </a>
                    <a
                      onClick={() => {
                        this.setState({ dashmenu: "کیف پول" });
                        this.setState({ mobileNav: !this.state.mobileNav });
                      }}
                      className={classActive("کیف پول")}
                    >
                      <GiWallet />
                      <span>کیف پول</span>
                    </a>
                    <a
                      onClick={() => {
                        this.setState({ dashmenu: "سرمایه های دریافت شده" });
                        this.setState({ mobileNav: !this.state.mobileNav });
                      }}
                      className={classActive("سرمایه های دریافت شده")}
                    >
                      <GiCoins />
                      <span>سرمایه های دریافت شده</span>
                    </a>
                    <a
                      href="https://fenefx.co/login-panel-fenefx/view/index.php"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IoMdAnalytics />
                      <span>پنل آنالیز</span>
                    </a>
                    <a
                      onClick={clickOnDashMen}
                      className={classActive("مسابقه بزرگ")}
                    >
                      <MdRuleFolder />
                      <span>مسابقه بزرگ</span>
                    </a>
                    <a
                      onClick={() => {
                        this.setState({ dashmenu: "دریافت سرمایه" });
                        this.setState({ mobileNav: !this.state.mobileNav });
                      }}
                      className={classActive("دریافت سرمایه")}
                    >
                      <FaTradeFederation />
                      <span>دریافت سرمایه</span>
                    </a>
                    <a
                      onClick={() => {
                        this.setState({ dashmenu: "ارسال تیکت" });
                        this.setState({ mobileNav: !this.state.mobileNav });
                      }}
                      className={classActive("ارسال تیکت")}
                    >
                      <BsFillTicketDetailedFill />
                      <span>ارسال تیکت</span>
                    </a>
                    <a
                      onClick={() => {
                        this.setState({ dashmenu: "احراز هویت" });
                        this.setState({ mobileNav: !this.state.mobileNav });
                      }}
                      className={classActive("احراز هویت")}
                    >
                      <SiAuthelia />
                      <span>احراز هویت</span>
                    </a>
                    <a
                      onClick={() => {
                        this.setState({ dashmenu: "تاریخچه تراکنش ها" });
                        this.setState({ mobileNav: !this.state.mobileNav });
                      }}
                      className={classActive("تاریخچه تراکنش ها")}
                    >
                      <FaMoneyCheckDollar />
                      <span>تاریخچه تراکنش ها</span>
                    </a>
                    <a
                      onClick={() => {
                        this.setState({ dashmenu: "سفیر فنفیکس" });
                        this.setState({ mobileNav: !this.state.mobileNav });
                      }}
                      className={classActive("سفیر فنفیکس")}
                    >
                      <TbCertificate />
                      <span> سفیر فنفیکس </span>
                    </a>
                    <a
                      onClick={clickOnDashMen}
                      className={classActive("قوانین")}
                    >
                      <MdRuleFolder />
                      <span>قوانین</span>
                    </a>
                    <a
                      onClick={() => {
                        logout();
                        this.props.navigate("/");
                      }}
                    >
                      <MdOutlineExitToApp />
                      <span>خروج</span>
                    </a>
                  </nav>
                </div>
                <div className="app-body-main-content">{showMainCon()}</div>
              </div>
            </div>
          </div>
        );
      } else {
        return <div style={{ height: "100vh" }}></div>;
      }
    };
    return <>{allContent()}</>;
  }
}
Dashbord.propTypes = {};
export default Dashbord;
