import { useDispatch, useSelector } from "react-redux";
import AppLayoutNavbar from "./AppLayoutNavbar";
import {
  allTab,
  exploreTab,
  myChatsTab,
  otherChatsTab,
  pendingChatsTab,
} from "./slices/messageSlice";
import { useContext, useEffect } from "react";
import { useSocket } from "./context/SocketContext";
import {
  setMyId,
  updateConversations,
  updateOnlineSiteUsersCount,
  updateOnlineUsersCount,
} from "./slices/userCountSlice";
import UserContext from "../../UserContext/UserContext";

function MessagesHeader() {
  const messages = useSelector((store) => store.message);
  const { chatCounts } = useSelector((store) => store.userCount);
  const userCount = useSelector((store) => store.userCount);
  const dispatch = useDispatch();
  const { user } = useContext(UserContext);
  const socket = useSocket();

  useEffect(() => {
    dispatch(setMyId(user?.dataes?.id));
    const handleOpenChatsList = (chats) => {
      dispatch(updateConversations(chats));
    };

    socket?.on("onlineUserCount", (count) => {
      dispatch(updateOnlineUsersCount(count));
    });
    socket?.on("siteOnlineUserCount", (count) => {
      dispatch(updateOnlineSiteUsersCount(count));
    });

    socket?.on("openChatsList", handleOpenChatsList);

    return () => {
      socket?.off("openChatsList", handleOpenChatsList);
      socket?.off("onlineUserCount");
      socket?.off("siteOnlineUserCount");
    };
  }, [user?.dataes?.id, dispatch, socket]);

  return (
    <div>
      <AppLayoutNavbar>
        <div className="tabs-me">
          <p
            className={messages.activeTab === "my" ? "active" : ""}
            onClick={() => dispatch(myChatsTab())}
          >
            My chats
            <span className="chat-count">{chatCounts.myChats}</span>
          </p>
          <p
            className={messages.activeTab === "pending" ? "active" : ""}
            onClick={() => dispatch(pendingChatsTab())}
          >
            Pending{" "}
            <span className="chat-count">{chatCounts.pendingChats}</span>
          </p>
          <p
            className={messages.activeTab === "other" ? "active" : ""}
            onClick={() => dispatch(otherChatsTab())}
          >
            Other <span className="chat-count">{chatCounts.otherChats}</span>
          </p>

          <p
            className={messages.activeTab === "all" ? "active" : ""}
            onClick={() => dispatch(allTab())}
          >
            Today
          </p>
          <p
            className={messages.activeTab === "explore" ? "active" : ""}
            onClick={() => dispatch(exploreTab())}
          >
            Explore
          </p>
        </div>
        <div className="operations">
          {/* <p style={{ marginRight: "1.2rem" }}>
            Supports:{" "}
            <span style={{ color: "var(--color-blue-500)" }}>
              {userCount.onlineSupportListCount}
            </span>
          </p> */}
          <p style={{ marginRight: "1.2rem" }} className="user-count-desktop">
            Site:{" "}
            <span style={{ color: "var(--color-blue-500)" }}>
              {userCount.siteOnlineUsersCount}
            </span>
          </p>
          <p style={{ marginRight: "1.2rem" }} className="user-count-desktop">
            Online users:{" "}
            <span style={{ color: "var(--color-blue-500)" }}>
              {userCount.onlineUsersCount}
            </span>
          </p>
          <p
            style={{ color: "var(--color-blue-500)" }}
            className="user-count-mobile"
          >
            {userCount.siteOnlineUsersCount} | {userCount.onlineUsersCount}
          </p>

          {/* <ButtonIcon>
            <MdOutlineSettings />
          </ButtonIcon> */}
        </div>
      </AppLayoutNavbar>
    </div>
  );
}

export default MessagesHeader;
