import {
  format,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  isToday,
  isYesterday,
} from "date-fns";
import axios from "axios";
import { TIMEOUT_SEC } from "./constants";

export function formatDate(dateString) {
  if (!dateString) return;
  const date = new Date(dateString); // Parse the input date
  const now = new Date(); // Get the current date

  // Check if it's today
  if (isToday(date)) {
    return `${format(date, "HH:mm")}`;
  }

  // Check if it's yesterday
  if (isYesterday(date)) {
    return "Yesterday";
  }

  // Calculate the difference in days
  const daysDifference = differenceInDays(now, date);

  // If it's 2-6 days ago, show the number of days ago
  if (daysDifference >= 2 && daysDifference <= 6) {
    return `${daysDifference} days ago`;
  }

  // If it's within the last week, show the day of the week
  if (daysDifference <= 7) {
    return format(date, "EEEE"); // EEEE = Full day name (e.g., Monday)
  }

  // Calculate the difference in weeks
  const weeksDifference = differenceInWeeks(now, date);

  if (weeksDifference === 1) {
    return "1 week ago";
  } else if (weeksDifference === 2) {
    return "2 weeks ago";
  }

  // Calculate the difference in months
  const monthsDifference = differenceInMonths(now, date);

  if (monthsDifference === 1) {
    return "1 month ago";
  } else if (monthsDifference === 2) {
    return "2 months ago";
  }

  // If it's older than 2 months, display the date in DD/MM/YYYY format
  return format(date, "dd/MM/yyyy");
}

export function formatDateTime(date) {
  const d = new Date(date);
  const formattedDate = format(d, "yyyy/MM/dd hh:mm a");
  return formattedDate;
}

export function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Format minutes and seconds to always have two digits
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
}

export function formatTimeMessages(date) {
  // Convert to a Date object
  const time = new Date(date);

  // Format the date to 'hh:mm a' (12-hour format with AM/PM)
  const formattedDate = format(time, "hh:mm a");

  return formattedDate;
}
export function getFileType(selectedFile) {
  if (!selectedFile) return null;

  const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

  const fileTypes = {
    image: ["jpg", "jpeg", "png", "gif"],
    video: ["mp4", "avi", "mov"],
    zip: ["zip", "rar"],
    pdf: ["pdf"], // Added pdf file type
  };

  for (const [type, extensions] of Object.entries(fileTypes)) {
    if (extensions.includes(fileExtension)) {
      return type;
    }
  }

  return "unknown";
}

export function userHasPermission(userData, permissionName) {
  /**
   * Check if the user has a specific permission.
   *
   * @param {Object} userData - The user data object containing permissions.
   * @param {string} permissionName - The name of the permission to check.
   * @returns {boolean} - Returns true if the user has the permission, false otherwise.
   */
  console.log(userData);

  // Extract the permissions from the user data
  const permissions = userData?.dataes?.permissions || [];

  // Check if any of the permissions match the specified permission name
  return permissions.some((permission) => permission.name === permissionName);
}

export const detectDirection = (text) => {
  const rtlPattern = /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/;
  return rtlPattern.test(text) ? "rtl" : "ltr";
};

export function isPersianText(text) {
  const persianRegex = /[\u0600-\u06FF\u0750-\u077F\uFB50-\uFDFF\uFE70-\uFEFF]/;
  return persianRegex.test(text);
}

// For stats

export function countChatsWithSender(data, senderId) {
  let count = 0;

  data?.forEach((chat) => {
    // Check if there is any message from the specific sender in the chat
    const hasMessageFromSender = chat.messages.some(
      (message) => message.sender_id === senderId && message.type === "support"
    );
    if (hasMessageFromSender) {
      count += 1; // Count the chat if the sender has a message
    }
  });

  return count;
}

// Reusable function to handle various HTTP methods and configurations
export const request = async function ({
  url,
  method = "GET",
  data = null,
  headers = {},
  params = {},
  token = null,
  contentType = "application/json",
}) {
  try {
    // Set default headers, including Authorization if token is provided
    const config = {
      method, // HTTP method (GET, POST, PUT, DELETE, etc.)
      url,
      headers: {
        "Content-Type": contentType,
        ...headers,
        ...(token && { Authorization: `bearer ${token}` }), // Add token to Authorization header
      },
      timeout: TIMEOUT_SEC * 1000, // Set timeout in milliseconds
      data, // Request body for POST/PUT requests
      params, // URL parameters for GET requests
    };
    console.log(config);

    // Make the Axios request
    const response = await axios(config);

    // Return the response data
    return response.data;
  } catch (err) {
    if (err.code === "ECONNABORTED") {
      console.error(
        `Request took too long! Timeout after ${TIMEOUT_SEC} seconds`
      );
    } else {
      console.error(err.response ? err.response.data : err.message);
    }
    throw err;
  }
};
