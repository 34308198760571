import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  myId: null,
  onlineUsersCount: 0,
  onlineUsersCountFromBack: 0,
  siteOnlineUsersCount: 0,
  onlineSupportList: [],
  onlineSupportListCount: 0,
  chatCounts: { myChats: 0, pendingChats: 0, otherChats: 0 },
  conversations: [],
};

const userCountSlice = createSlice({
  name: "userCount",
  initialState,
  reducers: {
    setOnlineUsersCount: (state, action) => {
      state.onlineUsersCount = action.payload;
    },
    updateOnlineSiteUsersCount: (state, action) => {
      state.siteOnlineUsersCount = action.payload;
    },
    updateOnlineUsersCount: (state, action) => {
      state.onlineUsersCountFromBack = action.payload;
    },
    setOnlineSupportList: (state, action) => {
      state.onlineSupportList = action.payload;
      state.onlineSupportListCount = action.payload.length;
    },
    updateConversations: (state, action) => {
      const conversations = action.payload.filter(
        (c) => c.latest_message !== null
      );
      state.conversations = conversations;

      // Update chat counts
      state.chatCounts = {
        myChats: conversations.filter((c) => +c.support_id === state.myId)
          .length,
        pendingChats: conversations.filter((c) => c.support_id === null).length,
        otherChats: conversations.filter(
          (c) => c.support_id !== state.myId && c.support_id !== null
        ).length,
      };

      // Update online users count
      const uniqueOnlineUsers = new Set();
      state.onlineUsersCount = conversations.filter((c) => {
        if (c.is_user_online && !uniqueOnlineUsers.has(c.user_id)) {
          uniqueOnlineUsers.add(c.user_id);
          return true;
        }
        return false;
      }).length;
    },
    setMyId: (state, action) => {
      state.myId = action.payload;
    },
  },
});

export const {
  setOnlineUsersCount,
  updateOnlineSiteUsersCount,
  updateOnlineUsersCount,
  setOnlineSupportList,
  updateConversations,
  setMyId,
} = userCountSlice.actions;

export default userCountSlice.reducer;
