import { getAllConversations } from "../../../services/apiMessages";
import useToken from "./hooks/useToken";
import { useQuery } from "@tanstack/react-query";

export function useAllConversations({ username }) {
  const token = useToken();

  const { data, isLoading } = useQuery({
    queryKey: ["conversations-all", username],
    queryFn: () => getAllConversations({ token, username }),
  });

  return {
    isLoading,
    data,
  };
}
