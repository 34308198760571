/* eslint-disable react/prop-types */
import Spinner from "./Spinner";

function ConfirmAction({
  message,
  onSubmit,
  btnName = "Submit",
  onCloseModal,
  isPending,
}) {
  if (isPending) return <Spinner />;
  return (
    <div
      style={{
        padding: "3.2rem",
      }}
    >
      <p style={{ marginBottom: "4rem" }}>{message}</p>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          gap: "2rem",
          justifyContent: "space-around",
        }}
      >
        <button onClick={onCloseModal} className="btn btn-primary">
          Cancel
        </button>
        <button
          onClick={() => {
            onSubmit();
            onCloseModal();
          }}
          className="btn btn-primary"
        >
          {btnName}
        </button>
      </div>
    </div>
  );
}

export default ConfirmAction;
