import { useMutation, useQueryClient } from "@tanstack/react-query";
import useToken from "../messages/hooks/useToken";
import { deleteCategory as deleteCategoryApi } from "../../../services/apiFAQ";
import toast from "react-hot-toast";

export const useDeleteCategory = () => {
  const token = useToken();
  const queryClient = useQueryClient();

  const { mutate: deleteCategory, isPending: isDeleting } = useMutation({
    mutationFn: (id) => {
      return deleteCategoryApi(id, token);
    },
    onSuccess: () => {
      toast.success("Category was deleted successfully");
      queryClient.invalidateQueries({ queryKey: ["categories"] });
    },
    onError: (err) => toast.error(err.response.data.message || err.message),
  });

  return { deleteCategory, isDeleting };
};
