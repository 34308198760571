import useToken from "./hooks/useToken";
import { sendFile as sendFileApi } from "../../../services/apiMessages"; // Import the createChat function
import { useMutation } from "@tanstack/react-query";

export const useSendFile = () => {
  const token = useToken();
  const { mutate: sendFile, isPending: isSending } = useMutation({
    mutationFn: (data) => sendFileApi(data, token),
  });

  return { isSending, sendFile };
};
