import { useMutation, useQueryClient } from "@tanstack/react-query";
import useToken from "./hooks/useToken";
import { suspendChat as suspendChatApi } from "../../../services/apiMessages";
import toast from "react-hot-toast";

export function useSuspendChat() {
  const queryClient = useQueryClient();
  const token = useToken();

  const { mutate: suspendChat, isPending } = useMutation({
    mutationFn: (chatId) => suspendChatApi({ token, id: chatId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["conversations"] });
      toast.success("Conversation successfully suspended");
    },
    onError: (err) => {
      console.log(err);

      return toast.error(
        err.response.data.message ||
          "Something went wrong " + err.status ||
          err.message
      );
    },
  });

  return { suspendChat, isPending };
}
