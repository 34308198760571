/* eslint-disable no-unused-vars */
import styled from "styled-components";
import { useOutsideClick } from "./hooks/useOutsideClick";

// Styled components
const StyledModal = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-grey-0);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-lg);
  /* padding: 3.2rem 4rem; */
  transition: all 0.5s;
  z-index: 1001;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--backdrop-color);
  backdrop-filter: blur(4px);
  z-index: 1000;
  transition: all 0.5s;
`;

const Button = styled.button`
  background: none;
  border: none;
  padding: 0.4rem;
  border-radius: var(--border-radius-sm);
  position: absolute;
  top: 1.2rem;
  right: 1.9rem;

  &:hover {
    background-color: var(--color-grey-100);
  }

  & svg {
    width: 2.4rem;
    height: 2.4rem;
    color: var(--color-grey-500);
  }
`;

// Modal Component
// eslint-disable-next-line react/prop-types
function ConfirmSendFile({ isOpen, onClose, children }) {
  const ref = useOutsideClick(onClose);

  if (!isOpen) return null;

  return (
    <>
      <Overlay />
      <StyledModal ref={ref}>
        {/* <Button onClick={onClose}>
          <HiXMark />
        </Button> */}
        {children}
      </StyledModal>
    </>
  );
}

export default ConfirmSendFile;
