import { useQuery } from "@tanstack/react-query";
import { getChatHistoryMessages } from "../../../services/apiMessages";
import useToken from "./hooks/useToken";

export function useChatHistoryMessages(id) {
  const token = useToken();
  const { isLoading, data, error } = useQuery({
    queryKey: id ? ["chat-history", id] : null,
    queryFn: () => (id ? getChatHistoryMessages(token, id) : null),
  });

  return { isLoading, data, error };
}
