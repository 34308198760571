import { useMutation, useQueryClient } from "@tanstack/react-query";
import useToken from "../messages/hooks/useToken";
import { deleteQuestion as deleteQuestionApi } from "../../../services/apiFAQ";
import toast from "react-hot-toast";

export const useDeleteQuestion = () => {
  const token = useToken();
  const queryClient = useQueryClient();

  const { mutate: deleteQuestion, isPending: isDeleting } = useMutation({
    mutationFn: (id) => {
      return deleteQuestionApi(id, token);
    },
    onSuccess: () => {
      toast.success("Question was deleted successfully");
      queryClient.invalidateQueries({ queryKey: ["categories"] });
    },
    onError: (err) => console.error(err.message),
  });

  return { deleteQuestion, isDeleting };
};
