// import styled from "styled-components";

// const ButtonIcon = styled.button`
//   background: none;
//   border: none;
//   padding: 0.6rem;
//   border-radius: var(--border-radius-sm);
//   transition: all 0.2s;

//   &.active {
//     border: 1px solid var(--color-primary-400);
//     background-color: var(--color-grey-400);
//   }

//   &:hover {
//     background-color: var(--color-grey-100);
//   }

//   & svg {
//     width: 1rem;
//     height: 1rem;
//     color: ${(props) => props.$color || "var(--color-brand-600)"};
//   }
// `;

// export default ButtonIcon;


import styled from "styled-components";

const ButtonIcon = styled.button`
  background: none;
  border: none;
  padding: 0.6rem;
  border-radius: 4px;
  transition: all 0.2s;

  &.active {
    border: 1px solid #3182ce;
    background-color: #e2e8f0;
  }

  &:hover {
    background-color: #dcdcdc; /* رنگ خاکستری روشن برای کنتراست بهتر */

    & svg {
      color: #000000; /* رنگ سیاه برای نمایش روی رنگ خاکستری روشن */
    }
  }

  & svg {
    width: 1rem;
    height: 1rem;
    color: ${(props) => props.$color || "#ffffff"}; /* سفید برای نمایش روی سبز لجنی */
  }
`;

export default ButtonIcon;