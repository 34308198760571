import React from "react";
import "./RecentEvents.css";
import { FaCalendarAlt, FaBolt } from "react-icons/fa";

function RecentEvents(props) {
  console.log("from recent:", props);
  const events = [
    {
      title: "بزرگ‌ترین مسابقات تاریخ ترید ایران",
      description: "یک کاربر جدید با نام کاربری ali123 به سایت پیوست.",
      date: "2025-01-07",
      status: "live", // وضعیت زنده
    },
    {
      title: "عیدانه سال نو",
      description: "کاربر john_doe مبلغ 1,500 تومان برداشت کرد.",
      date: "2025-01-01",
      status: "expired", // وضعیت در راه
    },
    {
      title: "سرمایه دو برابر 2X",
      description: "حساب کاربری ali123 به مبلغ 10,000 تومان افزایش یافت.",
      date: "2024-12-20",
      status: "expired", // وضعیت منقضی شده
    },
  ];

  // مرتب‌سازی براساس وضعیت
  const sortedEvents = events.sort((a, b) => {
    const order = { oncoming: 0, live: 1, expired: 2 };
    return order[a.status] - order[b.status];
  });

  const handleClick = (index) => {
    console.log("index", index);

    if (index === 0 && props.changePage) {
      console.log("clicked");
      props.changePage("مسابقه بزرگ"); // تابع ارسال شده به prop را فراخوانی می‌کند
    }
  };

  return (
    <div className="RecentEventsContainer">
      <h2 className="RecentEventsTitle">رویدادها</h2>
      <ul className="RecentEventsList">
        {sortedEvents.map((event, index) => (
          <li
            className="RecentEventItem"
            key={index}
            onClick={() => handleClick(index)} // افزودن هندلر کلیک
          >
            <div className="RecentEventContent">
              <p className="RecentEventDate">
                <FaCalendarAlt className="RecentEventDateIcon" />
                {event.date}
              </p>
              <div style={{ display: "flex", gap: "1rem" }}>
                <FaBolt className="RecentEventIcon" />
                <p className="RecentEventTitle">{event.title}</p>
              </div>
              <div
                className={`StatusDot ${
                  event.status === "live"
                    ? "LiveDot"
                    : event.status === "oncoming"
                    ? "OncomingDot"
                    : "ExpiredDot"
                }`}
              ></div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default RecentEvents;
