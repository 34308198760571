/* eslint-disable react/prop-types */

import styled from "styled-components";
import ButtonIcon from "../messages/ButtonIcon";
import { HiOutlineStar } from "react-icons/hi2";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { readyInputsToEdit } from "./slices/faqSlice";
import { detectDirection, userHasPermission } from "../../../utils/helper";
import Modal from "../messages/Modal";
import ConfirmAction from "../messages/ConfirmAction";
import { useDeleteQuestion } from "./useDeleteQuestion";

import { useContext } from "react";
import UserContext from "../../UserContext/UserContext";

const StyledQuestion = styled.div`
  background-color: var(--color-grey-100);
  padding: 1rem 0.5rem;
  position: relative;

  & .question {
    font-size: 1rem;
    margin-bottom: 0.6rem;
  }
  & .answer {
    font-size: 0.8rem;
    color: var(--color-grey-500);
    margin: 0 1rem;
    white-space: pre-wrap; /* This allows newlines and spaces to be respected */
  }

  & .btn-star {
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
  }
`;

function Question({ q }) {
  const dispatch = useDispatch();
  const { deleteQuestion, isDeleting } = useDeleteQuestion();
  const { user } = useContext(UserContext);

  function handleDeleteQuestion() {
    deleteQuestion(q.id);
  }

  function handleEditQuestion() {
    dispatch(
      readyInputsToEdit({
        question: q.question,
        answer: q.answer,
        isImportant: q.is_important,
        id: q.id,
      })
    );
  }
  const isRTL = (text) => {
    const rtlPattern = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]/; // الگوی شناسایی حروف فارسی و عربی
    return rtlPattern.test(text);
  };

  return (
    <StyledQuestion dir={detectDirection(q.question)}>
      <p
        className="question"
        style={{
          textAlign: "left",
        }}
      >
        {q.question}
      </p>
      <p
        className="answer"
        style={{
          textAlign: isRTL(q.answer) ? "right" : "left",
        }}
      >
        {q.answer}
      </p>
      <div className="btn-star">
        <Modal>
          <Modal.Open
            opens="delete-category"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ButtonIcon disabled={!userHasPermission(user, "faq-delete")}>
              <MdDelete />
            </ButtonIcon>
          </Modal.Open>
          <Modal.Window name="delete-category">
            <ConfirmAction
              message="The question will be deleted. Are you sure?"
              isPending={isDeleting}
              onSubmit={() => handleDeleteQuestion(q.id)}
            />
          </Modal.Window>
        </Modal>
        <ButtonIcon disabled={!userHasPermission(user, "faq-update")}>
          <MdEdit onClick={handleEditQuestion} />
        </ButtonIcon>
        <ButtonIcon>
          <HiOutlineStar fill={+q.is_important === 1 ? "red" : "none"} />
        </ButtonIcon>
      </div>
    </StyledQuestion>
  );
}

export default Question;
