import React, { useState } from "react";
import { GrUpdate } from "react-icons/gr";
function ClearCache() {
  const [isOpen, setIsOpen] = useState(false);

  const handleHardReload = async () => {
    // Clear localStorage and sessionStorage
    localStorage.clear();
    sessionStorage.clear();

    // Clear Service Worker caches (if supported)
    if ("caches" in window) {
      const keys = await caches.keys();
      await Promise.all(keys.map((key) => caches.delete(key)));
    }

    // Reload the page with a unique query parameter to bypass cache
    const noCacheUrl = `${window.location.origin}${
      window.location.pathname
    }?nocache=${new Date().getTime()}`;
    window.location.replace(noCacheUrl);
  };
  return (
    <div>
      <div
        title="جهت مشاهده بروزرسانی های انجام شده کلیک نمایید"
        className="ico"
        onClick={handleHardReload}
      >
        <GrUpdate
          style={{
            fontSize: "33px",
          }}
        />
      </div>
    </div>
  );
}

export default ClearCache;
