import { useQuery } from "@tanstack/react-query";
import { getConversations } from "../../../services/apiMessages";
import useToken from "./hooks/useToken";

export function useConversations() {
  const token = useToken();
  const { isLoading, data, error } = useQuery({
    queryKey: ["conversations"],
    queryFn: () => getConversations({ token }),
  });

  return { isLoading, data, error };
}
