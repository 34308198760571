/* eslint-disable react/prop-types */

import Spinner from "./Spinner";
import ConversationList from "./ConversationList";

function AllChats({ socket, conversations, isLoading, loadingOldChats }) {
  if (isLoading)
    return (
      <div className="center-abs">
        <Spinner />
      </div>
    );
  return (
    <div>
      <ConversationList
        conversations={conversations}
        socket={socket}
        loadingOldChats={loadingOldChats}
      />
    </div>
  );
}

export default AllChats;
