/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";

import { useState, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
// import { Heading } from "rsuite";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const parseCustomDate = (dateString) => {
  const [time, date] = dateString.split(" ");
  return new Date(`${date}T${time}`);
};
const processDataByMonth = (data) => {
  const monthlyData = {};
  // console.log("my data", data);

  if (Array.isArray(data)) {
    data.forEach((item) => {
      const date = parseCustomDate(item.created_at);
      // console.log('parsed date', date);
      const month = date.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      // console.log('month', month);

      // Initialize month if it doesn't exist
      if (!monthlyData[month]) {
        monthlyData[month] = {
          total: 0,
          newAcc: 0,
          notNew: 0,
          walletRecharge: 0,
          // walletRechargeAmount : 0,
          coinsBuy: 0,
          tcPay: 0,
        };
      }

      // Increment the total count for this month
      monthlyData[month].total += 1;

      // Increment the new account count if the transaction type is "New Account"

      if (
        item.transaction &&
        item.transaction.transition_type === "New Account"
      ) {
        monthlyData[month].newAcc += 1;
      } else if (
        item.transaction &&
        item.transaction.transition_type !== "New Account"
      ) {
        monthlyData[month].notNew += 1;
      }
      // -------------
      if (item.gateway == 1 && +item.verified_by > 1) {
        monthlyData[month].walletRecharge += 1;
        // monthlyData[month].walletRechargeAmount += +item.amount;
      } else if (item.gateway == 2) {
        monthlyData[month].coinsBuy += 1;
      } else if (item.gateway == 3) {
        monthlyData[month].tcPay += 1;
      }
    });
  }

  const labels = Object.keys(monthlyData);
  const counts = Object.values(monthlyData).map((item) => item.total);
  const newAccCounts = Object.values(monthlyData).map((item) => item.newAcc);
  const notNewAcc = Object.values(monthlyData).map((item) => item.notNew);
  const coinsBuy = Object.values(monthlyData).map((item) => item.coinsBuy);
  const walletRecharge = Object.values(monthlyData).map(
    (item) => item.walletRecharge
  );
  const tcPay = Object.values(monthlyData).map((item) => item.tcPay);
  // const walletRechargeAmount = Object.values(monthlyData).map((item) => item.walletRechargeAmount);
  return {
    labels,
    counts,
    newAccCounts,
    notNewAcc,
    tcPay,
    walletRecharge,
    coinsBuy,
  };
};
const processChartData = (data) => {
  const typeCounts = {};
  if (Array.isArray(data)) {
    data.forEach((d) => {
      const productType = d.order?.product?.type;
      const productTitle = d.order?.product?.title;

      if (productType && productTitle) {
        // Initialize the type if it doesn't exist
        if (!typeCounts[productType]) {
          typeCounts[productType] = {};
        }

        // Count each title within the type
        if (!typeCounts[productType][productTitle]) {
          typeCounts[productType][productTitle] = 1;
        } else {
          typeCounts[productType][productTitle] += 1;
        }
      }
    });
  }
  // Extract labels and datasets from typeCounts
  const labels = Object.keys(typeCounts); // Product types as labels

  // Prepare datasets based on each title across types
  const titleCounts = {}; // Temporary object to collect title counts across types

  labels.forEach((type) => {
    const titles = typeCounts[type];
    Object.keys(titles).forEach((title) => {
      if (!titleCounts[title]) {
        titleCounts[title] = Array(labels.length).fill(0); // Initialize array for each title with zeroes
      }
      titleCounts[title][labels.indexOf(type)] = titles[title];
    });
  });

  const datasets = Object.keys(titleCounts).map((title) => ({
    label: title,
    data: titleCounts[title],
    backgroundColor: `rgba(
       ${Math.floor(Math.random() * 255)},
       ${Math.floor(Math.random() * 255)}, 
       ${Math.floor(Math.random() * 255)}
       , 1)`,
  }));

  return { labels, datasets };
};
const processDataByWeekday = (data) => {
  const weeklyData = {};
  // console.log('my second data', data);
  if (Array.isArray(data)) {
    data.forEach((item) => {
      const date = parseCustomDate(item.created_at);
      // console.log('parsed date', date);
      const weekday = date.toLocaleString("default", { weekday: "long" }); // Get day of the week
      // console.log('weekday', weekday);
      // Initialize weekday if it doesn't exist
      if (!weeklyData[weekday]) {
        weeklyData[weekday] = {
          total: 0,
          newAcc: 0,
          notNew: 0,
          walletRecharge: 0,
          // walletRechargeAmount : 0,
          coinsBuy: 0,
          tcPay: 0,
        };
      }

      // Increment the total count for this day
      weeklyData[weekday].total += 1;
      // Increment counts based on transaction type
      if (
        item.transaction &&
        item.transaction.transition_type === "New Account"
      ) {
        weeklyData[weekday].newAcc += 1;
      } else if (
        item.transaction &&
        item.transaction.transition_type !== "New Account"
      ) {
        weeklyData[weekday].notNew += 1;
      }
      //---------------
      if (+item.gateway == 1 && +item.verified_by > 1) {
        weeklyData[weekday].walletRecharge += 1;
        // weeklyData[weekday].walletRechargeAmount += +item.amount;
      } else if (item.gateway == 2) {
        weeklyData[weekday].coinsBuy += 1;
      } else if (item.gateway == 3) {
        weeklyData[weekday].tcPay += 1;
      }
    });
  }
  const labels = [
    "Saturday",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
  ];
  const counts = Object.values(weeklyData).map((item) => item.total);
  const newAccCounts = Object.values(weeklyData).map((item) => item.newAcc);
  const notNewAcc = Object.values(weeklyData).map((item) => item.notNew);
  const coinsBuy = Object.values(weeklyData).map((item) => item.coinsBuy);
  const walletRecharge = Object.values(weeklyData).map(
    (item) => item.walletRecharge
  );
  // const walletRechargeAmount = Object.values(weeklyData).map((item) => item.walletRechargeAmount);
  const tcPay = Object.values(weeklyData).map((item) => item.tcPay);
  return {
    labels,
    counts,
    newAccCounts,
    notNewAcc,
    tcPay,
    walletRecharge,
    coinsBuy,
  };
};
const processDataByHour = (data) => {
  const hourlyData = {};

  if (Array.isArray(data)) {
    data.forEach((item) => {
      const date = parseCustomDate(item.created_at);
      const hour = date.getHours(); // Get the hour (0-23)

      // Initialize hour if it doesn't exist
      if (!hourlyData[hour]) {
        hourlyData[hour] = {
          total: 0,
          newAcc: 0,
          notNew: 0,
          walletRecharge: 0,
          // walletRechargeAmount : 0,
          coinsBuy: 0,
          tcPay: 0,
        };
      }

      // Increment the total count for this hour
      hourlyData[hour].total += 1;

      // Increment counts based on transaction type
      if (
        item.transaction &&
        item.transaction.transition_type === "New Account"
      ) {
        hourlyData[hour].newAcc += 1;
      } else if (
        item.transaction &&
        item.transaction.transition_type !== "New Account"
      ) {
        hourlyData[hour].notNew += 1;
      }
      //---------------
      if (item.gateway == 1 && +item.verified_by > 1) {
        hourlyData[hour].walletRecharge += 1;
        // hourlyData[hour].walletRechargeAmount += +item.amount;
      } else if (item.gateway == 2) {
        hourlyData[hour].coinsBuy += 1;
      } else if (item.gateway == 3) {
        hourlyData[hour].tcPay += 1;
      }
    });
  }

  // Generate labels for each hour (0 to 23) to ensure all hours are included, even if they have no data
  const labels = Array.from({ length: 24 }, (_, i) => `${i}:00`);

  // Map the data for each hour, filling in zeroes if there was no data for a particular hour
  const counts = labels.map((label) => hourlyData[parseInt(label)]?.total || 0);
  const newAccCounts = labels.map(
    (label) => hourlyData[parseInt(label)]?.newAcc || 0
  );
  const notNewAcc = labels.map(
    (label) => hourlyData[parseInt(label)]?.notNew || 0
  );
  const walletRecharge = labels.map(
    (label) => hourlyData[parseInt(label)]?.walletRecharge || 0
  );
  // const walletRechargeAmount = labels.map((label) => hourlyData[parseInt(label)]?.walletRechargeAmount || 0);

  const coinsBuy = labels.map(
    (label) => hourlyData[parseInt(label)]?.coinsBuy || 0
  );
  const tcPay = labels.map((label) => hourlyData[parseInt(label)]?.tcPay || 0);

  return {
    labels,
    counts,
    newAccCounts,
    notNewAcc,
    walletRecharge,
    tcPay,
    coinsBuy,
  };
};

const Analyze = (props) => {
  // console.log('prop type, ',props);
  // console.log("props.data", props.data);

  let count = 0;
  if (props.data.length > 0) {
    props.data.forEach((item) => {
      if (item.gateway === 1 && item.verified_by > 1) {
        count++;
      }
    });
  }

  // console.log("count = ", count);
  const chartData = (e) => {

    if (e === "month") {
      const { labels, counts, newAccCounts, notNewAcc } = processDataByMonth(
        props.data
      );
      return {
        labels: labels,
        datasets: [
          {
            label: "تعداد کل حساب های ایجاد شده",
            data: counts,
            backgroundColor: "blue",
          },
          {
            label: "تعداد حساب های با پرداخت",
            data: newAccCounts,
            backgroundColor: "rgba(75, 192, 80, 1)",
          },
          {
            label: "تعداد حساب های بدون پرداخت",
            data: notNewAcc,
            backgroundColor: "red",
          },
        ],
      };
    } else if (e === "week") {
      const { labels, counts, newAccCounts, notNewAcc } = processDataByWeekday(
        props.data
      );
      return {
        labels: labels,
        datasets: [
          {
            label: "تعداد کل حساب های ایجاد شده",
            data: counts,
            backgroundColor: "blue",
          },
          {
            label: "تعداد حساب های با پرداخت",
            data: newAccCounts,
            backgroundColor: "rgba(75, 192, 80, 1)",
          },
          {
            label: "تعداد حساب های بدون پرداخت",
            data: notNewAcc,
            backgroundColor: "red",
          },
        ],
      };
    } else if (e === "hour") {
      const { labels, counts, newAccCounts, notNewAcc } = processDataByHour(
        props.data
      );
      return {
        labels: labels,
        datasets: [
          {
            label: "تعداد کل حساب های ایجاد شده",
            data: counts,
            backgroundColor: "blue",
          },
          {
            label: "تعداد حساب های با پرداخت",
            data: newAccCounts,
            backgroundColor: "rgba(75, 192, 80, 1)",
          },
          {
            label: "تعداد حساب های بدون پرداخت",
            data: notNewAcc,
            backgroundColor: "red",
          },
        ],
      };
    }
  };
  const chartData2 = (e) => {
    if (e === "month") {
      // console.log('props data', props.data)
      const { labels, counts, tcPay, walletRecharge, coinsBuy } =
        processDataByMonth(props.data);
      //  const dd = processDataByMonth(props.data)
      //  console.log('processDataByMonth(props.data)', dd)

      return {
        labels: labels,
        datasets: [
          {
            label: "تعداد کل تراکنش ها",
            data: counts,
            backgroundColor: "yellowgreen",
          },
          {
            label: "واریز سود",
            data: walletRecharge,
            backgroundColor: "orange",
          },
          // {
          //   label: 'واریز سود (مقدار)',
          //   data: walletRechargeAmount,
          //   backgroundColor: 'purple',
          // },

          {
            label: "COINSBUY",
            data: coinsBuy,
            backgroundColor: "blue",
          },
          {
            label: "TOP CHANGE",
            data: tcPay,
            backgroundColor: "white",
          },
        ],
      };
    } else if (e === "week") {
      const { labels, counts, tcPay, walletRecharge, coinsBuy } =
        processDataByWeekday(props.data);

      return {
        labels: labels,
        datasets: [
          {
            label: "تعداد کل تراکنش ها",
            data: counts,
            backgroundColor: "yellowgreen",
          },
          // {
          //   label: 'واریز سود (مقدار)',
          //   data: walletRechargeAmount,
          //   backgroundColor: 'purple',
          // },
          {
            label: "واریز سود",
            data: walletRecharge,
            backgroundColor: "orange",
          },
          {
            label: "COINSBUY",
            data: coinsBuy,
            backgroundColor: "blue",
          },
          {
            label: "TOP CHANGE",
            data: tcPay,
            backgroundColor: "white",
          },
        ],
      };
    } else if (e === "hour") {
      const { labels, counts, tcPay, walletRecharge, coinsBuy } =
        processDataByHour(props.data);
      return {
        labels: labels,
        datasets: [
          {
            label: "تعداد کل تراکنش ها",
            data: counts,
            backgroundColor: "yellowgreen",
          },
          {
            label: "واریز سود",
            data: walletRecharge,
            backgroundColor: "orange",
          },
          //  {
          //   label: 'واریز سود (مقدار)',
          //   data: walletRechargeAmount,
          //   backgroundColor: 'purple',
          // },
          {
            label: "COINSBUY",
            data: coinsBuy,
            backgroundColor: "blue",
          },
          {
            label: "TOP CHANGE",
            data: tcPay,
            backgroundColor: "white",
          },
        ],
      };
    }
  };
  const options = (e) => {
    if (e === "month") {
      return {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
            labels: {
              color: "white", // Set color for legend labels
              font: {
                family: "iransans", // Optional: set font family
                size: 14, // Optional: set font size
              },
            },
          },
          title: {
            display: true,
            text: "نمودار ماهانه حساب های ایجاد شده",
            color: "white", // Set color for the title
            font: {
              family: "yekan", // Optional: set font family
              size: 20, // Optional: set font size
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "white", // Set color for X-axis labels
              font: {
                family: "iransans", // Optional: set font family
              },
            },
            grid: {
              color: "rgba(200, 200, 200, 0.2)", // Customize X-axis grid color
            },
          },
          y: {
            ticks: {
              color: "white", // Set color for Y-axis labels
              font: {
                family: "iransans", // Optional: set font family
              },
            },
            grid: {
              color: "rgba(200, 200, 200, 0.2)", // Customize Y-axis grid color
            },
            beginAtZero: true,
          },
        },
      };
    } else if (e === "week") {
      return {
        responsive: true,
        indexAxis: "y",
        plugins: {
          legend: {
            position: "top",
            labels: {
              color: "white", // Set color for legend labels
              font: {
                family: "iransans", // Optional: set font family
                size: 14, // Optional: set font size
              },
            },
          },
          title: {
            display: true,
            text: "نمودار هفتگی حساب های ایجاد شده",
            color: "white", // Set color for the title
            font: {
              family: "yekan", // Optional: set font family
              size: 20, // Optional: set font size
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "white", // Set color for X-axis labels
              font: {
                family: "iransans", // Optional: set font family
              },
            },
            grid: {
              color: "rgba(200, 200, 200, 0.2)", // Customize X-axis grid color
            },
          },
          y: {
            ticks: {
              color: "white", // Set color for Y-axis labels
              font: {
                family: "iransans", // Optional: set font family
              },
            },
            grid: {
              color: "rgba(200, 200, 200, 0.2)", // Customize Y-axis grid color
            },
            beginAtZero: true,
          },
        },
      };
    } else if (e === "hour") {
      return {
        responsive: true,
        indexAxis: "x",
        plugins: {
          legend: {
            position: "top",
            labels: {
              color: "white", // Set color for legend labels
              font: {
                family: "iransans", // Optional: set font family
                size: 14, // Optional: set font size
              },
            },
          },
          title: {
            display: true,
            text: "نمودار ساعتی حساب های ایجاد شده",
            color: "white", // Set color for the title
            font: {
              family: "yekan", // Optional: set font family
              size: 20, // Optional: set font size
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "white", // Set color for X-axis labels
              font: {
                family: "iransans", // Optional: set font family
              },
            },
            grid: {
              color: "rgba(200, 200, 200, 0.2)", // Customize X-axis grid color
            },
          },
          y: {
            ticks: {
              color: "white", // Set color for Y-axis labels
              font: {
                family: "iransans", // Optional: set font family
              },
            },
            grid: {
              color: "rgba(200, 200, 200, 0.2)", // Customize Y-axis grid color
            },
            beginAtZero: true,
          },
        },
      };
    }
  };
  const options2 = (e) => {
    if (e === "month") {
      return {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
            labels: {
              color: "white",
              font: {
                family: "iransans",
                size: 14,
              },
            },
          },
          title: {
            display: true,
            text: "نمودار ماهانه تراکنش های ایجاد شده",
            color: "white",
            font: {
              family: "yekan",
              size: 20,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "white", // Set color for X-axis labels
              font: {
                family: "iransans", // Optional: set font family
              },
            },
            grid: {
              color: "rgba(200, 200, 200, 0.2)", // Customize X-axis grid color
            },
          },
          y: {
            ticks: {
              color: "white", // Set color for Y-axis labels
              font: {
                family: "iransans", // Optional: set font family
              },
            },
            grid: {
              color: "rgba(200, 200, 200, 0.2)", // Customize Y-axis grid color
            },
            beginAtZero: true,
          },
        },
      };
    } else if (e === "week") {
      return {
        responsive: true,
        indexAxis: "y",
        plugins: {
          legend: {
            position: "top",
            labels: {
              color: "white", // Set color for legend labels
              font: {
                family: "iransans", // Optional: set font family
                size: 14, // Optional: set font size
              },
            },
          },
          title: {
            display: true,
            text: "نمودار هفتگی تراکنش های ایجاد شده",
            color: "white", // Set color for the title
            font: {
              family: "yekan", // Optional: set font family
              size: 20, // Optional: set font size
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "white", // Set color for X-axis labels
              font: {
                family: "iransans", // Optional: set font family
              },
            },
            grid: {
              color: "rgba(200, 200, 200, 0.2)", // Customize X-axis grid color
            },
          },
          y: {
            ticks: {
              color: "white", // Set color for Y-axis labels
              font: {
                family: "iransans", // Optional: set font family
              },
            },
            grid: {
              color: "rgba(200, 200, 200, 0.2)", // Customize Y-axis grid color
            },
            beginAtZero: true,
          },
        },
      };
    } else if (e === "hour") {
      return {
        responsive: true,
        indexAxis: "x",
        plugins: {
          legend: {
            position: "top",
            labels: {
              color: "white", // Set color for legend labels
              font: {
                family: "iransans", // Optional: set font family
                size: 14, // Optional: set font size
              },
            },
          },
          title: {
            display: true,
            text: "نمودار ساعتی تراکنش های ایجاد شده",
            color: "white", // Set color for the title
            font: {
              family: "yekan", // Optional: set font family
              size: 20, // Optional: set font size
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "white", // Set color for X-axis labels
              font: {
                family: "iransans", // Optional: set font family
              },
            },
            grid: {
              color: "rgba(200, 200, 200, 0.2)", // Customize X-axis grid color
            },
          },
          y: {
            ticks: {
              color: "white", // Set color for Y-axis labels
              font: {
                family: "iransans", // Optional: set font family
              },
            },
            grid: {
              color: "rgba(200, 200, 200, 0.2)", // Customize Y-axis grid color
            },
            beginAtZero: true,
          },
        },
      };
    } else if (e === "product") {
      return {
        responsive: true,
        indexAxis: "x",
        plugins: {
          legend: {
            position: "top",
            labels: {
              color: "white", // Set color for legend labels
              font: {
                family: "iransans", // Optional: set font family
                size: 14, // Optional: set font size
              },
            },
          },
          title: {
            display: true,
            text: "نمودار بر اساس محصولات",
            color: "white", // Set color for the title
            font: {
              family: "yekan", // Optional: set font family
              size: 20, // Optional: set font size
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "white", // Set color for X-axis labels
              font: {
                family: "iransans", // Optional: set font family
              },
            },
            grid: {
              color: "rgba(200, 200, 200, 0.2)", // Customize X-axis grid color
            },
          },
          y: {
            ticks: {
              color: "white", // Set color for Y-axis labels
              font: {
                family: "iransans", // Optional: set font family
              },
            },
            grid: {
              color: "rgba(200, 200, 200, 0.2)", // Customize Y-axis grid color
            },
            beginAtZero: true,
          },
        },
      };
    }
  };

  const mainShow = () => {
    
    if (props.type == 2) {
      return (
        <div>
          <Bar
            style={{}}
            data={chartData2("month", props.type)}
            options={options2("month")}
          />
          <Bar
            style={{}}
            data={chartData2("week", props.type)}
            options={options2("week")}
          />
          <Bar
            style={{}}
            data={chartData2("hour", props.type)}
            options={options2("hour")}
          />
        </div>
      );
    } else if (props.type == 1) {
      return (
        <div>
          <Bar
            style={{}}
            data={chartData("month", props.type)}
            options={options("month")}
          />
          <Bar
            style={{}}
            data={chartData("week", props.type)}
            options={options("week")}
          />
          <Bar
            style={{}}
            data={chartData("hour", props.type)}
            options={options("hour")}
          />
          <hr style={styles.separator} />

<div style={styles.container}>
            <h2 style={styles.title}>فیلتر قیمت محصول</h2>
            <form onSubmit={handleSubmit} style={styles.form}>
              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="productType">
                  نوع محصول:
                </label>
                <select
                  id="productType"
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                  style={styles.select}
                >
                  <option style={{color:'black'}} value="">انتخاب کنید</option>
                  <option style={{color:'black'}} value="all">همه محصولات</option>
                  <option style={{color:'black'}} value="چالش دو مرحله ای">چالش دو مرحله ای</option>
                  <option style={{color:'black'}} value="چالش تک مرحله ای">چالش تک مرحله‌ای</option>
                  <option style={{color:'black'}} value="پرو تریدر">پرو تریدر</option>
                </select>
              </div>
              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="priceFrom">
                  قیمت محصول از:
                </label>
                <input
                  type="number"
                  id="priceFrom"
                  value={priceFrom}
                  onChange={(e) => setPriceFrom(e.target.value)}
                  placeholder="مثال: 100000"
                  style={styles.input}
                />
              </div>
              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="priceTo">
                  قیمت محصول تا:
                </label>
                <input
                  type="number"
                  id="priceTo"
                  value={priceTo}
                  onChange={(e) => setPriceTo(e.target.value)}
                  placeholder="مثال: 500000"
                  style={styles.input}
                />
              </div>
              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="range">
                  بازه:
                </label>
                <select
                  id="range"
                  value={range}
                  onChange={(e) => setRange(e.target.value)}
                  style={styles.select}
                >
                  <option style={{color:'black'}} value="">انتخاب کنید</option>
                  <option style={{color:'black'}} value="20000">20k</option>
                  <option style={{color:'black'}} value="40000">40k</option>
                  <option style={{color:'black'}} value="50000">50k</option>
                  <option style={{color:'black'}} value="60000">60k</option>
                  <option style={{color:'black'}} value="70000">70k</option>
                </select>
              </div>

              <button type="submit" style={styles.button}>
                اعمال فیلتر
              </button>
              
            </form>





 
          </div>

          {chartData && (
            <div>
              <h3 style={{textAlign:'center'}}>نتایج فیلتر</h3>
              <Bar
                data={mychartData || { labels: [], datasets: [] }}
                options={chartOptionsWithLegend}
                ref={chartRef}
              />
              <button
              
                style={styles.button}
                onClick={handleBackToPreviousRange}
                disabled={!currentRange.step || currentRange.step === 100000} // غیرفعال‌سازی در حالت بازه اصلی
              >
                بازگشت به بازه قبلی
              </button>
            </div>
          )}
        </div>
      );
    }
  };

  // const handleBackToPreviousRange = () => {
  //   if (!currentRange || !currentRange.step) return;

  //   let newStep;

  //   // تعیین گام جدید بر اساس بازه فعلی
  //   if (currentRange.step === 1000) {
  //     newStep = 10000; // از 1k به 10k
  //   } else if (currentRange.step === 10000) {
  //     newStep = 100000; // از 10k به 100k
  //   } else {
  //     return; // در بازه اصلی هیچ کاری انجام نمی‌شود
  //   }

  //   // بازه جدید
  //   const newFrom = currentRange.from;
  //   const newTo = currentRange.to;

  //   // به‌روزرسانی نمودار
  //   updateChartData(newFrom, newTo);
  // };

  const styles = {
    container: {
      maxWidth: "100%",
      margin: "20px auto",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // حفظ سایه برای جذابیت
    },
    title: {
      textAlign: "center",
      marginBottom: "20px",
      fontSize: "20px",
      color: "white",
    },
    form: {
      display: "flex",
      flexDirection: "row",
      justifyContent:'space-evenly',
    },
    formGroup: {
      marginBottom: "15px",
    },
    label: {
      marginBottom: "5px",
      fontSize: "14px",
      color: "#fff",
      display: "block",
    },
    input: {
      background: 'rgba(0, 128, 126, 0.297)',
      color: 'white',
      outline: '0',
      border: '1px solid white',
      borderRadius:' 5px',
      fontSize: '12px',
      padding: '5px',
      width: '100%',
    },
    select: {
      background: 'rgba(0, 128, 126, 0.297)',
      color: 'white',
      outline: '0',
      border: '1px solid white',
      borderRadius:' 5px',
      fontSize: '12px',
      padding: '5px',
      width: '100%',
      transition: "border-color 0.3s ease",
    },
    button: {
      marginTop: '25px',
      height:'50%',
      padding: "5px 5px",
      fontSize: "12px",
      color: "black",
      backgroundColor: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
      width: '8rem',

    },
    separator: {
      border: "none", // حذف استایل پیش‌فرض
      height: "2px", // ضخامت خط
      backgroundColor: "white", // رنگ خط
      margin: "20px 0", // فاصله از بالا و پایین
    },
  };

  const [priceFrom, setPriceFrom] = useState("");
  const [priceTo, setPriceTo] = useState("");
  const [range, setRange] = useState("");
  const [productType, setProductType] = useState(""); // Input برای فیلتر نوع محصول
  const chartRef = useRef(null);
  const [currentRange, setCurrentRange] = useState({
    from: null,
    to: null,
    step: 100000,
  });
  const [previousRanges, setPreviousRanges] = useState([]); // ذخیره چند بازه قبلی
  const [initialRange, setInitialRange] = useState(null);

  const handleBackToPreviousRange = () => {
    console.log('prev state ', previousRanges);
  
    if (previousRanges.length > 0) {
      // گرفتن آخرین بازه از صف بازه‌های قبلی
      const lastRange = previousRanges[previousRanges.length - 1];
  
      console.log("بازگشت به بازه قبلی:", lastRange);
  
      // حذف آخرین بازه از صف
      setPreviousRanges((prev) => prev.slice(0, -1));
  
      // تنظیم آخرین بازه به‌عنوان بازه فعلی
      setCurrentRange({ ...lastRange });
  
      // به‌روزرسانی نمودار با بازه قبلی
      updateChartData(lastRange.from, lastRange.to, lastRange.step);
    } else if (initialRange) {
      console.log("بازگشت به بازه اولیه:", initialRange);
  
      // تنظیم بازه اولیه به‌عنوان بازه فعلی
      setCurrentRange({ ...initialRange });
  
      // به‌روزرسانی نمودار با بازه اولیه
      updateChartData(initialRange.from, initialRange.to, initialRange.step);
    } else {
      console.log("هیچ بازه‌ای برای بازگشت موجود نیست.");
    }
  };
  
  // const [mychartData, setChartData] = useState(null);
  const [mychartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const handleSubmit = (e) => {
    e.preventDefault();
  
    console.log("dddd", priceFrom, priceTo, range);
    if (!priceFrom || !priceTo || !range || !productType) {
      alert("لطفاً تمام فیلدها را پر کنید.");
      return;
    }
  
    const from = parseInt(priceFrom, 10);
    const to = parseInt(priceTo, 10);
    const step = parseInt(range, 10);
  
    if (to <= from || step <= 0) {
      alert("مقادیر واردشده معتبر نیست.");
      return;
    }
  
    // ذخیره بازه اولیه
    setInitialRange({ from, to, step });
  
    // پاک کردن بازه‌های قبلی
    setPreviousRanges([]);
  
    // محاسبه داده‌های نمودار
    const processedData = processChartDataWithRanges(
      props.data,
      from,
      to,
      step,
      productType
    );
    setChartData(processedData);
  
    // ذخیره بازه فعلی
    setCurrentRange({ from, to, step });
  };
  
  
  const processChartDataWithRanges = (data, from, to, step) => {
    if (!Array.isArray(data) || data.length === 0) {
      console.warn("No data provided for processing");
      return {
        labels: [],
        datasets: [],
      };
    }

    if (from >= to || step <= 0) {
      console.error("Invalid range or step values");
      return {
        labels: [],
        datasets: [],
      };
    }

    const buckets = [];
    const ranges = [];
    const counts = [];
    const colors = [];

    let currentStart = from;

    while (currentStart < to) {
      const currentEnd = Math.min(currentStart + step, to); // بازه آخر محدود به مقدار `to`
      buckets.push({ start: currentStart, end: currentEnd });
      ranges.push(`${currentStart / 1000}k - ${currentEnd / 1000}k`);
      currentStart += step;

      // رنگ تصادفی برای هر بازه
      colors.push(
        `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
          Math.random() * 255
        )}, ${Math.floor(Math.random() * 255)}, 1)`
      );
    }

    buckets.forEach((bucket) => {
      const count = data.filter((item) => {
        let productAmount = 0;
        let productCategory = "";

        // بررسی مقدار قیمت و نوع محصول
        if (item.order && item.order.product) {
          productAmount =
            parseInt(item.order.product.title.replace("k", "")) * 1000;
          productCategory = item.order.product.type; // نوع محصول از product
        } else if (item.order && item.order.category) {
          productAmount = item.order.product_id;
          productCategory = item.order.category.title; // نوع محصول از category
        }

        // بررسی قرارگیری در بازه
        const isInRange =
          productAmount >= bucket.start && productAmount < bucket.end;

        // بررسی تطابق نوع محصول (اگر همه محصولات انتخاب نشده باشد)
        const isProductTypeMatch =
          productType === "all" || productCategory === productType;

        return isInRange && isProductTypeMatch; // بررسی نهایی
      }).length;

      counts.push(count);
    });

    return {
      labels: ranges,
      datasets: [
        {
          label: "تعداد محصولات در بازه‌ها",
          data: counts,
          backgroundColor: colors, // رنگ‌های هر بازه
          borderWidth: 1,
        },
      ],
    };
  };

  const handleBarClick = (event) => {
    if (!chartRef.current) return;

    const chart = chartRef.current;
    const elements = chart.getElementsAtEventForMode(
      event,
      "nearest",
      { intersect: true },
      false
    );

    if (elements.length === 0) {
      console.log("هیچ المانی انتخاب نشده است.");
      return;
    }

    const clickedIndex = elements[0].index; // ایندکس میله کلیک‌شده
    const rangeLabel = chart.data.labels[clickedIndex]; // بازه میله کلیک‌شده

    // استخراج بازه کلیک‌شده
    const [from, to] = rangeLabel.includes("-")
      ? rangeLabel
          .split(" - ")
          .map((value) => parseInt(value.replace("k", "") * 1000))
      : [
          parseInt(rangeLabel.replace("k", "") * 1000),
          parseInt(rangeLabel.replace("k", "") * 1000) + 1000,
        ];

    // جلوگیری از تغییرات در صورت بازه 1k
    if (to - from === 1000) {
      console.log("بازه 1k است، تغییر انجام نمی‌شود.");
      return;
    }

    // ذخیره بازه فعلی به آرایه بازه‌های قبلی
    if (currentRange.from !== null && currentRange.to !== null) {
      setPreviousRanges((prev) => {
        const updatedRanges = [...prev, { ...currentRange }];
        return updatedRanges.length > 3
          ? updatedRanges.slice(1)
          : updatedRanges;
      });
    }

    console.log("بازه انتخابی جدید:", from, to);

    // بروزرسانی نمودار با بازه جدید
    updateChartData(from, to);
  };

  const chartOptionsWithLegend = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            family: "iransans", // تنظیم فونت
            size: 14,
          },
          color: "white", // رنگ متن
        },
      },
      // title: {
      //   display: true,
      //   text: "نمودار بازه‌ای محصولات با رنگ‌بندی بازه‌ها",
      //   color: "white",
      //   font: {
      //     family: "yekan",
      //     size: 18,
      //   },
      // },
    },
    scales: {
      x: {
        ticks: {
          color: "white",
          font: {
            family: "iransans",
            size: 12,
          },
        },
      },
      y: {
        ticks: {
          color: "white",
          font: {
            family: "iransans",
            size: 12,
          },
        },
        beginAtZero: true,
      },
    },
    onClick: handleBarClick,
  };

  const updateChartData = (from, to, step = null) => {
    if (!step) {
      const rangeSize = to - from;
      if (rangeSize <= 10000) {
        step = 1000; // بازه‌های 1k
      } else if (rangeSize <= 100000) {
        step = 10000; // بازه‌های 10k
      } else {
        step = 100000; // بازه‌های 100k
      }
    }
  
    // ذخیره بازه فعلی
    setCurrentRange({ from, to, step });
  
    const buckets = [];
    const ranges = [];
    const counts = [];
    const colors = [];
  
    let currentStart = from;
  
    while (currentStart < to) {
      const currentEnd = Math.min(currentStart + step, to);
      if (step === 1000) {
        ranges.push(`${currentEnd / 1000}k`);
      } else {
        ranges.push(`${currentStart / 1000}k - ${currentEnd / 1000}k`);
      }
      buckets.push({ start: currentStart, end: currentEnd });
      currentStart += step;
  
      colors.push(
        `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
          Math.random() * 255
        )}, ${Math.floor(Math.random() * 255)}, 1)`
      );
    }
  
    buckets.forEach((bucket) => {
      const count = props.data.filter((item) => {
        let productAmount = 0;
  
        if (item.order && item.order.product) {
          const title = item.order.product.title;
          if (title.endsWith("k")) {
            productAmount = parseInt(title.replace("k", ""), 10) * 1000;
          }
        } else if (item.order && item.order.product_id) {
          productAmount = item.order.product_id;
        }
  
        return productAmount >= bucket.start && productAmount < bucket.end;
      }).length;
  
      counts.push(count);
    });
  
    setChartData({
      labels: ranges,
      datasets: [
        {
          label: "تعداد محصولات در بازه‌ها",
          data: counts,
          backgroundColor: colors,
          borderWidth: 1,
        },
      ],
    });
  };

  
if (Array.isArray(props.data)) {
  return( 
    <div>{mainShow()}</div>
  );

}


};

export default Analyze;
