/* eslint-disable react/prop-types */
import styled from "styled-components";
import TruncatedText from "./TruncatedText";
import { detectDirection } from "../../../utils/helper";

const Container = styled.div`
  width: 100%;
  background-color: var(--color-green-900);
  padding: 1rem;
  position: absolute;
  bottom: 100%;
  max-height: 30rem;
  overflow-y: scroll;
  scrollbar-width: thin;
  color: var(--color-grey-100);

  & ul {
    list-style: none;
    white-space: preserve-breaks;

    & .title {
      text-align: center;
      background-color: var(--color-grey-700);
      border: 1px solid var(--color-grey-50);
      border-radius: var(--border-radius-lg);
      margin-bottom: 1.2rem;
    }

    & li {
      text-align: center;
      font-size: 1rem;
      border: 1px solid var(--color-grey-300);
      border-radius: var(--border-radius-lg);
      padding: 0.5rem;
      margin-bottom: 1rem;
      direction: auto;
      cursor: pointer;

      &.active {
        border: 1px solid var(--color-brand-500);
        background-color: var(--color-brand-500);
      }

      .highlight {
        background-color: var(--color-green-700);
        color: var(--color-blue-100);
        font-weight: bold;
      }
    }
  }
`;

function QuickResponse({
  quickResponse,
  quickResponseRefs,
  selectedCategoryIndex,
  selectedQuickResponseIndex,
  handleQuickResponseSelect,
  highlightText,
  quickResponseSearch,
}) {
  return (
    <>
      {quickResponse.length !== 0 ? (
        <Container>
          {quickResponse.map((category, categoryIndex) => (
            <ul key={categoryIndex}>
              <div className="title">{category.title}</div>
              {category.texts.map((text, textIndex) => (
                <li
                  key={text.id}
                  ref={(el) => {
                    if (!quickResponseRefs.current[categoryIndex]) {
                      quickResponseRefs.current[categoryIndex] = [];
                    }
                    quickResponseRefs.current[categoryIndex][textIndex] = el;
                  }}
                  className={
                    categoryIndex === selectedCategoryIndex &&
                    textIndex === selectedQuickResponseIndex
                      ? "active"
                      : ""
                  }
                  onClick={() => handleQuickResponseSelect(text.text)}
                >
                  <TruncatedText isRtl={detectDirection(text.text)}>
                    {highlightText(text.text, quickResponseSearch)}
                  </TruncatedText>
                </li>
              ))}
            </ul>
          ))}
        </Container>
      ) : null}
    </>
  );
}

export default QuickResponse;
