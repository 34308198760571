import { BiError } from "react-icons/bi";
import styled from "styled-components";

const Div = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-brand-500);
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

/* eslint-disable react/prop-types */
function Error({ message }) {
  return (
    <Div>
      <BiError />
      <p>{message}</p>
    </Div>
  );
}

export default Error;
