import { useMutation, useQueryClient } from "@tanstack/react-query";
import useToken from "./hooks/useToken";
import { banUser as banUseApi } from "../../../services/apiMessages";

export function useBan() {
  const queryClient = useQueryClient();
  const token = useToken();

  const { mutate: banUser, isPending: isBanning } = useMutation({
    mutationFn: ({ userId, data }) => banUseApi({ token, userId, data }),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ["conversations"] });
    },
    onError: (err) => console.error(err.message),
  });

  return { banUser, isBanning };
}
