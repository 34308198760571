/* eslint-disable react/prop-types */
// import styled from "styled-components";
import Chat from "./Chat";
import AllChats from "./AllChats";
import { useContext, useEffect, useMemo, useState } from "react";

import { useConversations } from "./useConversations";
// import useToken from "../../hooks/useToken";
// import newChat from "../../sounds/newChat.mp3";
// import { useResizePane } from "./hooks/useResizePane";
import { useDispatch, useSelector } from "react-redux";
import LeftSideWrapper from "./LeftSideWrapper";
import { updateConversations } from "./slices/userCountSlice";
import UserContext from "../../UserContext/UserContext";
import ButtonIcon from "./ButtonIcon";
import {
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
} from "react-icons/hi2";
// import { useNotification } from "../../hooks/useNotification";
// import { useNavigate } from "react-router-dom";

// const Resizer = styled.div`
//   width: 5px;
//   cursor: col-resize;
//   background-color: var(--color-grey-200);
//   position: relative;
// `;

function MessageMainPart({ handleFormat, socket }) {
  // const { handleMouseDown } = useResizePane();
  const dispatch = useDispatch();
  // const { requestPermission, sendNotification } = useNotification();
  // const navigate = useNavigate();

  const { activeTab, activeChatId } = useSelector((state) => state.message);
  // const { leftWidth, rightWidth } = useSelector((state) => state.message);

  // const token = useToken();

  const { isLoading, data: initialConversations } = useConversations(); // Get initial conversations
  const { conversations } = useSelector((state) => state.userCount);

  // const { data: user } = useUser();
  const { user, url } = useContext(UserContext);
  const [isOpenChatList, setIsOpenChatList] = useState(false);

  const activeChat = conversations?.filter((con) => con.id === activeChatId);

  const my = useMemo(
    () =>
      conversations?.filter(
        (conversation) => conversation.support_id === user?.dataes?.id
      ),
    [conversations, user]
  );
  const pending = useMemo(
    () =>
      conversations?.filter((conversation) => conversation.support_id === null),
    [conversations]
  );
  const other = useMemo(
    () =>
      conversations?.filter(
        (conversation) =>
          conversation.support_id !== user?.dataes.id &&
          conversation.support_id !== null
      ),
    [conversations, user]
  );
  console.log(isOpenChatList);

  const cons =
    activeTab === "my" ? my : activeTab === "pending" ? pending : other;

  // Initialize conversations once data is loaded
  useEffect(() => {
    if (initialConversations) {
      dispatch(updateConversations(initialConversations));
    }
  }, [dispatch, initialConversations]);

  return (
    <LeftSideWrapper
      isOpenChatList={isOpenChatList}
      //  $leftWidth={leftWidth} $rightWidth={rightWidth}
    >
      <div className="container-me">
        <button
          className="btn-chat-menu"
          onClick={() => setIsOpenChatList((v) => !v)}
        >
          {isOpenChatList ? (
            <HiOutlineChevronDoubleLeft />
          ) : (
            <HiOutlineChevronDoubleRight />
          )}
        </button>
        <div className="massage container-part chat-pane">
          <Chat
            socket={socket}
            activeChat={activeChat}
            handleFormat={handleFormat}
            key={activeChatId}
          />
        </div>
        {/* <Resizer onMouseDown={handleMouseDown} /> */}
        <div className="massage-chat container-part chat-list-pane">
          <AllChats
            socket={socket}
            isLoading={isLoading}
            conversations={cons}
          />
        </div>
      </div>
    </LeftSideWrapper>
  );
}

export default MessageMainPart;
