import { useMutation, useQueryClient } from "@tanstack/react-query";
import useToken from "../messages/hooks/useToken";
import { editQuestion as editQuestionApi } from "../../../services/apiFAQ";

export function useEditQuestion() {
  const queryClient = useQueryClient();
  const token = useToken();

  const { mutate: editQuestion, isPending: isEditing } = useMutation({
    mutationFn: ({ id, data }) => editQuestionApi(id, data, token),
    onSuccess: () => {
      return queryClient.invalidateQueries(["categories"]);
    },
    onError: (err) => console.error(err.message),
  });

  return { editQuestion, isEditing };
}
