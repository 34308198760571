import { MAIN_URL } from "../utils/constants";
import { request } from "../utils/helper";

export async function getCategories(token) {
  try {
    const res = await request({
      url: `${MAIN_URL}/admin/info/categories`,
      token,
    });

    return res;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
}

export async function createCategory(data, token) {
  try {
    const res = await request({
      url: `${MAIN_URL}/admin/info/category/create`,
      data,
      method: "POST",
      token,
    });
    return res;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
}

export async function editCategory({ name, parent_id, id }, token) {
  try {
    const res = await request({
      url: `${MAIN_URL}/admin/info/category/${id}/update`,
      data: { name, parent_id },
      method: "POST",
      token,
    });
    return res;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
}

export async function deleteCategory(id, token) {
  try {
    const res = await request({
      url: `${MAIN_URL}/admin/info/category/${id}/delete`,
      method: "POST",
      token,
    });
    return res;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
}

export async function createQuestion(data, token) {
  try {
    const res = await request({
      url: `${MAIN_URL}/admin/info/faq/create`,
      data,
      method: "POST",
      token,
    });
    return res;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
}

export async function editQuestion(id, data, token) {
  console.log(id, data);

  try {
    const res = await request({
      url: `${MAIN_URL}/admin/info/faq/${id}/update`,
      data,
      method: "POST",
      token,
    });
    return res;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
}

export async function deleteQuestion(id, token) {
  try {
    const res = await request({
      url: `${MAIN_URL}/admin/info/faq/${id}/delete`,
      method: "POST",
      token,
    });
    return res;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
}
