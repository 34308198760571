import React, { useState, useEffect, useContext,useRef } from "react";
import axios from "axios";
import UserContext from "../UserContext/UserContext";
import Cookies from "js-cookie";

function Rules() {

  const sectionRef = useRef(null); // ایجاد یک ریفرنس برای سکشن
  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: "smooth", // اسکرول نرم
        block: "start", // نمایش سکشن از بالا
      });
    }
  };
  const { load, url } = useContext(UserContext);
  const [rules, setRules] = useState({
    protrader: [],
    oneStepRules: [],
    twoStepRules: [],
  });

  const [riskly, setriskly] = useState(false);

  useEffect(()=>{
    scrollToSection()
    
  },[riskly])

  const [activeTab, setActiveTab] = useState("AllRules");

  const parseDescriptionToObject = (description) => {
    const lines = description
      .split("\r\n")
      .filter((line) => line.trim() !== "");
    if (lines.length > 1) {
      const title = lines[0].trim();
      const obj = { title };
      lines.slice(1).forEach((line) => {
        const [key, value] = line.split(":");
        if (key && value) {
          obj[key.trim()] = value.trim();
        }
      });
      return obj;
    } else {
      return { details: lines[0].trim() };
    }
  };

  const removeEmptyObjects = (rulesArray) => {
    return rulesArray.filter((rule) => {
      return Object.keys(rule).length > 0;
    });
  };

  useEffect(() => {
    const fetchRules = async () => {
      load(true)
      try {
        const response = await axios.get(url + "api/v1/panel/category/rules", {
          headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        });

        const data = response.data;
        load(false)

        const processedRules = {
          protrader: [],
          oneStepRules: [],
          twoStepRules: [],
        };

        data.forEach((category) => {
          switch (category.type) {
            case "pro-trader":
              processedRules.protrader = removeEmptyObjects(
                category.rules.map((rule) =>
                  parseDescriptionToObject(rule.description)
                )
              );
              break;
            case "1-step":
              processedRules.oneStepRules = removeEmptyObjects(
                category.rules.map((rule) =>
                  parseDescriptionToObject(rule.description)
                )
              );
              break;
            case "2-step":
              processedRules.twoStepRules = removeEmptyObjects(
                category.rules.map((rule) =>
                  parseDescriptionToObject(rule.description)
                )
              );
              break;
            default:
              break;
          }
        });

        setRules(processedRules);
      } catch (error) {
        load(false)
        console.error("خطا در دریافت داده‌ها:", error);
      }
    };

    fetchRules();
  }, [url]);



  const renderRules = () => {
    const selectedRules =
      activeTab === "protrader"
        ? rules.protrader
        : activeTab === "oneStepRules"
        ? rules.oneStepRules
        : rules.twoStepRules;

    const detailedRules = selectedRules.filter((rule) => "details" in rule);
    const structuredRules = selectedRules.filter(
      (rule) => !("details" in rule)
    );

    return { detailedRules, structuredRules };
  };

  const { detailedRules, structuredRules } = renderRules();

  return (
    <div style={{padding:'0 1rem'}}>
      <div className="app-header-navigation">
        <div className="tabs" ref={sectionRef}>
        <a
            onClick={() => {setActiveTab("AllRules");}}
            className={activeTab === "AllRules" ? "active" : ""}
          >
            قوانین کلی
          </a>
          <a
            onClick={() => {setActiveTab("twoStepRules");  setriskly(false)}}
            className={activeTab === "twoStepRules" ? "active" : ""}
          >
            قوانین 2 مرحله‌ای
          </a>
          <a
            onClick={() => {setActiveTab("oneStepRules");  setriskly(false)}}
            className={activeTab === "oneStepRules" ? "active" : ""}
          >
            قوانین 1 مرحله‌ای
          </a>
          <a
            onClick={() => {setActiveTab("protrader");  setriskly(false)}}
            className={activeTab === "protrader" ? "active" : ""}
          >
            قوانین ProTrader
          </a>
        </div>
      </div>
      {!riskly ? (
        activeTab !== 'AllRules' ?
        <div style={{ marginTop: "2rem" }}>
          <h3 style={{ textAlign: "center" }}>
            {activeTab === "protrader"
              ? "قوانین ProTrader"
              : activeTab === "oneStepRules"
              ? "قوانین 1 مرحله‌ای"
              : "قوانین 2 مرحله‌ای"}
          </h3>

          <div
            style={{
              marginTop: "1rem",
              border: "1px solid #ccc",
              padding: "1rem",
            }}
          >
            <ul style={{ listStyle: "none", padding: 0 }}>
              {structuredRules.map((rule, index) => (
                <li
                  key={index}
                  style={{
                    marginBottom: "1rem",
                    border: "1px solid rgba(255, 255, 255, 0.2)",
                    borderRadius: "8px",
                    padding: "1rem",
                    position: "relative",
                    overflow: "hidden", // محدود کردن افکت بلور
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      // backgroundColor: "rgba(44, 121, 0, 0.45)",
                      filter: "blur(100rem)", // بلور فقط روی پس‌زمینه
                      zIndex: -1,
                    }}
                  ></div>

                  {rule.title && (
                    <h5
                      style={{
                        fontSize: "1.2rem",
                        marginBottom: "0.5rem",
                        textAlign: "center",
                        color: "#f0f8ff",
                      }}
                    >
                      {rule.title}
                    </h5>
                  )}

                  <div
                    //   style={{
                    //     display: "flex",
                    //     flexWrap: "wrap",
                    //     gap: "1rem",
                    //     justifyContent: "center",
                    //   }}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gap: "1rem",
                    }}
                  >
                    {Object.entries(rule).map(
                      ([key, value], i) =>
                        key !== "title" && (
                          <div
                            key={i}
                            style={{
                              position: "relative", // برای اعمال backdrop-filter
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              border: "1px solid #CB9E52",
                              borderRadius: "6px",
                              padding: "0.5rem 1rem",
                              minWidth: "120px",
                              textAlign: "center",
                              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                              backdropFilter: "blur(10px)", // تار کردن پس‌زمینه
                              backgroundColor: "rgba(255, 255, 255, 0.1)", // ایجاد شفافیت برای نشان دادن پس‌زمینه
                            }}
                          >
                            <strong
                              style={{ fontSize: "0.9rem", color: "#6dbb40" }}
                            >
                              {key}
                            </strong>
                            <span
                              style={{ fontSize: "0.85rem", color: "white" }}
                            >
                              {value}
                            </span>
                          </div>
                        )
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div
            style={{
              marginTop: "1rem",
              border: "1px solid #ccc",
              padding: "1rem 4rem",
            }}
          >
            <ul>
              {detailedRules.map((rule, index) => (
                <li key={index}>
                  <p>{rule.details}</p>
                </li>
              ))}
            </ul>
          </div>

        </div>
        : <>
        
        <div
            style={{
              marginTop: "2rem",
              padding: "1.5rem 4rem",
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "rgba(31, 31, 31, 0.5)",

              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h2
              style={{
                textAlign: "center",
                marginBottom: "1rem",
                color: "#ffd700",
              }}
            >
              قوانین مربوط به میزان ریسک مجاز روی حساب‌های سرمایه:
            </h2>

            <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
              قوانین ریسک مجاز روزانه:
            </h3>
            <p
              style={{
                lineHeight: "1.6",
                marginBottom: "1rem",
                color: "rgba(200, 200, 200, 1)",
              }}
            >
              مجموع ریسک مجاز معاملات باز و بسته در یک روز معاملاتی، در حساب‌های
              چالش دو مرحله‌ای ۵ درصد و در حساب‌های چالش تک مرحله‌ای ۴ درصد
              می‌باشند. مبنای محاسبه ریسک روزانه، بالانس ابتدای روز به وقت بروکر
              می‌باشد. در طول یک روز معاملاتی نباید اکوییتی حساب به پایین‌تر از
              درصد ریسک مجاز (۵ درصد دو مرحله‌ای و ۴ درصد تک مرحله‌ای)، نسبت به
              بالانس ابتدای روز برسد. در صورتی که تریدر در طول روز سود یا زیانی
              به دست بیاورد همچنان مبنای ریسک از بالانس ابتدای روز می‌باشد به
              عنوان مثال برای محاسبه ۵ درصد: بالانس ابتدای روز 10.000 باشد تریدر
              در ابتدا 500 دلار اجازه ریسک دارد طی معاملات همان روز حساب به
              10.200 رسیده حالا تریدر اجازه 700 دلار ریسک برروی حساب تا پایان
              روز معاملاتی دارد. در این روش محاسبه به تریدر اجازه داده شده تا
              سود بدست آورده خود در طی روز را ریسک کند تا امکان بدست آوردن تارگت
              بالاتری داشته باشد. شایان ذکر است، حساب‌های پرو تریدر محدودیتی
              برای ریسک مجاز روزانه ندارند.
            </p>

            <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
              قوانین ریسک کل:
            </h3>
            <p style={{ lineHeight: "1.6", color: "rgba(200, 200, 200, 1)" }}>
              مجموع ریسک معاملات باز و بسته نباید بیشتر از ۱۲ درصد (در حساب‌های
              با چالش دو مرحله‌ای)، ۸ درصد (در حساب‌های با چالش تک مرحله‌ای) و ۵
              درصد (در حساب‌های پروتریدر). مبنای محاسبه ریسک کل، سرمایه اولیه‌ای
              که در اختیار تریدر گذاشته شده است، می‌باشد.
            </p>
          </div>

          <div
            style={{
              marginTop: "2rem",
              padding: "1.5rem 4rem",
              border: "1px solid #ccc",
              borderRadius: "8px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              backgroundColor: "rgba(31, 31, 31, 0.5)", // معادل رنگ #1f1f1f
            }}
          >
            {/* بخش قوانین برداشت از حساب‌های سرمایه */}
            <section style={{ marginBottom: "2rem" }}>
              <h2
                style={{
                  textAlign: "center",
                  marginBottom: "1rem",
                  color: "#ffd700",
                }}
              >
                قوانین برداشت از حساب‌های سرمایه:
              </h2>
              <p
                style={{
                  lineHeight: "1.6",
                  marginBottom: "1rem",
                  color: "rgba(200, 200, 200, 1)",
                }}
              >
                سهمی از سود حاصل از انجام معاملات روی حساب‌های سرمایه، قابل
                برداشت توسط تریدر خواهد بود.
              </p>
              <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
                محاسبات تقسیم سود حساب‌های چالشی:
              </h3>
              <p
                style={{
                  lineHeight: "1.6",
                  marginBottom: "1rem",
                  color: "rgba(200, 200, 200, 1)",
                }}
              >
                نحوه تقسیم سود حاصل از معاملات در مرحله حساب اصلی، تا دو برداشت ۸۰ درصد به نفع تریدر و از برداشت سوم، ۹۰ درصد به نفع تریدر خواهد بود. برداشت از این حساب‌ها، مستلزم انجام معامله روی حساب به مدت حداقل ۱۰ روز معاملاتی، خواهد بود.
              </p>
              <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
                شرایط ریفاند (عودت وجه) حساب‌های چالشی:
              </h3>
              <p
                style={{
                  lineHeight: "1.6",
                  marginBottom: "1rem",
                  color: "rgba(200, 200, 200, 1)",
                }}
              >
                تریدر پس از پاس کردن چالش‌ها، هم‎‌زمان با برداشت سوم و فارغ از
                میزان سود کسب شده، 100 درصد مبلغ پرداختی جهت تهیه تیکت را دریافت
                خواهد کرد.
              </p>
              <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
                محاسبات تقسیم سود حساب‌های پرو تریدر:
              </h3>
              <p
                style={{
                  lineHeight: "1.6",
                  marginBottom: "1rem",
                  color: "rgba(200, 200, 200, 1)",
                }}
              >
                نحوه تقسیم سود حاصل از معاملات در حساب پرو تریدر، به صورت مساوی
                بین مجموعه و تریدر (٪۵۰ - ٪۵۰) خواهد بود. برداشت از این حساب‌ها،
                مستلزم انجام معامله روی حساب به مدت حداقل ۵ روز معاملاتی، خواهد
                بود.
              </p>
              <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
                روزهای انجام عملیات برداشت و پرداخت سود:
              </h3>
              <p style={{ lineHeight: "1.6", color: "rgba(200, 200, 200, 1)" }}>
              دوازدهم، سیزدهم، بیست و هفتم و بیست و هشتم هر ماه میلادی می‌باشد. شایان ذکر است، حساب‌های پرو تریدر محدودیت زمانی برای ارائه درخواست برداشت ندارند.
              </p>
              <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />
            </section>

            {/* بخش قوانین افزایش سرمایه حساب‌ها */}
            <section style={{ marginBottom: "2rem" }}>
              <h2
                style={{
                  textAlign: "center",
                  marginBottom: "1rem",
                  color: "#ffd700",
                }}
              >
                قوانین افزایش سرمایه حساب‌ها:
              </h2>
              <p
                style={{
                  lineHeight: "1.6",
                  marginBottom: "1rem",
                  color: "rgba(200, 200, 200, 1)",
                }}
              >
                سرمایه تقدیم شده به تریدرهای گرامی، در صورت سود ده بودن حساب‌های
                معاملاتی، به صورت منظم افزایش خواهد یافت.
              </p>
              <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
                افزایش سرمایه حساب‌های چالشی (در مرحله حساب اصلی):
              </h3>
              <p
                style={{
                  lineHeight: "1.6",
                  marginBottom: "1rem",
                  color: "rgba(200, 200, 200, 1)",
                }}
              >
                تریدر پس از هر برداشت جمعا 30 درصد سود، امکان درخواست افزایش
                حساب را خواهد داشت. در این صورت، حساب ایشان به میزان 30 درصد
                افزایش داده خواهد شد.
              </p>
              <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
                افزایش سرمایه حساب‌های پرو تریدر:
              </h3>
              <p
                style={{
                  lineHeight: "1.6",
                  marginBottom: "1rem",
                  color: "rgba(200, 200, 200, 1)",
                }}
              >
                حساب سرمایه ارائه شده، با هر بار برداشت سود، به میزان ۵۰ درصد به صورت خودکار افزایش خواهد یافت.
              </p>
              <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />
            </section>

            {/* دیگر بخش‌ها همینطور ادامه پیدا می‌کنند */}
          </div>

          <div
            style={{
              marginTop: "2rem",
              padding: "1.5rem 4rem",
              border: "1px solid #ccc",
              borderRadius: "8px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              backgroundColor: "rgba(31, 31, 31, 0.5)", // معادل رنگ #1f1f1f
            }}
          >
            {/* بخش محدودیت‌های حساب‌های معاملاتی */}
            <section style={{ marginBottom: "2rem" }}>
              <h2
                style={{
                  textAlign: "center",
                  marginBottom: "1rem",
                  color: "#ffd700",
                }}
              >
                محدودیت‌های حساب‌های معاملاتی:
              </h2>
              <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
                حساب‌های غیر فعال:
              </h3>
              <p
                style={{
                  lineHeight: "1.6",
                  marginBottom: "1rem",
                  color: "rgba(200, 200, 200, 1)",
                }}
              >
                در صورت عدم انجام معاملات بدون هماهنگی روی حساب‌های فعال به مدت بیشتر از ۳۰ روز، حساب مربوطه منسوخ خواهد شد.
              </p>
              <ul
                style={{ lineHeight: "1.6", color: "rgba(200, 200, 200, 1)" }}
              >
                <li>
                  در صورت هماهنگی از طریق ارسال تیکت به پشتیبانی فنی، حساب مذکور
                  قابلیت ذخیره به صورت غیر فعال تا مدت مشخص را خواهد داشت. لطفا
                  توجه داشته باشید، در صورت ارسال چنین تیکتی، کاربر اجازه انجام
                  معاملات روی حساب غیر فعال را تا زمان مشخص شده نخواهد داشت و در
                  صورت انجام معاملات، حساب فوق‌الذکر منسوخ خواهد شد.
                </li>
                <li>
                  در صورت فعال نکردن حساب دریافت شده در بدو تهیه، این حساب
                  قابلیت نگهداری به صورت غیر فعال تا مدت طولانی را خواهد داشت.
                  در صورتی که حساب در ۳ ماهه اول سال میلادی تهیه شده باشد، تا
                  پایان سال میلادی جاری و در صورتی که در باقی ماه‌ها تهیه شده
                  باشد، تا پایان سال میلادی بعدی، اعتبار خواهد داشت.
                </li>
              </ul>
              <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
                تغییرات غیر مجاز در تنظیمات حساب:
              </h3>
              <p
                style={{
                  lineHeight: "1.6",
                  marginBottom: "1rem",
                  color: "rgba(200, 200, 200, 1)",
                }}
              >
                به دلیل ایجاد اختلال در روند بررسی حساب‌های معاملاتی، تریدر
                اجازه تعویض اینوستور پسورد حساب در هیچ یک از مراحل را ندارد و در
                صورت چنین تغییری، حساب ایشان منسوخ خواهد شد.
              </p>
              <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />
            </section>

            {/* بخش قوانین استرداد */}
            <section style={{ marginBottom: "2rem" }}>
              <h2
                style={{
                  textAlign: "center",
                  marginBottom: "1rem",
                  color: "#ffd700",
                }}
              >
                قوانین استرداد:
              </h2>
              <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
                انصراف و درخواست استرداد:
              </h3>
              <p
                style={{
                  lineHeight: "1.6",
                  marginBottom: "1rem",
                  color: "rgba(200, 200, 200, 1)",
                }}
              >
                انصراف و درخواست استرداد، صرفا تا 15 روز بعد از خرید تیکت همراه
                با احراز هویت امکان‌پذیر خواهد بود.
              </p>
              <p
                style={{
                  lineHeight: "1.6",
                  marginBottom: "1rem",
                  color: "rgba(200, 200, 200, 1)",
                }}
              >
                درصورت انصراف تریدر قبل از ساخت حساب و فعال شدن حساب 90 درصد
                مبلغ به تریدر عودت داده خواهد شد. پس از ساخت و فعال شدن حساب 80
                درصد مبلغ پرداختی به تریدر عودت داده خواهد شد. در صورت انجام حتی
                یک معامله روی حساب فعال شده، امکان انصراف و عودت وجه وجود نخواهد
                داشت.
              </p>
              <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />
            </section>

            {/* بخش احراز هویت */}
            <section style={{ marginBottom: "2rem" }}>
              <h2
                style={{
                  textAlign: "center",
                  marginBottom: "1rem",
                  color: "#ffd700",
                }}
              >
                احراز هویت:
              </h2>
              <p
                style={{
                  lineHeight: "1.6",
                  marginBottom: "1rem",
                  color: "rgba(200, 200, 200, 1)",
                }}
              >
                در حساب‌های چالشی بعد از موفقیت در چالش و در حساب‌های پروتریدر
                هم‌زمان با برداشت اول باید احراز هویت انجام پذیرد. جهت احراز
                هویت داشتن یکی از مدارک پاسپورت، کارت ملی معتبر یا شناسنامه
                الزامی می‌باشد.
              </p>


              <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />
            </section>


            {/* بخش شرط داوری */}
            <section style={{ marginBottom: "2rem" }}>
              <h2
                style={{
                  textAlign: "center",
                  marginBottom: "1rem",
                  color: "#ffd700",
                }}
              >
                شرط داوری:
              </h2>
              <p
                style={{
                  lineHeight: "1.6",
                  marginBottom: "1rem",
                  color: "rgba(200, 200, 200, 1)",
                }}
              >
                در صورت تخلف تریدر و نقض قوانین مشروحه در هر مرحله، تریدر حساب
                معاملاتی مورد نظر را از دست خواهد داد.
              </p>
              <p
                style={{
                  lineHeight: "1.6",
                  marginBottom: "1rem",
                  color: "rgba(200, 200, 200, 1)",
                }}
              >
                در نظر داشته باشید، در صورت انجام تخلف مدارک و مستندات از طریق
                پشتیبانی برای تریدر ارسال خواهد شد.
              </p>
              <p
                style={{
                  lineHeight: "1.6",
                  marginBottom: "1rem",
                  color: "rgba(200, 200, 200, 1)",
                }}
              >
                در صورت به‌ وجود آمدن اختلاف نظر بین تریدر و مجموعه در مورد حساب
                معاملاتی، حق داوری تماما با مدیریت مجموعه می باشد و تریدر می
                تواند با ارسال پیام رسمی به ایمیل fenefx@gmail.com مسئله خود را
                مطرح کرده تا در اسرع وقت به موضوع مورد نظر رسیدگی شود. پاسخ به
                موضوع از طریق پشتیبانی یا ایمیل به تریدر ابلاغ خواهد شد.
              </p>
            </section>

            <section>
            <h4
                style={{
                  cursor:'pointer',
                  textAlign: "center",
                  lineHeight: "1.6",
                  marginBottom: "1rem",
                  color: "rgba(200, 200, 200, 1)",
                  
                }}
                onMouseEnter={(e) => (e.target.style.color = "#ffa500")}
                onMouseLeave={(e) =>
                  (e.target.style.color = "rgba(200, 200, 200, 1)")
                }
                onClick={() => {
                  setriskly(true);
                 
                  
                }}
              >
                قوانین مربوط به استراتژی‌های پر ریسک و ممنوعه
              </h4>
              <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />
            </section>
          </div>
        </>
      ) : (
        <section
          style={{
            marginTop: "2rem",
            padding: "1.5rem 4rem",
            border: "1px solid #ccc",
            borderRadius: "8px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            backgroundColor: "rgba(31, 31, 31, 0.5)", // معادل رنگ #1f1f1f
          }}
        >
          <h2
            style={{
              textAlign: "center",
              marginBottom: "1rem",
              color: "#ffd700",
            }}
          >
            قوانین و محدودیت‌های معاملات
          </h2>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            روش‌ها و تکنیک‌های مختلف انجام معاملات با ریسک کنترل نشده و غیر
            منطقی، طبیعتا از دیدگاه سرمایه‌گذار پذیرفته نخواهد بود. بدیهی است
            اتخاذ تکنیک‌های مختلف سو استفاده از انواع اختلالات، تاخیرها،
            اختلافات، ضعف سیستم‌ها و غیره. در متن بازار و یا داخل هر کدام از
            بروکرهای طرف همکاری، .منجر به عدم همکاری بروکر یا پروایدر مربوطه
            خواهد شد و در نتیجه، سودهای به دست آمده از این طریق قابل پرداخت
            نخواهد بود.
          </p>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            در صورت اثبات و احراز انجام هرگونه سو استفاده از مارکت یا بروکرها،
            چه در حساب‌های مراحل چالش و چه در حساب‌های اصلی متصل به سرمایه، حساب
            مورد نظر مسدود و از ارائه خدمات به فرد یا افراد خاطی، جلوگیری خواهد
            شد. در ادامه، برخی از مصادیق سو استفاده از حساب‌های معاملاتی در
            راستای گریز از ریسک طبیعی مارکت یا کسب سودهای غیر منطقی آورده شده.
          </p>
          <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />
          <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
            محدودیت زمانی معاملات:
          </h3>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            با توجه به تاخیر طبیعی سرورهای ربات برای انتقال معاملات روی حساب‌های
            سرمایه و اتفاق افتادن تاخیر مذکور در باز و بست معاملات و اتفاق
            افتادن تفاضل سود یا ضرر با معامله اصلی نسبت به حساب سرمایه، در صورت
            انجام گرفتن معامله در بازه زمانی زیر یک دقیقه و سود ده بودن معامله
            مذکور، سود حاصل از آن قابل محاسبه و پرداخت نخواهد بود.
          </p>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            تفاضل سود معاملات بالای یک دقیقه، قابل اغماض بوده و سود معاملات طبق
            حساب در اختیار تریدر، محاسبه و تقدیم خواهد شد.
          </p>

          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            باز ماندن معاملات در روزهای آخر هفته یا انجام معاملات میان مدت چند
            روزه، بلامانع است.
          </p>
          <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />
          <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
            معاملات در بازه زمانی خبر:
          </h3>
          <p>
            با توجه به تاخیر طبیعی سرورهای ربات برای انتقال معاملات روی حساب‌های
            سرمایه و اتفاق افتادن تاخیر مذکور در باز و بست معاملات و اتفاق
            افتادن تفاضل سود یا ضرر با معامله اصلی نسبت به حساب سرمایه و ایجاد
            تفاضل فاحش در صورت بروز نوسانات شدید در بازار با توجه به اسلیپیج به
            وجود آمده :
          </p>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            در بازه نوسانات قابل پیش‌بینی (اخبار قرمز رنگ به استناد سایت
            www.forexfactory.com) سود معاملات ناشی از ورود به معامله در بازه
            زمانی نزدیک به خبر، قابل محاسبه نخواهد بود. در بازه زمانی غیر قابل
            پیش‌بینی (نوسانات احتمالی بازار در بازه غیر از خبر)، سود کامل مطابق
            حساب در اختیار تریدر محاسبه و تقدیم خواهد شد.
          </p>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            در بازه زمانی غیر قابل پیش‌بینی (نوسانات احتمالی بازار در بازه غیر
            از خبر) با وجود ایجاد تفاضل سود بین حساب سرمایه اصلی و حساب تریدر،
            سود کامل مطابق حساب در اختیار تریدر محاسبه و تقدیم خواهد شد.
          </p>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            * معامله در حساب‌های بروکر موند اف ایکس، مستثنی از محدودیت معاملات
            در بازه خبر خواهد بود و سود تمام معاملات تمام و کمال محاسبه و تقدیم
            خواهد شد.
          </p>
          <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />
          <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
            مشکلات ناشی از بروکر:
          </h3>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            در صورت به وجود آمدن هر گونه اختلالات در خدمات ارائه شده از سمت
            بروکرهای طرف همکاری، ناشی از قطعی سرورها یا ایرادات زیرساختی و وارد
            شدن ضرر به حساب‌های معاملاتی:
          </p>
          <ul
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            <li>
              {" "}
              در شرایط وقوع اختلالات لحظه‌ای در بازه خبر یا نوسانات شدید یا کاهش
              سطح نقدینگی مارکت و بر اثر انتقال حرکات طبیعی مارکت از پروایدر به
              بروکر ( نقش خنثی بروکر در وقوع اختلالات ): این اتفاقات غیر قابل
              پیگیری بوده و ناشی از حرکات طبیعی مارکت می‌باشند.
            </li>
            <li>
              در شرایط نرمال مارکت و بروز اختلال از قبل بروکر خدمات دهنده،
              اتفاقات قابل پیگیری خواهند بود.
            </li>
          </ul>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            در صورت نارضایتی تریدر از بروکر میزبان و درخواست تغییر بروکر، این
            درخواست در زمان‌های درخواست پاس چالش یا درخواست برداشت (بسته به
            مرحله و نوع حساب) قابل انجام خواهد بود.
          </p>

          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            در هیچ کدام از مراحل چالش یا حساب‌های اصلی و در هیچ‌کدام از بروکرهای
            طرف همکاری، افتتاح حساب از نوع نتینگ (netting) مجاز نخواهد بود.
          </p>
          <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />
          <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
            انواع استراتژی هج:
          </h3>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            اجرای انواع روش‌های هج بر روی یک حساب واحد، ولو این که معاملات روی
            نمادهای متفاوت یا حجم‌های غیر یکسان اتخاذ شوند، بلامانع هستند.
          </p>
          <h6 style={{ marginBottom: "1rem", color: "#ffa500" }}>
            هج بین حسابی:
          </h6>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            در صورتی که با استفاده از چند حساب و گرفتن معاملات خلاف جهت هم به
            صورت هم‌زمان، سعی در بی اثر کردن ریسک طبیعی مارکت شود و توسط این متد
            با فدا کردن یک یا چند حساب، سعی در کسب سود قطعی بدون ریسک شود، مصداق
            بارز تلاش برای سو استفاده از ماهیت مارکت اتفاق افتاده‌. در صورت
            احراز چنین شرایطی تمام حساب‌های فرد خاطی مسدود خواهد شد و از ارائه
            خدمات بیشتر خودداری خواهد شد.
          </p>
          <h6 style={{ marginBottom: "1rem", color: "#ffa500" }}>هج گروهی:</h6>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            در صورتی که اقدام مشابه توسط دو یا چند گروه مختلف و متفاوت صورت
            گیرد، بالطبع اقدام مشابه در راستای قطع دسترسی تمام افراد خاطی صورت
            خواهد گرفت.
          </p>
          <h6 style={{ marginBottom: "1rem", color: "#ffa500" }}>
            هج بین شرکتی:
          </h6>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            به حالتی از گرفتن پوزیشن‌های خلاف جهت هم در حساب‌های متعلق به
            شرکت‌های متفاوت گفته می‌شود. با توجه به دسترسی به برخی از اطلاعات آی
            پی های شرکت‌های همکار، در صورت احراز چنین روش سو استفاده، دسترسی
            تمام افراد خاطی، مسدود خواهد شد.
          </p>
          <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />

          <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
            معاملات فرکانس بالا (HFT):
          </h3>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            انجام تعداد معاملات بالا در فواصل زمانی نزدیک جهت سو استفاده از
            تاخیر طبیعی زیرساخت های سرورهای بروکرها که به چند روش از جمله
            روش‌های زیر قابل انجام است؛ قابل پیگیری و اثبات بوده و در صورت اثبات
            و احراز، حساب‌های معاملاتی افراد خاطی مسدود خواهد شد.
          </p>

          <h6 style={{ marginBottom: "1rem", color: "#ffa500" }}>
            روی یک حساب:
          </h6>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            ممکن است معاملات HFT روی یک حساب متمرکز شوند، در این صورت تعداد
            زیادی معامله در فواصل زمانی بسیار کم، باز و بسته ‌می‌شوند تا از
            تاخیر چند میلی ثانیه‌ای سرورها نسبت به یکدیگر، سو استفاده کنند.
          </p>

          <h6 style={{ marginBottom: "1rem", color: "#ffa500" }}>
            با استفاده از ربات:
          </h6>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            متدهای به خصوصی از معاملات HFT توسط اکسپرت‌ها انجام می‌شود، در صورت
            احراز استفاده از هرگونه ربات به غیر از نسخه تایید گرفته شده،
            علی‌الخصوص در شرایطی که ربات دارای کدهای مخرب بوده باشد، حساب مربوطه
            مسدود خواهد شد.
          </p>

          <h6 style={{ marginBottom: "1rem", color: "#ffa500" }}>
            HFT تاخیری:
          </h6>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            در شرایطی که معاملات HFT روی چند حساب پراکنده باشند و معاملات هج با
            فواصل چند میلی ثانیه و با هدف شکار تاخیر (latency) بین حساب‌ها انجام
            پذیرد، مصداق سو استفاده تلقی خواهد شد. در چنین حالتی، فاصله باز و
            بست معاملات لزوما سریع نیست و ممکن است خروج از حالت هج فریز، دقایق
            یا ساعاتی بعد از ورود به معامله انجام گیرد.
          </p>

          <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />

          <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
            انواع استراتژی مارتینگل:
          </h3>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            تمام انواع استراتژی‌های مارتینگل اعم از مارتینگل معکوس، مارتینگل هج،
            لدرینگ و ....؛ به شرطی که روی یک حساب انجام گیرند، بلامانع می‌باشند.
            بدیهی است اتخاذ هرگونه استراتژی مشروط به رعایت فضای مجاز دراداون‌های
            روزانه و کل خواهد بود.
          </p>
          <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />

          <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
            ترید گروهی یا ترید شخص واحد با چند حساب معاملاتی:
          </h3>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            در شرایطی که تیم‌های ترید، به صورت گروهی حساب تهیه نموده و اقدام به
            انجام معاملات بکنند، با توجه به احتمال بالای شباهت معاملات، در صورت
            اطلاع رسانی و هماهنگی با پشتیبانی از طریق تیکت، شباهت معاملات بین
            چند حساب در اختیار تیم تا ۹۰ درصد، بلامانع خواهد بود.
          </p>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            در صورتی که یک نفر، چند حساب معاملاتی در اختیار داشته باشد و معاملات
            مشابه هم روی تمام آنها انجام دهد، شامل همین قانون خواهد بود‌. بالطبع
            در صورت شباهت کامل معاملات، ادغام حساب‌ها تصمیمی منطقی خواهد بود.
          </p>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            نقض این قانون منجر به مسدودی حساب نخواهد شد.
          </p>
          <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />

          <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
            معاملات قمارگونه:
          </h3>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            انجام معاملات با ریسک کنترل نشده و حجم غیر منطقی با هدف کسب سود
            شانسی، به هیچ عنوان از دید سرمایه‌گذار به عنوان روش اصولی مدیریت
            سرمایه، پذیرفته نیست. معاملات ناگهانی یا معاملاتی که با حجم بالا
            نسبت به مارجین حساب انجام می‌شوند، غالبا بدون تحلیل و بدون اتخاذ
            اصول منطقی کنترل ریسک و زیان انجام می‌شوند.
          </p>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            طبیعتا انجام چنین معاملاتی، برای حساب‌های سرمایه مخاطره آمیز خواهد
            بود. انجام این قبیل معاملات روی حساب، در دفعات اول باعث تذکر به
            همراه عدم محاسبه سود معاملات پر ریسک و در دفعات بعدی، منجر به مسدودی
            حساب خواهد شد.
          </p>
          <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />

          <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
            استفاده از ربات‌های معامله‌گر (اکسپرت):
          </h3>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            استفاده از انواع ربات‌های معامله گر (اکسپرت‌ها یا EA) چه به منظور
            صرف مدیریت سرمایه و چه به منظور دسترسی کامل انجام معاملات، بلا مانع
            می‌باشد. با این حال در نظر داشته باشید استفاده از این ابزارها، منوط
            به بررسی و موافقت تیم فنی خواهد بود.
          </p>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            در صورت احراز استفاده از اکسپرت بدون اطلاع مجموعه در مراحل بررسی پاس
            یا سیکل برداشت، حساب مربوطه مسدود خواهد شد.
          </p>
          <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />

          <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
            ایجاد عرضه یا تقاضای غیر واقعی در بازار:
          </h3>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            انجام معاملات با حجم‌های غیر واقعی، باز و بسته کردن تعداد و حجم
            بالای اوردرهای پندینگ به صورت کاذب با هدف تلقین عرضه یا تقاضای
            مصنوعی (غیر واقعی) به بازار، مصداق سو استفاده تلقی می‌گردد.
          </p>
          <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />

          <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>کپی ترید:</h3>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            کپی کردن معاملات بین حساب‌های مجموعه یا از مراجع خارج از مجموعه روی
            حساب‌ معاملاتی، غیر مجاز شناخته می‌شود. کپی کردن معاملات از حساب
            معاملاتی روی حساب‌های خارج از مجموعه، بلا مانع است.
          </p>
          <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />

          <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>آربیتراژ:</h3>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            معاملاتی که با هدف سو استفاده از تفاوت قیمت نمادها بین سرورها یا
            بروکرهای مختلف انجام می‌شوند، غیر مجاز بوده و به جهت کسب سود غیر
            منطقی و بدون پذیرش ریسک طبیعی مارکت، قابل محاسبه و پرداخت نخواهند
            بود.
          </p>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            به طور کلی، هر گونه سو استفاده از هرگونه اختلال سرور، باگ امنیتی،
            ضعف احتمالی زیرساخت، تاخیر فید قیمتی، ماهیت دو طرفه بازار CFDو ...
            توسط هیچ بروکر یا پرووایدری پذیرفته نیست و در صورت احراز هر کدام این
            تخلف‌ها، حساب فرد خاطی توسط بروکر مسدود می‌شود؛ به تبع همین اصل و با
            توجه به اتصال تمام معاملات به بروکرهای مختلف، چنین روش‌ها و حساب‌های
            معاملاتی، در مجموعه فنفیکس نیز پذیرفته نخواهند بود.
          </p>
          <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />

          <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
            تاخیر فید قیمتی:
          </h3>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            بهره جویی از تفاوت سرعت سرورهای مختلف در بروکرهای متفاوت، جهت پیش‌
            بینی قطعی و بدون ریسک حرکات آینده بازار و انجام معاملات بر اساس این
            داده‌ها، مصداق بارز سو استفاده در معاملات تلقی خواهد شد.
          </p>
          <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />

          <h3 style={{ marginBottom: "1rem", color: "#ffa500" }}>
            تغییرات در حساب معاملاتی:
          </h3>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            با توجه به آنالیز و بررسی تیم فنی مجموعه روی حساب‌های معاملاتی توسط
            اینوستور پسورد (رمز سرمایه گذار یا investor password)، تغییر این رمز
            منجر به قطع حساب از سیستم نظارتی مجموعه و در ادامه، منسوخی حساب
            خواهد شد.
          </p>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            با این حال، در صورت ساخت حساب توسط مجموعه، تغییر مستر پسورد (رمز
            تریدر یا master password) اکیدا توصیه می‌شود تا از امنیت کامل حساب
            معاملاتی، نهایت اطمینان حاصل شود.
          </p>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            <strong>توضیح:</strong> رمز سرمایه گذار، فاقد دسترسی انجام معاملات
            یا ایجاد هرگونه تغییر در معاملات می‌باشد؛ این رمز صرفا برای مشاهده
            حساب طراحی شده است. در مقابل، رمز تریدر (مستر پسورد) دسترسی کامل به
            حساب‌ معاملاتی داشته و برای انجام معاملات استفاده می‌شود. تمام امور
            نظارت، بررسی و آنالیز روی حساب‌های معاملاتی، توسط اینوستور پسورد
            انجام می‌شود و عملا دسترسی به مستر پسورد توسط مجموعه، وجود ندارد؛ با
            این حال، به جهت امنیت بیشتر حساب‌های معاملاتی، همواره تاکید بر تغییر
            مستر پسورد انجام می‌شود.
          </p>
          <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />

          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            با توجه به اهمیت بالای رعایت مدیریت ریسک منطقی جهت حفظ کیفیت
            حساب‌های تحت معامله و پایداری در چرخه سود، اکیدا توصیه می‌شود که
            تریدرهای عزیز، پای‌بند به اصول مشخص معامله‌گری باشند و از انجام
            هرگونه معاملات پر ریسک و خطرناک پرهیز نمایند.
          </p>
          <p
            style={{
              lineHeight: "1.6",
              marginBottom: "1rem",
              color: "rgba(200, 200, 200, 1)",
            }}
          >
            موارد بالا، صرفا مصادیق و مثال‌های احتمالی وقوع شرایط سو استفاده
            بوده و روش‌های مختلف سو استفاده، منحصر به این موارد نمی‌باشد.
          </p>
          <hr style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              style={{
                textAlign: "center",
                background: "#2c790073",
                border: "none",
                transition: "all 0.3s ease-in-out",
                width: "100%",
                padding: "10px 15px",
                fontSize: "16px",
                cursor: "pointer",
                borderRadius: "5px",
              }}
              onMouseEnter={(e) => {
                e.target.style.background = "#2c7900";
                e.target.style.color = "#fff";
                e.target.style.transform = "scale(1.05)";
              }}
              onMouseLeave={(e) => {
                e.target.style.background = "#2c790073";
                e.target.style.color = "inherit";
                e.target.style.transform = "scale(1)";
              }}
              onClick={() => {
                scrollToSection()
                setriskly(false);
                
              }}
            >
              بازگشت به قوانین کلی
            </button>
          </div>
        </section>
      )}
    </div>
  );
}

export default Rules;
