import { useMutation, useQueryClient } from "@tanstack/react-query";
import { pickChat } from "../../../services/apiMessages";
import useToken from "./hooks/useToken";
import { useContext } from "react";
import UserContext from "../../UserContext/UserContext";

export function usePick() {
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext);
  const token = useToken();

  const { mutate: pickMessage, isPending: isPicking } = useMutation({
    mutationFn: ({ chatId }) =>
      pickChat({ userId: user?.dataes.id, chatId: chatId, token }),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ["conversations"] });
    },
    onError: (err) => console.error(err.message),
  });

  return { pickMessage, isPicking };
}
