/* eslint-disable react/prop-types */
import Spinner from "./Spinner";
import { formatDateTime } from "../../../utils/helper";
import { useMessageHistory } from "./useMessageHistory";

function EditedMessages({ id }) {
  const { isLoading, data } = useMessageHistory(id);
  console.log(data);

  if (isLoading) return <Spinner />;

  return (
    <div>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr style={{ borderBottom: "1px solid #ccc" }}>
            <th style={{ padding: "8px", textAlign: "left" }}>Editor</th>
            <th style={{ padding: "8px", textAlign: "left" }}>Time</th>
            <th style={{ padding: "8px", textAlign: "left" }}>Message</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.map((edit, idx) => (
            <tr key={idx} style={{ borderBottom: "1px solid #ccc" }}>
              <td style={{ padding: "8px" }}>{edit.editorNickname}</td>
              <td style={{ padding: "8px" }}>
                {formatDateTime(edit.editedAt)}
              </td>
              <td style={{ padding: "8px" }}>{edit.previousMessage}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default EditedMessages;
