/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from "react";
import { MdAdsClick, MdCancel } from "react-icons/md";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Success from "../Success/Success";
import axios from "axios";
import Cookies from "js-cookie";
import UserContext from "../UserContext/UserContext";
import {
  IoChevronBackCircle,
  IoArrowForwardCircleSharp,
} from "react-icons/io5";
import moment from "moment-jalaali";

export default function SupportCoupon() {
  const [modal, setModal] = useState(false);
  const [users, setUsers] = useState({ data: [], links: [] });
  const [eachUser, setEach] = useState({});
  const [cops, setCops] = useState([]);
  const [percent, setPercent] = useState("");
  const [code, setCode] = useState("");
  const [time, setTime] = useState("");
  const [limit, setLimit] = useState("");
  const [one, setOne] = useState(0);
  const [show, setShow] = useState(false);
  const [products, setProducts] = useState([]);
  const [act, setAct] = useState({ a1: "active", a2: "", a3: "" });
  const inputClear = () => {
    setPercent("");
    setCode("");
    setTime("");
    setLimit("");
    setOne(0);
  };
  const isUsed = (d, i) => {
    if (d === 0) {
      return "چند بار";
    } else {
      if (i === 1) {
        return "یکبار / استفاده شده";
      } else {
        return "یکبار / استفاده نشده";
      }
    }
  };
  const inModalShow = () => {
    return (
      <>
        <div className="bb">
          {" "}
          کد های تخفیف {eachUser.username
            ? eachUser.username
            : "ایجاد شده"} :{" "}
        </div>
        <table border={1} className="table table-hover ">
          <thead>
            <tr>
              <th> کد </th>
              <th> درصد </th>
              <th> تا </th>
              <th> توسط </th>
              <th> تعداد مجاز </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {cops.map((d) => {
              return (
                <tr key={d.id}>
                  <td> {d.code} </td>
                  <td> {d.percent} </td>
                  <td> {moment(d.expire_date).format("jYYYY/jM/jD")} </td>
                  <td>
                    {" "}
                    {d.created_by === 0 || d.created_by === null
                      ? "سیستم"
                      : d.created_by.first_name +
                        " " +
                        d.created_by.last_name}{" "}
                  </td>
                  <td> {isUsed(d.one_time, d.used)} </td>
                  <td>
                    {" "}
                    <MdCancel
                      style={{ fontSize: "20px", verticalAlign: "middle" }}
                      onClick={() => {
                        if (
                          window.confirm("آیا از حذف کد تخفیف اطمینان دارید؟")
                        ) {
                          load(true);
                          axios
                            .post(
                              url + "api/v1/admin/coupon/" + d.id + "/delete",
                              {},
                              {
                                headers: {
                                  Authorization: `bearer ${Cookies.get(
                                    "token"
                                  )}`,
                                },
                              }
                            )
                            .then((res) => {
                              load(true);
                              axios
                                .get(url + "api/v1/admin/coupon/user/" + d.id, {
                                  headers: {
                                    Authorization: `bearer ${Cookies.get(
                                      "token"
                                    )}`,
                                  },
                                })
                                .then((res) => {
                                  load(false);
                                  setCops(res.data);
                                  console.log(res.data);
                                  alert("کد تخفیف با موفقیت حذف شد");
                                })
                                .catch((err) => {
                                  load(false);
                                  console.log(err);
                                });
                            })
                            .catch((err) => {
                              load(false);
                              console.log(err);
                            });
                        }
                      }}
                    />{" "}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="flex1">
          <div className="gg"> درصد تخفیف : </div>
          <input
            value={percent}
            onChange={(e) => {
              setPercent(e.target.value);
            }}
            type="text"
            className="loginInput"
          />
        </div>
        <div className="flex1">
          <div className="gg"> کد تخفیف : </div>
          <input
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
            type="text"
            className="loginInput"
          />
        </div>
        <div className="flex1">
          <div className="gg"> تا سقف : </div>
          <input
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value);
            }}
            type="text"
            className="loginInput"
          />
        </div>
        <div className="flex1">
          <div className="gg"> تا تاریخ : </div>
          <input
            value={time}
            onChange={(e) => {
              setTime(e.target.value);
            }}
            type="date"
            style={{ width: "166px" }}
            className="loginInput"
          />
        </div>
        <div
          style={{
            justifyContent: "right",
            padding: "10px 0px",
            borderBottom: "1px solid",
            borderTop: "1px solid",
          }}
          className="flex1 "
        >
          <input
            checked={one === 1 ? true : false}
            className="radio mipaziram"
            onChange={(e) => {
              if (e.target.checked) {
                setOne(1);
              } else {
                setOne(0);
              }
            }}
            name="gavanin"
            type="checkbox"
          />
          <div className="f12"> کد یکبار مصرف باشد </div>
        </div>
        <button
          onClick={() => {
            if (show) {
              let dataGlo = {
                user_id: 1,
                percent: percent,
                limit: limit,
                expire_date: time,
                code: code,
                one: one,
                type: "user",
                global: 1,
              };

              if (
                percent !== "" &&
                limit !== "" &&
                time !== "" &&
                code !== ""
              ) {
                load(true);
                axios
                  .post(url + "api/v1/admin/coupon/store", dataGlo, {
                    headers: {
                      Authorization: `bearer ${Cookies.get("token")}`,
                    },
                  })
                  .then((res) => {
                    load(false);
                    inputClear();
                    setModal(false);
                    NotificationManager.success(
                      "کد تخفیف با موفقیت ایجاد شد",
                      "موفق"
                    );
                  })
                  .catch((err) => {
                    load(false);

                    NotificationManager.error(
                      "کد تخفیف تکراری می باشد",
                      "موفق"
                    );
                    console.log(err);
                  });
              } else {
                NotificationManager.warning("لطفا تمامی فیلد ها را پر نمایید");
              }
            } else {
              if (act.a2) {
                let dataAc = {
                  product_id: eachUser.id,
                  percent: percent,
                  limit: limit,
                  expire_date: time,
                  code: code,
                  one: one,
                  type: "product",
                };

                if (
                  percent !== "" &&
                  limit !== "" &&
                  time !== "" &&
                  code !== ""
                ) {
                  load(true);
                  axios
                    .post(url + "api/v1/admin/coupon/store", dataAc, {
                      headers: {
                        Authorization: `bearer ${Cookies.get("token")}`,
                      },
                    })
                    .then((res) => {
                      load(false);
                      inputClear();
                      setModal(false);
                      NotificationManager.success(
                        "کد تخفیف با موفقیت ایجاد شد",
                        "موفق"
                      );
                    })
                    .catch((err) => {
                      load(false);

                      NotificationManager.error(
                        "کد تخفیف تکراری می باشد",
                        "موفق"
                      );
                      console.log(err);
                    });
                } else {
                  NotificationManager.warning(
                    "لطفا تمامی فیلد ها را پر نمایید"
                  );
                }
              } else {
                let data = {
                  user_id: eachUser.id,
                  percent: percent,
                  limit: limit,
                  expire_date: time,
                  code: code,
                  one: one,
                  type: "user",
                };

                if (
                  percent !== "" &&
                  limit !== "" &&
                  time !== "" &&
                  code !== ""
                ) {
                  load(true);
                  axios
                    .post(url + "api/v1/admin/coupon/store", data, {
                      headers: {
                        Authorization: `bearer ${Cookies.get("token")}`,
                      },
                    })
                    .then((res) => {
                      load(false);
                      inputClear();
                      setModal(false);
                      NotificationManager.success(
                        "کد تخفیف با موفقیت ایجاد شد",
                        "موفق"
                      );
                    })
                    .catch((err) => {
                      load(false);

                      NotificationManager.error(
                        "کد تخفیف تکراری می باشد",
                        "موفق"
                      );
                      console.log(err);
                    });
                } else {
                  NotificationManager.warning(
                    "لطفا تمامی فیلد ها را پر نمایید"
                  );
                }
              }
            }
          }}
          className="logBtn"
        >
          ساخت کد تخفیف
        </button>
      </>
    );
  };
  const { load, url } = useContext(UserContext);

  useEffect(() => {
    load(true);
    axios
      .get(url + "api/v1/panel/users", {
        headers: { Authorization: `bearer ${Cookies.get("token")}` },
      })
      .then((res) => {
        load(false);

        setUsers(res.data.data);
      })
      .catch((err) => {
        load(false);
        console.log(err);
      });
  }, []);
  //  const update = ()=>{
  //     load(true)
  //     axios.get(url+"api/v1/panel/users",
  //         { headers: { "Authorization": `bearer ${Cookies.get('token')}`
  //     } }).then(res=>{
  //         load(false)
  //         setUsers(res.data.data)
  //     }).catch(err=>{
  //         load(false)
  //         console.log(err);
  //     })
  //  }
  const usersShow = () => {
   
    return (
      <>
        {console.log(users.data)}
        {users.data  && users.data.map((d) => {
        if (d.roles){
            return (
                <tr key={d.username}>
                  <th className="lastTh">{d.id}</th>
                  <th> {d.username} </th>
                  <th>
                    {" "}
                    {d.first_name !== null
                      ? d.first_name + " " + d.last_name
                      : "ثبت نشده"}{" "}
                  </th>
                  <th> {d.roles[0]?.fa_name} </th>   
                  <th className="lastTh">
                    <MdAdsClick
                      onClick={() => {
                        load(true);
                        axios
                          .get(url + "api/v1/admin/coupon/user/" + d.id, {
                            headers: {
                              Authorization: `bearer ${Cookies.get("token")}`,
                            },
                          })
                          .then((res) => {
                            load(false);
                            setModal(true);
                            setEach(d);
                            setCops(res.data);
                            console.log(res.data);
                          })
                          .catch((err) => {
                            load(false);
                            console.log(err);
                          });
                      }}
                    />{" "}
                  </th>
                </tr>
              );
            
        }
          
        })}
      </>
    );
  };
  const liShows = () => {
    return (
      <>
        {users.links.map((dd) => {
          let c = () => {
            if (dd.active) {
              return "ac";
            } else {
              return null;
            }
          };
          if (dd.label !== "&laquo; Previous" && dd.label !== "Next &raquo;") {
            return (
              <li
                key={dd.label}
                className={"page__numbers" + " " + c()}
                onClick={() => {
                  if (dd.url !== null) {
                    load(true);
                    axios
                      .get(dd.url, {
                        headers: {
                          Authorization: `bearer ${Cookies.get("token")}`,
                        },
                      })
                      .then((res) => {
                        load(false);

                        setUsers(res.data.data);
                      })
                      .catch((err) => {
                        load(false);
                        console.log(err);
                      });
                  }
                }}
              >
                {" "}
                {dd.label}{" "}
              </li>
            );
          }
        })}
      </>
    );
  };

  const mainShow = () => {
    if (act.a2) {
      return (
        <>
          <div className="transAction">
            <div className="eachTicket">
              <div className="table-responsive-md">
                <div style={{ padding: "10px", textAlign: "center" }}>
                  <button
                    onClick={() => {
                      setModal(true);
                      setShow(true);
                    }}
                    className="logBtn"
                  >
                    ساخت کد تخفیف عمومی
                  </button>
                </div>
                <table border={1} className="table table-hover">
                  <thead>
                    <tr>
                      <th> عنوان </th>
                      <th> نوع </th>
                      <th> قیمت </th>
                      <th className="lastTh"> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((d, i) => {
                      return (
                        <tr key={i}>
                          <th>{d.title}</th>
                          <th> {d.type} </th>
                          <th> {d.price} </th>
                          <th className="lastTh">
                            <MdAdsClick
                              onClick={() => {
                                setModal(true);
                                setEach(d);
                              }}
                            />{" "}
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      );
    } else if (act.a1) {
      return (
        <>
          <div className="transAction">
            <div className="eachTicket">
              <div className="table-responsive-md">
              <div style={{ padding: "10px", textAlign: "center" }}>
                  <button
                    onClick={() => {
                      setModal(true);
                      setShow(true);
                    }}
                    className="logBtn"
                  >
                    ساخت کد تخفیف عمومی
                  </button>
                </div>
                <table border={1} className="table table-hover">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}> ID </th>
                      <th className="dfjc">
                        {" "}
                        نام کاربری
                        <input
                          className="searchIn"
                          onChange={(e) => {
                            setTimeout(() => {
                              if (e.target.value.length > 2) {
                                load(true);
                                axios
                                  .get(
                                    url +
                                      "api/v1/panel/users/search?query=" +
                                      e.target.value,
                                    {
                                      headers: {
                                        Authorization: `bearer ${Cookies.get(
                                          "token"
                                        )}`,
                                      },
                                    }
                                  )
                                  .then((res) => {
                                    load(false);
                                    setUsers(res.data.data);
                                  })
                                  .catch((err) => {
                                    load(false);
                                    console.log(err);
                                  });
                              } else {
                                load(true);
                                axios
                                  .get(url + "api/v1/panel/users", {
                                    headers: {
                                      Authorization: `bearer ${Cookies.get(
                                        "token"
                                      )}`,
                                    },
                                  })
                                  .then((res) => {
                                    load(false);
                                    console.log(res.data);
                                    setUsers(res.data.data);
                                  })
                                  .catch((err) => {
                                    load(false);
                                    console.log(err);
                                  });
                              }
                            }, 2000);
                          }}
                          type="text"
                          placeholder="جستجو نام کاربری"
                        />{" "}
                      </th>

                      <th> نام کامل </th>
                      <th> نقش </th>
                      <th className="lastTh"> </th>
                    </tr>
                  </thead>
                  <tbody>{usersShow()}</tbody>
                </table>
                <ul class="page">
                  <li
                    onClick={() => {
                      if (users.prev_page_url !== null) {
                        load(true);
                        axios
                          .get(users.prev_page_url, {
                            headers: {
                              Authorization: `bearer ${Cookies.get("token")}`,
                            },
                          })
                          .then((res) => {
                            load(false);

                            setUsers(res.data.data);
                          })
                          .catch((err) => {
                            load(false);
                            console.log(err);
                          });
                      }
                    }}
                    className="page__btn"
                  >
                    <span class="material-icons">
                      <IoChevronBackCircle />
                    </span>
                  </li>
                  {liShows()}
                  <li
                    onClick={() => {
                      if (users.next_page_url !== null) {
                        load(true);
                        axios
                          .get(users.next_page_url, {
                            headers: {
                              Authorization: `bearer ${Cookies.get("token")}`,
                            },
                          })
                          .then((res) => {
                            load(false);

                            setUsers(res.data.data);
                          })
                          .catch((err) => {
                            load(false);
                            console.log(err);
                          });
                      }
                    }}
                    className="page__btn"
                  >
                    <span class="material-icons">
                      <IoArrowForwardCircleSharp />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <div>
      <Success
        show={modal}
        closeModal={() => {
          setModal(false);
          inputClear();
          setShow(false);
        }}
        name="gfgfgfg"
      >
        {inModalShow()}
      </Success>
      <NotificationContainer />
      <div className="app-header-navigation">
        <div className="tabs">
          <a
            onClick={() => {
              setAct({ a1: "active", a2: "", a3: "" });
              setEach({});
            }}
            className={act.a1}
          >
            برحسب کاربر
          </a>
          {/* <a
            onClick={() => {
              load(true);
              setEach({});
              setAct({ a1: "", a2: "active", a3: "" });
              axios
                .get(url + "api/v1/admin/products", {
                  headers: { Authorization: `bearer ${Cookies.get("token")}` },
                })
                .then((res) => {
                  load(false);
                  console.log(res.data);
                  setProducts(res.data.data);
                  setAct({ a1: "", a2: "active", a3: "" });
                })
                .catch((err) => {
                  load(false);
                  console.log(err);
                });
            }}
            className={act.a2}
          >
            برحسب محصول
          </a> */}
        </div>
        {mainShow()}
      </div>
    </div>
  );
}
