/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import UserContext from "../UserContext/UserContext";
import Success from "../Success/Success";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { MdAdsClick } from "react-icons/md";
import { FaCopy } from "react-icons/fa";
import {
  IoChevronBackCircle,
  IoArrowForwardCircleSharp,
} from "react-icons/io5";

function formatToK(number) {
  if (number >= 1000) {
    const result = number / 1000; // تقسیم بر هزار
    return result % 1 === 0 ? result.toFixed(0) + "k" : result.toFixed(2) + "k";
  }
  return number.toString();
}

export default function SupportWidReq() {
  const [m, setM] = useState([]);
  const [des, setDes] = useState({});
  const [amount, setAmount] = useState("");
  const [imageSer, setImageSer] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(""); // تاریخ شروع
  const [endDate, setEndDate] = useState(""); // تاریخ پایان
  const [requestStatus, setRequestStatus] = useState("all"); // پیش‌فرض: همه

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const startDateInput = useRef();

  // const resetDate =  () => {
  //   // تاریخ شروع و پایان را خالی کنید
  //   setStartDate("");
  //   setEndDate("");
  //   setSearchTerm("")
  //    setRequestStatus("")

  //   // ارسال درخواست به سرور بدون فیلتر تاریخ
  //   load(true); // فعال کردن لودر (در صورت نیاز)
  //   let queryString = ""; // کوئری استرینگ خالی

  //   // بررسی وضعیت تب انتخاب‌شده و اضافه کردن به کوئری استرینگ
  //   if (tab() === "approved" && requestStatus && requestStatus !== "all") {
  //     queryString += `&status=${requestStatus}`;
  //   }

  //   console.log("resetDate query:", `${url}api/v1/panel/payouts?type=${tab()}${queryString}`);

  //   // ارسال درخواست به سرور
  //   axios
  //     .get(`${url}api/v1/panel/payouts?type=${tab()}${queryString}`, {
  //       headers: { Authorization: `bearer ${Cookies.get("token")}` },
  //     })
  //     .then((res) => {
  //       load(false); // غیر فعال کردن لودر
  //       setHesab(res.data); // ذخیره داده‌های دریافت شده
  //       NotificationManager.success("فیلتر تاریخ ریست شد و داده‌ها به‌روزرسانی شدند.");
  //     })
  //     .catch((err) => {
  //       load(false); // غیر فعال کردن لودر
  //       NotificationManager.error("خطا در دریافت داده‌ها.");
  //       console.error(err);
  //     });
  // };

  const resetDate = () => {
    // خالی کردن state‌ها
    setStartDate("");
    setEndDate("");
    setSearchTerm("");

    // استفاده از callback function برای تنظیم و استفاده از مقدار requestStatus
    setRequestStatus(() => {
      const localStatus = ""; // مقدار جدید requestStatus

      load(true);

      // کوئری استرینگ با مقدار localStatus
      let queryString = "";

      if (tab() === "approved" && localStatus !== "all") {
        queryString += `&status=${localStatus}`;
      }

      console.log(
        "resetDate query:",
        `${url}api/v1/panel/payouts?type=${tab()}${queryString}`
      );

      // ارسال درخواست
      axios
        .get(`${url}api/v1/panel/payouts?type=${tab()}${queryString}`, {
          headers: { Authorization: `bearer ${Cookies.get("token")}` },
        })
        .then((res) => {
          load(false); // غیرفعال کردن لودر
          setHesab(res.data); // ذخیره داده‌های دریافتی
          NotificationManager.success(
            "فیلتر تاریخ ریست شد و داده‌ها به‌روزرسانی شدند."
          );
        })
        .catch((err) => {
          load(false); // غیرفعال کردن لودر
          NotificationManager.error("خطا در دریافت داده‌ها.");
          console.error(err);
        });

      return localStatus; // مقدار جدید requestStatus که در state ذخیره می‌شود
    });
  };

  const downloadExcel = () => {
    // ساخت Query String در همان تابع
    let queryString = "";

    // تاریخ شروع و پایان
    if (startDate) {
      queryString += `&start_date=${startDate}`;
    }
    if (endDate) {
      queryString += `&end_date=${endDate}`;
    }

    // جستجو
    if (searchTerm) {
      queryString += `&query=${searchTerm}`;
    }

    // وضعیت فقط در صورت حضور در تب تکمیل شده
    if (tab() === "approved" && requestStatus) {
      // نام درست state مربوط به وضعیت
      queryString += `&status=${requestStatus}`;
    }

    const excelUrl = `${url}api/v1/panel/payouts?type=${tab()}${queryString}&export=excel`;
    console.log("my excel query", excelUrl);

    axios
      .get(excelUrl, {
        headers: { Authorization: `bearer ${Cookies.get("token")}` },
        responseType: "blob", // نوع پاسخ برای فایل
      })
      .then((response) => {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", "filtered_data.xlsx"); // نام فایل دانلودی
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        NotificationManager.success("فایل اکسل با موفقیت دریافت شد.");
      })
      .catch((error) => {
        console.error(error);
        NotificationManager.error("خطایی در دریافت فایل اکسل رخ داد.");
      });
  };

  const handleFilter = () => {
    console.log("handleFilter");
    setSearchTerm("");
    if (!startDate || !endDate) {
      NotificationManager.warning("لطفاً تاریخ شروع و پایان را وارد کنید.");
      return;
    }

    load(true);

    // ساخت query string شامل تاریخ و وضعیت
    let queryString = `&start_date=${startDate}&end_date=${endDate}`;

    // اگر در تب تکمیل‌شده‌ها هستیم، وضعیت را اضافه کن
    if (tab() === "approved" && requestStatus && requestStatus !== "all") {
      queryString += `&status=${requestStatus}`;
    }

    console.log(
      "my ",
      `${url}api/v1/panel/payouts?type=${tab()}${queryString}`
    );

    axios
      .get(`${url}api/v1/panel/payouts?type=${tab()}${queryString}`, {
        headers: { Authorization: `bearer ${Cookies.get("token")}` },
      })
      .then((res) => {
        load(false);
        setHesab(res.data);
      })
      .catch((err) => {
        load(false);
        NotificationManager.error("خطا در دریافت داده‌ها");
        console.log(err);
      });
  };

  const handleStatusFilter = (status) => {
    setRequestStatus(status); // وضعیت انتخاب‌شده را به‌روزرسانی کنید
    load(true);
    setSearchTerm("");

    // ایجاد کوئری استرینگ براساس وضعیت و تاریخ
    let queryString = status === "all" ? "" : `&status=${status}`;
    queryString += startDate ? `&start_date=${startDate}` : "";
    queryString += endDate ? `&end_date=${endDate}` : "";

    console.log(
      "my query status",
      `${url}api/v1/panel/payouts?type=approved${queryString}`
    );

    axios
      .get(`${url}api/v1/panel/payouts?type=approved${queryString}`, {
        headers: { Authorization: `bearer ${Cookies.get("token")}` },
      })
      .then((res) => {
        load(false);
        setHesab(res.data); // داده‌های جدید را ذخیره کنید
      })
      .catch((err) => {
        load(false);
        NotificationManager.error("خطا در دریافت داده‌ها");
        console.log(err);
      });
  };

  const tab = () => {
    if (activer.a1) {
      return "pending";
    } else if (activer.a2) {
      return "completed";
    } else if (activer.a3) {
      return "mine";
    } else if (activer.a4) {
      return "cancelled";
    } else if (activer.a5) {
      return "approved";
    } else if (activer.a6) {
      return "certificate";
    } else if (activer.a7) {
      return "first-a";
    } else if (activer.a8) {
      return "first-r";
    } else {
      return "";
    }
  };
  // const sendSearchRequest = (term) => {
  //   load(true);
  //   if (activer.a6) {
  //     load(true);
  //     axios
  //       .get(url + "api/v1/admin/payouts/certificates/search?query=" + term, {
  //         headers: { Authorization: `bearer ${Cookies.get("token")}` },
  //       })
  //       .then((res) => {
  //         load(false);
  //         setHesab(res.data);
  //       })
  //       .catch((err) => {
  //         load(false);
  //         console.log(err);
  //       });
  //   } else {
  //     load(true);
  //     axios
  //       .get(
  //         url + "api/v1/panel/payouts/search?type=" + tab() + "&&query=" + term,
  //         { headers: { Authorization: `bearer ${Cookies.get("token")}` } }
  //       )
  //       .then((res) => {
  //         load(false);
  //         setHesab(res.data);
  //       })
  //       .catch((err) => {
  //         load(false);
  //         console.log(err);
  //       });
  //   }
  // };

  const sendSearchRequest = (term) => {
    load(true);

    let queryString = `query=${term}`; // اضافه کردن عبارت جستجو به کوئری استرینگ

    // اگر activer.a5 فعال باشد، تاریخ و وضعیت را اضافه کن
    if (activer.a5) {
      if (startDate) {
        queryString += `&start_date=${startDate}`;
      }
      if (endDate) {
        queryString += `&end_date=${endDate}`;
      }
      if (requestStatus && requestStatus !== "all") {
        queryString += `&status=${requestStatus}`;
      }
    }

    // URL مناسب براساس وضعیت activer
    const apiUrl = activer.a6
      ? `${url}api/v1/admin/payouts/certificates/search?${queryString}`
      : `${url}api/v1/panel/payouts/search?type=${tab()}&&${queryString}`;

    console.log("sendSearchRequest query:", apiUrl);

    axios
      .get(apiUrl, {
        headers: { Authorization: `bearer ${Cookies.get("token")}` },
      })
      .then((res) => {
        load(false);
        setHesab(res.data); // ذخیره داده‌های دریافت شده
      })
      .catch((err) => {
        load(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      sendSearchRequest(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);
  useEffect(() => {
    console.log("im here");
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 2000);
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);
  useEffect(() => {
    user.dataes.permissions.map((d) => {
      setM((e) => [...e, d.name]);
    });
    load(true);
    axios
      .get(url + "api/v1/panel/payouts", {
        headers: { Authorization: `bearer ${Cookies.get("token")}` },
      })
      .then((res) => {
        load(false);
        setHesab(res.data);
      })
      .catch((err) => {
        load(false);
        console.log(err);
      });
  }, []);
  const { load, url, user } = useContext(UserContext);
  const [modal, setModal] = useState(false);
  const [hesab, setHesab] = useState({ data: [], links: [], user: [] });
  const [radd, setRadd] = useState("");
  const [id, setId] = useState("");
  const [btn, setBtn] = useState([]);
  const [shows, setShows] = useState(0);
  const [nextAmount, setNextAm] = useState("");
  const [taidRad, setTaidRad] = useState(false);

  const hasPermission = (permissionName) => {
    return user.dataes.permissions.some(
      (permission) => permission.name === permissionName
    );
  };
  const hesabs = () => {
    const translator = (n) => {
      if (n === "First Step") {
        return "مرحله اول";
      } else if (n === "Second Step") {
        return "مرحله دوم";
      } else if (n === "Pro Trader") {
        return "پرو تریدر";
      } else if (n === "Real") {
        return "REAL";
      } else {
        return n;
      }
    };

    return (
      <>
        {hesab.data.map((d, i) => {
          // console.log('my d',d);
          return (
            <tr key={i}>
              <th> {d.user.username} </th>
              <th>
                {d.account.order && d.account.order.product
                  ? `${d.account.order.product.type}/${d.account.order.product.title}`
                  : `${d.account.order.category?.title || "پرو تریدر"}/${
                      d.account.order.product_id === "13"
                        ? "2.5k"
                        : formatToK(d.account.order.product_id)
                    }`}
              </th>

              <th>
                {" "}
                {d.account.order.server + " / " + d.account.order.platform}{" "}
              </th>
              <th> {d.account.login} </th>
              <th style={{ direction: "ltr", textAlign: "left" }}>
                {d.account.investor_password}
              </th>
              <th> {translator(d.account.type)} </th>
              <th> {d.created_at} </th>
              <th> {d.updated_at} </th>
              {activer.a2 === "active" || activer.a4 === "active" ? (
                <th>
                  {" "}
                  {d.did_by !== null
                    ? d.did_by.first_name + " " + d.did_by.last_name
                    : null}{" "}
                </th>
              ) : null}
              {activer.a5 !== "active" ? (
                <th className="lastTh">
                  {!btn.includes(d.id) ? (
                    <MdAdsClick
                      onClick={() => {
                        if (activer.a3 === "active" && d.status !== 0) {
                          if (d.status === 3 || d.status === 4) {
                            NotificationManager.warning(
                              "این درخواست قبلا رد شده"
                            );
                          } else if (d.status === 1 || d.status === 2) {
                            NotificationManager.warning(
                              "این درخواست قبلا تایید شده"
                            );
                          }
                        } else {
                          setId(d.id);
                          setModal(true);
                          setDes(d);
                          console.log(d);
                        }
                      }}
                    />
                  ) : null}{" "}
                </th>
              ) : (
                <th>
                  {" "}
                  {d.status === 4 ? (
                    <div
                      onClick={() => {
                        setDes([d, false]);
                        setTaidRad(true);
                        setModal(true);
                      }}
                      className="red  joziat"
                    >
                      رد شده
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        console.log(d);
                        setDes([d, true]);
                        setTaidRad(true);
                        setModal(true);
                      }}
                      className="green joziat"
                    >
                      تایید شده
                    </div>
                  )}{" "}
                </th>
              )}
            </tr>
          );
        })}
        {activer.a5 && hasPermission("payouts_export_excel") && (
          <button
            className="btn"
            style={{
              padding: "0px 1rem",
              height: "2rem",
              marginTop: "10px",
              marginRight: "10px",
              marginBottom: "10px",
            }}
            onClick={downloadExcel}
          >
            دریافت فایل اکسل
          </button>
        )}
      </>
    );
  };
  const btnShow = () => {
    if (shows === 1) {
      return (
        <>
          <textarea
            className="raddWhy mt"
            value={radd}
            onChange={(e) => {
              setRadd(e.target.value);
            }}
            placeholder="در صورت رد علت رد را وارد نمایید"
          />
          {/* <button
            style={{ width: "100%", background: "red" }}
            className="btn btn-success mt"
            onClick={() => {
              if (radd === "") {
                NotificationManager.error(
                  "علت رد خالی می باشد",
                  "رد تقاضای برداشت انجام نشد",
                  5000
                );
              } else {
                const st = () => {
                  if (activer.a4) {
                    return 4;
                  } else {
                    return 3;
                  }
                };
                let verify = () => {
                  if (activer.a4 === "active" || activer.a2 === "active") {
                    return "/super_verify";
                  } else {
                    return "/verify";
                  }
                };
                load(true);
                axios
                  .post(
                    url + "api/v1/panel/payout/" + id + verify(),
                    { status: st(), text: radd },
                    {
                      headers: {
                        Authorization: `bearer ${Cookies.get("token")}`,
                      },
                    }
                  )
                  .then(() => {
                    load(false);
                    NotificationManager.success(
                      "تقاضای درخواست برداشت با موفقیت رد شد",
                      "انجام شد",
                      5000
                    );
                    setModal(false);
                    setBtn((e) => [...e, id]);
                    setRadd("");
                  })
                  .catch((err) => {
                    load(false);
                    NotificationManager.error(
                      "رد درخواست برداشت انجام نشد دوباره سعی کنید",
                      "انجام نشد",
                      5000
                    );
                    console.log(err);
                  });
              }
            }}
          >
            {" "}
            رد نهایی
          </button> */}
          <button
            style={{ width: "100%", background: "red" }}
            className="btn btn-success mt"
            onClick={() => {
              if (radd === "") {
                NotificationManager.error(
                  "علت رد خالی می باشد",
                  "رد تقاضای برداشت انجام نشد",
                  5000
                );
              } else {
                // نمایش تأییدیه قبل از ارسال درخواست
                const isConfirmed = window.confirm(
                  `آیا مطمئن هستید که می‌خواهید  درخواست ${des.account?.login} را رد کنید؟`
                );

                if (!isConfirmed) {
                  // اگر کاربر تأیید نکرد، عملیات متوقف می‌شود
                  return;
                }

                const st = () => {
                  if (activer.a4) {
                    return 4;
                  } else {
                    return 3;
                  }
                };

                let verify = () => {
                  if (activer.a4 === "active" || activer.a2 === "active") {
                    return "/super_verify";
                  } else {
                    return "/verify";
                  }
                };

                load(true);
                axios
                  .post(
                    url + "api/v1/panel/payout/" + id + verify(),
                    { status: st(), text: radd },
                    {
                      headers: {
                        Authorization: `bearer ${Cookies.get("token")}`,
                      },
                    }
                  )
                  .then(() => {
                    load(false);
                    NotificationManager.success(
                      "تقاضای درخواست برداشت با موفقیت رد شد",
                      "انجام شد",
                      5000
                    );
                    setModal(false);
                    setBtn((e) => [...e, id]);
                    setRadd("");
                  })
                  .catch((err) => {
                    load(false);
                    NotificationManager.error(
                      "رد درخواست برداشت انجام نشد دوباره سعی کنید",
                      "انجام نشد",
                      5000
                    );
                    console.log(err);
                  });
              }
            }}
          >
            رد نهایی
          </button>
        </>
      );
    } else if (shows === 2) {
      return (
        <>
          <textarea
            className="raddWhy mt"
            value={radd}
            onChange={(e) => {
              setRadd(e.target.value);
            }}
            placeholder="توضیحات ..."
          />

          {activer.a2 === "active" ? (
            <>
              <div className="mt dfjc" style={{ width: "50%" }}>
                <label> مبلغ دریافتی : </label>
                <input
                  value={amount}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                  type="text"
                />
              </div>
              <div className="mt dfjc" style={{ width: "50%" }}>
                <label> بالانس بعدی : </label>
                <input
                  value={nextAmount}
                  onChange={(e) => {
                    setNextAm(e.target.value);
                  }}
                  type="text"
                />
              </div>
            </>
          ) : null}
          {/* <button
            style={{ width: "100%", background: "green" }}
            className="btn btn-success mt"
            onClick={() => {
              if (radd !== "") {
                let status = () => {
                  if (activer.a1 === "active") {
                    return 1;
                  } else if (activer.a2 === "active") {
                    return 2;
                  } else {
                    return 1;
                  }
                };

                let verify = () => {
                  if (activer.a2 === "active" || activer.a4 === "active") {
                    return "/super_verify";
                  } else {
                    return "/verify";
                  }
                };
                if (activer.a2 === "active") {
                  if (amount !== "" && nextAmount !== "") {
                    load(true);

                    axios
                      .post(
                        url + "api/v1/panel/payout/" + id + verify(),
                        {
                          status: status(),
                          text: radd,
                          amount: amount,
                          balance: nextAmount,
                        },
                        {
                          headers: {
                            Authorization: `bearer ${Cookies.get("token")}`,
                          },
                        }
                      )
                      .then((res) => {
                        load(false);
                        NotificationManager.success(
                          "تقاضای برداشت با موفقیت تایید شد",
                          "انجام شد",
                          5000
                        );
                        setModal(false);
                        setBtn((e) => [...e, id]);
                        console.log(res.data);
                      })
                      .catch((err) => {
                        load(false);
                        NotificationManager.error(
                          "تایید درخواست برداشت انجام نشد دوباره سعی کنید",
                          "انجام نشد",
                          5000
                        );
                        console.log(err);
                      });
                  } else {
                    NotificationManager.warning("همه فیلد ها را پر نمایید");
                  }
                } else {
                  load(true);
                  axios
                    .post(
                      url + "api/v1/panel/payout/" + id + verify(),
                      { status: status(), text: radd },
                      {
                        headers: {
                          Authorization: `bearer ${Cookies.get("token")}`,
                        },
                      }
                    )
                    .then((res) => {
                      load(false);
                      NotificationManager.success(
                        "تقاضای برداشت با موفقیت تایید شد",
                        "انجام شد",
                        5000
                      );
                      setModal(false);
                      setBtn((e) => [...e, id]);
                      console.log(res.data);
                    })
                    .catch((err) => {
                      load(false);
                      NotificationManager.error(
                        "تایید درخواست برداشت انجام نشد دوباره سعی کنید",
                        "انجام نشد",
                        5000
                      );
                      console.log(err);
                    });
                }
              } else {
                NotificationManager.error(
                  "متن پیام خالی می باشد",
                  "انجام نشد",
                  4000
                );
              }
            }}
          >
            {" "}
            تایید نهایی{" "}
          </button> */}
          <button
            style={{ width: "100%", background: "green" }}
            className="btn btn-success mt"
            onClick={() => {
              if (radd !== "") {
                // نمایش تأییدیه قبل از ارسال درخواست
                const isConfirmed = window.confirm(
                  `آیا مطمئن هستید که می‌خواهید تایید نهایی ${des.account?.login} را انجام دهید؟`
                );

                if (!isConfirmed) {
                  // اگر کاربر عملیات را تأیید نکرد، هیچ‌چیز انجام نمی‌شود
                  return;
                }

                let status = () => {
                  if (activer.a1 === "active") {
                    return 1;
                  } else if (activer.a2 === "active") {
                    return 2;
                  } else {
                    return 1;
                  }
                };

                let verify = () => {
                  if (activer.a2 === "active" || activer.a4 === "active") {
                    return "/super_verify";
                  } else {
                    return "/verify";
                  }
                };

                if (activer.a2 === "active") {
                  if (amount !== "" && nextAmount !== "") {
                    load(true);

                    axios
                      .post(
                        url + "api/v1/panel/payout/" + id + verify(),
                        {
                          status: status(),
                          text: radd,
                          amount: amount,
                          balance: nextAmount,
                        },
                        {
                          headers: {
                            Authorization: `bearer ${Cookies.get("token")}`,
                          },
                        }
                      )
                      .then((res) => {
                        load(false);
                        NotificationManager.success(
                          "تقاضای برداشت با موفقیت تایید شد",
                          "انجام شد",
                          5000
                        );
                        setModal(false);
                        setBtn((e) => [...e, id]);
                        console.log(res.data);
                      })
                      .catch((err) => {
                        load(false);
                        NotificationManager.error(
                          "تایید درخواست برداشت انجام نشد دوباره سعی کنید",
                          "انجام نشد",
                          5000
                        );
                        console.log(err);
                      });
                  } else {
                    NotificationManager.warning("همه فیلد ها را پر نمایید");
                  }
                } else {
                  load(true);
                  axios
                    .post(
                      url + "api/v1/panel/payout/" + id + verify(),
                      { status: status(), text: radd },
                      {
                        headers: {
                          Authorization: `bearer ${Cookies.get("token")}`,
                        },
                      }
                    )
                    .then((res) => {
                      load(false);
                      NotificationManager.success(
                        "تقاضای برداشت با موفقیت تایید شد",
                        "انجام شد",
                        5000
                      );
                      setModal(false);
                      setBtn((e) => [...e, id]);
                      console.log(res.data);
                    })
                    .catch((err) => {
                      load(false);
                      NotificationManager.error(
                        "تایید درخواست برداشت انجام نشد دوباره سعی کنید",
                        "انجام نشد",
                        5000
                      );
                      console.log(err);
                    });
                }
              } else {
                NotificationManager.error(
                  "متن پیام خالی می باشد",
                  "انجام نشد",
                  4000
                );
              }
            }}
          >
            تایید نهایی
          </button>
        </>
      );
    }
  };
  const inModalShow = () => {
    if (taidRad) {
      return (
        <div>
          <div style={{ textAlign: "center" }} className="bb">
            {" "}
            اطلاعات برداشت کاربر
            {" " + des[0].user.username}{" "}
          </div>
          {des[0].f_did_by && (
            <div className="des">
              <p className="why">
                {" "}
                توضیحات توسط{" "}
                {des[0].f_did_by.first_name +
                  " " +
                  des[0].f_did_by.last_name} :{" "}
              </p>
              <p> {des[0].f_description} </p>
            </div>
          )}
          <div className="des">
            <p className="why">
              {" "}
              توضیحات توسط{" "}
              {des[0].did_by
                ? des[0].did_by.first_name + " " + des[0].did_by.last_name + ""
                : null}{" "}
              :{" "}
            </p>
            <p> {des[0].description} </p>
          </div>
          <div className="des mt">
            <p className="why">
              {" "}
              توضیحات توسط{" "}
              {des[0].verified_by
                ? des[0].verified_by.first_name +
                  " " +
                  des[0].verified_by.last_name +
                  ""
                : null}{" "}
              :{" "}
            </p>
            <p> {des[0].supervisor_description} </p>
          </div>
          <div className="orderDetail bb mt">
            <div className="fl"> تعداد برداشت </div>
            <div>
              {" "}
              {des[0].payouts_count}{" "}
              <FaCopy
                onClick={() => {
                  navigator.clipboard.writeText(des.payouts_count);
                }}
                style={{
                  verticalAlign: "middle",
                  fontSize: "25px",
                  marginLeft: "10px",
                }}
              />{" "}
            </div>
          </div>
          {des[1] && (
            <>
              <div className="orderDetail bb mt">
                <div className="fl"> مبلغ دریافتی </div>
                <div> {des[0].received_amount} </div>
              </div>

              {des[0].image ? (
                <div style={{ display: "grid" }}>
                  <div className="flex1 bb">
                    <div className="gg"> تصویر سرتیفیکیت </div>
                    <img
                      style={{
                        height: "200px",
                        width: "200px",
                      }}
                      onClick={() => {
                        window.open(url + "storage" + des[0].image, "_blank");
                      }}
                      src={url + "storage" + des[0].image}
                      alt="serImage"
                    />
                  </div>

                  <div className="flex1">
                    <div className="gg"> تعویض سرتیفیکیت </div>
                    <input
                      style={{ width: "100%" }}
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        setImageSer(e.target.files[0]);
                      }}
                      className="loginInput"
                    />
                  </div>
                  <button
                    style={{ width: "100%" }}
                    className="btnreply mt"
                    onClick={() => {
                      if (imageSer !== "") {
                        load(true);
                        axios
                          .post(
                            url +
                              "api/v1/admin/payouts/" +
                              des[0].id +
                              "/certificate",
                            {
                              certificate: imageSer,
                            },
                            {
                              headers: {
                                Authorization: `bearer ${Cookies.get("token")}`,
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          )
                          .then(() => {
                            load(false);
                            NotificationManager.success("با موفقیت انجام شد");
                            setModal(false);
                            setImageSer("");
                            load(true);
                            axios
                              .get(url + "api/v1/admin/payouts/certificates", {
                                headers: {
                                  Authorization: `bearer ${Cookies.get(
                                    "token"
                                  )}`,
                                },
                              })
                              .then((res) => {
                                load(false);
                                setHesab(res.data);
                              })
                              .catch((err) => {
                                load(false);
                                console.log(err);
                              });
                          })
                          .catch((err) => {
                            NotificationManager.error("درخواست انجام نشد");
                            load(false);
                            console.log(err);
                          });
                      } else {
                        NotificationManager.warning(
                          "تصویر سرتیفیکیت بارگزاری نشده است"
                        );
                      }
                    }}
                  >
                    {" "}
                    تعویض تصویر CERTIFICATE{" "}
                  </button>
                </div>
              ) : (
                <div className="mt">تصویر Certificate بارگزاری نشده</div>
              )}
            </>
          )}
        </div>
      );
    } else {
      if (activer.a6) {
        if (des.user !== undefined) {
          return (
            <div>
              <div key={des.description} className="des">
                <p className="why">
                  {" "}
                  توضیحات توسط{" "}
                  {des.verified_by
                    ? des.verified_by.first_name +
                      " " +
                      des.verified_by.last_name +
                      ""
                    : null}{" "}
                  :{" "}
                </p>
                <p> {des.supervisor_description}</p>
                <p>ورود : {des.account.login}</p>
              </div>
              <div className="orderDetail bb mt">
                <div className="fl "> مبلغ دریافتی </div>
                <div>
                  {" "}
                  {des.received_amount}{" "}
                  <FaCopy
                    onClick={() => {
                      navigator.clipboard.writeText(des.received_amount);
                    }}
                    style={{
                      verticalAlign: "middle",
                      fontSize: "25px",
                      marginLeft: "10px",
                    }}
                  />{" "}
                </div>
              </div>

              <div className="orderDetail bb">
                <div className="fl"> نام کامل </div>
                <div>
                  {" "}
                  {des.user.first_name + " " + des.user.last_name}{" "}
                  <FaCopy
                    onClick={() => {
                      navigator.clipboard.writeText(
                        des.user.first_name + " " + des.user.last_name
                      );
                    }}
                    style={{
                      verticalAlign: "middle",
                      fontSize: "25px",
                      marginLeft: "10px",
                    }}
                  />{" "}
                </div>
              </div>
              <div className="orderDetail bb">
                <div className="fl"> نام کاربری </div>
                <div>
                  {" "}
                  {des.user.username}{" "}
                  <FaCopy
                    onClick={() => {
                      navigator.clipboard.writeText(des.user.username);
                    }}
                    style={{
                      verticalAlign: "middle",
                      fontSize: "25px",
                      marginLeft: "10px",
                    }}
                  />{" "}
                </div>
              </div>
              <div className="orderDetail bb">
                <div className="fl"> تعداد برداشت </div>
                <div>
                  {" "}
                  {des.payouts_count}{" "}
                  <FaCopy
                    onClick={() => {
                      navigator.clipboard.writeText(des.payouts_count);
                    }}
                    style={{
                      verticalAlign: "middle",
                      fontSize: "25px",
                      marginLeft: "10px",
                    }}
                  />{" "}
                </div>
              </div>
              <div className="flex1">
                <div className="gg"> تصویر سرتیفیکیت </div>
                <input
                  style={{ width: "166.3px" }}
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    setImageSer(e.target.files[0]);
                  }}
                  className="loginInput"
                />
              </div>
              <button
                style={{ width: "100%" }}
                className="btnreply mt"
                onClick={() => {
                  if (imageSer !== "") {
                    load(true);
                    axios
                      .post(
                        url + "api/v1/admin/payouts/" + des.id + "/certificate",
                        {
                          certificate: imageSer,
                        },
                        {
                          headers: {
                            Authorization: `bearer ${Cookies.get("token")}`,
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      )
                      .then((res) => {
                        load(false);
                        NotificationManager.success("با موفقیت انجام شد");
                        setModal(false);
                        setImageSer("");
                        load(true);
                        axios
                          .get(url + "api/v1/admin/payouts/certificates", {
                            headers: {
                              Authorization: `bearer ${Cookies.get("token")}`,
                            },
                          })
                          .then((res) => {
                            load(false);
                            setHesab(res.data);
                          })
                          .catch((err) => {
                            load(false);
                            console.log(err);
                          });
                      })
                      .catch((err) => {
                        NotificationManager.error("درخواست انجام نشد");
                        load(false);
                        console.log(err);
                      });
                  } else {
                    NotificationManager.warning(
                      "تصویر سرتیفیکیت بارگزاری نشده است"
                    );
                  }
                }}
              >
                {" "}
                بارگزاری CERTIFICATE{" "}
              </button>
            </div>
          );
        }
      } else {
        return (
          <div>
            <div className="bb">
              {" "}
              تایید یا رد درخواست برداشت حساب {des.user?.username}{" "}
            </div>
            <p className="mt">
              {" "}
              تیم مانیتورینگ عزیز لطفا پس از انجام بررسی های لازم ، وضعیت
              درخواست برداشت کاربر را تایید یا رد نمایید{" "}
            </p>
            {des.f_did_by && (
              <div className="des">
                <p className="why">
                  {" "}
                  توضیحات توسط{" "}
                  {des.f_did_by.first_name +
                    " " +
                    des.f_did_by.last_name} :{" "}
                </p>
                <p> {des.f_description} </p>
              </div>
            )}
            {activer.a4 === "active" || activer.a2 === "active" ? (
              <div className="des">
                <p className="why">
                  {" "}
                  توضیحات توسط{" "}
                  {des.did_by
                    ? des.did_by.first_name + " " + des.did_by.last_name + ""
                    : null}{" "}
                  :{" "}
                </p>
                <p> {des.description} </p>
              </div>
            ) : null}

            <div className="orderDetail bb mt">
              <div className="fl"> تعداد برداشت </div>
              <div>
                {" "}
                {des.payouts_count}{" "}
                <FaCopy
                  onClick={() => {
                    navigator.clipboard.writeText(des.payouts_count);
                  }}
                  style={{
                    verticalAlign: "middle",
                    fontSize: "25px",
                    marginLeft: "10px",
                  }}
                />{" "}
              </div>
            </div>
            <div className="orderDetail bb mt">
              <div className="fl"> ورود</div>
              <div>
                {" "}
                {des.account?.login}{" "}
                <FaCopy
                  onClick={() => {
                    navigator.clipboard.writeText(des.account?.login);
                  }}
                  style={{
                    verticalAlign: "middle",
                    fontSize: "25px",
                    marginLeft: "10px",
                  }}
                />{" "}
              </div>
            </div>
            <button
              onClick={() => {
                if (user.dataes.roles[0].name === "admin") {
                  load(true);
                  axios
                    .post(
                      url + "api/v1/auth/support/login",
                      { email: des.user?.username },
                      {
                        headers: {
                          Authorization: `bearer ${Cookies.get("token")}`,
                        },
                      }
                    )
                    .then((res) => {
                      load(false);
                      Cookies.set("support_token", Cookies.get("token"));
                      Cookies.set("token", res.data.access_token);
                      Cookies.set("username", des.user?.username);
                      window.location.reload();
                    })
                    .catch((err) => {
                      load(false);
                      console.log(err);
                    });
                } else {
                  load(true);
                  axios
                    .post(
                      url + "api/v1/panel/admin/login",
                      { id: des.user?.id },
                      {
                        headers: {
                          Authorization: `bearer ${Cookies.get("token")}`,
                        },
                      }
                    )
                    .then((res) => {
                      load(false);
                      Cookies.set("support_token", Cookies.get("token"));
                      Cookies.set("token", res.data.access_token);
                      Cookies.set("username", des.user?.username);
                      window.location.reload();
                    })
                    .catch((err) => {
                      load(false);
                      console.log(err);
                    });
                }
              }}
              className="logBtn mt"
              style={{ width: "100%" }}
            >
              {" "}
              ورود به پنل کاربری{" "}
            </button>
            <div className="closeModal">
              <button
                className="btnreply mt"
                onClick={() => {
                  setShows(1);
                }}
              >
                {" "}
                رد
              </button>
              <button
                className="btnreply mt"
                onClick={() => {
                  setShows(2);
                }}
              >
                {" "}
                تایید{" "}
              </button>
            </div>
            {btnShow()}
          </div>
        );
      }
    }
  };
  const [activer, setActiver] = useState({
    a1: "active",
    a2: "",
    a3: "",
    a4: "",
    a5: "",
    a6: "",
    a7: "",
    a8: "",
  });
  const onSearch = () => {
    if (searchTerm.length > 2) {
      return "&&query=" + searchTerm;
    } else {
      return "";
    }
  };

  const buildQueryString = () => {
    let query = "";
    if (startDate) query += `&start_date=${startDate}`;
    if (endDate) query += `&end_date=${endDate}`;
    // فقط وقتی در تب تکمیل‌شده هستیم، status را اضافه کن
    if (activer.a5 === "active" && requestStatus && requestStatus !== "all") {
      query += `&status=${requestStatus}`;
    }
    if (searchTerm.length > 2) query += `&query=${searchTerm}`;
    return query;
  };

  const liShows = () => {
    return (
      <>
        {hesab.links.map((dd) => {
          let c = () => {
            if (dd.active) {
              return "ac";
            } else {
              return null;
            }
          };
          if (dd.label !== "&laquo; Previous" && dd.label !== "Next &raquo;") {
            return (
              <li
                key={dd.label}
                className={"page__numbers" + " " + c()}
                onClick={() => {
                  console.log(
                    "my query",
                    `${dd.url}&type=${tab()}${buildQueryString()}`
                  );
                  if (dd.url !== null) {
                    load(true);
                    axios
                      .get(`${dd.url}&type=${tab()}${buildQueryString()}`, {
                        headers: {
                          Authorization: `bearer ${Cookies.get("token")}`,
                        },
                      })
                      .then((res) => {
                        load(false);
                        setHesab(res.data);
                      })
                      .catch((err) => {
                        load(false);
                        console.log(err);
                      });
                  }
                }}
              >
                {dd.label}
              </li>
            );
          }
        })}
      </>
    );
  };

  // const liShows = () => {
  //   return (
  //     <>
  //       {hesab.links.map((dd) => {
  //         let c = () => {
  //           if (dd.active) {
  //             return "ac";
  //           } else {
  //             return null;
  //           }
  //         };
  //         if (dd.label !== "&laquo; Previous" && dd.label !== "Next &raquo;") {
  //           return (
  //             <li
  //               key={dd.label}
  //               className={"page__numbers" + " " + c()}
  //               onClick={() => {
  //                 console.log('my api',dd.url + "&&type=" + tab() + onSearch())
  //                 if (dd.url !== null) {
  //                   load(true);
  //                   axios
  //                     .get(dd.url + "&&type=" + tab() + onSearch(), {
  //                       headers: {
  //                         Authorization: `bearer ${Cookies.get("token")}`,
  //                       },
  //                     })
  //                     .then((res) => {
  //                       load(false);
  //                       setHesab(res.data);
  //                     })
  //                     .catch((err) => {
  //                       load(false);
  //                       console.log(err);
  //                     });
  //                 }
  //               }}
  //             >
  //               {" "}
  //               {dd.label}{" "}
  //             </li>
  //           );
  //         }
  //       })}
  //     </>
  //   );
  // };

  return (
    <div>
      <Success
        show={modal}
        closeModal={() => {
          setModal(false);
          setImageSer("");
          setShows(0);
          setDes({});
          setRadd("");
          setTaidRad(false);
        }}
        name="insidefjhfj"
      >
        {inModalShow()}
      </Success>
      <NotificationContainer />
      <div className="app-header-navigation">
        <div className="tabs">
          <a
            onClick={() => {
              setActiver({
                a1: "active",
                a2: "",
                a4: "",
                a3: "",
                a5: "",
                a6: "",
                a7: "",
                a8: "",
              });

              const queryString =
                (startDate ? `&start_date=${startDate}` : "") +
                (endDate ? `&end_date=${endDate}` : "");

              load(true);
              axios
                .get(url + "api/v1/panel/payouts?type=pending" + queryString, {
                  headers: { Authorization: `bearer ${Cookies.get("token")}` },
                })
                .then((res) => {
                  load(false);
                  setHesab(res.data);
                })
                .catch((err) => {
                  load(false);
                  console.log(err);
                });
            }}
            className={activer.a1}
          >
            درخواست های برداشت
          </a>

          <a
            onClick={() => {
              setActiver({
                a1: "",
                a2: "",
                a3: "active",
                a4: "",
                a5: "",
                a6: "",
                a7: "",
                a8: "",
              });

              const queryString =
                (startDate ? `&start_date=${startDate}` : "") +
                (endDate ? `&end_date=${endDate}` : "");

              load(true);
              axios
                .get(url + "api/v1/panel/payouts?type=mine" + queryString, {
                  headers: { Authorization: `bearer ${Cookies.get("token")}` },
                })
                .then((res) => {
                  load(false);
                  setHesab(res.data);
                })
                .catch((err) => {
                  load(false);
                  console.log(err);
                });
            }}
            className={activer.a3}
          >
            بررسی های من
          </a>

          {m.includes("payouts") && (
            <>
              <a
                onClick={() => {
                  setActiver({
                    a1: "",
                    a2: "",
                    a3: "",
                    a4: "",
                    a5: "",
                    a6: "",
                    a7: "active",
                    a8: "",
                  });

                  const queryString =
                    (startDate ? `&start_date=${startDate}` : "") +
                    (endDate ? `&end_date=${endDate}` : "");
                  console.log(
                    "my query",
                    url + "api/v1/panel/payouts?type=first-a" + queryString
                  );

                  load(true);
                  axios
                    .get(
                      url + "api/v1/panel/payouts?type=first-a" + queryString,
                      {
                        headers: {
                          Authorization: `bearer ${Cookies.get("token")}`,
                        },
                      }
                    )
                    .then((res) => {
                      load(false);
                      setHesab(res.data);
                    })
                    .catch((err) => {
                      load(false);
                      console.log(err);
                    });
                }}
                className={activer.a7}
              >
                تایید اولیه
              </a>

              <a
                onClick={() => {
                  setActiver({
                    a1: "",
                    a2: "",
                    a3: "",
                    a4: "",
                    a5: "",
                    a6: "",
                    a7: "",
                    a8: "active",
                  });

                  const queryString =
                    (startDate ? `&start_date=${startDate}` : "") +
                    (endDate ? `&end_date=${endDate}` : "");

                  load(true);
                  axios
                    .get(
                      url + "api/v1/panel/payouts?type=first-r" + queryString,
                      {
                        headers: {
                          Authorization: `bearer ${Cookies.get("token")}`,
                        },
                      }
                    )
                    .then((res) => {
                      load(false);
                      setHesab(res.data);
                    })
                    .catch((err) => {
                      load(false);
                      console.log(err);
                    });
                }}
                className={activer.a8}
              >
                رد اولیه
              </a>
            </>
          )}

          {m.includes("payouts-verify") && (
            <>
              <a
                onClick={() => {
                  setActiver({
                    a1: "",
                    a2: "active",
                    a3: "",
                    a4: "",
                    a5: "",
                    a6: "",
                    a7: "",
                    a8: "",
                  });

                  const queryString =
                    (startDate ? `&start_date=${startDate}` : "") +
                    (endDate ? `&end_date=${endDate}` : "");

                  load(true);
                  axios
                    .get(
                      url + "api/v1/panel/payouts?type=completed" + queryString,
                      {
                        headers: {
                          Authorization: `bearer ${Cookies.get("token")}`,
                        },
                      }
                    )
                    .then((res) => {
                      load(false);
                      setHesab(res.data);
                    })
                    .catch((err) => {
                      load(false);
                      console.log(err);
                    });
                }}
                className={activer.a2}
              >
                تایید نهایی
              </a>

              <a
                onClick={() => {
                  setActiver({
                    a1: "",
                    a2: "",
                    a3: "",
                    a4: "active",
                    a5: "",
                    a6: "",
                    a7: "",
                    a8: "",
                  });

                  const queryString =
                    (startDate ? `&start_date=${startDate}` : "") +
                    (endDate ? `&end_date=${endDate}` : "");

                  load(true);
                  axios
                    .get(
                      url + "api/v1/panel/payouts?type=cancelled" + queryString,
                      {
                        headers: {
                          Authorization: `bearer ${Cookies.get("token")}`,
                        },
                      }
                    )
                    .then((res) => {
                      load(false);
                      setHesab(res.data);
                    })
                    .catch((err) => {
                      load(false);
                      console.log(err);
                    });
                }}
                className={activer.a4}
              >
                رد نهایی
              </a>
            </>
          )}

          <a
            onClick={() => {
              setActiver({
                a1: "",
                a2: "",
                a3: "",
                a4: "",
                a5: "active",
                a6: "",
                a7: "",
                a8: "",
              });

              const queryString =
                (startDate ? `&start_date=${startDate}` : "") +
                (endDate ? `&end_date=${endDate}` : "");

              load(true);
              axios
                .get(url + "api/v1/panel/payouts?type=approved" + queryString, {
                  headers: { Authorization: `bearer ${Cookies.get("token")}` },
                })
                .then((res) => {
                  load(false);
                  setHesab(res.data);
                })
                .catch((err) => {
                  load(false);
                  console.log(err);
                });
            }}
            className={activer.a5}
          >
            تکمیل شده
          </a>

          {user.dataes.permissions.find(
            (e) => e.name === "payouts-certificate"
          ) && (
            <a
              onClick={() => {
                setActiver({
                  a1: "",
                  a2: "",
                  a3: "",
                  a4: "",
                  a5: "",
                  a6: "active",
                  a7: "",
                  a8: "",
                });

                const queryString =
                  (startDate ? `&start_date=${startDate}` : "") +
                  (endDate ? `&end_date=${endDate}` : "");

                load(true);
                axios
                  .get(
                    url + "api/v1/admin/payouts/certificates" + queryString,
                    {
                      headers: {
                        Authorization: `bearer ${Cookies.get("token")}`,
                      },
                    }
                  )
                  .then((res) => {
                    load(false);
                    setHesab(res.data);
                  })
                  .catch((err) => {
                    load(false);
                    console.log(err);
                  });
              }}
              className={activer.a6}
            >
              CERTIFICATE
            </a>
          )}
        </div>

        <>
          <div className="transAction">
            <div className="eachTicket">
              <div className="bb"> کل درخواست های ثبت شده برای برداشت : </div>
              <p className="mt f10">
                {" "}
                *برای تایید یا رد درخواست بر روی آیکون
                <MdAdsClick
                  style={{
                    fontSize: "20px",
                    verticalAlign: "middle",
                    marginRight: "3px",
                  }}
                />
                کلیک نمایید{" "}
              </p>

              {activer.a5 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "1rem",
                    margin: "10px",
                  }}
                >
                  <div style={{ display: "flex", gap: "1rem", margin: "10px" }}>
                    <label>
                      از تاریخ :
                      <input
                        ref={startDateInput}
                        style={{ width: "8rem", marginRight: "0.7rem" }}
                        className="searchIn"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </label>
                    <label>
                      تا تاریخ :
                      <input
                        className="searchIn"
                        style={{ width: "8rem", marginRight: "0.7rem" }}
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </label>
                  </div>
                  <button
                    className="btn"
                    onClick={handleFilter}
                    // style={{ margin: "10px" }}
                    style={{
                      padding: "0px 1rem",
                      height: "2rem",
                      marginTop: "10px",
                    }}
                  >
                    اعمال فیلتر تاریخ
                  </button>
                  <button
                    className="btn"
                    onClick={resetDate}
                    // style={{ margin: "10px" }}
                    style={{
                      padding: "0px 1rem",
                      height: "2rem",
                      marginTop: "10px",
                    }}
                  >
                    لغو تمامی فیلتر ها
                  </button>
                </div>
              )}

              {activer.a5 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "2rem",
                    height: "2rem",
                    marginTop: "10px",
                  }}
                >
                  <select
                    className="searchIn"
                    style={{ width: "12rem" }}
                    onChange={(e) => {
                      load(true);
                      let have = () => {
                        if (e.target.value == 1) {
                          return "api/v1/admin/payouts/have_certificates?type=have";
                        } else if (e.target.value == 2) {
                          return "api/v1/admin/payouts/have_certificates?type=not";
                        } else {
                          return "api/v1/panel/payouts?type=approved";
                        }
                      };

                      axios
                        .get(url + have(), {
                          headers: {
                            Authorization: `bearer ${Cookies.get("token")}`,
                          },
                        })
                        .then((res) => {
                          load(false);
                          setHesab(res.data);
                        })
                        .catch((err) => {
                          load(false);
                          console.log(err);
                        });
                    }}
                  >
                    <option style={{ color: "black" }} value={0}>
                      فیلتر بر اساس سرتیفیکیت
                    </option>
                    <option style={{ color: "black" }} value={1}>
                      بارگزاری شده
                    </option>
                    <option style={{ color: "black" }} value={2}>
                      بارگزاری نشده
                    </option>
                  </select>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      margin: "10px",
                    }}
                  >
                    {/* <label>فیلتر وضعیت:</label> */}
                    <select
                      className="searchIn"
                      style={{ width: "12rem" }}
                      value={requestStatus}
                      onChange={(e) => handleStatusFilter(e.target.value)}
                    >
                      <option value="all">همه</option>
                      <option value="approved">تایید شده</option>
                      <option value="rejected">رد شده</option>
                    </select>
                  </div>
                </div>
              )}
              <div className="table-responsive-md">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <input
                    style={{ width: "35%", margin: "10px" }}
                    className="searchIn"
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    type="text"
                    placeholder="جستجو نام کاربری ..."
                    value={searchTerm}
                  />
                </div>

                <table border={1} className="table table-hover">
                  <thead>
                    <tr>
                      <th> کاربر </th>
                      <th> حساب </th>
                      <th> سرور </th>
                      <th> ورود </th>
                      <th> گذرواژه </th>
                      <th> مرحله </th>
                      <th> تاریخ </th>
                      <th> بروزرسانی </th>
                      {activer.a2 === "active" || activer.a4 === "active" ? (
                        <th> انتخاب توسط </th>
                      ) : null}
                      <th className="lastTh">
                        {" "}
                        {activer.a5 === "active" ? "وضعیت" : ""}{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>{hesabs()}</tbody>
                </table>
                <ul className="page">
                  <li
                    onClick={() => {
                      if (hesab.prev_page_url !== null) {
                        const queryString =
                          (startDate ? `&start_date=${startDate}` : "") +
                          (endDate ? `&end_date=${endDate}` : "") +
                          (searchTerm.length > 2 ? `&query=${searchTerm}` : "");

                        console.log(
                          "my prev api",
                          hesab.prev_page_url + "&&type=" + tab() + queryString
                        );

                        load(true);
                        axios
                          .get(
                            hesab.prev_page_url +
                              "&&type=" +
                              tab() +
                              queryString,
                            {
                              headers: {
                                Authorization: `bearer ${Cookies.get("token")}`,
                              },
                            }
                          )
                          .then((res) => {
                            load(false);
                            setHesab(res.data);
                          })
                          .catch((err) => {
                            load(false);
                            console.log(err);
                          });
                      }
                    }}
                    className="page__btn"
                  >
                    <span className="material-icons">
                      <IoChevronBackCircle />
                    </span>
                  </li>
                  {liShows()} {/* لینک‌های صفحات دیگر */}
                  <li
                    onClick={() => {
                      if (hesab.next_page_url !== null) {
                        const queryString =
                          (startDate ? `&start_date=${startDate}` : "") +
                          (endDate ? `&end_date=${endDate}` : "") +
                          (searchTerm.length > 2 ? `&query=${searchTerm}` : "");

                        console.log(
                          "my next api",
                          hesab.next_page_url + "&&type=" + tab() + queryString
                        );

                        load(true);
                        axios
                          .get(
                            hesab.next_page_url +
                              "&&type=" +
                              tab() +
                              queryString,
                            {
                              headers: {
                                Authorization: `bearer ${Cookies.get("token")}`,
                              },
                            }
                          )
                          .then((res) => {
                            load(false);
                            setHesab(res.data);
                          })
                          .catch((err) => {
                            load(false);
                            console.log(err);
                          });
                      }
                    }}
                    className="page__btn"
                  >
                    <span className="material-icons">
                      <IoArrowForwardCircleSharp />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}
