import React from "react";
import { createPortal } from "react-dom";
import { useState, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import UserContext from "../UserContext/UserContext";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useQueryClient } from "@tanstack/react-query";
import { DateInput } from "react-hichestan-datetimepicker";

function ModalCompetition({ modalData, closeModal, invalidateQuery }) {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    nationalCode: "",
    nationalCard: null,
    selfie: null,
    birth_date: "",
    gender: "",
  });
  const queryClient = useQueryClient();

  const [formErrors, setFormErrors] = useState({});
  const { user, load, url } = useContext(UserContext);

  const validateForm = () => {
    console.log("hellooo");

    const errors = {};
    if (!formData.firstName) errors.firstName = "نام را وارد کنید";
    if (!formData.lastName) errors.lastName = "نام خانوادگی را وارد کنید";
    if (!formData.nationalCard)
      errors.nationalCard = "عکس کارت ملی را وارد کنید";
    if (!formData.selfie) errors.selfie = "عکس سلفی را وارد کنید";
    if (!formData.nationalCode) errors.nationalCode = "کد ملی خود را وارد کنید";
    if (!formData.gender) errors.gender = "جنسیت خود را انتخاب کنید";
    if (!formData.birth_date)
      errors.birth_date = "تاریخ تولد خود را انتخاب کنید";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleRegister = async (id) => {
    const token = Cookies.get("token");

    if (!token) {
      NotificationManager.error("برای ثبت‌نام ابتدا وارد شوید.", "خطا", 3000);
      return;
    }

    try {
      // toggleLoading(true);

      const response = await axios.post(
        `${url}api/v1/panel/competitions/${id}/add-user`,
        {}, // Body خالی
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          timeout: 10000,
        }
      );

      if (response.status === 200) {
        NotificationManager.success(
          "ثبت‌نام با موفقیت انجام شد.",
          "موفقیت",
          3000
        );
        queryClient.invalidateQueries(["competitions"]);
        // invalidateQuery();
      } else {
        NotificationManager.error("مشکلی در ثبت‌نام پیش آمد.", "خطا", 3000);
      }
    } catch (error) {
      console.error("Error:", error);

      if (error.response?.status === 401) {
        NotificationManager.error(
          "دسترسی غیرمجاز. لطفاً وارد سیستم شوید.",
          "خطا",
          3000
        );
      } else if (error.code === "ECONNABORTED") {
        NotificationManager.error(
          "درخواست زمان زیادی طول کشید. لطفاً دوباره تلاش کنید.",
          "خطا",
          3000
        );
      } else if (error.response?.status === 400) {
        NotificationManager.error(
          "لطفا اطلاعات هویتی خود را تکمیل کنید",
          "خطا",
          3000
        );
      } else {
        NotificationManager.error(
          "خطا در ارسال درخواست. لطفاً دوباره تلاش کنید.",
          "خطا",
          3000
        );
      }
    } finally {
      // toggleLoading(false);
    }
  };
  const [isLoading, setIsLoading] = useState(false);

  // const makeRequests = async (id) => {
  //   try {

  //     console.log('formData',formData)
  //     console.log('2222', formData.selfie.name);
  //     // دریافت توکن از کوکی
  //     const token = Cookies.get("token");
  //     if (!token) {
  //       NotificationManager.error("توکن یافت نشد. لطفاً وارد شوید.", "خطا");
  //       return;
  //     }

  //     // درخواست اول
  //     const response1 = await axios.post(
  //      `${url}api/v1/panel/edit/`,
  //       { name : formData.firstName , last_name : formData.lastName ,national_code: formData.nationalCode},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     console.log("First request succeeded:", response1.data);

  //     // درخواست دوم
  //     const response2 = await axios.post(
  //       `${url}api/v1/panel/edit_user_images`,
  //       (() => {
  //         const formDataData = new FormData();
  //         formDataData.append("self_image", formData.selfie); // اضافه کردن تصویر سلفی
  //         formDataData.append("id_card_image", formData.nationalCard); // اضافه کردن تصویر کارت ملی
  //         return formDataData;
  //       })(),
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data", // نوع محتوا
  //         },
  //       }
  //     );

  //     console.log("Second request succeeded:", response2.data);

  //     // درخواست سوم
  //     const handleRegister = async (id) => {
  //       try {
  //         const response = await axios.post(
  //           `${url}api/v1/panel/competitions/${id}/add-user`,
  //           {}, // Body خالی
  //           {
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //             },
  //             timeout: 10000,
  //           }
  //         );

  //         if (response.status === 200) {
  //           NotificationManager.success(
  //             "ثبت‌نام با موفقیت انجام شد.",
  //             "موفقیت",
  //             3000
  //           );
  //         } else {
  //           NotificationManager.error("مشکلی در ثبت‌نام پیش آمد.", "خطا", 3000);
  //         }
  //       } catch (error) {
  //         console.error("Error in third request:", error);

  //         if (error.response?.status === 401) {
  //           NotificationManager.error(
  //             "دسترسی غیرمجاز. لطفاً وارد سیستم شوید.",
  //             "خطا",
  //             3000
  //           );
  //         } else if (error.code === "ECONNABORTED") {
  //           NotificationManager.error(
  //             "درخواست زمان زیادی طول کشید. لطفاً دوباره تلاش کنید.",
  //             "خطا",
  //             3000
  //           );
  //         } else if (error.response?.status === 400) {
  //           NotificationManager.error(
  //             "لطفا اطلاعات هویتی خود را تکمیل کنید",
  //             "خطا",
  //             3000
  //           );
  //         } else {
  //           NotificationManager.error(
  //             "خطا در ارسال درخواست. لطفاً دوباره تلاش کنید.",
  //             "خطا",
  //             3000
  //           );
  //         }
  //       }
  //     };

  //     // اجرای درخواست سوم
  //     await handleRegister(id);
  //   } catch (error) {
  //     // مدیریت خطا و نمایش پیام
  //     const errorMessage = error.response?.data?.message || "خطایی رخ داد.";
  //     NotificationManager.error(errorMessage, "خطا");
  //     console.error("Request failed:", errorMessage);
  //   }
  // };

  const makeRequests = async (id) => {
    try {
      setIsLoading(true); // شروع بارگذاری
      console.log("formData", formData);
      console.log("2222", formData.selfie.name);

      // دریافت توکن از کوکی
      const token = Cookies.get("token");
      if (!token) {
        NotificationManager.error("توکن یافت نشد. لطفاً وارد شوید.", "خطا");
        setIsLoading(false); // توقف بارگذاری در صورت عدم وجود توکن
        return;
      }

      // درخواست اول
      const response1 = await axios.post(
        `${url}api/v1/panel/edit/competition`,
        {
          name: formData.firstName,
          last_name: formData.lastName,
          national_code: formData.nationalCode,
          birth_date: formData.birth_date,
          gender: formData.gender,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("First request succeeded:", response1.data);

      // درخواست دوم
      const response2 = await axios.post(
        `${url}api/v1/panel/edit_user_images`,
        (() => {
          const formDataData = new FormData();
          formDataData.append("self_image", formData.selfie); // اضافه کردن تصویر سلفی
          formDataData.append("id_card_image", formData.nationalCard); // اضافه کردن تصویر کارت ملی
          return formDataData;
        })(),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // نوع محتوا
          },
        }
      );

      console.log("Second request succeeded:", response2.data);

      // درخواست سوم
      const handleRegister = async (id) => {
        try {
          const response = await axios.post(
            `${url}api/v1/panel/competitions/${id}/add-user`,
            {}, // Body خالی
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              timeout: 10000,
            }
          );

          if (response.status === 200) {
            NotificationManager.success(
              "ثبت‌نام با موفقیت انجام شد.",
              "موفقیت",
              3000
            );
            invalidateQuery();
            closeModal();
          } else {
            NotificationManager.error("مشکلی در ثبت‌نام پیش آمد.", "خطا", 3000);
          }
        } catch (error) {
          console.error("Error in third request:", error);

          if (error.response?.status === 401) {
            NotificationManager.error(
              "دسترسی غیرمجاز. لطفاً وارد سیستم شوید.",
              "خطا",
              3000
            );
          } else if (error.code === "ECONNABORTED") {
            NotificationManager.error(
              "درخواست زمان زیادی طول کشید. لطفاً دوباره تلاش کنید.",
              "خطا",
              3000
            );
          } else if (error.response?.status === 400) {
            NotificationManager.error(
              "لطفا اطلاعات هویتی خود را تکمیل کنید",
              "خطا",
              3000
            );
          } else {
            NotificationManager.error(
              "خطا در ارسال درخواست. لطفاً دوباره تلاش کنید.",
              "خطا",
              3000
            );
          }
        }
      };
      

      // اجرای درخواست سوم
      await handleRegister(id);
    }catch (error) {
      // مدیریت خطا و نمایش پیام
      const errorMessage = error.response?.data?.message || "خطایی رخ داد.";
    
      if (errorMessage.includes("SQLSTATE[23000]: Integrity constraint violation")) {
        // نمایش پیام سفارشی در صورت وجود متن مشخص
        NotificationManager.error("کد ملی شما قبلاً ثبت شده است", "خطا");
      } else {
        // نمایش پیام خطای عمومی
        NotificationManager.error(errorMessage, "خطا");
      }
    
      console.error("Request failed:", errorMessage);
    }
     finally {
      setIsLoading(false); // توقف بارگذاری پس از پایان همه درخواست‌ها
    }
  };

  const handleSubmit = (id) => {
    if (validateForm()) {
      makeRequests(id);
    }
  };

  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: "#fff",
    padding: "2rem",
    borderRadius: "10px",
    color: "#333",
    width: "90%",
    maxWidth: "700px",
    maxHeight: "80vh",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    alignItems: "center",
  };

  const sectionStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    alignItems: "flex-start",
    width: "100%",
  };

  const rowStyle = {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "0.5rem 1rem",
    backgroundColor: "#f9f9f9",
    borderRadius: "5px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
  };

  const buttonStyle = {
    padding: "0.75rem 1.5rem",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "1rem",
    transition: "background-color 0.3s",
    alignSelf: "center",
  };

  const winnersContainer = {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: "1rem 0",
    width: "100%",
  };

  const winnerStyle = {
    textAlign: "center",
    backgroundColor: "#f9f9f9",
    padding: "1rem",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    flex: 1,
    margin: "0 0.5rem",
  };

  const firstPlaceStyle = {
    ...winnerStyle,
    transform: "scale(1.2)",
    backgroundColor: "#f8e1a1",
  };

  const secondPlaceStyle = {
    ...winnerStyle,
    backgroundColor: "#e3e3e3",
  };

  const thirdPlaceStyle = {
    ...winnerStyle,
    backgroundColor: "#d4a373",
  };

  const tableStyle = {
    width: "100%",
    marginTop: "2rem",
    borderCollapse: "collapse",
    direction: "rtl",
    fontFamily: "'Vazir', sans-serif",
  };

  const tableHeaderStyle = {
    background: "linear-gradient(90deg, #ffd700, #ff8c00)",
    color: "#fff",
    fontWeight: "bold",
    fontSize: "1.2rem",
    textAlign: "center",
    borderBottom: "2px solid #ddd",
  };

  const tableRowStyle = {
    borderBottom: "1px solid #ddd",
    backgroundColor: "#fdfdfd",
  };

  const tableCellHeaderStyle = {
    padding: "1rem",
    fontSize: "1.1rem",
    textAlign: "center",
  };

  const tableCellStyle = {
    padding: "1rem",
    fontSize: "1rem",
    textAlign: "center",
    color: "#333",
  };

  const tableData = [
    { plan: "پلن طلایی", rank: "4th" },
    { plan: "پلن نقره‌ای", rank: "5th" },
    { plan: "پلن برنزی", rank: "6th" },
  ];

  const formStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  };

  const inputStyle = {
    padding: "0.75rem",
    fontSize: "1rem",
    border: "1px solid #ccc",
    borderRadius: "5px",
    width: "100%",
  };

  const fileInputStyle = {
    ...inputStyle,
    padding: "0.5rem",
  };

  const errorStyle = {
    color: "red",
    fontSize: "0.875rem",
  };

  const renderModalContent = () => {
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      if (name === "birth_date") {
        setFormData({ ...formData, [name]: value.split("T")[0] });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    };

    const handleFileChange = (e) => {
      const { name, files } = e.target;
      setFormData({ ...formData, [name]: files[0] });
    };

    switch (modalData.status) {
      case 1: // آینده
        if (modalData.flag) {
          return (
            <div style={modalContentStyle} data-aos="zoom-in">
              <h2>ثبت‌نام در مسابقه</h2>
              {/* <form style={formStyle} onSubmit={(e) => e.preventDefault()}>
            <div>
              <input
                type="text"
                name="firstName"
                placeholder="نام"
                style={inputStyle}
                value={formData.firstName}
                onChange={handleInputChange}
              />
              {formErrors.firstName && <p style={errorStyle}>{formErrors.firstName}</p>}
            </div>
            <div>
              <input
                type="text"
                name="lastName"
                placeholder="نام خانوادگی"
                style={inputStyle}
                value={formData.lastName}
                onChange={handleInputChange}
              />
              {formErrors.lastName && <p style={errorStyle}>{formErrors.lastName}</p>}
            </div>
            <div>
              <input
                type="number"
                name="nationalCode"
                style={inputStyle}
                onChange={handleInputChange}
                value={formData.nationalCode}
              />
              {formErrors.nationalCode && <p style={errorStyle}>{formErrors.nationalCode}</p>}
            </div>
            <div>
              <input
                type="file"
                name="nationalCard"
                style={fileInputStyle}
                onChange={handleFileChange}
              />
              {formErrors.nationalCard && <p style={errorStyle}>{formErrors.nationalCard}</p>}
            </div>
            <div>
              <input
                type="file"
                name="selfie"
                style={fileInputStyle}
                onChange={handleFileChange}
              />
              {formErrors.selfie && <p style={errorStyle}>{formErrors.selfie}</p>}
            </div>

            <button type="button" style={buttonStyle} onClick={()=>handleSubmit(modalData.id)}>
              ثبت‌نام
            </button>
          </form> */}
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1.5rem",
                  padding: "2rem",
                  backgroundColor: "#f4f4f4",
                  borderRadius: "10px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                  maxWidth: "600px",
                  margin: "0 auto",
                }}
                onSubmit={(e) => e.preventDefault()}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <label
                    htmlFor="firstName"
                    style={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      color: "#333",
                      minWidth: "100px",
                      textAlign: "right",
                    }}
                  >
                    نام:
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="نام"
                    style={{
                      padding: "0.75rem",
                      fontSize: "1rem",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      flex: 1,
                    }}
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                </div>
                {formErrors.firstName && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "0.875rem",
                      textAlign: "right",
                    }}
                  >
                    {formErrors.firstName}
                  </p>
                )}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <label
                    htmlFor="lastName"
                    style={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      color: "#333",
                      minWidth: "100px",
                      textAlign: "right",
                    }}
                  >
                    نام خانوادگی:
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="نام خانوادگی"
                    style={{
                      padding: "0.75rem",
                      fontSize: "1rem",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      flex: 1,
                    }}
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                </div>
                {formErrors.lastName && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "0.875rem",
                      textAlign: "right",
                    }}
                  >
                    {formErrors.lastName}
                  </p>
                )}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <label
                    htmlFor="nationalCode"
                    style={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      color: "#333",
                      minWidth: "100px",
                      textAlign: "right",
                    }}
                  >
                    کد ملی:
                  </label>
                  <input
                    type="number"
                    id="nationalCode"
                    name="nationalCode"
                    placeholder="کد ملی"
                    style={{
                      padding: "0.75rem",
                      fontSize: "1rem",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      flex: 1,
                    }}
                    value={formData.nationalCode}
                    onChange={handleInputChange}
                  />
                </div>
                {formErrors.nationalCode && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "0.875rem",
                      textAlign: "right",
                    }}
                  >
                    {formErrors.nationalCode}
                  </p>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <label
                    htmlFor="nationalCode"
                    style={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      color: "#333",
                      minWidth: "100px",
                      textAlign: "right",
                    }}
                  >
                    جنسیت:
                  </label>
                  <select
                    type="number"
                    id="gender"
                    name="gender"
                    placeholder="جنسیت"
                    style={{
                      padding: "0.75rem",
                      fontSize: "1rem",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      flex: 1,
                    }}
                    value={formData.gender}
                    onChange={handleInputChange}
                  >
                    <option value=""> انتخاب نمایید</option>
                    <option value="male"> مرد</option>
                    <option value="female"> زن</option>
                  </select>
                </div>
                {formErrors.firstName && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "0.875rem",
                      textAlign: "right",
                    }}
                  >
                    {formErrors.gender}
                  </p>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <label
                    htmlFor="birth_date"
                    style={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      color: "#333",
                      minWidth: "100px",
                      textAlign: "right",
                    }}
                  >
                    تاریخ تولد:
                  </label>
                  {/* <input
                    type="date"
                    id="nationalCode"
                    name="nationalCode"
                    placeholder="کد ملی"
                    style={{
                      padding: "0.75rem",
                      fontSize: "1rem",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      flex: 1,
                    }}
                    value={formData.nationalCode}
                    onChange={handleInputChange}
                  /> */}
                  <div style={{ width: "100%" }} className="compeitionDate">
                    <DateInput
                      disabled
                      id="birth_date"
                      name="birth_date"
                      value={formData.birth_date}
                      onChange={handleInputChange}
                      style={{
                        padding: "0.75rem",
                        fontSize: "1rem",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        flex: 1,
                      }}
                      placeholder="انتخاب از تقویم"
                    />
                  </div>
                </div>
                {formErrors.firstName && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "0.875rem",
                      textAlign: "right",
                    }}
                  >
                    {formErrors.birth_date}
                  </p>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <label
                    htmlFor="nationalCard"
                    style={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      color: "#333",
                      minWidth: "100px",
                      textAlign: "right",
                    }}
                  >
                    عکس کارت ملی:
                  </label>
                  <input
                    type="file"
                    id="nationalCard"
                    name="nationalCard"
                    style={{
                      padding: "0.5rem",
                      fontSize: "1rem",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      flex: 1,
                    }}
                    onChange={handleFileChange}
                  />
                </div>
                {formErrors.nationalCard && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "0.875rem",
                      textAlign: "right",
                    }}
                  >
                    {formErrors.nationalCard}
                  </p>
                )}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <label
                    htmlFor="selfie"
                    style={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      color: "#333",
                      minWidth: "100px",
                      textAlign: "right",
                    }}
                  >
                    عکس سلفی با کارت ملی
                  </label>
                  <input
                    type="file"
                    id="selfie"
                    name="selfie"
                    style={{
                      padding: "0.5rem",
                      fontSize: "1rem",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      flex: 1,
                    }}
                    onChange={handleFileChange}
                  />
                </div>
                {formErrors.selfie && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "0.875rem",
                      textAlign: "right",
                    }}
                  >
                    {formErrors.selfie}
                  </p>
                )}

                <button
                  type="button"
                  style={{
                    padding: "0.75rem 1.5rem",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontSize: "1rem",
                    alignSelf: "center",
                    transition: "background-color 0.3s",
                  }}
                  onClick={() => handleSubmit(modalData.id)}
                >
                  ثبت‌نام
                </button>
              </form>

              <button onClick={closeModal} style={buttonStyle}>
                بستن
              </button>
            </div>
          );
        }
        {
          console.log("modal data:::", modalData);
        }
        return (
          <div style={modalContentStyle} data-aos="zoom-in">
            <h2> {modalData.title}</h2>
            <div style={sectionStyle}>
              <div style={rowStyle}>
                <strong>شروع:</strong>
                <span>
                  {new Date(modalData.start).toLocaleDateString("fa-IR")}
                  <span
                    style={{
                      fontSize: "0.9rem",
                      color: "#666",
                      marginRight: "0.5rem",
                    }}
                  >
                    ({new Date(modalData.start).toLocaleDateString("en-US")})
                  </span>
                </span>
              </div>
              <div style={rowStyle}>
                <strong>پایان:</strong>
                <span>
                  {new Date(modalData.end).toLocaleDateString("fa-IR")}
                  <span
                    style={{
                      fontSize: "0.9rem",
                      color: "#666",
                      marginRight: "0.5rem",
                    }}
                  >
                    ({new Date(modalData.end).toLocaleDateString("en-US")})
                  </span>
                </span>
              </div>
              <div style={rowStyle}>
                <strong>جایزه:</strong>
                <span>{modalData.prize}</span>
              </div>
              <div style={rowStyle}>
                <strong>تعداد شرکت‌کنندگان:</strong>
                <span>{modalData.contestants}</span>
              </div>
            </div>
            <button onClick={closeModal} style={buttonStyle}>
              بستن
            </button>
          </div>
        );

      case 0: // در حال اجرا
        return (
          <div style={modalContentStyle}>
            <h2>مسابقه {modalData.title}</h2>
            <div style={sectionStyle}>
              <div style={rowStyle}>
                <strong>تعداد شرکت‌کنندگان:</strong>
                <span>{modalData.contestants}</span>
              </div>
              <div style={rowStyle}>
                <strong>شروع:</strong>
                <span>
                  {new Date(modalData.start).toLocaleDateString("fa-IR")}
                </span>
              </div>
              <div style={rowStyle}>
                <strong>پایان:</strong>
                <span>
                  {new Date(modalData.end).toLocaleDateString("fa-IR")}
                </span>
              </div>
              <div style={rowStyle}>
                <strong>جایزه:</strong>
                <span>{modalData.prize}</span>
              </div>
            </div>
            <button onClick={closeModal} style={buttonStyle}>
              بستن
            </button>
          </div>
        );

      case 2: // پایان‌یافته
        return (
          <div style={modalContentStyle}>
            <h2>برندگان مسابقه {modalData.title}</h2>
            <div style={winnersContainer}>
              <div style={secondPlaceStyle}>
                <p>🥈 نفر دوم</p>
                <p>$3,000 پول نقد</p>
              </div>
              <div style={firstPlaceStyle}>
                <p>🥇 نفر اول</p>
                <p>$7,000 پول نقد</p>
              </div>
              <div style={thirdPlaceStyle}>
                <p>🥉 نفر سوم</p>
                <p>$2,000 پول نقد</p>
              </div>
            </div>
            <h3>رتبه‌بندی پلن‌ها</h3>
            <table style={tableStyle}>
              <thead>
                <tr style={tableHeaderStyle}>
                  <th style={tableCellHeaderStyle}>نام پلن</th>
                  <th style={tableCellHeaderStyle}>رتبه</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index} style={tableRowStyle}>
                    <td style={tableCellStyle}>{row.plan}</td>
                    <td style={tableCellStyle}>{row.rank}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={closeModal} style={buttonStyle}>
              بستن
            </button>
          </div>
        );

      default:
        return (
          <div style={modalContentStyle}>
            <h2>اطلاعات مسابقه در دسترس نیست</h2>
            <button onClick={closeModal} style={buttonStyle}>
              بستن
            </button>
          </div>
        );
    }
  };

  if (isLoading) {
    load(true);
  } else {
    load(false);
  }
  return createPortal(
    <div style={modalStyle}>{renderModalContent()}</div>,
    document.body
  );
}

export default ModalCompetition;
