import React, { useEffect } from "react";
import "./UserPerformance.css";
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import AOS from "aos";
import "aos/dist/aos.css";

function UserPerformance({ payouts }) {
  // تبدیل داده‌ها به فرمت مناسب برای نمودار
  const aggregatedData = payouts.reduce((acc, item) => {
    const dateParts = item.updated_at.split(" "); // جدا کردن زمان از تاریخ
    const formattedDate = dateParts[1]; // تاریخ به فرمت YYYY-MM-DD
    const month = new Date(formattedDate).toLocaleString("fa-IR", {
      month: "long",
    });

    const amount = parseFloat(item.received_amount);

    if (acc[month]) {
      acc[month] += amount;
    } else {
      acc[month] = amount;
    }

    return acc;
  }, {});

  const growthData = Object.keys(aggregatedData).map((month) => ({
    time: month,
    growth: aggregatedData[month],
  }));

  return (
    <div className="UserPerformanceContainer">
      <h2 className="UserPerformanceTitle">عملکرد کاربر</h2>

      {/* نمودار درصد رشد */}
      <div className="ChartContainer">
        {payouts && payouts.length > 0 ? (
          <>
            <h3>نمودار درصد رشد (ماهانه)</h3>
            <ResponsiveContainer width="100%" margin="0" height={300}>
              <AreaChart data={growthData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="growth"
                  stroke="#2196f3"
                  fill="#cb9e52" // رنگ پر کردن
                  name="درصد رشد"
                />
              </AreaChart>
            </ResponsiveContainer>
          </>
        ) : (
          <div
            style={{
              textAlign: "center",
              color: "#ff6f61",
              fontWeight: "bold",
            }}
          >
             عملکردی برای شما ثبت نشده است.
          </div>
        )}
      </div>
    </div>
  );
}

export default UserPerformance;
