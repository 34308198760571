import { useEffect } from "react";

export const useNotification = () => {
  // Check and request notification permission
  const requestPermission = async () => {
    if (Notification.permission === "default") {
      try {
        const permission = await Notification.requestPermission();
        return permission;
      } catch (err) {
        console.error("Notification permission error:", err);
        return "denied";
      }
    }
    return Notification.permission;
  };

  // Function to show notification
  const sendNotification = (title, options) => {
    if (Notification.permission === "granted") {
      const notification = new Notification(title, options);

      // Handle notification click
      notification.onclick = () => {
        console.log("Notification clicked");
        if (options.onClick) {
          options.onClick();
        }
      };

      // Handle notification close
      notification.onclose = () => {
        console.log("Notification closed");
        if (options.onClose) {
          options.onClose();
        }
      };

      // Handle notification error
      notification.onerror = (err) => {
        console.error("Notification error:", err);
        if (options.onError) {
          options.onError(err);
        }
      };

      return notification;
    } else {
      console.log("Notifications are not enabled.");
    }
  };

  // Hook initialization: check permissions on mount
  useEffect(() => {
    requestPermission();
  }, []);

  return {
    requestPermission,
    sendNotification,
  };
};
