/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Suspense, useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  closeEdit,
  toggleEmojiPicker,
  closeSupportTyping,
  openSupportTyping,
} from "./slices/inputSlice";
import Textarea from "./MessageTextarea";
import { MdOutlineEmojiEmotions } from "react-icons/md";
import { BsFillSendFill, BsReplyFill } from "react-icons/bs";
import { ImAttachment } from "react-icons/im";
import { IoCloseOutline } from "react-icons/io5";
import { RiEdit2Line } from "react-icons/ri";
import InputButtons from "./InputButtons";
import ConfirmSendFile from "./ConfirmSendFile";
import ShowFile from "./ShowFile";
import ReplyOrEdit from "./ReplyOrEdit";
import { HiPencilSquare } from "react-icons/hi2";
import toast from "react-hot-toast";
import QuickResponse from "./QuickResContainer";
import { userHasPermission } from "../../../utils/helper";
import UserContext from "../../UserContext/UserContext";

const EmojiPicker = React.lazy(() => import("emoji-picker-react"));

function InputArea({
  activeChat,
  socket,
  handleFormat,
  inputValue,
  setInputValue,
  handleInput,
  handleInputChange,
  handleSendMessage,
  handlePasteOrDrag,
  fileInputRef,
  selectedFile,
  setSelectedFile,
  messages,
  handleCloseReply,
  handleButtonClick,
  handleFileChange,
  textareaRef,
  pickMessage,
  quickResponse,
  quickResponseRefs,
  selectedCategoryIndex,
  selectedQuickResponseIndex,
  handleQuickResponseSelect,
  highlightText,
  quickResponseSearch,
}) {
  const dispatch = useDispatch();
  const { activeTab, activeChatId } = useSelector((state) => state.message);
  const {
    replyId,
    replyMessage,
    // userTyping,
    // supportIsTyping,
    showEmojiPicker,
    editId,
    isEditing,
  } = useSelector((state) => state.input);

  const [userTyping, setUserTyping] = useState(null); // Local state for typing indication
  const [supportIsTyping, setSupportIsTyping] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (activeChatId && socket) {
      socket.on("userTyping", (data) => {
        if (data.chatId === activeChatId) {
          // dispatch(openUserIsTyping(data.message + " ... "));
          setUserTyping(`${data.message} ...`);
        }
      });

      socket.on("supportType", (data) => {
        if (data.chatId === activeChatId) {
          dispatch(openSupportTyping());
          setSupportIsTyping(true);
        }
      });
      socket.on("supportStoppedType", () => {
        dispatch(closeSupportTyping());
        setSupportIsTyping(false);
      });
      socket.on("userStoppedTyping", () => {
        // dispatch(closeUserIsTyping());
        setUserTyping(null);
      });

      return () => {
        socket.off("userTyping");
        socket.off("supportType");
        socket.off("supportStoppedType");
        socket.off("userStoppedTyping");
      };
    }
  }, [activeChatId, dispatch, socket]);

  function handlePick() {
    pickMessage(
      { chatId: activeChatId },
      {
        onSuccess: () => {
          handleFormat("my", activeChatId);
          socket.emit("getOpenChats");
        },
        onError: (e) => {
          toast.error(
            e.response.data.message || e.response.data.error || e.message
          );

          socket.emit("getOpenChats");
        },
      }
    );
  }

  const addEmoji = (emoji) => {
    setInputValue((prev) => prev + emoji.emoji);
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        {/* {activeTab === "other" && (
        <>
          {userTyping && (
            <ReplyOrEdit>
              <div className="reply-edit-message">
                <HiPencilSquare />
                <span>
                  {" "}
                  Is Typing{" "}
                  <span style={{ color: "var(--color-grey-600)" }}>
                    {userTyping}
                  </span>{" "}
                </span>
              </div>
            </ReplyOrEdit>
          )}
          {supportIsTyping && (
            <ReplyOrEdit>
              <div className="reply-edit-message">
                <HiPencilSquare />
                <span>{activeChat[0]?.support?.nickname} Is Typing ... </span>
              </div>
            </ReplyOrEdit>
          )}
        </>
      )} */}
        <QuickResponse
          quickResponse={quickResponse}
          quickResponseRefs={quickResponseRefs}
          selectedCategoryIndex={selectedCategoryIndex}
          selectedQuickResponseIndex={selectedQuickResponseIndex}
          handleQuickResponseSelect={handleQuickResponseSelect}
          highlightText={highlightText}
          quickResponseSearch={quickResponseSearch}
        />
        {activeTab === "pending" && userHasPermission(user, "create chats") && (
          <div
            style={{
              padding: "0.6rem 2rem",
              backgroundColor: "var(--color-brand-700)",
              textAlign: "center",
              borderRadius: "10rem",
              cursor: "pointer",
              fontSize: "1rem",
            }}
          >
            <div onClick={handlePick}>Tap to pick chat</div>
          </div>
        )}
        {activeTab === "other" && (
          <>
            {" "}
            {supportIsTyping && (
              <ReplyOrEdit>
                <div className="reply-edit-message">
                  <HiPencilSquare />
                  <span>
                    {" "}
                    Is Typing{" "}
                    <span style={{ color: "var(--color-grey-600)" }}>
                      {userTyping}
                    </span>{" "}
                  </span>
                </div>
              </ReplyOrEdit>
            )}
            <div
              style={{
                padding: "0.6rem 0.4rem",
                backgroundColor: "var(--color-brand-700)",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <span>
                This chat already picked by{" "}
                <span style={{ color: "var(--color-blue-500)" }}>
                  {activeChat?.at(0)?.support?.nickname
                    ? activeChat?.at(0)?.support?.nickname
                    : activeChat?.at(0)?.support?.first_name}
                </span>
              </span>
            </div>
          </>
        )}
        {messages.length > 0 && activeTab !== "pending" && (
          <div
            style={{
              position: "relative",
            }}
          >
            {/* {activeChat[0]?.support?.nickname && (
            <div
              style={{
                padding: "1.2rem 2.4rem",
                backgroundColor: "var(--color-grey-100)",
                textAlign: "center",
              }}
            >
              <p>This chat is picked by {activeChat[0]?.support?.nickname}</p>
            </div>
          )} */}
            {replyId && (
              <ReplyOrEdit>
                <div className="reply-edit-message">
                  <BsReplyFill />
                  <span>
                    {" "}
                    Reply for{" "}
                    <span style={{ color: "var(--color-grey-500)" }}>
                      &ldquo;
                      {replyMessage.length > 50
                        ? replyMessage.slice(0, 50) + "..."
                        : replyMessage}
                      &rdquo;
                    </span>
                  </span>
                </div>
                <div className="close" onClick={handleCloseReply}>
                  <IoCloseOutline />
                </div>
              </ReplyOrEdit>
            )}
            {isEditing && (
              <ReplyOrEdit>
                <div className="reply-edit-message">
                  <RiEdit2Line />
                  <span> Edit message </span>
                </div>
                <div
                  className="close"
                  onClick={() => {
                    dispatch(closeEdit());
                    setInputValue("");
                  }}
                >
                  <IoCloseOutline />
                </div>
              </ReplyOrEdit>
            )}
            {userTyping && (
              <ReplyOrEdit>
                <div className="reply-edit-message">
                  <HiPencilSquare />
                  <span>
                    {" "}
                    Is Typing{" "}
                    <span style={{ color: "var(--color-grey-600)" }}>
                      {userTyping}
                    </span>{" "}
                  </span>
                </div>
              </ReplyOrEdit>
            )}

            {userHasPermission(user, "create chats") && (
              <>
                <Suspense>
                  <EmojiPicker
                    open={showEmojiPicker}
                    onEmojiClick={addEmoji}
                    lazyLoadEmojis={true}
                    emojiSize={22} // Adjust the size for lighter rendering
                    style={{
                      position: "absolute",
                      bottom: "5rem",
                      right: "1rem",
                    }}
                  />
                </Suspense>
                <Textarea
                  ref={textareaRef}
                  value={inputValue}
                  onChange={handleInputChange}
                  onPaste={handlePasteOrDrag}
                  onDrop={handlePasteOrDrag} // Handle dropping images
                  onDragOver={(e) => e.preventDefault()} // Prevent default drag behavior
                  placeholder="Write a message..."
                  onInput={handleInput} // Call handleInput on every input
                  rows={1} // Start with one row
                  dir="auto"
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <InputButtons className="input-btns">
                  <button className="btn">
                    <MdOutlineEmojiEmotions
                      size="2rem"
                      onClick={() => dispatch(toggleEmojiPicker())}
                    />
                  </button>
                  {inputValue ? (
                    <button className="btn">
                      <BsFillSendFill size="2rem" onClick={handleSendMessage} />
                    </button>
                  ) : (
                    <button className="btn">
                      <ImAttachment size="2rem" onClick={handleButtonClick} />
                    </button>
                  )}
                </InputButtons>
              </>
            )}
          </div>
        )}
      </div>
      <div>
        <ConfirmSendFile isOpen={selectedFile && true}>
          <ShowFile
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            socket={socket}
            fileInputRef={fileInputRef}
          />
        </ConfirmSendFile>
      </div>
    </>
  );
}

export default InputArea;
