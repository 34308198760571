import styled from "styled-components";

const Textarea = styled.textarea`
  /* border: 1px solid var(--color-grey-300); */
  border-width: 1px 0 1px 0;
  /* background-color: var(--color-brand-700); */
  color: var(--color-grey-100);
  background-color: rgba(0, 128, 126, 0.297);
  padding: 0.8rem 8rem 0.8rem 1.2rem;
  /* box-shadow: var(--shadow-sm); */
  resize: vertical;
  width: 100%;
  font-size: 1rem;
  line-height: 1.8rem;
  min-height: 6rem;
  max-height: 20rem;
  overflow-y: auto;

  &::placeholder {
    color: var(--color-grey-100);
  }
`;

export default Textarea;
