/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState , useContext, useEffect } from 'react'
import "./AdminUsers.css"
import { MdAdsClick } from "react-icons/md";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Success from '../Success/Success';
import axios from 'axios';
import Cookies from 'js-cookie';
import UserContext from "../UserContext/UserContext"
import { IoChevronBackCircle , IoArrowForwardCircleSharp } from "react-icons/io5";
export default function AdminCamp() {
const [modal , setModal] = useState(false)
const [users , setUsers] = useState({data:[] , links:[] })
const [title , setTitle] = useState("")
const [type , setType] = useState("")
const [faal , setFaal] = useState("")
const [start , setStart] = useState("")
const [end , setEnd] = useState("")
const [each , setEach] = useState("")
const translator = (text) => {
    if(text === "email"){
        return "ایمیل"
    }else if(text === "ads"){
        return "تبلیغاتی"
    }else if(text === "social_media"){
        return 'شبکه های اجتماعی'
    }else if(text === "promotion"){
      return 'کد تخفیف'
    }
}
const inModalShow = ()=>{
    return <>
       <div>
         <div style={{textAlign:"center"}} className='bb'> ویرایش کمپین {each.name&&each.name} </div>
         <div style={{
            textAlign:"right"
         }} className="row">
                    <div className='col-md-12 mt'>
                        <label className=" small" > عنوان کمپین : ( {each.name&&each.name} ) </label>
                        <input type='text' className='form-control br0' 
                         onChange={(e) => {setTitle(e.target.value)}}
                         
                         />
                    </div>
                    <div className='col-md-6 mt'>
                        <label className=" small"> نوع کمپین : </label>
                        <select value={type} className='select_input'
                         onChange={(e) => {setType(e.target.value)}}>
                            <option value=""> انتخاب نمایید ( {translator(each.type&&each.type)} ) </option>
                            <option style={{color:"red" }} value="email"> ایمیل </option>
                            <option style={{color:"orange" }} value="social_media"> فضای مجازی </option>
                            <option style={{color:"green" }} value="promotion"> کد تخفیف </option>
                            <option style={{color:"green" }} value="ads"> تبلیغاتی </option>
                        </select>
                    </div>
                    <div className='col-md-6 mt'>
                        <label className=" small"> وضعیت کمپین : </label>
                        <select value={faal} className='select_input'
                         onChange={(e) => {setFaal(e.target.value)}}>
                            <option value=""> انتخاب نمایید ( {each.is_active?"فعال":"غیر فعال"} ) </option>
                            <option style={{color:"red" }} value={1}> فعال</option>
                            <option style={{color:"orange" }} value={0}> غیر فعال </option>
                        </select>
                    </div>
                    <div className='col-md-6 mt'>
                        <label className=" small" > تاریخ شروع : ( {each.start_date?.split(" ")[0]} ) </label>
                        <input  type='date' className='form-control br0' 
                         onChange={(e) => {setStart(e.target.value)}}/>
                    </div>
                    <div className='col-md-6 mt'>
                        <label className=" small" >تاریخ اتمام : ( {each.start_date?.split(" ")[0]} ) </label>
                        <input type='date' className='form-control br0' 
                         onChange={(e) => {setEnd(e.target.value)}}/>
                    </div>
                    {/* <div className='col-md-12 mt'>
                        <label className=" small"> توضیحات کمپین ( مخاطبان هدف ، تارگت نهایی و ... ) : </label>
                        <textarea value={des} className='select_input'
                         onChange={(e) => {setDes(e.target.value)}}/>
                    </div>        */}
            </div>
            <button onClick={()=>{
               if(title!=="" && type!== "" && 
                faal!== "" && start!== "" && end!== ""){
                    load(true)
                    axios.post(url+"api/v1/panel/campaigns/"+each.id+"/update",{
                        name:title,
                        type:type,
                        start_date:start,
                        end_date:end,
                        is_active:faal
                    },
                        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                    } }).then(res=>{
                        load(false)
                        console.log(res.data);
                        setStart("")
                        setEnd("")
                        setTitle("")
                        setType("")
                        setFaal("")
                        NotificationManager.success('کمپین با موفقیت ویرایش شد')
                        load(true)
                        setModal(false)
                        axios.get(url+"api/v1/panel/campaigns",
                            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                        } }).then(res=>{
                            load(false)
                            console.log(res.data);
                            setUsers(res.data)
                            setActiver({a1:"active",a2:"",a3:""})
                        }).catch(err=>{
                            load(false)
                            console.log(err);
                        })
                    }).catch(err=>{
                        load(false)
                        console.log(err);
                        NotificationManager.error('خطا در ایجاد کمپین')
                    })
               }else{
                NotificationManager.error('لطفا تمام فیلدهای مورد نیاز را وارد نمایید')
               }
            }} className='btnreply1 mt'> ویرایش کمپین </button>
            <button style={{
                background:"gold",
                border:"none",
                padding:"20px",
                width:"100%",
                borderRadius:"10px",
                cursor:"pointer"
            }} onClick={()=>{
               
            }} className=' mt'> بررسی کمپین </button>
       </div>
    </>
 }
 const { load , url  } = useContext(UserContext);

 useEffect(()=>{ 
    load(true)
    axios.get(url+"api/v1/panel/campaigns",
        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
    } }).then(res=>{
        load(false)
        console.log(res.data);
        setUsers(res.data)
    }).catch(err=>{
        load(false)
        console.log(err);
    })
 },[])
 const usersShow = ()=>{
    return <>
    {users.data.map(d=>{
            return <tr  style={{
                textAlign:"center"
             }} key={d.id}>
                     <th>{d.name}</th>
                     <th> {translator(d.type)} </th>
                     <th> {d.start_date.split(" ")[0]} </th>
                     <th> {d.end_date.split(" ")[0]} </th>
                     <th> {d.is_active?"فعال":"غیر فعال"} </th>
                     <th className='lastTh' > 
                     <MdAdsClick onClick={()=>{
                       setEach(d)
                       setModal(true)
                    }} /> </th>    
                 </tr>
        
    })}
    </>
 }
 const liShows = () =>{
    return <>
    {
        users.links.map(dd=>{
            let c = ()=>{
                if(dd.active){
                    return "ac"
                }else{
                    return null
                }
            }
            if(dd.label !== "&laquo; Previous" && dd.label !=="Next &raquo;"){
                return <li key={dd.label} className={"page__numbers "+ c()} onClick={()=>{
                    if(dd.url !== null){
                        load(true)
                    axios.get(dd.url,
                        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                    } }).then(res=>{
                        load(false)
                       
                        setUsers(res.data)
                    }).catch(err=>{
                        load(false)
                        console.log(err);
                    })
                    }
                }}> {dd.label} </li>
            }
         
        })
    }
    </>
    
 }
 const [activer, setActiver] = useState({a1:"active" , a2:"" , a3:"" })
 const mainShow = ()=>{
  if(activer.a1){
   return <>
            <div className='transAction'>
                <div className='eachTicket'>
                <input style={{marginBottom:"10px"}}
                      className='searchIn'
                      onChange={(e)=>{
                      setTimeout(()=>{
                          if(e.target.value.length >1){
                            load(true)
                            axios.get(url+"api/v1/panel/campaigns?query="+e.target.value,
                                { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                            } }).then(res=>{
                                load(false)
                                console.log(res.data);
                                setUsers(res.data)
                            }).catch(err=>{
                                load(false)
                                console.log(err);
                            })
                      }else{
                        load(true)
                        axios.get(url+"api/v1/panel/campaigns",
                            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                        } }).then(res=>{
                            load(false)
                            console.log(res.data);
                            setUsers(res.data)
                        }).catch(err=>{
                            load(false)
                            console.log(err);
                        })
                        }
                        },2000)
                        }} type='text' placeholder='جستجو عنوان کمپین'/>
                    <div className='table-responsive-md'>
                    
                        <table border={1} className="table table-hover">
                            
                            <thead >
                                    <tr  style={{
                                      textAlign:"center"
                                        }}>
                                    <th> عنوان کمپین</th>            
                                    <th> نوع </th>
                                    <th> تاریخ شروع </th>
                                    <th> تاریخ پایان </th>
                                    <th> وضعیت </th>
                                    <th className='lastTh'>  </th>    
                                </tr>
                                
                            </thead>
                            <tbody>
                                {usersShow()}
                            </tbody>

                        </table>
                        <ul class="page">
                               <li onClick={()=>{
                                if(users.prev_page_url !== null){
                                    load(true)
                                axios.get(users.prev_page_url,
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                   
                                    setUsers(res.data.data)
                                }).catch(err=>{
                                    load(false)
                                    console.log(err);
                                })
                                }
                               }} className="page__btn"><span class="material-icons"><IoChevronBackCircle /></span></li>
                               {liShows()}
                               <li onClick={()=>{
                                if(users.next_page_url !== null){
                                    load(true)
                                axios.get(users.next_page_url,
                                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                                } }).then(res=>{
                                    load(false)
                                   
                                    setUsers(res.data.data)
                                }).catch(err=>{
                                    load(false)
                                    console.log(err);
                                })
                                }
                               }} className="page__btn"><span class="material-icons"><IoArrowForwardCircleSharp /></span></li>
                        </ul>
                    </div>

                </div>
            </div>
            </>
  }else{
    return <>
    <div className='transAction'>
        <div className='eachTicket'>
            <div className="row">
                    <div className='col-md-12 mt'>
                        <label className=" small" > عنوان کمپین : </label>
                        <input value={title}  type='text' className='form-control br0' 
                         onChange={(e) => {setTitle(e.target.value)}}/>
                    </div>
                    <div className='col-md-6 mt'>
                        <label className=" small"> نوع کمپین : </label>
                        <select value={type} className='select_input'
                         onChange={(e) => {setType(e.target.value)}}>
                            <option value=""> انتخاب کنید </option>
                            <option style={{color:"red" }} value="email"> ایمیل </option>
                            <option style={{color:"orange" }} value="social_media"> فضای مجازی </option>
                            <option style={{color:"green" }} value="promotion"> کد تخفیف </option>
                            <option style={{color:"blue" }} value="ads"> تبلیغاتی </option>
                        </select>
                    </div>
                    <div className='col-md-6 mt'>
                        <label className=" small"> وضعیت کمپین : </label>
                        <select value={faal} className='select_input'
                         onChange={(e) => {setFaal(e.target.value)}}>
                            <option value=""> انتخاب کنید </option>
                            <option style={{color:"red" }} value={1}> فعال</option>
                            <option style={{color:"orange" }} value={0}> غیر فعال </option>
                        </select>
                    </div>
                    <div className='col-md-6 mt'>
                        <label className=" small" > تاریخ شروع : </label>
                        <input  type='date' className='form-control br0' 
                         onChange={(e) => {setStart(e.target.value)}}/>
                    </div>
                    <div className='col-md-6 mt'>
                        <label className=" small" >تاریخ اتمام : </label>
                        <input type='date' className='form-control br0' 
                         onChange={(e) => {setEnd(e.target.value)}}/>
                    </div>
                    {/* <div className='col-md-12 mt'>
                        <label className=" small"> توضیحات کمپین ( مخاطبان هدف ، تارگت نهایی و ... ) : </label>
                        <textarea value={des} className='select_input'
                         onChange={(e) => {setDes(e.target.value)}}/>
                    </div>        */}
            </div>
            <button onClick={()=>{
               if(title!=="" && type!== "" && 
                faal!== "" && start!== "" && end!== ""){
                    load(true)
                    axios.post(url+"api/v1/panel/campaigns/create",{
                        name:title,
                        type:type,
                        start_date:start,
                        end_date:end,
                        is_active:faal
                    },
                        { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                    } }).then(res=>{
                        load(false)
                        console.log(res.data);
                        setStart("")
                        setEnd("")
                        setTitle("")
                        setType("")
                        setFaal("")
                        NotificationManager.success('کمپین با موفقیت ایجاد شد')
                        load(true)
                        axios.get(url+"api/v1/panel/campaigns",
                            { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                        } }).then(res=>{
                            load(false)
                            console.log(res.data);
                            setUsers(res.data)
                            setActiver({a1:"active",a2:"",a3:""})
                        }).catch(err=>{
                            load(false)
                            console.log(err);
                        })
                    }).catch(err=>{
                        load(false)
                        console.log(err);
                        NotificationManager.error('خطا در ایجاد کمپین')
                    })
               }else{
                NotificationManager.error('لطفا تمام فیلدهای مورد نیاز را وارد نمایید')
               }
            }} className='btnreply1 mt'> ایجاد کمپین </button>
        </div>
    </div>
    </>
  }
 }
 
  return (
    <div>
        <Success show={modal} closeModal={()=>{setModal(false)} } name="ihefjhfj" >
          {inModalShow()}
        </Success>
        <NotificationContainer/>
      <div className="app-header-navigation">
        <div className="tabs">
            <a onClick={()=>{
                load(true)
                axios.get(url+"api/v1/panel/campaigns",
                    { headers: { "Authorization": `bearer ${Cookies.get('token')}` 
                } }).then(res=>{
                    load(false)
                    console.log(res.data);
                    setUsers(res.data)
                    setActiver({a1:"active",a2:"",a3:""})
                    
                    setStart("")
                        setEnd("")
                        setTitle("")
                        setType("")
                        setFaal("")
                }).catch(err=>{
                    load(false)
                    console.log(err);
                })
            }} className={activer.a1}>
             کمپین ها
            </a>
           <a onClick={()=>{
                setActiver({a1:"",a2:"active",a3:""})
                
            }}  className={activer.a2}>
             افزودن کمپین
            </a>
        </div>
        {mainShow()}
    </div>
    </div>
  )
}
