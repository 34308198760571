import { MAIN_SOCKET, MAIN_URL } from "../utils/constants";
import { request } from "../utils/helper";

export async function getConversations({ token }) {
  try {
    const res = await request({
      url: `${MAIN_URL}/admin/chats/openChats`,
      token,
    });
    return res;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
}

export async function getAllConversations({ token, username }) {
  try {
    const res = await request({
      url: `${MAIN_URL}/admin/chats`,
      token,
      params: { username },
    });

    return res;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
}

export async function getSupportChats(token, id, from, to) {
  try {
    const res = await request({
      url: `${MAIN_URL}/admin/chats/supportChatsAnalytics`,
      token,
      params: { userId: id, from, to },
    });

    return res;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
}

export async function getSupportChatsPaginate({
  token,
  supportId,
  from,
  to,
  pageOfPaginate,
}) {
  try {
    const res = await request({
      url: `${MAIN_URL}/admin/chats/supportChats`,
      token,
      params: {
        userId: `${supportId ? supportId : 0}`,
        from,
        to,
        page: pageOfPaginate,
      },
    });

    return res;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
}

export async function getMessageHistory(token, id) {
  if (!id) return;
  try {
    const res = await request({
      url: `${MAIN_URL}/admin/chats/messageHistory/${id}`,
      token,
    });

    return res;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
}

export async function getChatHistoryMessages(token, id) {
  if (!id) return;
  try {
    const res = await request({
      url: `${MAIN_URL}/panel/chats/getChatMessages/${id}`,
      token,
    });

    return res;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
}

// export async function getQuickResponses() {
//   try {
//     const res = await request({
//       url: `${MAIN_QUICK}`,
//     });
//     return res;
//   } catch (e) {
//     console.error(e.message);
//     throw e;
//   }
// }

export async function pickChat({ userId, chatId, token }) {
  try {
    const res = await request({
      url: `${MAIN_URL}/admin/chats/pickChat`,
      method: "POST",
      data: { userId: userId, chatId: chatId },
      token,
    });
    return res;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
}

export async function suspendChat({ token, id }) {
  try {
    const res = await request({
      url: `${MAIN_URL}/admin/chats/suspendChat`,
      method: "POST",
      data: {
        chatId: id,
      },
      token,
    });
    return res;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
}

export const sendFile = async (data, token) => {
  try {
    const res = await request({
      url: `${MAIN_URL}/panel/chats/upload`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      token,
    });
    return res;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
};

export async function banUser({ userId, data, token }) {
  try {
    const res = await request({
      url: `${MAIN_URL}/admin/chats/${userId}/ban`,
      method: "POST",
      data,
      token,
    });
    return res;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
}
