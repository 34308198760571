import React, { useState, useContext } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import CompetitionsList from "./CompetitionCard";
import UserContext from "../UserContext/UserContext";
import axios from "axios";
import Cookies from "js-cookie";

function Competition({changePage}) {
  const [activeTab, setActiveTab] = useState("list"); // مدیریت تب فعال
  const { load, url } = useContext(UserContext);
  const queryClient = useQueryClient(); // دسترسی به QueryClient

  // تابع برای invalidate کردن کوئری
  const invalidateCompetitionQuery = () => {
    queryClient.invalidateQueries(["competitions"]);
  };

  // Fetch competitions using React Query
  const {
    data: MyCompetitions = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ["competitions"], // Unique query key for caching
    queryFn: async () => {
      const response = await axios.get(`${url}api/v1/panel/competitions`, {
        headers: {
          Authorization: `bearer ${Cookies.get("token")}`,
        },
      });
      return response.data.data; // Returning the competition data
    },
  });

  const renderContent = () => {
    if (isLoading) {
      load(true);
      return <div>در حال بارگذاری...</div>;
    } else {
      load(false);
    }

    if (error) {
      return <div>خطایی رخ داد: {error.message}</div>;
    }

    let filteredCompetitions = [];
    switch (activeTab) {
      case "list":
        filteredCompetitions = MyCompetitions; // نمایش تمام مسابقات
        break;
      case "upcoming":
        filteredCompetitions = MyCompetitions.filter(
          (competition) => competition.status === 1 // آینده
        );
        break;
      case "inprogress":
        filteredCompetitions = MyCompetitions.filter(
          (competition) => competition.status === 0 // در حال اجرا
        );
        break;
      case "finished":
        filteredCompetitions = MyCompetitions.filter(
          (competition) => competition.status === 2 // پایان‌یافته
        );
        break;
      default:
        filteredCompetitions = []; // در صورتی که تب انتخابی معتبر نباشد
    }

    return (
      <CompetitionsList
        competitions={filteredCompetitions}
        toggleLoading={(status) => load(status)}
        invalidateQuery={invalidateCompetitionQuery} // پاس دادن تابع invalidate
        changePage={changePage}
      />
    );
  };

  return (
    <div>
      {/* هدر */}
      <header className="headerStyle">
        <div className="textOverlayStyle">
          <h1>مسابقه بزرگ فنفیکس</h1>
          <p>در این رقابت هیجان‌انگیز شرکت کنید و جوایز ارزشمند برنده شوید!</p>
        </div>
      </header>
      {/* سوئیچ باکس */}
      <div className="tabContainerStyle">
        <button
          className={activeTab === "list" ? "activeTabStyle" : "tabStyle"}
          onClick={() => setActiveTab("list")}
        >
          لیست مسابقات
        </button>
        <button
          className={activeTab === "upcoming" ? "activeTabStyle" : "tabStyle"}
          onClick={() => setActiveTab("upcoming")}
        >
          مسابقات آینده
        </button>
        <button
          className={activeTab === "inprogress" ? "activeTabStyle" : "tabStyle"}
          onClick={() => setActiveTab("inprogress")}
        >
          در حال اجرا
        </button>
        <button
          className={activeTab === "finished" ? "activeTabStyle" : "tabStyle"}
          onClick={() => setActiveTab("finished")}
        >
          پایان‌یافته
        </button>
      </div>
      {/* محتوای تب */}
      <div className="contentStyle">{renderContent()}</div>
    </div>
  );
}

// استایل‌ها
// const headerStyle = {
//   position: "relative",
//   width: "100%",
//   height: "300px",
//   backgroundImage: "url('/header.jfif')",
//   backgroundSize: "cover",
//   backgroundPosition: "center",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// };

export default Competition;
