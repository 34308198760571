import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  leftWidth: 1,
  rightWidth: 0.3,
};

const layoutWidthSlice = createSlice({
  name: "layoutWidth",
  initialState,
  reducers: {
    setLeftWidth: (state, action) => {
      state.leftWidth = action.payload;
    },
    setRightWidth: (state, action) => {
      state.rightWidth = action.payload;
    },
  },
});

export const { setLeftWidth, setRightWidth } = layoutWidthSlice.actions;

export default layoutWidthSlice.reducer;
