import Success from "../Success/Success";

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import AOS from "aos";
import "aos/dist/aos.css";
import UserContext from "../UserContext/UserContext";
import "./MainDashboard.css";
import {
  FaChartLine,
  FaEnvelopeOpenText,
  FaMoneyBillWave,
  FaUserShield,
  FaKey,
  FaCalendarAlt,
  FaEye, // برای نمایش آیکون مشاهده
} from "react-icons/fa";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export const fetchAccountData = async (userId, url, startDate, endDate) => {
  const response = await axios.get(
    `${url}api/v1/panel/dashboard/${userId}?start_date=${startDate}&end_date=${endDate}`,
    {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    }
  );
  console.log("my response adminn :::", response.data);
  return response.data;
};

function MainDashboard() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [modal, setModal] = useState(false); // برای مدیریت باز و بسته شدن مودال
  const [modalContent, setModalContent] = useState({
    oldValues: {},
    newValues: {},
  }); // داده‌های داخل مودال
  const pageSize = 5;
  const [modalType, setModalType] = useState(""); // "old" یا "new"

  // const today = new Date();
  // const lastWeek = new Date();
  // lastWeek.setDate(today.getDate() - 7);

  // const [startDate, setStartDate] = useState(
  //   lastWeek.toISOString().split("T")[0]
  // );
  // const [endDate, setEndDate] = useState(today.toISOString().split("T")[0]);

  const today = new Date(); // تاریخ امروز

  const [startDate, setStartDate] = useState(
    today.toISOString().split("T")[0] // شروع از امروز
  );
  const [endDate, setEndDate] = useState(
    today.toISOString().split("T")[0] // پایان تا امروز
  );

  const { url, user, load } = useContext(UserContext);

  const translations = {
    // دسته‌های اصلی
    "Account Actions": "عملیات حساب",
    Payouts: "برداشت‌ها",
    "Pass Requests": "درخواست‌های پاس",
    Tickets: "تیکت‌ها",
    Authentications: "احراز هویت‌ها",
    Deposits: "واریزی‌ها",
    Sell: "فروش‌ها",

    // کلیدهای داخلی
    total_actions_count: " کل عملیات",
    total_payouts_amount: "مجموع مبلغ برداشت‌ها",
    total_payouts: " کل برداشت‌ها",
    total_confirmed_payouts: " برداشت‌های تأیید شده",
    total_unconfirmed_payouts: " برداشت‌های تأیید نشده",
    total_on_progress_payouts: "برداشت‌های در حال بررسی",

    total_requests: " کل درخواست‌ها",
    total_confirmed_pass_requests: " درخواست‌های پاس تأیید شده",
    total_unconfirmed_pass_requests: " درخواست‌های پاس تأیید نشده",
    total_on_progress_pass_requests: "درخواست‌های پاس در حال بررسی",

    total_tickets: " کل تیکت‌ها",
    total_answered_tickets: " تیکت‌های پاسخ داده شده",
    total_pending_tickets: " تیکت‌های در انتظار",

    total_authentications: " کل احراز هویت‌ها",
    total_confirmed_authentications: " احراز هویت‌های تأیید شده",
    total_unconfirmed_authentications: " احراز هویت‌های تأیید نشده",

    total_deposits: "مجموع واریزی‌ها",
    coins_buy_deposit: "واریزی coins buy",
    tc_pay_deposit: "واریزی TC Pay",
    withdraws: "برداشت‌ها",
    rejected_withdraws: "برداشت‌های رد شده",
    accepted_withdraws: "برداشت‌های تأیید شده",

    // کلیدهای مربوط به Sell
    total_sell: "مجموع فروش",
    non_paid_count: "  حساب های بدون پرداخت",
    new_count: " حساب های با پرداخت",
    two_step_count: " چالش دو مرحله‌ای",
    one_step_count: " چالش تک مرحله‌ای",
    pro_trader_count: " pro Trader ",
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);
      try {
        const result = await fetchAccountData(
          user.dataes.id,
          url,
          startDate,
          endDate
        );
        console.log("my result", result);
        setData(result);
      } catch (error) {
        setIsError(true);
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [url, user.dataes.id, startDate, endDate]);

  if (isLoading) {
    load(true);
    return;
  } else {
    load(false);
  }

  // if (isLoading) return <div>در حال بارگذاری...</div>;
  if (isError) return <div>خطا در دریافت داده‌ها</div>;

  const cardData = Object.entries(data.Actions).map(([key, value]) => {
    const totalKey = Object.keys(value).find((k) => k.includes("total"));
    const mineKey = Object.keys(value).find((k) => k.includes("user"));

    return {
      title: key,
      total: value[totalKey] || 0,
      mine: value[mineKey] || 0,
    };
  });
  console.log("dddd", data, "user :::", user);

  // const sortedLogs = (data.Logs || []).sort(
  //   (a, b) => new Date(b.time) - new Date(a.time)
  // );

  const iconMap = {
    "عملیات حساب": <FaChartLine color="#8888ff" size={30} />,
    "پاسخ تیکت": <FaEnvelopeOpenText color="#ff6600" size={30} />,
    برداشت: <FaMoneyBillWave color="#00ff00" size={30} />,
    "احراز هویت": <FaUserShield color="#ff4444" size={30} />,
    "بررسی پاس": <FaKey color="#ffcc00" size={30} />,
  };

  const headerColors = {
    "عملیات حساب": "#cfd8fc", // آبی ملایم
    "پاسخ تیکت": "#ffd4b2", // نارنجی ملایم
    برداشت: "#d4f8d4", // سبز ملایم
    "احراز هویت": "#ffc8c8", // قرمز ملایم
    "بررسی پاس": "#fff4cc", // زرد ملایم
  };

  const sortedLogs = data?.Logs?.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  // جدا کردن نام کلاس از auditable_type
  const extractType = (auditableType) => {
    const parts = auditableType.split("\\");
    return parts[parts.length - 1];
  };
  const openModal = (log, type) => {
    setModalType(type);
    setModalContent(type === "old" ? log.old_values : log.new_values);
    setModal(true);
  };

  const { Charts = [] } = data || {}; // داده‌های Charts از data

  // تبدیل ماه به نام‌های فارسی

  const chartData = Charts.map((item) => ({
    ...item,
    monthName: `${item.year}/${item.month}`, // نمایش سال/ماه به فرمت میلادی
  }));

  if (
    user.dataes.roles[0].name === "support" ||
    user.dataes.roles[0].name === "monitoring"
  ) {
    return (
      <div className="DContainer">
        <Success show={modal} closeModal={() => setModal(false)}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h5>{modalType === "old" ? "Old Values" : "New Values"}</h5>
            <table style={{ minWidth: "16rem" }}>
              <tbody>
                {Object.entries(modalContent).map(([key, value], index) => (
                  <tr key={index}>
                    <td>{key}</td>
                    <td>{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Success>

        <div className="DateFilter">
          <label>
            تاریح شروع
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </label>
          <label>
            تاریخ پایان
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </label>
        </div>

        <div className="HeaderContainer">شاخص ها</div>
        <div className="DGrid" data-aos="fade-up">
          {cardData.map((card, index) => (
            <div key={index} className="Dchild">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "1rem",
                }}
              >
                {iconMap[card.title] || (
                  <FaChartLine color="#8888ff" size={30} />
                )}
              </div>
              <h3
                className="Dheader"
                style={{
                  backgroundColor: headerColors[card.title] || "#444",
                  padding: "0.5rem",
                  borderRadius: "5px",
                  color: "#285943",
                  textAlign: "center",
                }}
              >
                {card.title}
              </h3>
              <div className="Dmetrics">
                <p className="Dmetric">کل:</p>
                <p className="Dmetric">{card.total}</p>
              </div>
              <div className="Dmetrics">
                <p className="Dmetric">من:</p>
                <p className="Dmetric">{card.mine}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="D3">
          <div className="HeaderContainer">گزارشات</div>
          <table
            style={{ color: "white", textAlign: "center" }}
            border={1}
            className="table table-hover"
          >
            <thead>
              <tr>
                <th>رویداد</th>
                <th>نوع قابل پیگیری</th>
                <th>آدرس IP</th>
                <th>عامل کاربر</th>
                <th>تاریخ ایجاد</th>
                <th>مقادیر قدیمی</th>
                <th>مقادیر جدید</th>
              </tr>
            </thead>
            <tbody>
              {sortedLogs
                .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                .map((log, index) => (
                  <tr key={index}>
                    <td>{log.event}</td>
                    <td>{extractType(log.auditable_type)}</td>
                    <td>{log.ip_address}</td>
                    <td>{log.user_agent}</td>
                    <td>{new Date(log.created_at).toLocaleString("fa-IR")}</td>
                    <td>
                      <FaEye
                        color="#00ff00"
                        size={20}
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal(log, "old")} // باز کردن مودال برای Old Values
                      />
                    </td>
                    <td>
                      <FaEye
                        color="#ffcc00"
                        size={20}
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal(log, "new")} // باز کردن مودال برای New Values
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="Pagination">
            <button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              قبلی
            </button>
            <span>
              صفحه {currentPage} از {Math.ceil(sortedLogs.length / pageSize)}
            </span>
            <button
              disabled={currentPage === Math.ceil(sortedLogs.length / pageSize)}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              بعدی
            </button>
          </div>
        </div>
      </div>
      // <p>is loading</p>
    );
  } else if (user.dataes.roles[0].name === "admin") {
    return (
      <div className="AdminDashboardContainer">
        <div className="DateFilter">
          <label>
            تاریح شروع
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </label>
          <label>
            تاریخ پایان
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </label>
        </div>

        <div className="AdminDashboardMetrics" data-aos="zoom-out">
          {Object.entries(data.Actions).map(([key, values], index) => (
            <div key={index} className="AdminMetricCard">
              <h3 className="AdminMetricTitle">{translations[key] || key}</h3>
              <div className="AdminMetricContent">
                {Object.entries(values).map(([subKey, value], subIndex) => (
                  <div key={subIndex} className="MetricItem">
                    <p className="MetricKey">
                      {translations[subKey] || subKey}
                    </p>
                    <p className="MetricValue">{value}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* نمایش یک نمودار با 3 خط */}
        {!Charts.length ? (
          <p>داده‌ای برای نمایش نمودارها موجود نیست.</p>
        ) : (
          <div className="ChartsContainer" style={{ width: "100%" }}>
            <h3>نمودار عملکرد ماهیانه</h3>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="monthName" />
                <YAxis />
                <Tooltip
                  formatter={(value) =>
                    typeof value === "number" ? value.toFixed(2) : value
                  }
                />
                <Legend verticalAlign="top" />
                <Line
                  type="monotone"
                  dataKey="total_sell"
                  stroke="#3357FF" // آبی روشن
                  name="فروش‌ها"
                  strokeWidth={3}
                />
                <Line
                  type="monotone"
                  dataKey="total_deposit"
                  stroke="#FF5733" // نارنجی روشن
                  name="واریزی‌ها"
                  strokeWidth={3}
                />
                <Line
                  type="monotone"
                  dataKey="total_payouts"
                  stroke="#33FF57" // سبز روشن
                  name="برداشت‌ها"
                  strokeWidth={3}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    );
  }
}

export default MainDashboard;
