/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Button, Box } from "@mui/material";
import React, { useEffect, useContext, useState, useRef } from "react";
import "./Order.css";
import axios from "axios";
import Cookies from "js-cookie";
import UserContext from "../UserContext/UserContext";
import { RiInformation2Fill } from "react-icons/ri";
import TransAction from "../TransAction/TransAction";
import { useQuery } from "@tanstack/react-query";
import { ThreeCircles } from 'react-loader-spinner';

import {
  fetchOrderData,
  fetchCategoryProducts,
} from "./fetchers/orderFetchers";

export default function Order(props) {
  console.log('my props:',props)
  const { load, url } = useContext(UserContext);
  const [category_id, setcategory_id] = useState(null);

  // const [range, setRange] = useState({min: null, max:null, step: null})
  // const [orderData, setOrder] = useState({
  //   brokers: [],
  //   packages: [],
  //   messages: {},
  // });
  // const [categ, setCateg] = useState({ products: [] });

  const [orderDatB, setOrderB] = useState({ broker_servers: "" });
  const [platforms, setPlat] = useState("");
  const [broker, setBrok] = useState("");

  const [prod, setProd] = useState("");
  const [server, setServer] = useState("");
  const [btnSuc, setBtn] = useState(false);
  const [trans, setTrans] = useState(false);

  const [selectedRadio, setSelectedRadio] = useState(null);

  const [allData, setAllData] = useState({});
  const [c, setC] = useState("");
  const ref = useRef(null);
  // const [quantity, setQuantity] = useState(1000); // مقدار پیش‌فرض
  const [price, setPrice] = useState(0);

  const {
    data: orderData = { brokers: [], packages: [], messages: {} },
    isLoading,
    error,
  } = useQuery({
    queryKey: ["orderData"],
    queryFn: () => fetchOrderData(url),
  });

  const {
    data: categ = { products: [] },
    isLoading: isLoadingProducts,
    error: errorProducts,
  } = useQuery({
    queryKey: ["categoryProducts", category_id],
    queryFn: () => fetchCategoryProducts(category_id, url),
    enabled: !!category_id,
    onSuccess: (data) => {
      console.log("my data", data);
      // const categ = data?.products;
    },
  });
  const {
    data: range = { min: null, max: null, step: null },
    isLoading: isLoadingRanges,
    error: errorRanges,
  } = useQuery({
    queryKey: ["range", category_id],
    queryFn: () => fetchCategoryProducts(category_id, url),
    enabled: !!category_id, // درخواست فقط زمانی ارسال می‌شود که `category_id` تنظیم شده باشد
  });

//   if (isLoading || isLoadingProducts || isLoadingRanges) {
// {/* <ThreeCircles
//                 height="80"
//                 width="80"
//                 radius="9"
//                 color="green"
//                 ariaLabel="three-dots-loading"
//                 wrapperClass='loader'
//               /> */}
//     load(true);
//   } else {
//     load(false);
//   }

  ///////formools//////////////

  const calculatePrice = (quantity) => {
    const quantities = [1000, 5000, 10000, 20000, 50000, 100000];
    const prices = [58, 248, 488, 978, 2380, 4860];

    // محاسبه قیمت با استفاده از فرمول خطی
    for (let i = 0; i < quantities.length - 1; i++) {
      if (quantity >= quantities[i] && quantity <= quantities[i + 1]) {
        const price =
          prices[i] +
          ((prices[i + 1] - prices[i]) / (quantities[i + 1] - quantities[i])) *
            (quantity - quantities[i]);
        return parseFloat(price.toFixed(2));
      }
    }

    return null;
  };

  const calculatePrice2 = (quantity) => {
    const quantities = [
      5000, 10000, 20000, 50000, 100000, 250000, 500000, 1000000,
    ];
    const prices = [54, 88, 138, 298, 498, 998, 1880, 3680];

    // پیدا کردن بازه‌ای که quantity در آن قرار دارد
    for (let i = 0; i < quantities.length - 1; i++) {
      if (quantity >= quantities[i] && quantity <= quantities[i + 1]) {
        // فرمول خطی برای محاسبه خروجی در این بازه
        const price =
          prices[i] +
          ((prices[i + 1] - prices[i]) / (quantities[i + 1] - quantities[i])) *
            (quantity - quantities[i]);
        return parseFloat(price.toFixed(2));
      }
    }

    return null; // اگر مقدار ورودی خارج از بازه‌ها باشد
  };

  const handleSliderChange = (event, value) => {
    const roundedValue = Math.round(value / 1000) * 1000; // گرد کردن به نزدیک‌ترین مقدار صحیح مضرب 1000
    // setQuantity(roundedValue);
    const result = calculatePrice(roundedValue);
    console.log(result);
    setPrice(result);
  };

  const handleSliderChange2 = (event, value) => {
    const roundedValue = Math.round(value / 1000) * 1000; // گرد کردن به نزدیک‌ترین مقدار صحیح مضرب 1000
    // setQuantity(roundedValue);
    const result = calculatePrice2(roundedValue);
    console.log('res',result);
    setPrice(result);
  };
  // const handleSliderChange3 = (event, value) => {
  //   const roundedValue = Math.round(value / 1000) * 1000; // گرد کردن به نزدیک‌ترین مقدار صحیح مضرب 1000
  //   // setQuantity(roundedValue);
  //   const result = calculatePrice2(roundedValue);
  //   console.log('res',result);
  //   setPrice(result*1.3);
  // };
  const handleSliderChange3 = (event, value) => {
    const roundedValue = Math.round(value / 1000) * 1000; // گرد کردن به نزدیک‌ترین مقدار صحیح مضرب 1000
    const result = calculatePrice2(roundedValue);
    console.log('res', result);

    // محدود کردن نتیجه به 2 رقم اعشار
    const finalPrice = parseFloat((result * 1.3).toFixed(2));
    setPrice(finalPrice);
};


  // useEffect(() => {
  //   load(true);
  //   axios
  //     .get(url + "api/v1/panel/order_page", {
  //       headers: { Authorization: `bearer ${Cookies.get("token")}` },
  //     })
  //     .then((res) => {
  //       load(false);
  //       setOrder(res.data);
  //     })
  //     .catch((err) => {
  //       load(false);
  //       console.log(err);
  //     });
  // }, []);
  useEffect(() => {
    if (btnSuc) {
      ref.current.scrollIntoView({ block: "center" });
    }
  }, [btnSuc]);
  const catShow = () => {
    if (isLoading) {
      return (
        <ThreeCircles
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="three-dots-loading"
          wrapperClass='loader'
        />
      );
    }else{
      return (
        <fieldset>
          <legend> انتخاب نوع حساب </legend>
          {orderData.packages.map((d) => {
            return (
              <div className="form-control-radio">
                <input
                  onChange={(e) => {
                    console.log("clicked", e);
                    setC(d.title);
                    setcategory_id(+e.currentTarget.value);
                    // setCateg({ products: [] });
                    setProd("");
                    setBrok("");
                    setServer("");
                    setOrderB({ broker_servers: "" });
                    setPlat("");
                    setBtn(false);
  
                    // load(true);
                    // axios
                    //   .get(
                    //     url +
                    //       "api/v1/panel/category_products?package_id=" +
                    //       e.currentTarget.value,
                    //     {
                    //       headers: {
                    //         Authorization: `bearer ${Cookies.get("token")}`,
                    //       },
                    //     })
                    //   .then((res) => {
                    //     load(false);
                    //     setCateg(res.data);
                    //     console.log('res.data is :',res.data);
                    //     setRange({min: res.data.min, max: res.data.max, step: res.data.step});
  
                    //     setC(d.title);
                    //     // set range.min and range.max
                    //   })
                    //   .catch((err) => {
                    //     load(false);
                    //     console.log(err);
                    //   });
                  }}
                  className="radio"
                  value={d.id}
                  name="category"
                  type="radio"
                />
                <label> {d.title} </label>
              </div>
            );
          })}
        </fieldset>
      );

    }

  };

  const products = () => {
    if (categ.products.length !== 0) {
      if (isLoadingProducts || isLoadingRanges){
        return (
          <ThreeCircles
            height="80"
            width="80"
            radius="9"
            color="green"
            ariaLabel="three-dots-loading"
            wrapperClass='loader'
          />
        );
      }else{
        return (
          <fieldset style={{ columns: "1" }}>
            <legend> انتخاب حساب </legend>
            <div style={{ columns: "2" }}>
              {categ.products.map((d) => {
                return (
                  <div className="form-control-radio" key={d.id}>
                    <input
                      onChange={(e) => {
                        if (d.id == e.target.value) {
                          setSelectedRadio(d.id); // تنظیم رادیو انتخاب‌شده
                          if (d.category_id == 4) {
                            setPrice([+d.price, +d.discount]);
                            //   setProd([d.title, d.id]); // is not required
                            setProd([
                              d.title.slice(-1) === "k" // بررسی اگر آخرین کاراکتر "k" باشد
                                ? (
                                    parseFloat(d.title.slice(0, -1)) * 1000
                                  ).toString() // ضرب در 1000
                                : d.title.slice(-1) === "m" // بررسی اگر آخرین کاراکتر "m" باشد
                                ? (
                                    parseFloat(d.title.slice(0, -1)) * 1000000
                                  ).toString() // ضرب در 1 میلیون
                                : d.title, // در غیر این صورت، مقدار اصلی
                              d.id,
                            ]);
                          } else {
                            setPrice(+d.price);
                            setProd([
                              d.title.slice(-1) === "k" // بررسی اگر آخرین کاراکتر "k" باشد
                                ? (
                                    parseFloat(d.title.slice(0, -1)) * 1000
                                  ).toString() // ضرب در 1000
                                : d.title.slice(-1) === "m" // بررسی اگر آخرین کاراکتر "m" باشد
                                ? (
                                    parseFloat(d.title.slice(0, -1)) * 1000000
                                  ).toString() // ضرب در 1 میلیون
                                : d.title, // در غیر این صورت، مقدار اصلی
                              d.id,
                            ]);
                          }
                          console.log("price", price);
                          console.log("prod", prod);
                        }
                      }}
                      value={d.id}
                      name="products"
                      type="radio"
                      checked={selectedRadio === d.id} // انتخاب‌شده بودن رادیو
                    />
                    <label> {d.title} </label>
                  </div>
                );
              })}
            </div>
            {/*   
            {c === "پرو تریدر" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "3rem",
                }}
              >
                <div className="dfjc">
                  <span>{100000}</span>
                  <span>{isNaN(prod[0]) ? "Choose" : prod[0] / 1000 + "k"}</span>
                  <span>{1000}</span>
                </div>
                <input
                  style={{
                    direction: "ltr",
                  }}
                  type="range"
                  value={prod[0]}
                  onChange={(e) => {
                    handleSliderChange(e, +e.target.value);
                    setProd([e.target.value, +e.target.value]);
                    setSelectedRadio(null); // خالی کردن انتخاب رادیو
                    console.log("price", price);
                    console.log("prod", prod);
                  }}
                  min={1000} // set min
                  max={100000} // set max
                  step={1000} // set step
                  className="range mt"
                />
              </div>
            )}
    
            {c === "چالش دو مرحله ای" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "3rem",
                }}
              >
                <div className="dfjc">
                  <span>{1000000}</span>
                  <span>{isNaN(prod[0]) ? "Choose" : prod[0] / 1000 + "k"}</span>
                  <span>{5000}</span>
                </div>
                <input
                  style={{
                    direction: "ltr",
                  }}
                  type="range"
                  value={prod[0]}
                  onChange={(e) => {
                    handleSliderChange2(e, +e.target.value);
                    setProd([e.target.value, +e.target.value]);
                    setSelectedRadio(null); // خالی کردن انتخاب رادیو
                  //   console.log("price", price);
                  //   console.log("prod", prod);
                  }}
                  min={5000} // set min
                  max={1000000} // set max
                  step={1000} // set step
                  className="range mt"
                />
              </div>
            )} */}
            {c === "پرو تریدر" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "3rem",
                }}
              >
                <div className="dfjc">
                  <span>{range.max}</span>
                  <span>{isNaN(prod[0]) ? "Choose" : prod[0] / 1000 + "k"}</span>
                  <span>{range.min}</span>
                </div>
                <div
                  className="range-container"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10PX",
                    direction: "ltr",
                  }}
                >
                  {/* دکمه کاهش */}
                  <Button
                    sx={{
                      fontSize: "32px", // اندازه متن
                      fontWeight: "bold", // بولد کردن
                      padding: "10px", // فضای داخلی
                      minWidth: "30px", // حداقل عرض
                      height: "30px", // اندا��ه ارتفا�� دکمه
                    }}
                    onClick={() => {
                      const defaultValue = range.max / 2;
                      const currentValue = prod[0]
                        ? parseInt(prod[0])
                        : defaultValue;
                      const newValue = Math.max(currentValue - 1000, range.min);
                      //   const newValue = Math.max(
                      //     parseInt(prod[0]) - 1000, // کاهش بر اساس step
                      //     1000 // حداقل مقدار
                      //   );
                      setProd([newValue, +newValue]);
                      handleSliderChange(null, newValue); // انجام تمام عملیات onChange
                    }}
                    style={{ marginRight: "10px" }}
                  >
                    -
                  </Button>
                  {/* رنج اینپوت */}
                  <input
                    style={{
                      direction: "ltr",
                      flex: 1, // برای پر کردن فضای بین دکمه‌ها
                      transform: "translateY(-114%)",
                    }}
                    type="range"
                    value={prod[0]}
                    onChange={(e) => {
                      handleSliderChange(e, +e.target.value);
                      setProd([e.target.value, +e.target.value]);
                      setSelectedRadio(null); // خالی کردن انتخاب رادیو
                    }}
                    min={range.min} // set min
                    max={range.max} // set max
                    step={range.step} // set step
                    className="range mt"
                  />
                  {/* دکمه افزایش */}
                  <Button
                    sx={{
                      fontSize: "32px", // اندازه متن
                      fontWeight: "bold", // بولد کردن
                      padding: "10px", // فضای داخلی
                      minWidth: "30px", // حداقل عرض
                      height: "30px", // اندا��ه ارتفا�� دکمه
                    }}
                    onClick={() => {
                      const defaultValue = range.max / 2;
                      const currentValue = prod[0]
                        ? parseInt(prod[0])
                        : defaultValue;
                      const newValue = Math.min(currentValue + 1000, range.max);
                      //   const newValue = Math.min(
                      //     parseInt(prod[0]) + 1000, // افزایش بر اساس step
                      //     100000 // حداکثر مقدار
                      //   );
                      setProd([newValue, +newValue]);
                      handleSliderChange(null, newValue); // انجام تمام عملیات onChange
                    }}
                    style={{ marginLeft: "10px" }}
                  >
                    +
                  </Button>
                </div>
              </div>
            )}
  
            {c === "چالش دو مرحله ای" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "3rem",
                }}
              >
                <div className="dfjc">
                  <span>{range.max}</span>
                  <span>{isNaN(prod[0]) ? "Choose" : prod[0] / 1000 + "k"}</span>
                  <span>{range.min}</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10PX",
                    direction: "ltr",
                  }}
                >
                  {/* دکمه کاهش */}
                  <Button
                    sx={{
                      fontSize: "32px", // اندازه متن
                      fontWeight: "bold", // بولد کردن
                      padding: "10px", // فضای داخلی
                      minWidth: "30px", // حداقل عرض
                      height: "30px", // اندا��ه ارتفا�� دکمه
                    }}
                    onClick={() => {
                      const defaultValue = range.max / 2;
                      const currentValue = prod[0]
                        ? parseInt(prod[0])
                        : defaultValue;
                      const newValue = Math.max(currentValue - 1000, range.min);
                      //   const newValue = Math.max(
                      //     parseInt(prod[0]) - 1000, // کاهش بر اساس step
                      //     5000 // حداقل مقدار
                      //   );
                      setProd([newValue, +newValue]);
                      handleSliderChange2(null, newValue); // انجام تمام عملیات onChange
                    }}
                    style={{ marginRight: "10px" }}
                  >
                    -
                  </Button>
                  {/* رنج اینپوت */}
                  <input
                    style={{
                      direction: "ltr",
                      flex: 1,
                      transform: "translateY(-114%)",
                    }}
                    type="range"
                    value={prod[0]}
                    onChange={(e) => {
                      handleSliderChange2(e, +e.target.value);
                      setProd([e.target.value, +e.target.value]);
                      setSelectedRadio(null); // خالی کردن انتخاب رادیو
                    }}
                    min={range.min} // set min
                    max={range.max} // set max
                    step={range.step} // set step
                    className="range mt"
                  />
                  {/* دکمه افزایش */}
                  <Button
                    sx={{
                      fontSize: "32px", // اندازه متن
                      fontWeight: "bold", // بولد کردن
                      padding: "10px", // فضای داخلی
                      minWidth: "30px", // حداقل عرض
                      height: "30px", // اندا��ه ارتفا�� دکمه
                    }}
                    onClick={() => {
                      const defaultValue = range.max / 2;
                      const currentValue = prod[0]
                        ? parseInt(prod[0])
                        : defaultValue;
                      const newValue = Math.min(currentValue + 1000, range.max);
  
                      //   const newValue = Math.min(
                      //     parseInt(prod[0]) + 1000, // افزایش بر اساس step
                      //     1000000 // حداکثر مقدار
                      //   );
                      setProd([newValue, +newValue]);
                      handleSliderChange2(null, newValue); // انجام تمام عملیات onChange
                    }}
                    style={{ marginLeft: "10px" }}
                  >
                    +
                  </Button>
                </div>
              </div>
            )}
  
            {c === "چالش تک مرحله ای" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "3rem",
                }}
              >
                <div className="dfjc">
                  <span>{range.max}</span>
                  <span>{isNaN(prod[0]) ? "Choose" : prod[0] / 1000 + "k"}</span>
                  <span>{range.min}</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10PX",
                    direction: "ltr",
                  }}
                >
                  {/* دکمه کاهش */}
                  <Button
                    sx={{
                      fontSize: "32px", // اندازه متن
                      fontWeight: "bold", // بولد کردن
                      padding: "10px", // فضای داخلی
                      minWidth: "30px", // حداقل عرض
                      height: "30px", // اندا��ه ارتفا�� دکمه
                    }}
                    onClick={() => {
                      const defaultValue = range.max / 2;
                      const currentValue = prod[0]
                        ? parseInt(prod[0])
                        : defaultValue;
                      const newValue = Math.max(currentValue - 1000, range.min);
                      //   const newValue = Math.max(
                      //     parseInt(prod[0]) - 1000, // کاهش بر اساس step
                      //     5000 // حداقل مقدار
                      //   );
                      setProd([newValue, +newValue]);
                      handleSliderChange3(null, newValue); // انجام تمام عملیات onChange
                    }}
                    style={{ marginRight: "10px" }}
                  >
                    -
                  </Button>
                  {/* رنج اینپوت */}
                  <input
                    style={{
                      direction: "ltr",
                      flex: 1,
                      transform: "translateY(-114%)",
                    }}
                    type="range"
                    value={prod[0]}
                    onChange={(e) => {
                      handleSliderChange3(e, +e.target.value);
                      setProd([e.target.value, +e.target.value]);
                      setSelectedRadio(null); // خالی کردن انتخاب رادیو
                    }}
                    min={range.min} // set min
                    max={range.max} // set max
                    step={range.step} // set step
                    className="range mt"
                  />
                  {/* دکمه افزایش */}
                  <Button
                    sx={{
                      fontSize: "32px", // اندازه متن
                      fontWeight: "bold", // بولد کردن
                      padding: "10px", // فضای داخلی
                      minWidth: "30px", // حداقل عرض
                      height: "30px", // اندا��ه ارتفا�� دکمه
                    }}
                    onClick={() => {
                      const defaultValue = range.max / 2;
                      const currentValue = prod[0]
                        ? parseInt(prod[0])
                        : defaultValue;
                      const newValue = Math.min(currentValue + 1000, range.max);
  
                      //   const newValue = Math.min(
                      //     parseInt(prod[0]) + 1000, // افزایش بر اساس step
                      //     1000000 // حداکثر مقدار
                      //   );
                      setProd([newValue, +newValue]);
                      handleSliderChange3(null, newValue); // انجام تمام عملیات onChange
                    }}
                    style={{ marginLeft: "10px" }}
                  >
                    +
                  </Button>
                </div>
              </div>
            )}
          </fieldset>
        );

      }


    }
  };
  const brokerChecks = () => {
    if (prod !== "") {
      return (
        <fieldset>
          <legend> انتخاب بروکر</legend>
          {orderData.brokers.map((d) => {
            return (
              <div className="form-control-radio">
                <input
                  onChange={(e) => {
                    if (d.id == e.currentTarget.value) {
                      setBrok([d.name, d.id]);
                    }
                    setBtn(false);
                    load(true);
                    setOrderB({ broker_servers: "" });
                    setPlat("");
                    setServer("");
                    load(true);
                    axios
                      .get(
                        url +
                          "api/v1/panel/broker_servers?broker_id=" +
                          e.currentTarget.value,
                        {
                          headers: {
                            Authorization: `bearer ${Cookies.get("token")}`,
                          },
                        }
                      )
                      .then((res) => {
                        load(false);
                        setOrderB(res.data);
                      })
                      .catch((err) => {
                        load(false);
                        console.log(err);
                      });
                  }}
                  value={d.id}
                  name="broker"
                  type="radio"
                />
                <label> {d.name} </label>
              </div>
            );
          })}
        </fieldset>
      );
    }
  };
  const afterBrok = () => {
    let x = [];

    if (orderDatB.broker_servers !== "") {
      Object.keys(orderDatB.broker_servers).map((d) => {
        x.push(d);
      });
      return (
        <fieldset style={{ columns: "1" }}>
          <legend> انتخاب پلتفرم</legend>
          <p style={{ width: "100%" }} className="f12">
            {" "}
            * پنل آنالیز قابل استفاده برای پلتفرم MT5{" "}
          </p>
          {x.map((dd) => {
            return (
              <div className="form-control-radio">
                <input
                  onChange={(e) => {
                    setPlat(e.currentTarget.value);
                  }}
                  value={dd}
                  name="platform"
                  type="radio"
                />
                <label> {dd.toUpperCase()} </label>
              </div>
            );
          })}
        </fieldset>
      );
    }
  };
  const afterPlat = () => {
    if (platforms === "mt4") {
      return (
        <fieldset className="mobileServer">
          <legend style={{ direction: "rtl" }}>
            {" "}
            MetaTrader 4 انتخاب حساب
          </legend>
          {orderDatB.broker_servers.mt4.map((d) => {
            return (
              <div className="form-control-radio">
                <input
                  onChange={(e) => {
                    setServer(d.title);
                    setBtn(true);
                  }}
                  value={d.title}
                  name="server"
                  type="radio"
                />
                <label> {d.title} </label>
              </div>
            );
          })}
        </fieldset>
      );
    } else if (platforms === "mt5") {
      return (
        <fieldset className="mobileServer">
          <legend style={{ direction: "rtl" }}>
            {" "}
            MetaTrader 5 انتخاب حساب
          </legend>
          {orderDatB.broker_servers.mt5.map((d) => {
            return (
              <div className="form-control-radio">
                <input
                  onChange={(e) => {
                    setServer(d.title);
                    setBtn(true);
                  }}
                  value={d.title}
                  name="server"
                  type="radio"
                />
                <label> {d.title} </label>
              </div>
            );
          })}
        </fieldset>
      );
    }
  };
  const orderSubmit = () => {
    if (btnSuc) {
      return (
        <button
          onClick={() => {
            load(true);
            let prYek = price[1] ? price[1] : price;
            let alldataes = {
              price: prYek,
              platform: platforms,
              broker: broker[1],
              server: server,
              brokerName: broker[0],
              serverName: c,
              productPrice: prod[0],
              prodName: prod[0],
              category_id: category_id,
            };
            console.log("all datas", alldataes);
            setAllData(alldataes);
            setTrans(true);
            load(false);
          }}
          className="orderBtn"
        >
          {" "}
          درخواست حساب{" "}
        </button>
      );
    }
  };
  const messages = () => {
    return (
      <ul ref={ref} className="messageCon">
        {Object.keys(orderData.messages).map((d) => {
          return (
            <p key={d.id}>
              {" "}
              <RiInformation2Fill
                className="information"
                color="greenyellow"
              />{" "}
              {orderData.messages[d].text}.{" "}
            </p>
          );
        })}
      </ul>
    );
  };
  const lastConst = () => {
    if (trans) {
      return (
        <TransAction
          allData={allData}
          messages={orderData.messages}
          pageChange={props.changePage}
          gift={price[1] ? true : false}
        />
      );
    } else {
      if (window.innerWidth > 1200) {
        return (
          <div className="app-header-navigation">
            <div className="tabs">
              <a href className="active">
                شرکت در چالش ها
              </a>
            </div>
            <div className="row m10">
              <div className="col-xl-5 ">
                <div className="orderAmount">
                  <div style={{ padding: "5px" }}>کل مبلغ پرداختی</div>
                  <h4 className="bB p5">
                    {" "}
                    {price[1] ? (
                      <div>
                        <div
                          style={{
                            textDecoration: "line-through",
                            color: "red",
                            fontSize: "17px",
                          }}
                        >
                          {price[0] + " $"}
                        </div>
                        <div style={{ color: "yellowgreen" }}>
                          {price[1] + " $"}
                        </div>
                      </div>
                    ) : (
                      price + " $"
                    )}{" "}
                  </h4>
                  <div className="orderDetail bb">
                    <div className="fl"> نوع حساب </div>
                    <div> {c} </div>
                  </div>
                  <div className="orderDetail bb">
                    <div className="fl"> حساب </div>
                    <div style={{ direction: "ltr" }}> {prod[0]} </div>
                  </div>
                  <div className="orderDetail bb">
                    <div className="fl"> بروکر </div>
                    <div> {broker[0]} </div>
                  </div>
                  <div className="orderDetail bb">
                    <div className="fl"> پلتفرم </div>
                    <div> {platforms.toUpperCase()} </div>
                  </div>

                  <div className="orderDetail bb">
                    <div className="fl"> سرور </div>
                    <div> {server} </div>
                  </div>
                  <div className="orderDetail ">
                    <div className="fl"> قیمت </div>
                    <div> {price[1] ? price[1] + " $" : price + " $"} </div>
                  </div>
                </div>
                {orderSubmit()}
                {messages()}
              </div>

              <div className="col-xl-7 pr0">
                <div>
                  <div className="orderChoose">
                    <h6 className="bb"> نوع سفارش خود را انتخاب نمایید </h6>
                    {catShow()}
                    {products()}
                    {brokerChecks()}
                    {afterBrok()}
                    {afterPlat()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="app-header-navigation">
            <div className="tabs">
              <a href className="active">
                شرکت در چالش ها
              </a>
            </div>
            <div className="row m10">
              <div className="col-xl-7 pr0">
                <div>
                  <div className="orderChoose">
                    <h6 className="bb"> نوع سفارش خود را انتخاب نمایید </h6>
                    {catShow()}
                    {products()}
                    {brokerChecks()}
                    {afterBrok()}
                    {afterPlat()}
                  </div>
                </div>
              </div>
              <div className="col-xl-5 ">
                <div className="orderAmount">
                  <div style={{ padding: "5px" }}>کل مبلغ پرداختی</div>
                  <h4 className="bB p5">
                    {price[1] ? (
                      <div>
                        <div
                          style={{
                            textDecoration: "line-through",
                            color: "red",
                            fontSize: "17px",
                          }}
                        >
                          {price[0] + " $"}
                        </div>
                        <div style={{ color: "yellowgreen" }}>
                          {price[1] + " $"}
                        </div>
                      </div>
                    ) : (
                      price + " $"
                    )}
                  </h4>
                  <div className="orderDetail bb">
                    <div className="fl"> نوع حساب </div>
                    <div> {c} </div>
                  </div>
                  <div className="orderDetail bb">
                    <div className="fl"> حساب </div>
                    <div> {prod[0]} </div>
                  </div>
                  <div className="orderDetail bb">
                    <div className="fl"> بروکر </div>
                    <div> {broker[0]} </div>
                  </div>
                  <div className="orderDetail bb">
                    <div className="fl"> پلتفرم </div>
                    <div> {platforms.toUpperCase()} </div>
                  </div>

                  <div className="orderDetail bb">
                    <div className="fl"> سرور </div>
                    <div> {server} </div>
                  </div>
                  <div className="orderDetail ">
                    <div className="fl"> قیمت </div>
                    <div> {price[1] ? price[1] + " $" : price + " $"} </div>
                  </div>
                </div>
                {orderSubmit()}
                {messages()}
              </div>
            </div>
          </div>
        );
      }
    }
  };

  return <>{lastConst()}</>;
}
