import { configureStore } from "@reduxjs/toolkit";
import messageReducer from "./slices/messageSlice";
import layoutWidthReducer from "./slices/layoutWidthSlice";
import userCountReducer from "./slices/userCountSlice";
import inputSlice from "./slices/inputSlice";
import faqSlice from "../faq/slices/faqSlice";

const store = configureStore({
  reducer: {
    userCount: userCountReducer,
    layoutWidth: layoutWidthReducer,
    message: messageReducer,
    input: inputSlice,
    faq: faqSlice,
  },
});
export default store;
