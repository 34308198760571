import React, { useState, useEffect, useContext } from "react";
import ModalCompetition from "./ModalCompetition";
import CompetitionProfile from "./CompetitionProfile";
import axios from "axios";
import Cookies from "js-cookie";
import UserContext from "../UserContext/UserContext";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import "react-notifications/lib/notifications.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "./competition.css";

import { FaCheckCircle } from "react-icons/fa";

const CompetitionCard = ({
  competition,
  openModal,
  toggleProfile,
  toggleLoading,
  invalidateQuery,
}) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [isHovered, setIsHovered] = useState(false); // اضافه کردن state برای مدیریت هاور



  useEffect(() => {
    // اگر مسابقه پایان‌یافته است، تایمر را متوقف کنید
    if (competition.status === 2) return; // 2 برای "پایان‌یافته"

    const padWithZero = (number) => (number < 10 ? `0${number}` : number);

    const calculateTimeLeft = () => {
      const now = new Date();
      const targetTime =
        competition.status === 1 // 1 برای "آینده"
          ? new Date(competition.start)
          : new Date(competition.end); // 0 برای "در حال اجرا"
      const difference = targetTime - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTimeLeft({
          days: padWithZero(days),
          hours: padWithZero(hours),
          minutes: padWithZero(minutes),
          seconds: padWithZero(seconds),
        });
      } else {
        setTimeLeft(null); // وقتی تایمر به پایان برسد
      }
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [competition]);

  const { user, load, url } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false); // تعریف صحیح useState



  const handleRegister = async (id) => {
    const token = Cookies.get("token");

    if (!token) {
      NotificationManager.error("برای ثبت‌نام ابتدا وارد شوید.", "خطا", 3000);
      return;
    }

    try {
      toggleLoading(true);

      const response = await axios.post(
        `${url}api/v1/panel/competitions/${id}/add-user`,
        {}, // Body خالی
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          timeout: 10000,
        }
      );

      if (response.status === 200) {
        NotificationManager.success(
          "ثبت‌نام با موفقیت انجام شد.",
          "موفقیت",
          3000
        );
        invalidateQuery();
      } else {
        NotificationManager.error("مشکلی در ثبت‌نام پیش آمد.", "خطا", 3000);
      }
    } catch (error) {
      console.error("Error:", error);

      if (error.response?.status === 401) {
        NotificationManager.error(
          "دسترسی غیرمجاز. لطفاً وارد سیستم شوید.",
          "خطا",
          3000
        );
      } else if (error.code === "ECONNABORTED") {
        NotificationManager.error(
          "درخواست زمان زیادی طول کشید. لطفاً دوباره تلاش کنید.",
          "خطا",
          3000
        );
      } else if (error.response?.status === 400) {
        NotificationManager.error(
          "لطفا اطلاعات هویتی خود را تکمیل کنید",
          "خطا",
          3000
        );
      } else {
        NotificationManager.error(
          "خطا در ارسال درخواست. لطفاً دوباره تلاش کنید.",
          "خطا",
          3000
        );
      }
    } finally {
      toggleLoading(false);
    }
  };

  const renderButtons = () => {
    const buttonContainerStyle = {
      display: "flex",
      width: "100%",
      gap: "1rem",
    };

    return (
      <div style={buttonContainerStyle}>
        {competition.status === 1 && ( // 1 برای "آینده"
          <>
            <button
              // style={buttonStyleHalf}
              className="unique-hover-button"
              onClick={() => openModal(competition)}
            >
              مشاهده جزئیات
            </button>
            {!competition.isReg ? (
              <button
                // style={buttonStyleHalf}
                className="unique-hover-button"
                onClick={() => {
                  if (user.dataes.authenticated === 1) {
                    handleRegister(competition.id);
                  } else {
                    openModal(competition, true);
                  }
                }}
              >
                ثبت‌نام
              </button>
            ) : (
              <button
                // style={buttonStyleHalf}
                className="unique-hover-button"
                onClick={() => toggleProfile(competition)}
              >
                پروفایل
              </button>
            )}
          </>
        )}
        {competition.status === 0 && ( // 0 برای "در حال اجرا"
          <>
            <button
              // style={buttonStyleHalf}
              className="unique-hover-button"
              onClick={() => openModal(competition)}
            >
              مشاهده جزئیات
            </button>
            <button
              className="buttonStyle1"
              style={{
                flex: 1,
                textAlign: "center",
                backgroundColor: "#ccc", // رنگ پس‌زمینه خاکستری
                color: "#999", // رنگ متن خاکستری روشن
                cursor: "not-allowed", // نشانگر موس به حالت غیرفعال تغییر می‌کند
              }}
              // onClick={() => handleRegister(competition.id)}
              disabled={true} // غیرفعال کردن دکمه
            >
              ثبت‌نام
            </button>
          </>
        )}
        {competition.status === 2 && ( // 2 برای "پایان‌یافته"
          <>
            <button
              className="unique-hover-button"
              onClick={() => openModal(competition)}
            >
              مشاهده برندگان
            </button>
            <button className="unique-hover-button">آرشیو مسابقه</button>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="cardStyle1">
      <NotificationContainer />
      <div className="headerStyle1">
        <h6> {competition.title} </h6>{" "}
        <div className="statusStyle1">
          {competition.status === 0
            ? "در حال اجرا"
            : competition.status === 1
            ? "آینده"
            : "پایان‌یافته"}
        </div>
        {/* نمایش آیکن چک‌مارک اگر isReg برابر true باشد */}{" "}
        {competition.isReg && (
          <div
            style={{
              display: "inline-block",
              position: "relative",
            }}
          >
            <FaCheckCircle
              style={{
                color: "green",
                fontSize: "1.5rem",
                marginLeft: "10px",
                verticalAlign: "middle",
                cursor: "pointer",
              }}
              onMouseEnter={() => setIsHovered(true)} // وقتی موس روی آیکن می‌رود
              onMouseLeave={() => setIsHovered(false)} // وقتی موس از آیکن خارج می‌شود
            />{" "}
            {/* متن مخفی که در هاور ظاهر می‌شود */}{" "}
            <span
              style={{
                position: "absolute",
                top: "100%",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "#333",
                color: "#fff",
                padding: "5px",
                borderRadius: "5px",
                fontSize: "0.9rem",
                whiteSpace: "nowrap",
                visibility: isHovered ? "visible" : "hidden", // وقتی هاور است نمایش می‌دهد
                opacity: isHovered ? 1 : 0, // وقتی هاور است، متن نمایان می‌شود
                transition: "opacity 0.3s ease-in-out, visibility 0.3s", // افکت نمایش دادن
              }}
            >
              ثبت‌نام شده{" "}
            </span>{" "}
          </div>
        )}{" "}
      </div>{" "}
      <div className="contentStyle1">
        <div className="itemStyle1">
          <div className="titleStyle1"> شروع: </div>{" "}
          <div> {competition.start} </div>{" "}
        </div>{" "}
        <div className="itemStyle1">
          <div className="titleStyle1"> پایان: </div>{" "}
          <div> {competition.end} </div>{" "}
        </div>{" "}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "0.2rem",
            alignItems: "center",
            marginBottom: "0.5rem",
          }}
        >
          <div className="titleStyle1"> جایزه: </div>{" "}
          <div> {competition.prize} </div>{" "}
        </div>{" "}
        <div className="itemStyle1">
          <div className="titleStyle1"> شرکت‌کنندگان: </div>{" "}
          <div> {competition.contestants} </div>{" "}
        </div>{" "}
      </div>{" "}
      <div
        style={{
          display: "flex",
          margin: "1rem",
          gap: "1rem",
          justifyContent: "center",
        }}
      >
        <div className="titleStyle1">
          {competition.status === 1
            ? "تا شروع : "
            : competition.status === 0
            ? "تا پایان :"
            : ""}
        </div>
        <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          {" "}
          {competition.status === 2 ? (
            <div style={{ color: "red", fontWeight: "bold" }}> پایان یافت </div>
          ) : (
            ["seconds", "minutes", "hours", "days"].map((unit) => (
              <div
                key={unit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <div
                  style={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                    color: "#ffffff",
                  }}
                >
                  {timeLeft[unit]}{" "}
                </div>{" "}
                <div style={{ fontSize: "1rem", color: "gray" }}>
                  {" "}
                  {unit === "days"
                    ? "روز"
                    : unit === "hours"
                    ? "ساعت"
                    : unit === "minutes"
                    ? "دقیقه"
                    : "ثانیه"}{" "}
                </div>{" "}
              </div>
            ))
          )}{" "}
        </div>{" "}
      </div>{" "}
      <div className="buttonContainerStyle1"> {renderButtons()} </div>{" "}
    </div>
  );
};

const CompetitionsList = ({ competitions, toggleLoading, invalidateQuery ,changePage }) => {
  console.log('functionnnn',changePage)
  const [currentView, setCurrentView] = useState("list");
  const [selectedCompetition, setSelectedCompetition] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [Error, setError] = useState(false);

  const [MyCompetitions, setMyCompetitions] = useState([]);
  const { user, load, url } = useContext(UserContext);

  // const toggleLoading = (status) => {
  //   setIsLoading(status);

  // };
  // useEffect(() => {
  //   setCurrentView('list')
  // }, [competitions]);

  useEffect(() => {
    console.log("hereee mounting");

    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  const openModal = (competition, flag = false) => {
    if (flag) {
      setModalData({ ...competition, flag: flag });
    } else {
      setModalData(competition);
    }
  };

  const closeModal = () => {
    setModalData(null);
  };

  const toggleProfile = (competition) => {
    console.log("im hereee");
    setCurrentView("profile");
    setSelectedCompetition(competition);
  };
  const togglelist = () => {
    console.log("im hereee");
    setCurrentView("list");
  };

  const renderCurrentView = () => {
    if (currentView === "profile") {
      console.log("here 22");
      return (
        <CompetitionProfile
          competition={selectedCompetition}
          toggleLoading={toggleLoading}
          togglelist={togglelist}
          changePage={changePage}
        />
      );
    }
    return (
      <div
        className="listStyle1"
        // data-aos={window.screen.width > 768 ? "fade-bottom" : ""}
      >
        {" "}
        {competitions.map((competition, index) => (
          <CompetitionCard
            key={index}
            competition={competition}
            openModal={openModal}
            toggleProfile={toggleProfile}
            toggleLoading={toggleLoading}
            invalidateQuery={invalidateQuery}
          />
        ))}{" "}
        {modalData && (
          <ModalCompetition
            modalData={modalData}
            closeModal={closeModal}
            register={true}
            invalidateQuery={invalidateQuery}
          />
        )}{" "}
      </div>
    );
  };

  return <div> {renderCurrentView()} </div>;
};

export default CompetitionsList;
