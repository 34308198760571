/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import styled from "styled-components";
import { MAIN_URL_STORE } from "../../../utils/constants";
import { VscCheckAll } from "react-icons/vsc";
import { RiRadioButtonLine } from "react-icons/ri";
import { detectDirection, formatDate } from "../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveChatId,
  updateHistoryActiveChatId,
} from "./slices/messageSlice";
import { useContext } from "react";
import UserContext from "../../UserContext/UserContext";

const StyledLi = styled.li`
  width: 100%;
  display: flex;
  gap: 0.6rem;
  list-style: none;
  color: var(--color-grey-100);
  padding: 0.4rem 1.2rem;
  border-top: 1px solid var(--color-green-800);
  border-bottom: 1px solid var(--color-green-800);
  direction: ltr;

  cursor: pointer;

  & .details {
    font-size: 0.7rem;
    width: 100%;
    display: flex;
    justify-content: space-between;

    & .details-main {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: start;
    }

    & .details-time-unread {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      & div.unread {
        align-self: flex-end;
        padding: 0.2rem 0.5rem;
        background-color: var(--color-green-700);
        /* color: #cb9e52; */
        border-radius: 50%;
      }
      & div.selected {
        align-self: flex-end;
        color: var(--color-blue-700);

        border-radius: 50%;
      }
    }
  }

  &:hover {
    background-color: rgba(0, 128, 126, 0.297);
    /* box-shadow: var(--shadow-sd); */
  }

  &.active {
    background-color: rgba(0, 128, 126, 0.297);
    color: var(--color-grey-100);
  }
`;

function Conversation({ conversation, socket }) {
  const dispatch = useDispatch();
  const { activeTab, activeChatId } = useSelector((state) => state.message);

  const { user } = useContext(UserContext);

  function handleClick() {
    if (activeTab === "all" || activeTab === "explore")
      return dispatch(updateHistoryActiveChatId(conversation.id));

    dispatch(setActiveChatId(conversation.id));

    if (activeTab !== "my") return;
    if (!socket) return;
    socket.emit("markMessagesSeen", {
      chatId: conversation.id,
      userId: user.dataes.id,
    });

    socket.emit("readMessage", conversation.id);
    socket.emit("getOpenChats");
  }

  if (!conversation.latest_message) return null;

  return (
    <StyledLi
      className={activeChatId === conversation?.id ? "active" : ""}
      onClick={() => handleClick()}
    >
      <div className="image-profile">
        <img
          src={`${MAIN_URL_STORE}${conversation.user.image}`}
          style={{
            borderRadius: "50%",
            height: "50px",
            width: "50px",
            objectFit: "cover",
          }}
          alt="profile"
        />
      </div>
      <div className="details">
        <div className="details-main">
          <div>
            <strong>
              <p className="supportName flex-center-horizontal">
                <span>
                  {conversation?.user?.username?.length > 15
                    ? conversation?.user?.username.slice(0, 15) + "..."
                    : conversation?.user?.username || conversation?.user_id}
                </span>
                {conversation?.is_user_online && (
                  <RiRadioButtonLine fill="var(--color-green-500)" />
                )}
              </p>
            </strong>
          </div>
          <div
            className="message"
            dir={detectDirection(conversation?.latest_message)}
          >
            {conversation?.latest_message?.length > 20
              ? conversation?.latest_message.slice(0, 20) + "..."
              : conversation?.latest_message}
          </div>
        </div>
        <div className="details-time-unread">
          <div style={{ alignSelf: "flex-end" }}>
            {formatDate(conversation?.latest_message_time)}
          </div>
          {activeChatId !== conversation?.id &&
            conversation?.unread_count !== 0 && (
              <div
                style={{
                  display: "flex",
                  gap: "0.6rem",
                  alignSelf: "flex-end",
                  alignItems: "end",
                }}
              >
                <span
                  style={{
                    color: "var(--color-grey-50)",
                  }}
                >
                  {conversation?.support?.nickname}
                </span>
                {activeTab !== "all" && (
                  <div className="unread">{conversation?.unread_count}</div>
                )}
              </div>
            )}

          {!conversation?.unread_count && activeTab !== "all" && (
            <>
              {activeTab === "other" && (
                <span style={{ color: "var(--color-grey-50)" }}>
                  {conversation?.support?.nickname}
                </span>
              )}
              {activeTab !== "other" && (
                <VscCheckAll
                  fill="var(--color-grey-50)"
                  style={{ alignSelf: "flex-end" }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </StyledLi>
  );
}

export default Conversation;
