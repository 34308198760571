/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { MdBlock, MdOutlineArchive, MdOutlineSettings } from "react-icons/md";
// import ConfirmAction from "../../ui/ConfirmAction";
import Modal from "./Modal";
import BanUser from "./BanUser";
import { useSuspendChat } from "./useSuspendChat";
import { useDispatch, useSelector } from "react-redux";
import { BiCommentAdd, BiTransfer } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import { PiHandWithdrawBold } from "react-icons/pi";
import { toggleToVesper } from "./slices/inputSlice";
import { useState } from "react";
import {
  allTab,
  setSearchQuery,
  updateHistoryActiveChatId,
} from "./slices/messageSlice";
import TransferChatForm from "./TransferChatForm";
import ButtonIcon from "./ButtonIcon";
import ConfirmAction from "./ConfirmAction";
import styled from "styled-components";
import { RiRadioButtonLine } from "react-icons/ri";
import {
  HiArrowSmallRight,
  HiMiniEllipsisVertical,
  HiMiniXMark,
} from "react-icons/hi2";

const StyledTopActions = styled.div`
  padding: 0.4rem;
  border-bottom: 1px solid #cb9e52;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .user-info {
    display: flex;
    font-size: 0.8rem;
    gap: 1rem;
    letter-spacing: 1px;
    color: var(--color-grey-300);

    & .user-name {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      cursor: pointer;
      position: relative;
    }

    & .copied {
      position: absolute;
      bottom: -1.5rem;
      left: 50%;
      transform: translateX(-50%);
      padding: 0.2rem 0.4rem;
      font-size: 0.8rem;
    }

    & .status {
      color: ${(props) =>
        props.onlineStatus ? "var(--color-green-500)" : "var(--color-red-700)"};
    }

    & .link-see-chats {
      text-decoration: underline;
      color: var(--color-blue-500);
      cursor: pointer;
    }
  }

  & .chat-operations {
    display: flex;
    gap: 1rem;
    align-items: center;

    & .link-see-chats-mobile {
      display: none;
    }
  }

  & .chat-operations-mobile {
    display: none;

    & button {
      padding: 0.3rem;
    }

    & button svg {
      width: 0.8rem;
      height: 0.8rem;
    }
  }
  @media (max-width: 800px) {
    & {
      padding: 0.2rem 0.4rem;
    }

    & .user-info,
    & .user-info .user-name,
    & .user-info .copied,
    & .user-info .status,
    & .user-info .link-see-chats,
    & .chat-operations {
      font-size: 0.6rem;
    }

    & .chat-operations {
      gap: 0.4rem;
    }
    & .chat-operations button {
      padding: 0.3rem;
    }

    & .chat-operations button svg {
      width: 0.8rem;
      height: 0.8rem;
    }
    & .link-see-chats {
      display: none;
    }
    & .chat-operations .link-see-chats-mobile {
      display: block;
    }
  }

  @media (max-width: 500px) {
    & .chat-operations {
      display: ${(props) => (props.isOperationOpenInMobile ? "flex" : "none")};
      position: absolute;
      flex-direction: column;
      background-color: var(--color-grey-800);
      right: 0.4rem;
      top: 1.8rem;
      z-index: 500;
    }

    & .chat-operations-mobile {
      display: block;
    }
  }
`;

function TopActions({ userId, socket, activeChat }) {
  const { activeChatId, userLocation } = useSelector((state) => state.message);
  const [copied, setCopied] = useState(false);
  const [isOperationOpenInMobile, setIsOperationOpenInMobile] = useState(false);
  const { messageType } = useSelector((state) => state.input);
  const dispatch = useDispatch();
  const { suspendChat, isPending } = useSuspendChat();
  const onlineStatus = activeChat?.is_user_online;

  function handleSuspend() {
    suspendChat(activeChatId, {
      onSuccess: () => {
        socket.emit("getOpenChats");
      },
    });
  }

  function handleCopyUsername() {
    navigator.clipboard.writeText(activeChat?.user?.username || "");
    setCopied(true);
    setTimeout(() => setCopied(false), 1000); // Hide the message after 1 second
  }

  function handleSeeChats(username) {
    dispatch(allTab());
    dispatch(setSearchQuery(username));
    dispatch(updateHistoryActiveChatId(null));
  }

  return (
    <StyledTopActions
      onlineStatus={onlineStatus}
      isOperationOpenInMobile={isOperationOpenInMobile}
    >
      <div className="user-info">
        <div>
          {activeChat?.user?.name ? activeChat?.user.name : "Not defined"}
        </div>
        <div className="user-name" onClick={handleCopyUsername}>
          <AiOutlineMail fontSize={"13px"} />
          {activeChat?.user?.username}
          <div className="status">
            <RiRadioButtonLine
              fill={
                onlineStatus ? "var(--color-green-500" : "var(--color-red-700)"
              }
            />
          </div>
          {copied && (
            <span style={{}} className="copied">
              Copied!
            </span>
          )}
        </div>

        <div
          onClick={() => handleSeeChats(activeChat?.user?.username)}
          className="link-see-chats"
        >
          see chats
        </div>
      </div>
      <div className="chat-operations-mobile">
        <ButtonIcon onClick={() => setIsOperationOpenInMobile((v) => !v)}>
          {isOperationOpenInMobile ? (
            <HiMiniXMark />
          ) : (
            <HiMiniEllipsisVertical />
          )}
        </ButtonIcon>
      </div>
      <div className="chat-operations">
        <Modal>
          {/* <Modal.Open opens="quick-setting-modal">
            <button>
              <MdOutlineSettings />
            </button>
          </Modal.Open>
          <Modal.Window name="quick-setting-modal">
            <QuickSetting />
          </Modal.Window> */}
          <div
            className="link-see-chats-mobile"
            onClick={() => handleSeeChats(activeChat?.user?.username)}
          >
            <ButtonIcon $color="var(--color-blue-700)" title="See chats chat">
              <HiArrowSmallRight />
            </ButtonIcon>
          </div>
          <Modal.Open opens="ban-modal">
            <ButtonIcon $color="var(--color-red-700)" title="Ban user">
              <MdBlock />
            </ButtonIcon>
          </Modal.Open>
          <Modal.Window name="ban-modal">
            <BanUser userId={userId} />
          </Modal.Window>

          <Modal.Open opens="suspend-modal">
            <ButtonIcon $color="var(--color-green-300)" title="Suspend chat">
              <MdOutlineArchive />
            </ButtonIcon>
          </Modal.Open>
          <Modal.Window name="suspend-modal">
            <ConfirmAction
              message={"Are you sure you want to suspend this chat?"}
              onSubmit={handleSuspend}
              btnName="Suspend"
              isPending={isPending}
            />
          </Modal.Window>
          <ButtonIcon
            className={messageType === "vesper" ? "active" : ""}
            $color="var(--color-yellow-500)"
            onClick={() => dispatch(toggleToVesper())}
          >
            <BiCommentAdd title="Whisper (not vesper)" />
          </ButtonIcon>
          {/* <ButtonIcon $color="var(--color-blue-300)">
            <PiHandWithdrawBold title="Pick" />
          </ButtonIcon> */}
          {/* <Modal.Open opens="transfer-chat">
            <ButtonIcon $color="var(--color-indigo-300)">
              <BiTransfer title="Transfer" />
            </ButtonIcon>
          </Modal.Open>
          <Modal.Window name="transfer-chat">
            <TransferChatForm />
          </Modal.Window> */}
        </Modal>
      </div>
    </StyledTopActions>
  );
}

export default TopActions;
