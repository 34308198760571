import React from "react";
import { useQuery,useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import "./SupCompetition.css";
import UserContext from "../../UserContext/UserContext";
import { useState , useContext , useEffect } from "react";
import SupCompList from "./supCompList.jsx"

function SupCompetition() {
    const { load, url } = useContext(UserContext);
      const [activeTab, setActiveTab] = useState("list"); // مدیریت تب فعال
      const queryClient = useQueryClient(); // دسترسی به QueryClient
  const {
    data: competitions = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ["competitions"], // همان queryKey استفاده‌شده
    queryFn: async () => {
      const response = await axios.get(`${url}api/v1/panel/competitions`, {
        headers: {
          Authorization: `bearer ${Cookies.get("token")}`,
        },
      });
      console.log(response.data.data)
      return response.data.data;
    },
  });
  const invalidateCompetitionQuery = () => {
    queryClient.invalidateQueries(["competitions"]);
  };
  const renderContent = () => {
    if (isLoading) {
      load(true);
      return <div>در حال بارگذاری...</div>;
    } else {
      load(false);
    }

    if (error) {
      return <div>خطایی رخ داد: {error.message}</div>;
    }

    let filteredCompetitions = [];
    switch (activeTab) {
      case "list":
        filteredCompetitions = competitions; // نمایش تمام مسابقات
        break;
      case "upcoming":
        filteredCompetitions = competitions.filter(
          (competition) => competition.status === 1 // آینده
        );
        break;
      case "inprogress":
        filteredCompetitions = competitions.filter(
          (competition) => competition.status === 0 // در حال اجرا
        );
        break;
      case "finished":
        filteredCompetitions = competitions.filter(
          (competition) => competition.status === 2 // پایان‌یافته
        );
        break;
      default:
        filteredCompetitions = []; // در صورتی که تب انتخابی معتبر نباشد
    }

    return (
      <SupCompList
        competitions={filteredCompetitions}
        toggleLoading={(status) => load(status)}
        invalidateQuery={invalidateCompetitionQuery} // پاس دادن تابع invalidate
      />
    );
  };

  return (
    <div>
    {/* هدر */}
    <header className="headerStyle">
      <div className="textOverlayStyle">
        <h1>مسابقه بزرگ فنفیکس</h1>
        {/* <p>در این رقابت هیجان‌انگیز شرکت کنید و جوایز ارزشمند برنده شوید!</p> */}
      </div>
    </header>
    {/* سوئیچ باکس */}
    <div className="tabContainerStyle">
      <button
        className={activeTab === "list" ? "activeTabStyle" : "tabStyle"}
        onClick={() => setActiveTab("list")}
      >
        لیست مسابقات
      </button>
      <button
        className={activeTab === "upcoming" ? "activeTabStyle" : "tabStyle"}
        onClick={() => setActiveTab("upcoming")}
      >
        مسابقات آینده
      </button>
      <button
        className={activeTab === "inprogress" ? "activeTabStyle" : "tabStyle"}
        onClick={() => setActiveTab("inprogress")}
      >
        در حال اجرا
      </button>
      <button
        className={activeTab === "finished" ? "activeTabStyle" : "tabStyle"}
        onClick={() => setActiveTab("finished")}
      >
        پایان‌یافته
      </button>
    </div>
    {/* محتوای تب */}
    <div className="contentStyle">{renderContent()}</div>
  </div>
  );
}

export default SupCompetition;
