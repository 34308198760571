// /* eslint-disable react-hooks/exhaustive-deps */

// import './App.css';
// import Login from './components/Login/Login';
// import {  Routes, Route  } from "react-router-dom";
// import { useEffect, useState } from 'react';
// import UserContext from './components/UserContext/UserContext'
// import axios from 'axios';
// import { ThreeCircles } from 'react-loader-spinner'
// import Cookies from 'js-cookie';
// import DashbordContainer from './components/DashboardContainer';
// import { useNavigate } from 'react-router-dom';
// import AutoLogin from './components/AutoLogin';
// import ComingSoon from './ComingSoon';
// // import './disableConsole';
// function App() {
//   const [user, setUser] = useState({dataes: null , at: "" , status: false});
//   const [loading, setLoading] = useState(false);
//   const [maintance, setMAinTance] = useState(false);
//   const load = (lion) => {
//     setLoading(lion)
//   }
//   const login = (userData) => {
//     setUser(userData);
//   };
//   const str = window.location.href.split('?ref=')[1]
//   // const url = "https://crm.fenefx.net/"
//   const url = "http://192.168.8.189:8000/"
//   const navigate = useNavigate()
//   useEffect(() => {
//     // if(window.console || window.console.firebug) {
//     //   console.clear();
//     // }
//     let cook = Cookies.get('token')
//     load(true)
//       axios.post(url+"api/v1/auth/me",{},
//         { headers: { "Authorization": `bearer ${cook}` } }).then(resp => {
//           console.log(resp.data);
//             load(false)
//             if(resp.data.maintenanceMode){
//               setMAinTance(true)
//             }else{
//               setMAinTance(false)
//               setUser({dataes : resp.data , at : cook })
//               navigate("/dashboard")
//             }

//         }).catch(err=>{
//           if(err){
//             load(false)
//             console.log(err);

//             setUser({dataes: null , at: "" , status: false})
//           }
//         })

//   }
//   ,[])

//   const logout = () => {
//     load(true)
//     axios.post(url+"api/v1/auth/logout", {},
//       { headers: { "Authorization": `bearer ${user.at}` } }
//     ).then(res => {
//       if (res.status === 200) {
//         load(false)
//         setUser({dataes: null , at:"" , status: false})
//         Cookies.remove("time")
//         Cookies.remove("token")
//         Cookies.remove("support_token")
//       }
//     }).catch(()=>{
//       load(false)
//       window.location.reload()
//     })
//   };
//   const auth = (compName) => {
//    if(compName === "login"){
//       if (user.dataes !== null) {
//         return <DashbordContainer/>
//       } else {
//         return <Login  />
//       }
//     }
//   }
//   return (
//        <UserContext.Provider value={{ user, login, logout, load , str , url }}>
//        {!maintance?<>
//          {loading&&<ThreeCircles
//            height="80"
//            width="80"
//            radius="9"
//            color="green"
//            ariaLabel="three-dots-loading"
//            wrapperClass='loader'
//           />}

//           <AutoLogin/>
//           <Routes>
//              <Route index element={auth("login")} />
//              <Route path='dashboard' element={<DashbordContainer/>} />
//           </Routes>
//         </>:<>
//                <ComingSoon/>
//            </>}
//        </UserContext.Provider>
//   );
// }
// export default App;

/* eslint-disable react-hooks/exhaustive-deps */

import "./App.css";
import Login from "./components/Login/Login";
import { Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import UserContext from "./components/UserContext/UserContext";
import axios from "axios";
import { ThreeCircles } from "react-loader-spinner";
import Cookies from "js-cookie";
import DashbordContainer from "./components/DashboardContainer";
import { useNavigate } from "react-router-dom";
import AutoLogin from "./components/AutoLogin";
import ComingSoon from "./ComingSoon";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "./disableConsole";

// ایجاد نمونه QueryClient
const queryClient = new QueryClient();
function App() {
  const [user, setUser] = useState({ dataes: null, at: "", status: false });
  const [loading, setLoading] = useState(false);
  const [maintance, setMAinTance] = useState(false);

  const load = (lion) => {
    setLoading(lion);
  };

  const login = (userData) => {
    setUser(userData);
  };

  const str = window.location.href.split("?ref=")[1];
  // const url = "http://192.168.8.167:8000/";
  const url = "https://crm.fenefx.net/";
  const navigate = useNavigate();

  useEffect(() => {
    if (window.console || window.console.firebug) {
      console.clear();
    }
    console.clear();
    let cook = Cookies.get("token");
    load(true);
    axios
      .post(
        url + "api/v1/auth/me",
        {},
        { headers: { Authorization: `bearer ${cook}` } }
      )
      .then((resp) => {
        console.log("my me log ::::", resp.data);
        load(false);
        if (resp.data.maintenanceMode) {
          setMAinTance(true);
        } else {
          setMAinTance(false);
          setUser({ dataes: resp.data, at: cook });
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        if (err) {
          load(false);
          console.log(err);
          setUser({ dataes: null, at: "", status: false });
        }
      });

    // console.clear();
  }, []);

  const logout = () => {
    load(true);
    axios
      .post(
        url + "api/v1/auth/logout",
        {},
        { headers: { Authorization: `bearer ${user.at}` } }
      )
      .then((res) => {
        if (res.status === 200) {
          load(false);
          setUser({ dataes: null, at: "", status: false });
          Cookies.remove("time");
          Cookies.remove("token");
          Cookies.remove("support_token");
        }
      })
      .catch(() => {
        load(false);
        window.location.reload();
      });
  };

  const auth = (compName) => {
    if (compName === "login") {
      if (user.dataes !== null) {
        return <DashbordContainer />;
      } else {
        return <Login />;
      }
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <span dir="ltr">
        <ReactQueryDevtools devtoolsPosition="top-left"></ReactQueryDevtools>
      </span>

      <UserContext.Provider value={{ user, login, logout, load, str, url }}>
        {!maintance ? (
          <>
            {loading && (
              <ThreeCircles
                height="80"
                width="80"
                radius="9"
                color="green"
                ariaLabel="three-dots-loading"
                wrapperClass="loader"
              />
            )}

            <AutoLogin />
            <Routes>
              <Route index element={auth("login")} />
              <Route path="dashboard" element={<DashbordContainer />} />
            </Routes>
          </>
        ) : (
          <ComingSoon />
        )}
      </UserContext.Provider>
    </QueryClientProvider>
  );
}
export default App;
