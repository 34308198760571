import { useCallback } from "react";
// import { MAIN_SOCKET } from "../../utils/constants"; // Your socket URL

export const useSendMessage = (socket) => {
  // Emit message to the server
  const sendMessage = useCallback(
    ({ chatId, message, senderId, senderType, messageType, replyId }) => {
      if (replyId) {
        socket.emit(
          "sendMessage",
          chatId,
          message,
          senderId,
          senderType,
          messageType,
          replyId
        );
      } else {
        socket.emit(
          "sendMessage",
          chatId,
          message,
          senderId,
          senderType,
          messageType
        );
      }
    },
    [socket]
  );

  // Listen for new incoming messages
  // useEffect(() => {
  //   socket.on("newMessage", (newMessage) => {
  //     console.log(newMessage);
  //     setMessages((prevMessages) => [...prevMessages, newMessage]);
  //   });

  //   // Clean up the event listener when component unmounts
  //   return () => {
  //     socket.off("newMessage");
  //   };
  // }, []);

  return { sendMessage };
};
