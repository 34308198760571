import { useQuery } from "@tanstack/react-query";

import useToken from "./hooks/useToken";
import { request } from "../../../utils/helper";
import { MAIN_URL } from "../../../utils/constants";

async function getAdminsList(token) {
  try {
    const data = await request({
      url: `${MAIN_URL}/admin/users/admins`,
      token: token,
    });

    return data;
  } catch (e) {
    console.error(
      e?.response?.data?.error ||
        e?.response?.data?.error ||
        e?.message ||
        "Something went wrong"
    );
    throw e;
  }
}

export function useAdminsList() {
  const token = useToken();
  const { isLoading, data, error } = useQuery({
    queryKey: ["admins-list"],
    queryFn: () => getAdminsList(token),
    staleTime: 1000 * 60 * 5, // 5 minute
  });
  return { isLoading, data, error };
}
