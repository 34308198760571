import AllChats from "./AllChats";
// import { useResizePane } from "./hooks/useResizePane";
import { useSelector } from "react-redux";
import LeftSideWrapper from "./LeftSideWrapper";
import { useAllConversations } from "./useAllConversation";
import ChatForHistory from "./ChatForHistory";
// import Resizer from "./Resizer";

// function transformChats(data) {
//   return data?.chats?.map((chat) => {
//     const latestMessageObj =
//       (chat.messages && chat.messages[chat.messages.length - 1]) || {};
//     const latestMessage = latestMessageObj.message || null;
//     const latestMessageTimeUpdated = latestMessageObj.created_at || null;

//     return {
//       id: chat.id,
//       user_id: chat.user_id,
//       status: chat.status,
//       support_id: chat.support_id,
//       unread_count: 0, // Assuming unread count is 0 if not provided
//       latest_message: latestMessage,
//       latest_message_time_updated: latestMessageTimeUpdated,
//       is_user_online: false, // Assuming offline if no status is provided
//       is_support_online: false, // Assuming offline if no status is provided
//       user: {
//         id: chat.user_id,
//         username: data?.username || "Unknown",
//         name: `${data?.first_name || "null"} ${data?.last_name || "null"}`,
//         image: data?.image || "/users/avatar/avatar.svg",
//       },
//       support: {
//         username: "admin", // Defaulted as "admin" if not specified
//         name: "Admin Admin", // Defaulted as "Admin Admin" if not specified
//       },
//     };
//   });
// }

function MessagesHistory() {
  // const { handleMouseDown } = useResizePane();

  const { historyActiveChatId, searchQuery } = useSelector(
    (state) => state.message
  );

  const { leftWidth, rightWidth } = useSelector((state) => state.layoutWidth);

  // Fetching conversations with infinite scroll
  const { data, isLoading } = useAllConversations({ username: searchQuery });

  return (
    <LeftSideWrapper $leftWidth={leftWidth} $rightWidth={rightWidth}>
      <div className="container-me">
        <div className="massage container-part chat-pane">
          <ChatForHistory key={historyActiveChatId} />
        </div>
        {/* <Resizer onMouseDown={handleMouseDown} /> */}
        <div className="massage-chat container-part chat-list-pane">
          <AllChats conversations={data} loadingOldChats={isLoading} />
        </div>
      </div>
    </LeftSideWrapper>
  );
}

export default MessagesHistory;
