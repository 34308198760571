import axios from 'axios';
import Cookies from 'js-cookie';



export const fetchOrderData = async (url) => {


  const response = await axios.get(url + "api/v1/panel/order_page", {
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  });
  console.log('my respons.data',response.data);

  return response.data;
};

export const fetchCategoryProducts = async (packageId, url) => {
    const response = await axios.get(`${url}api/v1/panel/category_products?package_id=${packageId}`, {
      headers: {
        Authorization: `bearer ${Cookies.get("token")}`,
      },
    });
    return response.data; // داده‌های محصولات دسته‌بندی را باز می‌گرداند
  };

// export const fetchOrderData = async () => {
//     const response = await axios.get(url + "api/v1/panel/order_page", {
//       headers: { Authorization: `bearer ${Cookies.get("token")}` },
//     });
//     return response.data;
//   };

export const fetchAccountData = async (accountId,url) => {
  console.log('im hereee in fetchhhing')
  const response = await axios.get(`${url}api/v1/panel/account/user_details/${accountId}`, {
    headers: {
      Authorization: `Bearer ${Cookies.get('token')}`, // توکن ذخیره شده در کوکی
    },
  });
  console.log('sss',response.data)
  return response.data;
};