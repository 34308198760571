/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from "react";
import { MdAdsClick } from "react-icons/md";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Success from "../Success/Success";
import axios from "axios";
import Cookies from "js-cookie";
import UserContext from "../UserContext/UserContext";

import {
  IoChevronBackCircle,
  IoArrowForwardCircleSharp,
} from "react-icons/io5";
import { InputNumber, Stack } from "rsuite";
export default function SupportGift() {
  const [modal, setModal] = useState(false);
  const [users, setUsers] = useState({ data: [], links: [] });
  const [pakages, setPakages] = useState([]);
  const [userD, setUser] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [auth, setAuth] = useState({ data: [], links: [] });
  const [btn, setBtn] = useState([]);
  const [showUser, setShowUser] = useState(false);
  const [show, setShow] = useState(false);
  const [imageSer, setImageSer] = useState([false, ""]);
  const [id, setId] = useState("");
  const [startDate, setStartDate] = useState(""); // تاریخ شروع
  const [endDate, setEndDate] = useState(""); // تاریخ پایان

  const hasPermission = (permissionName) => {
    return user.dataes.permissions.some(
      (permission) => permission.name === permissionName
    );
  };

  const resetDate = () => {
    // نمایش پیام به کاربر برای آغاز عملیات
    NotificationManager.info("فیلترهای تاریخ حذف می‌شوند...");

    load(true); // شروع نمایش لودر

    // ارسال درخواست به API بدون فیلتر تاریخ
    axios
      .get(`${url}api/v1/admin/accounts/gift/show`, {
        headers: {
          Authorization: `bearer ${Cookies.get("token")}`,
        },
      })
      .then((res) => {
        load(false); // پایان نمایش لودر
        setAuth(res.data); // ذخیره‌سازی داده‌های جدید
        setStartDate(""); // خالی کردن مقدار تاریخ شروع
        setEndDate(""); // خالی کردن مقدار تاریخ پایان
        NotificationManager.success("فیلترهای تاریخ با موفقیت بازنشانی شد.");
      })
      .catch((err) => {
        load(false); // پایان نمایش لودر
        NotificationManager.error(
          "مشکلی در بازنشانی فیلترهای تاریخ پیش آمده است."
        );
        console.error(err);
      });
  };

  function formatToK(number) {
    if (number >= 1000) {
      const result = number / 1000; // تقسیم بر هزار
      return result % 1 === 0 ? result.toFixed(0) + "k" : result.toFixed(2) + "k";
    }
    return number.toString();
  }
  const handleDateFilter = () => {
    if (!startDate || !endDate) {
      NotificationManager.warning("لطفاً تاریخ شروع و پایان را وارد کنید.");
      return;
    }
    console.log(
      "my api for filter date in gift accounts : ",
      `${url}api/v1/admin/accounts/gift/show?start_date=${startDate}&end_date=${endDate}`
    );

    load(true);

    axios
      .get(
        `${url}api/v1/admin/accounts/gift/show?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            Authorization: `bearer ${Cookies.get("token")}`,
          },
        }
      )
      .then((res) => {
        load(false);
        setAuth(res.data); // داده‌های جدید را ذخیره می‌کنیم
        NotificationManager.success("فیلتر با موفقیت اعمال شد");
      })
      .catch((err) => {
        load(false);
        NotificationManager.error("مشکلی پیش آمده است");
        console.error(err);
      });
  };

  const handleDownloadExcel = () => {
    // بررسی وجود تاریخ شروع و پایان
    if (!startDate || !endDate) {
      NotificationManager.warning("لطفاً تاریخ شروع و پایان را وارد کنید.");
      return;
    }

    // ساخت query string درون تابع
    const queryString = `start_date=${startDate}&end_date=${endDate}&export=excel`;

    console.log(
      "my api for download excel in gift accounts : ",
      `${url}api/v1/admin/accounts/gift/show?${queryString}`
    );

    load(true);

    // ارسال درخواست به API
    axios
      .get(`${url}api/v1/admin/accounts/gift/show?${queryString}`, {
        headers: {
          Authorization: `bearer ${Cookies.get("token")}`,
        },
        responseType: "blob", // تنظیم دریافت فایل
      })
      .then((res) => {
        load(false);

        // ایجاد لینک دانلود فایل
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "gift_accounts.xlsx"); // نام فایل
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        NotificationManager.success("فایل با موفقیت دانلود شد");
      })
      .catch((err) => {
        load(false);
        NotificationManager.error("مشکلی پیش آمده است");
        console.error(err);
      });
  };

  useEffect(() => {
    if (selectedCategory) {
      const minPersian = convertToPersian(selectedCategory.min.toString());
      setSelectedProduct(minPersian); // مقدار حداقل به فارسی
    }
  }, [selectedCategory]);

  function convertToPersian(input) {
    // تبدیل اعداد انگلیسی به فارسی
    const persianDigits = "۰۱۲۳۴۵۶۷۸۹";
    return input.replace(/[0-9]/g, (d) => persianDigits[d]);
  }

  function convertToEnglish(value) {
    const persianDigits = "۰۱۲۳۴۵۶۷۸۹";
    const englishDigits = "0123456789";

    // جایگزینی اعداد فارسی با معادل انگلیسی آنها
    return value.replace(
      /[۰-۹]/g,
      (d) => englishDigits[persianDigits.indexOf(d)]
    );
  }

  // Handle category change
  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    const category = pakages.find((cat) => cat.id === parseInt(categoryId));
    setSelectedCategory(category);
    setSelectedProduct(null); // Reset product selection when category changes
  };

  function validate(value, min, max) {
    console.log("Original value:", value); // بررسی مقدار اولیه

    if (!value || typeof value !== "string") {
      console.log("Invalid input value, returning error");
      return "مقدار وارد شده نامعتبر است.";
    }

    // تبدیل اعداد فارسی به انگلیسی
    const englishValue = convertToEnglish(value);
    console.log("Converted to English:", englishValue); // مقدار تبدیل‌شده

    // فیلتر کردن کاراکترهای غیرعددی
    const filteredValue = englishValue.replace(/[^0-9]/g, "");
    console.log("Filtered value:", filteredValue); // بررسی مقدار فیلترشده

    // تبدیل به عدد
    const numericValue = parseInt(filteredValue, 10);
    console.log("Numeric value:", numericValue); // بررسی مقدار عددی

    // بررسی اگر مقدار عددی نباشد
    if (isNaN(numericValue)) {
      console.log("Numeric value is NaN, returning error");
      return "مقدار وارد شده باید عدد باشد.";
    }

    // بررسی مقدار کمتر از min
    if (numericValue < min) {
      console.log(`Value is less than minimum (${min})`);
      return `مقدار وارد شده نباید کمتر از ${min} باشد.`;
    }

    // بررسی مقدار بیشتر از max
    if (numericValue > max) {
      console.log(`Value is greater than maximum (${max})`);
      return `مقدار وارد شده نباید بیشتر از ${max} باشد.`;
    }

    // بررسی بخش‌پذیری بر 1000
    if (numericValue % 1000 !== 0) {
      console.log("Value is not divisible by 1000");
      return "مقدار وارد شده باید مضربی از 1000 باشد.";
    }

    // اگر همه شرایط برقرار باشد
    return true; // موفقیت
  }

  // // مثال‌ها
  // console.log(validate(2000)); // true
  // console.log(validate(1500)); // false
  // console.log(validate(0));    // true (0 به هر عددی بخش‌پذیر است)

  // Handle product change
  const handleProductChange = (e) => {
    const rawValue = e.target.value;

    // تبدیل به انگلیسی برای پردازش داخلی
    const englishValue = convertToEnglish(rawValue);

    // بررسی مقدار ورودی
    if (!isNaN(englishValue) && englishValue !== "") {
      // تبدیل مجدد به فارسی برای نمایش
      const persianValue = convertToPersian(englishValue);
      setSelectedProduct(persianValue);
    } else {
      setSelectedProduct(""); // اگر ورودی معتبر نیست، مقدار را خالی کنید
    }
  };
  // const handleProductChange = (e) => {

  //     setSelectedProduct(e.target.value);

  //   // setSelectedProduct(value);
  //   // const productId = e.target.value;
  //   // const product = selectedCategory.products.find(
  //   //   (prod) => prod.id === parseInt(productId)
  //   // );
  //   // setSelectedProduct(product);
  // };

  // Handle button click to send Axios request
  const handleSubmit = () => {
    if (selectedCategory && selectedProduct) {
      // اجرای تابع validate برای بررسی مقدار
      const validationMessage = validate(
        selectedProduct,
        selectedCategory.min,
        selectedCategory.max
      );

      if (validationMessage === true) {
        // اگر مقدار معتبر است، درخواست ارسال شود
        const data = {
          user_id: userD.id,
          product_id: convertToEnglish(selectedProduct),
          category_id: selectedCategory.id,
        };
        console.log("data:::::", data);
        axios

          .post(url + "api/v1/admin/accounts/gift", data, {
            headers: { Authorization: `bearer ${Cookies.get("token")}` },
          })
          .then((response) => {
            NotificationManager.success("حساب هدیه با موفقیت ساخته شد");
            console.log(response.data);
            setModal(false);
          })
          .catch((error) => {
            NotificationManager.error("انجام نشد مشکل در سرور");
            console.log(error);
          });
      } else {
        // اگر مقدار نامعتبر است، پیام هشدار نمایش داده شود
        NotificationManager.warning(validationMessage); // پیام برگشتی از validate
      }
    } else {
      NotificationManager.warning("لطفاً نوع حساب و مقدار را وارد کنید.");
    }
  };

  const inModalShow = () => {
    return (
      <div
        style={{
          display: "grid",
          gap: "10px",
          padding: "10px",
          minWidth: "300px",
        }}
      >
        <div className="bb"> حساب هدیه برای {userD.username} </div>
        <label htmlFor="category">نوع حساب را انتخاب نمایید</label>
        <select id="category" onChange={handleCategoryChange}>
          <option value="">-- نوع حساب --</option>
          {pakages.map((category) => (
            <option key={category.id} value={category.id}>
              {category.title}
            </option>
          ))}
        </select>
        {selectedCategory && (
          <>
            {console.log("selecteddd:", selectedCategory)}
            <label htmlFor="product">نوع محصول را انتخاب نمایید :</label>

            <input
              className="myNumberInP"
              type="number"
              min={selectedCategory.min}
              max={selectedCategory.max}
              step={1000}
              id="product"
              onChange={handleProductChange}
              value={selectedProduct}
            />
            {/* <select id="product" onChange={handleProductChange}>
              <option value="">-- محصول --</option>
              {selectedCategory.products.map((product) => (
                <option key={product.id} value={product.id}>
                  {product.title}
                </option>
              ))}
            </select> */}
          </>
        )}

        {/* Submit button (visible only when both category and product are selected) */}
        {selectedCategory && selectedProduct && (
          <button className="btn mt" onClick={handleSubmit}>
            ساخت حساب هدیه
          </button>
        )}
        <button
          onClick={() => {
            if (user.dataes.roles[0].name === "admin") {
              load(true);
              axios
                .post(
                  url + "api/v1/auth/support/login",
                  { email: userD.username },
                  {
                    headers: {
                      Authorization: `bearer ${Cookies.get("token")}`,
                    },
                  }
                )
                .then((res) => {
                  load(false);
                  Cookies.set("support_token", Cookies.get("token"));
                  Cookies.set("token", res.data.access_token);
                  Cookies.set("username", userD.username);
                  window.location.reload();
                })
                .catch((err) => {
                  load(false);
                  console.log(err);
                });
            } else {
              load(true);
              axios
                .post(
                  url + "api/v1/panel/admin/login",
                  { id: userD.id },
                  {
                    headers: {
                      Authorization: `bearer ${Cookies.get("token")}`,
                    },
                  }
                )
                .then((res) => {
                  load(false);
                  Cookies.set("support_token", Cookies.get("token"));
                  Cookies.set("token", res.data.access_token);
                  Cookies.set("username", userD.username);
                  window.location.reload();
                })
                .catch((err) => {
                  load(false);
                  console.log(err);
                });
            }
          }}
          style={{ width: "100%" }}
          className="logBtn mt"
        >
          {" "}
          ورود به پنل کاربری{" "}
        </button>
      </div>
    );
  };
  const { load, url, user } = useContext(UserContext);

  useEffect(() => {
    load(true);
    axios
      .get(url + "api/v1/panel/users?type=users", {
        headers: { Authorization: `bearer ${Cookies.get("token")}` },
      })
      .then((res) => {
        load(false);
        setUsers(res.data.data);
      })
      .catch((err) => {
        load(false);
        console.log(err);
      });
  }, []);

  const usersShow = () => {
    return (
      <>
        {users.data.map((d) => {
          if (d.roles[0]) {
            return (
              <tr className="chatUsers" key={d.username}>
                <th className="lastTh">{d.id}</th>
                <th> {d.username} </th>
                <th>
                  {" "}
                  {d.first_name !== null
                    ? d.first_name + " " + d.last_name
                    : "ثبت نشده"}{" "}
                </th>
                <th> {d.roles[0].fa_name} </th>
                <th className="lastTh">
                  <MdAdsClick
                    onClick={() => {
                      setUser(d);
                      console.log("my d", d);
                      setModal([]);
                      load(true);
                      axios
                        .get(url + "api/v1/admin/accounts/gift/details", {
                          headers: {
                            Authorization: `bearer ${Cookies.get("token")}`,
                          },
                        })
                        .then((res) => {
                          load(false);
                          console.log("my packages: ", res.data.packages);
                          setPakages(res.data.packages);
                        })
                        .catch((err) => {
                          load(false);
                          console.log(err);
                        });
                    }}
                  />{" "}
                </th>
              </tr>
            );
          }
        })}
      </>
    );
  };
  // const liShows = () => {
  //   if (active.a1) {
  //     return (
  //       <>
  //         {users.links.map((dd) => {
  //           let c = () => {
  //             if (dd.active) {
  //               return "ac";
  //             } else {
  //               return null;
  //             }
  //           };
  //           if (
  //             dd.label !== "&laquo; Previous" &&
  //             dd.label !== "Next &raquo;"
  //           ) {
  //             console.log("my API: ",dd.url + "&&type=users");
  //             return (
  //               <li
  //                 key={dd.label}
  //                 className={"page__numbers " + c()}
  //                 onClick={() => {
  //                   if (dd.url !== null) {
  //                     load(true);
  //                     axios
  //                       .get(dd.url + "&&type=users", {
  //                         headers: {
  //                           Authorization: `bearer ${Cookies.get("token")}`,
  //                         },
  //                       })
  //                       .then((res) => {
  //                         load(false);

  //                         setUsers(res.data.data);
  //                       })
  //                       .catch((err) => {
  //                         load(false);
  //                         console.log(err);
  //                       });
  //                   }
  //                 }}
  //               >
  //                 {" "}
  //                 {dd.label}{" "}
  //               </li>
  //             );
  //           }
  //         })}
  //       </>
  //     );
  //   } else {
  //     return (
  //       <>
  //         {auth.links.map((dd) => {
  //           let c = () => {
  //             if (dd.active) {
  //               return "ac";
  //             } else {
  //               return null;
  //             }
  //           };
  //           if (
  //             dd.label !== "&laquo; Previous" &&
  //             dd.label !== "Next &raquo;"
  //           ) {
  //             console.log("my API: ",dd.url + "&&type=users");
  //             return (
  //               <li
  //                 key={dd.label}
  //                 className={"page__numbers " + c()}
  //                 onClick={() => {
  //                   if (dd.url !== null) {
  //                     load(true);
  //                     axios
  //                       .get(dd.url, {
  //                         headers: {
  //                           Authorization: `bearer ${Cookies.get("token")}`,
  //                         },
  //                       })
  //                       .then((res) => {
  //                         load(false);
  //                         setAuth(res.data);
  //                         console.log("my auth: ", res.data);
  //                       })
  //                       .catch((err) => {
  //                         load(false);
  //                         console.log(err);
  //                       });
  //                   }
  //                 }}
  //               >
  //                 {" "}
  //                 {dd.label}{" "}
  //               </li>
  //             );
  //           }
  //         })}
  //       </>
  //     );
  //   }
  // };
  const liShows = () => {
    if (active.a1) {
      return (
        <>
          {users.links.map((dd) => {
            let c = () => (dd.active ? "ac" : null);

            if (
              dd.label !== "&laquo; Previous" &&
              dd.label !== "Next &raquo;"
            ) {
              return (
                <li
                  key={dd.label}
                  className={"page__numbers " + c()}
                  onClick={() => {
                    if (dd.url !== null) {
                      load(true);
                      axios
                        .get(dd.url + "&&type=users", {
                          headers: {
                            Authorization: `bearer ${Cookies.get("token")}`,
                          },
                        })
                        .then((res) => {
                          load(false);
                          setUsers(res.data.data);
                        })
                        .catch((err) => {
                          load(false);
                          console.error(err);
                        });
                    }
                  }}
                >
                  {dd.label}
                </li>
              );
            }
          })}
        </>
      );
    } else {
      return (
        <>
          {auth.links.map((dd) => {
            let c = () => (dd.active ? "ac" : null);

            if (
              dd.label !== "&laquo; Previous" &&
              dd.label !== "Next &raquo;"
            ) {
              // افزودن پارامترهای فیلتر تاریخ
              const filterParams =
                startDate && endDate
                  ? `&start_date=${startDate}&end_date=${endDate}`
                  : "";

              return (
                <li
                  key={dd.label}
                  className={"page__numbers " + c()}
                  onClick={() => {
                    console.log("my API: ", dd.url + filterParams);
                    if (dd.url !== null) {
                      load(true);
                      axios
                        .get(dd.url + filterParams, {
                          headers: {
                            Authorization: `bearer ${Cookies.get("token")}`,
                          },
                        })
                        .then((res) => {
                          load(false);
                          setAuth(res.data);
                          console.log("my auth: ", res.data);
                        })
                        .catch((err) => {
                          load(false);
                          console.error(err);
                        });
                    }
                  }}
                >
                  {dd.label}
                </li>
              );
            }
          })}
        </>
      );
    }
  };

  const [active, setActive] = useState({ a1: "active", a2: "" });
  const authShow = () => {
    const translator = (n) => {
      if (n === "First Step") {
        return "مرحله اول";
      } else if (n === "Second Step") {
        return "مرحله دوم";
      } else if (n === "Pro Trader") {
        return "پرو تریدر";
      } else if (n === "Real") {
        return "REAL";
      } else {
        return n;
      }
    };
    return (
      <>
        {auth?.data?.map((d) => {
          return (
            <>
              <tr className="font">
                <th> {d.id} </th>
                <th> {d.user?.username} </th>
                <th>
                  {d.order.product
                    ? d.order.product.category?.title +
                      " " +
                      d.order.product.title
                    : (d.order.category?.title || "پرو تریدر ") +
                      " " +
                      (d.order.product_id === "13"
                        ? "2.5k"
                        : formatToK(d.order.product_id))}
                </th>

                <th>{d.order?.server + "/" + d.order?.platform}</th>
                <th> {d.login} </th>
                <th> {translator(d.type)} </th>
                <th> {d.created_at} </th>
                <th> {d.updated_at} </th>
              </tr>
            </>
          );
        })}
        {console.log(user.dataes)}
        {/* {m.includes('export_excel') ? 'ss' : 'ss'} */}

        {hasPermission("gift_accounts_export_excel") && ( // به جای "your-permission-name" نام دسترسی موردنظر را وارد کنید
          <div style={{ marginRight: "4rem" }}>
            <button
              className="btn"
              onClick={handleDownloadExcel}
              style={{
                display: "block",
                marginLeft: "1rem",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              دریافت فایل Excel
            </button>
          </div>
        )}

        {/* <div style={{ marginRight: "4rem" }}>
          <button
            className="btn"
            onClick={handleDownloadExcel}
            style={{
              display: "block",
              marginLeft: "1rem",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            دریافت فایل Excel
          </button>
        </div> */}
      </>
    );
  };
  const mainShow = () => {
    return (
      <>
        <div className="app-header-navigation">
          <div className="tabs">
            <a
              onClick={() => {
                setActive({ a1: "active", a2: "" });
                load(true);
                axios
                  .get(url + "api/v1/panel/users?type=users", {
                    headers: {
                      Authorization: `bearer ${Cookies.get("token")}`,
                    },
                  })
                  .then((res) => {
                    load(false);
                    setUsers(res.data.data);
                  })
                  .catch((err) => {
                    load(false);
                    console.log(err);
                  });
              }}
              className={active.a1}
            >
              ایجاد حساب هدیه
            </a>
            <a
              onClick={() => {
                setActive({ a1: "", a2: "active" });
                load(true);
                axios
                  .get(url + "api/v1/admin/accounts/gift/show", {
                    headers: {
                      Authorization: `bearer ${Cookies.get("token")}`,
                    },
                  })
                  .then((res) => {
                    load(false);
                    console.log(res.data.data);
                    setAuth(res.data);
                  })
                  .catch((err) => {
                    load(false);
                    console.log(err);
                  });
              }}
              className={active.a2}
            >
              حساب های هدیه
            </a>
          </div>
        </div>
        <div className="transAction">
          {active.a1 ? (
            <div className="eachTicket">
              <div className="table-responsive-md">
                <table
                  style={{ color: "white", textAlign: "center" }}
                  border={1}
                  className="table table-hover"
                >
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}> ID </th>
                      <th className="dfjc">
                        {" "}
                        نام کاربری
                        <input
                          className="searchIn"
                          onChange={(e) => {
                            setTimeout(() => {
                              if (e.target.value.length > 2) {
                                load(true);
                                axios
                                  .get(
                                    url +
                                      "api/v1/panel/users/search?query=" +
                                      e.target.value +
                                      "&&type=users",
                                    {
                                      headers: {
                                        Authorization: `bearer ${Cookies.get(
                                          "token"
                                        )}`,
                                      },
                                    }
                                  )
                                  .then((res) => {
                                    load(false);
                                    setUsers(res.data.data);
                                  })
                                  .catch((err) => {
                                    load(false);
                                    console.log(err);
                                  });
                              } else {
                                load(true);
                                axios
                                  .get(url + "api/v1/panel/users?type=users", {
                                    headers: {
                                      Authorization: `bearer ${Cookies.get(
                                        "token"
                                      )}`,
                                    },
                                  })
                                  .then((res) => {
                                    load(false);
                                    console.log(res.data);
                                    setUsers(res.data.data);
                                  })
                                  .catch((err) => {
                                    load(false);
                                    console.log(err);
                                  });
                              }
                            }, 2000);
                          }}
                          type="text"
                          placeholder="جستجو نام کاربری"
                        />{" "}
                      </th>
                      <th> نام کامل </th>
                      <th> نقش </th>
                      <th className="lastTh"> </th>
                    </tr>
                  </thead>
                  <tbody>{usersShow()}</tbody>
                </table>
                <ul class="page">
                  <li
                    onClick={() => {
                      if (users.prev_page_url !== null) {
                        load(true);
                        axios
                          .get(users.prev_page_url + "&&type=users", {
                            headers: {
                              Authorization: `bearer ${Cookies.get("token")}`,
                            },
                          })
                          .then((res) => {
                            load(false);
                            console.log("my res.data.data", res.data.data);

                            setUsers(res.data.data);
                          })
                          .catch((err) => {
                            load(false);
                            console.log(err);
                          });
                      }
                    }}
                    className="page__btn"
                  >
                    <span class="material-icons">
                      <IoChevronBackCircle />
                    </span>
                  </li>
                  {liShows()}
                  <li
                    onClick={() => {
                      if (users.next_page_url !== null) {
                        load(true);

                        axios
                          .get(users.next_page_url + "&&type=users", {
                            headers: {
                              Authorization: `bearer ${Cookies.get("token")}`,
                            },
                          })
                          .then((res) => {
                            load(false);
                            console.log("my res.data.data", res.data.data);
                            setUsers(res.data.data);
                          })
                          .catch((err) => {
                            load(false);
                            console.log(err);
                          });
                      }
                    }}
                    className="page__btn"
                  >
                    <span class="material-icons">
                      <IoArrowForwardCircleSharp />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div className="eachTicket">
              <p className="bb">
                با کلیک بر روی آیکون کلیک اطلاعات حساب کاربر را وارد کنید :{" "}
              </p>
              <div className="table-responsive-md">
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    marginTop: "1rem",
                    justifyContent: "center",
                  }}
                >
                  <label>
                    از تاریخ:
                    <input
                      className="searchIn"
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      style={{ marginRight: "1rem", width: "8rem" }}
                    />
                  </label>
                  <label>
                    تا تاریخ:
                    <input
                      className="searchIn"
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      style={{ marginRight: "1rem", width: "8rem" }}
                    />
                  </label>
                  <button
                    className="btn"
                    style={{ marginBottom: "1rem", padding: "2px 1rem" }}
                    onClick={handleDateFilter}
                  >
                    اعمال فیلتر
                  </button>
                  <button
                    className="btn"
                    onClick={resetDate}
                    // style={{ margin: "10px" }}
                    style={{
                      padding: "0px 1rem",
                      height: "2rem",
                    }}
                  >
                    تمامی حساب های هدیه
                  </button>
                </div>

                <table border={1} className="table table-hover  ">
                  <thead>
                    <tr className="font">
                      <th style={{ textAlign: "center" }}> # </th>
                      <th> کاربر </th>
                      <th> محصول </th>
                      <th> سرور </th>
                      <th> حساب </th>
                      <th> مرحله </th>
                      <th> تاریخ درخواست </th>
                      <th> بروزرسانی </th>
                    </tr>
                  </thead>
                  <tbody>{authShow()}</tbody>
                </table>
                <ul class="page">
                  {active.a1 ? (
                    <>
                      <li
                        onClick={() => {
                          if (users.prev_page_url !== null) {
                            load(true);
                            axios
                              .get(users.prev_page_url + "&&type=users", {
                                headers: {
                                  Authorization: `bearer ${Cookies.get(
                                    "token"
                                  )}`,
                                },
                              })
                              .then((res) => {
                                load(false);
                                console.log("my res.data", res.data);

                                setUsers(res.data.data);
                              })
                              .catch((err) => {
                                load(false);
                                console.log(err);
                              });
                          }
                        }}
                        className="page__btn"
                      >
                        <span class="material-icons">
                          <IoChevronBackCircle />
                        </span>
                      </li>
                      {liShows()}
                      <li
                        onClick={() => {
                          if (users.next_page_url !== null) {
                            load(true);

                            axios
                              .get(users.next_page_url + "&&type=users", {
                                headers: {
                                  Authorization: `bearer ${Cookies.get(
                                    "token"
                                  )}`,
                                },
                              })
                              .then((res) => {
                                load(false);
                                console.log("my res.data", res.data);
                                setUsers(res.data.data);
                              })
                              .catch((err) => {
                                load(false);
                                console.log(err);
                              });
                          }
                        }}
                        className="page__btn"
                      >
                        <span class="material-icons">
                          <IoArrowForwardCircleSharp />
                        </span>
                      </li>
                    </>
                  ) : (
                    <>
                      <li
                        onClick={() => {
                          console.log("im here previously");
                          if (auth.prev_page_url !== null) {
                            // افزودن پارامترهای فیلتر تاریخ به URL
                            const filterParams =
                              startDate && endDate
                                ? `&start_date=${startDate}&end_date=${endDate}`
                                : "";
                            console.log(
                              "my query",
                              auth.prev_page_url + filterParams
                            );
                            load(true);
                            axios
                              .get(auth.prev_page_url + filterParams, {
                                headers: {
                                  Authorization: `bearer ${Cookies.get(
                                    "token"
                                  )}`,
                                },
                              })
                              .then((res) => {
                                load(false);
                                setAuth(res.data);
                              })
                              .catch((err) => {
                                load(false);
                                console.log(err);
                              });
                          } else {
                            console.log("im here previously 2");
                          }
                        }}
                        className="page__btn"
                      >
                        <span class="material-icons">
                          <IoChevronBackCircle />
                        </span>
                      </li>
                      {liShows()}
                      <li
                        onClick={() => {
                          console.log("im here next");

                          if (auth.next_page_url !== null) {
                            // افزودن پارامترهای فیلتر تاریخ به URL
                            const filterParams =
                              startDate && endDate
                                ? `&start_date=${startDate}&end_date=${endDate}`
                                : "";

                            load(true);
                            console.log(
                              "my query",
                              auth.next_page_url + filterParams
                            );
                            axios
                              .get(auth.next_page_url + filterParams, {
                                headers: {
                                  Authorization: `bearer ${Cookies.get(
                                    "token"
                                  )}`,
                                },
                              })
                              .then((res) => {
                                load(false);
                                console.log("my res.data", res.data);
                                setAuth(res.data); // مقداردهی دوباره به auth
                              })
                              .catch((err) => {
                                load(false);
                                console.log(err);
                              });
                          }
                        }}
                        className="page__btn"
                      >
                        <span class="material-icons">
                          <IoArrowForwardCircleSharp />
                        </span>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div>
      <Success
        show={modal}
        closeModal={() => {
          setModal(false);
        }}
        name="ihjeffse"
      >
        {inModalShow()}
      </Success>
      <NotificationContainer />
      {mainShow()}
    </div>
  );
}
