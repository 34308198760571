import styled from "styled-components";

const ListItem = styled.div`




  background-color: var(--color-grey-100);
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom:1px solid #cb9e52;
  margin-bottom :0.4rem;
  padding:0.3rem;

  &:hover {
    background-color: var(--color-grey-200);
  }

  @media (max-width: 1280px) {
    padding: 0.8rem;
  }
  @media (max-width: 1080px) {
    padding: 0.6rem;
  }
  @media (max-width: 900) {
    padding: 0 0.4rem;
  }
`;

export default ListItem;
