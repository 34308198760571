import { useCallback, useContext } from "react";
import UserContext from "../../UserContext/UserContext";
// import { MAIN_SOCKET } from "../../utils/constants"; // Your socket URL

export const useEditMessage = (socket) => {
  const { user } = useContext(UserContext);
  // Emit message to the server
  const editMessage = useCallback(
    (messageId, message) => {
      console.log(messageId, message);

      socket.emit("editMessage", messageId, message, user.dataes.id);
      socket.emit("getOpenChats");
    },
    [socket]
  );

  return { editMessage };
};
