/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setSearchQuery } from "./slices/messageSlice";

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  border: 1px solid var(--color-grey-300);
  background-color: rgba(0, 128, 126, 0.297);
  border-radius: 15rem;
  padding: 0.6rem 1.2rem;
  box-shadow: var(--shadow-sm);
  width: 90%;
  margin: 0.6rem 0;
  direction: ltr;
  color: #fff !important;
  &::placeholder {
    color: #fff !important;
  }
`;

function SearchInHistory() {
  const dispatch = useDispatch();
  const searchQuery = useSelector((state) => state.message.searchQuery);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    dispatch(setSearchQuery(query)); // Update the Redux search query
  };

  return (
    <Div>
      <Input
        value={searchQuery}
        type="search"
        placeholder="Search..."
        onChange={handleSearch}
      />
    </Div>
  );
}

export default SearchInHistory;
