export const TIMEOUT_SEC = 30;
export const PEYMAN = "192.168.8.167";

// export const MAIN_URL = `http://${PEYMAN}:8000/api/v1`; //  local
// export const MAIN_URL_STORE = `http://${PEYMAN}:8000/storage`; //  local
// export const MAIN_SOCKET = `http://${PEYMAN}:4008/api/`; //  local
// export const SOCKET = `http://${PEYMAN}:4008/`;

export const MAIN_URL = `https://crm.fenefx.net/api/v1`;
export const MAIN_URL_STORE = `https://crm.fenefx.net/storage`;
export const MAIN_SOCKET = `https://chat2.fenefx.net/api/`;
export const SOCKET = `https://chat2.fenefx.net`;
