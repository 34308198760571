import styled from "styled-components";

const Empty = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* margin: 0 auto; */
  padding: 0.4rem 0.8rem;
  background-color: var(--color-green-900);
  border-radius: var(--border-radius-md);
  direction: ltr;
`;

export default Empty;
