/* eslint-disable no-unused-expressions */
import { useContext, useState } from "react";
import styled from "styled-components";
import { useCategories } from "./useCategories";
import MainCat from "./MainCat";
import { IoIosArrowBack } from "react-icons/io";
import { useCreateCategory } from "./useCreateCategory";
import toast from "react-hot-toast";
import { useCreateQuestion } from "./useCreateQuestion";
import { useQueryClient } from "@tanstack/react-query";
import Question from "./Question";
import { useEditQuestion } from "./useEditQuestion";
import { useDispatch, useSelector } from "react-redux";
import {
  clearInputs,
  showForm,
  updateAnswer,
  updateIsImportant,
  updateQuestion,
  updateSubCategory,
} from "./slices/faqSlice";
import SubCat from "./SubCat";
import { useEditCategory } from "./useEditCategory";
import { userHasPermission } from "../../../utils/helper";
import UserContext from "../../UserContext/UserContext";

const FAQContainer = styled.div`
  display: flex;
  padding: 2rem;
  gap: 2rem;
  min-height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MainContent = styled.div`
  flex: 1;
  box-shadow: 0 4px 8px rgba(240, 234, 234, 0.8);
  direction: ltr;

  border-radius: 9px;
  padding: 2rem;
  overflow-y: auto;
  scrollbar-width: thin;
`;

const Box = styled.div`
  /* Box */

  border: 1px solid #cb9e52; /* معادل var(--color-grey-100) */
  box-shadow: 0 4px 8px rgba(247, 234, 182, 0.4);
  border-radius: 8px; /* معادل var(--border-radius-md) */

  padding: 1.6rem;
  direction: ltr;

  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  @media (max-width: 1000px) {
    padding: 2rem;
  }
  @media (max-width: 480px) {
    padding: 1rem;
  }
`;

const FAQManagement = () => {
  // eslint-disable-next-line no-unused-vars
  const { isLoading, data, error } = useCategories();
  const [currentCategoryId, setCurrentCategoryId] = useState(null);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext);

  const {
    subCatName,
    question,
    answer,
    isImportant,
    isEditingQuestion,
    editQuestionId,
    formIsShown,
    formName,
    isEditingCategory,
    editCategoryId,
  } = useSelector((state) => state.faq);

  const { createCategory, isCreating: isCreatingCategory } =
    useCreateCategory();

  const { editCategory, iSEditing: isEditingCat } = useEditCategory();
  const { createQuestion, isCreating: isCreatingQuestion } =
    useCreateQuestion();
  // eslint-disable-next-line no-unused-vars
  const { editQuestion, isEditing: isEditingQues } = useEditQuestion();

  const isWorking =
    isCreatingCategory || isCreatingQuestion || isEditingCat || isEditingQues;

  const currentCategory = currentCategoryId
    ? findCategoryById(data?.categories, currentCategoryId)
    : null;
  // Navigate to a subcategory
  function handleCategorySelect(category) {
    setCurrentCategoryId(category.id);
    dispatch(clearInputs());
  }
  // Go back to the parent category
  function handleBack() {
    const parentCategory = findParentCategory(
      data?.categories,
      currentCategory?.id
    );
    dispatch(clearInputs());
    setCurrentCategoryId(parentCategory ? parentCategory.id : null);
  }
  // Find the parent category recursively
  const findParentCategory = (categories, childId) => {
    for (const category of categories) {
      if (category.subcategories.some((sub) => sub.id === childId)) {
        return category;
      }
      if (category.subcategories.length) {
        const parent = findParentCategory(category.subcategories, childId);
        if (parent) return parent;
      }
    }
    return null;
  };

  function findCategoryById(categories, id) {
    if (!categories) return null;
    for (const category of categories) {
      if (category.id === id) return category;
      if (category.subcategories?.length) {
        const found = findCategoryById(category.subcategories, id);
        if (found) return found;
      }
    }
    return null;
  }

  // Add a new subcategory
  const handleAddSubcategory = () => {
    if (!subCatName.trim()) return;
    if (!isEditingCategory) {
      createCategory(
        { name: subCatName, parent_id: currentCategory?.id },
        {
          onSuccess: (res) => {
            toast.success(
              res.message || "New category has ben inserted successfully."
            ),
              dispatch(clearInputs());
            queryClient.invalidateQueries(["categories"]);
          },
        }
      );
    } else {
      editCategory(
        {
          name: subCatName,
          parent_id: currentCategory?.id,
          id: editCategoryId,
        },
        {
          onSuccess: (res) => {
            toast.success(
              res.message || "Subcategory has ben updated successfully."
            ),
              dispatch(clearInputs());
            queryClient.invalidateQueries(["categories"]);
          },
        }
      );
    }
  };

  // Add a new question
  const handleAddQuestion = () => {
    if (!question.trim() || !answer.trim()) return;
    if (isEditingQuestion) {
      editQuestion(
        {
          id: editQuestionId,
          data: {
            category_id: currentCategory?.id,
            question: question.trim(),
            answer: answer.trim(),
            is_important: isImportant,
          },
        },
        {
          onSuccess: (res) => {
            toast.success(
              res.message || "question has ben updated successfully."
            ),
              dispatch(clearInputs());
          },
        }
      );
    } else {
      createQuestion(
        {
          category_id: currentCategory?.id,
          question: question.trim(),
          answer: answer.trim(),
          is_important: isImportant,
        },
        {
          onSuccess: (res) => {
            toast.success(
              res.message || "New question has ben inserted successfully."
            ),
              dispatch(clearInputs());
          },
        }
      );
    }
  };

  const categoriesToShow = currentCategory
    ? currentCategory.subcategories
    : data?.categories;

  return (
    <FAQContainer
      dir="ltr"
      style={{
        fontSize: "55%",
      }}
    >
      <MainCat categories={data} handleCategorySelect={handleCategorySelect} />
      <MainContent dir="ltr">
        {currentCategory ? (
          <>
            <button
              onClick={handleBack}
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "0.8rem",
              }}
              disabled={isWorking}
              className="btn btn-secondary"
            >
              <IoIosArrowBack />
              <span> back</span>
            </button>
            <h4 as="h4" style={{ textAlign: "center" }}>
              {currentCategory.name}
            </h4>
            <h4 as="h3" style={{ textAlign: "left" }}>
              Articles: {currentCategory.articles}
            </h4>

            {/* Show Subcategories */}
            {categoriesToShow && categoriesToShow.length > 0 && (
              <div
                style={{
                  marginBottom: "2rem",
                  fontSize: "1rem",
                  border: "1px solid #cb9e52",
                  padding: "1rem",
                  borderRadius: "1rem",
                  boxShadow: "0 4px 8px rgba(247, 234, 182, 0.4)",
                }}
              >
                <h4 as="h2" style={{ textAlign: "center" }}>
                  Subcategories
                </h4>
                {categoriesToShow.map((sub) => (
                  <SubCat
                    key={sub.id}
                    sub={sub}
                    handleCategorySelect={handleCategorySelect}
                  />
                ))}
              </div>
            )}

            {/* Show Questions */}
            {currentCategory.questions &&
              currentCategory.questions.length > 0 && (
                <Box style={{ gap: "0.8rem" }}>
                  <h2 style={{ textAlign: "center" }}>Questions</h2>
                  {currentCategory.questions.map((q, idx) => (
                    <Question key={idx} q={q} />
                  ))}
                </Box>
              )}

            {/* Add New Subcategory */}
            {formName === "subCat" &&
              formIsShown &&
              userHasPermission(user, "faq") && (
                <div style={{ marginTop: "1rem" }}>
                  <h2 style={{ textAlign: "center" }}>Add Subcategory</h2>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Subcategory Name"
                    value={subCatName}
                    onChange={(e) =>
                      dispatch(updateSubCategory(e.target.value))
                    }
                    disabled={isWorking}
                  />
                  <button
                    style={{ marginTop: "1rem" }}
                    className="btn btn-primary"
                    onClick={handleAddSubcategory}
                    disabled={isWorking}
                  >
                    {isEditingCategory
                      ? "Update Subcategory"
                      : "Add Subcategory"}
                  </button>
                </div>
              )}
            {/* Add || Update New Question */}
            {formName === "question" &&
              formIsShown &&
              userHasPermission(user, "faq") && (
                <div style={{ fontSize: "1rem", marginTop: "1rem" }}>
                  <h2 style={{ textAlign: "center" }}>
                    {isEditingQuestion ? "Update" : "Add"} Question
                  </h2>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Question"
                      value={question}
                      onChange={(e) => dispatch(updateQuestion(e.target.value))}
                      disabled={isWorking}
                      style={{ flexGrow: "1" }}
                    />
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Answer"
                      value={answer}
                      onChange={(e) => dispatch(updateAnswer(e.target.value))}
                      disabled={isWorking}
                    />
                    <div style={{ display: "flex", gap: "1rem" }}>
                      <input
                        type="checkbox"
                        checked={isImportant}
                        onChange={(e) =>
                          dispatch(updateIsImportant(e.target.checked))
                        }
                      />
                      Is important !
                    </div>
                  </div>

                  <button
                    className="btn btn-primary"
                    style={{ marginTop: "1rem" }}
                    onClick={handleAddQuestion}
                    disabled={isWorking}
                  >
                    {isEditingQuestion ? "Update" : "Add"} Question
                  </button>
                </div>
              )}
            {userHasPermission(user, "faq") && (
              <div
                style={{
                  margin: "6.4rem 0",
                  display: "flex",
                  gap: "2rem",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => dispatch(showForm("subCat"))}
                >
                  Add new sub category
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    if (showForm === "question") {
                      dispatch(clearInputs());
                    } else {
                      dispatch(clearInputs());
                      dispatch(showForm("question"));
                    }
                  }}
                >
                  {showForm === "question" ? "Cancel" : "Add new Question"}
                </button>
              </div>
            )}
          </>
        ) : (
          <h2>Select a Category</h2>
        )}
      </MainContent>
    </FAQContainer>
  );
};

export default FAQManagement;
