import { useMutation, useQueryClient } from "@tanstack/react-query";
import useToken from "../messages/hooks/useToken";
import { createCategory as createCategoryApi } from "../../../services/apiFAQ";

export function useCreateCategory() {
  const queryClient = useQueryClient();
  const token = useToken();

  const { mutate: createCategory, isPending: isCreating } = useMutation({
    mutationFn: (data) => createCategoryApi(data, token),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["categories"] });
    },
    onError: (err) => console.error(err.message),
  });

  return { createCategory, isCreating };
}
