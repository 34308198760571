/* eslint-disable no-unused-vars */
import { useSelector } from "react-redux";
import Empty from "./Empty";
import Container from "./Container";
import MessagesWrapper from "./MessagesWrapper";
import { format } from "date-fns";
import Message from "./Message";
import { useChatHistoryMessages } from "./useChatHistoryMessages";
import Spinner from "./Spinner";
import Error from "./Error";

function groupMessagesByDate(messages) {
  if (!messages) return;
  const groupedMessages = {};

  messages?.forEach((msg) => {
    const messageDate = new Date(msg.created_at); // Assuming msg.updated_at is the timestamp
    const dateKey = format(messageDate, "yyyy-MM-dd"); // Format as "2024-11-18"

    if (!groupedMessages[dateKey]) {
      groupedMessages[dateKey] = [];
    }

    groupedMessages[dateKey].push(msg);
  });

  // Reverse the order of grouped messages
  const reversedGroupedMessages = Object.fromEntries(
    Object.entries(groupedMessages).sort(
      ([a], [b]) => new Date(b) - new Date(a)
    )
  );

  return reversedGroupedMessages;
}

function ChatForHistory() {
  const { historyActiveChatId } = useSelector((state) => state.message);

  const {
    isLoading,
    data: messages,
    error,
  } = useChatHistoryMessages(historyActiveChatId);

  const groupedMessages = groupMessagesByDate(messages);
  if (!historyActiveChatId)
    return (
      <Container>
        <MessagesWrapper>
          <Empty>Select a chat to see messaging</Empty>
        </MessagesWrapper>
      </Container>
    );
  if (isLoading)
    return (
      <Container>
        <div className="center-abs">
          <Spinner />
        </div>
      </Container>
    );
  if (!messages || !messages.length)
    return (
      <Container>
        <MessagesWrapper>
          <Empty>There is no any message</Empty>
        </MessagesWrapper>
      </Container>
    );

  if (error)
    return (
      <Container>
        <div>
          <Error message={error.message} />
        </div>
      </Container>
    );
  return (
    <Container>
      {" "}
      <MessagesWrapper>
        {!messages?.length && <Empty>Select a chat to start messaging</Empty>}
        {Object?.keys(groupedMessages).map((dateKey) => (
          <div key={dateKey}>
            <div style={{ textAlign: "center", margin: "1rem 0" }}>
              {format(new Date(dateKey), "EEEE dd MMM yyyy")}{" "}
            </div>
            <div className="day">
              {groupedMessages[dateKey].map((msg, index) => (
                <Message key={index} message={msg} />
              ))}
            </div>
          </div>
        ))}
      </MessagesWrapper>
    </Container>
  );
}

export default ChatForHistory;
