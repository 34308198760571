/* eslint-disable react/prop-types */
import styled from "styled-components";

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  border: 1px solid var(--color-grey-300);
  background-color: rgba(0, 128, 126, 0.297);
  border-radius: 15rem;
  padding: 0.6rem 1.2rem;
  box-shadow: var(--shadow-sm);
  width: 90%;
  margin: 0.6rem 0;
  direction: ltr;
  color: #fff !important;
  &::placeholder {
    color: #fff !important;
  }
`;

function Search({ conversations, setFilteredConversations }) {
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();

    if (query.trim() === "") {
      // If the search input is empty, setFilteredConversations to an empty array
      setFilteredConversations([]);
      return;
    }

    // Filter the conversations based on the query
    const filtered = conversations?.filter(
      (conversation) =>
        conversation.user?.name?.toLowerCase().includes(query) ||
        conversation.user?.username?.toLowerCase().includes(query)
    );

    // Update the filtered conversations (empty array if no matches)
    setFilteredConversations(filtered);
  };

  return (
    <Div>
      <Input type="search" placeholder="Search..." onChange={handleSearch} />
    </Div>
  );
}

export default Search;
