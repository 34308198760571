import ReactPlayer from "react-player";
import styled from "styled-components";
import { useSendFile } from "./useSendFile";
import { getFileType } from "../../../utils/helper";
import { useSelector } from "react-redux";
import { useContext } from "react";
import UserContext from "../../UserContext/UserContext";

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1.6rem;
  justify-content: space-around;
  padding: 2rem;

  & button {
    min-width: 40%;
  }
`;

const Image = styled.img`
  border-top-left-radius: "var(--border-radius-lg)";
  border-top-right-radius: "var(--border-radius-lg)";
  overflow: hidden;
  object-fit: contain;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    filter: blur(1px) brightness(80%);
  }
`;

/* eslint-disable react/prop-types */
function ShowFile({ selectedFile, setSelectedFile, socket, fileInputRef }) {
  const { isSending, sendFile } = useSendFile();
  const { activeChatId } = useSelector((state) => state.message);
  const { user } = useContext(UserContext);

  const type = getFileType(selectedFile);

  const playerStyles = {
    maxWidth: "100%",
    border: "2px solid #ccc",
    borderRadius: "10px",
    overflow: "hidden",
  };

  function handleSendFile() {
    sendFile(
      { file: selectedFile },
      {
        onSuccess: (res) => {
          socket.emit(
            "sendMessage",
            activeChatId,
            res.fileUrl,
            user.dataes.id,
            "support",
            type
          );

          socket.emit("getOpenChats");
          setSelectedFile(null);
          fileInputRef.current.value = "";
        },
      }
    );
  }
  return (
    <div>
      {type === "image" && (
        <Image src={URL.createObjectURL(selectedFile)} alt="Attached Image" />
      )}

      {/* Render video if it's a video file */}
      {type === "video" && (
        <div style={playerStyles}>
          <ReactPlayer
            url={URL.createObjectURL(selectedFile)}
            controls
            width="100%"
            max-height="50%"
          />
        </div>
      )}

      {(type === "zip" || type === "pdf") && (
        <div
          style={{
            padding: "2rem",
            textAlign: "center",
            backgroundColor: "var(--color-grey-100)",
          }}
        >
          {selectedFile.name} ({type.toUpperCase()})
        </div>
      )}
      <ButtonsContainer>
        <button
          onClick={() => {
            setSelectedFile(null);
          }}
          disabled={isSending}
        >
          cancel
        </button>
        <button disabled={isSending} onClick={handleSendFile}>
          send
        </button>
      </ButtonsContainer>
    </div>
  );
}

export default ShowFile;
