import {React , useContext, useEffect} from 'react'
import DashWithHook from '../navigate'
import UserContext from "./UserContext/UserContext"
import DashWithHook1 from "../navig2"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
export default function DashboardContainer() {
    const navigate = useNavigate()
    const { user , url } = useContext(UserContext);
    useEffect(()=>{
        axios.post(url+"api/v1/auth/me",{},
            { headers: { "Authorization": `bearer ${Cookies.get('token')}` } }).then((res)=>{
                
              }).catch(err=>{
              
               navigate("/")
             
            })
    },[])
    
    const all = ()=>{
        
        if(user.dataes !== null){
            if(user.dataes.roles[0].name === "user"){
                return (
                <>
                  <DashWithHook/>
                </>
              )
             }else{
                return <DashWithHook1/>
             }
        }else{
            return <div style={{height:"101vh"}}></div>
        }
    }
    return <div>
        {Cookies.get("support_token")?  <div onClick={()=>{
            Cookies.set("token" , Cookies.get("support_token") )
            Cookies.remove("support_token")
            window.location.reload()
        }} className='bazgasht'> بازگشت به لیست کاربران </div>: null}
        {all()}
    </div>
  
}
