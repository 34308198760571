import MessageMainPart from "./MessageMainPart";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MessagesHeader from "./MessagesHeader";
import { setActiveChatId, setActiveTab } from "./slices/messageSlice";
import MessagesHistory from "./MessagesHistory";
import { useSocket } from "./context/SocketContext";
import MessagesSupport from "./MessagesSupport";

function Messages() {
  const socket = useSocket();

  const messages = useSelector((store) => store.message);
  const dispatch = useDispatch();

  function handleFormat(active, id = null) {
    dispatch(setActiveTab(active));
    dispatch(setActiveChatId(id));
  }

  return (
    <div className="chat-container--main">
      <MessagesHeader />
      {messages.activeTab !== "all" && messages.activeTab !== "explore" && (
        <MessageMainPart handleFormat={handleFormat} socket={socket} />
      )}
      {messages.activeTab === "all" && <MessagesHistory />}
      {messages.activeTab === "explore" && <MessagesSupport />}
    </div>
  );
}

export default Messages;
