import styled from "styled-components";

const MessagesWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto; /* Enable scrolling if messages exceed available height */
  padding: 0.8rem 0.6rem;
  display: flex;
  flex-direction: column-reverse;
  /* background-image: url("dubai-2.jpg"); */
  scrollbar-width: thin;
  position: relative;
  transition: all 0.3s;
  overflow-x: hidden;

  &.highlight {
    background-color: var(--color-blue-100);
  }

  & .day {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
`;

export default MessagesWrapper;
