import styled from "styled-components";

const ReplyOrEdit = styled.div`
  padding: 0.4rem;
  font-size: 0.8rem;
  background-color: var(--color-grey-100);

  display: flex;
  justify-content: space-between;

  & svg {
    font-size: 1.6rem;
  }

  & .reply-edit-message {
    display: flex;
    gap: 0.6rem;
    align-items: center;
    color: var(--color-blue-500);
  }

  & .close {
    cursor: pointer;
    color: var(--color-grey-400);
  }
`;

export default ReplyOrEdit;
