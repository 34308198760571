/* eslint-disable react/prop-types */
import { MdDelete, MdEdit } from "react-icons/md";
import ButtonIcon from "../messages/ButtonIcon";
import ListItem from "./ListItem";
import Modal from "../messages/Modal";
import ConfirmAction from "../messages/ConfirmAction";
import { useDispatch } from "react-redux";
import { readyInputsToEditCat } from "./slices/faqSlice";
import { useDeleteCategory } from "./useDeleteCategory";
import toast from "react-hot-toast";
import { userHasPermission } from "../../../utils/helper";
import { useContext } from "react";
import UserContext from "../../UserContext/UserContext";

function SubCat({ sub, handleCategorySelect }) {
  const dispatch = useDispatch();
  const { deleteCategory, isDeleting } = useDeleteCategory();
  const { user } = useContext(UserContext);

  function handleDeleteCategory(id) {
    deleteCategory(id, {
      onSuccess: (res) => {
        toast.success(res.message || "Category has ben deleted successfully.");
      },
      onError: (err) => toast.error(err.response.data.message || err.message),
    });
  }

  return (
    <ListItem onClick={() => handleCategorySelect(sub)}>
      <span>
        {sub?.name} ({sub?.articles} articles)
      </span>
      <div>
        {userHasPermission(user, "faq-update") && (
          <ButtonIcon
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                readyInputsToEditCat({
                  editCategoryId: sub.id,
                  subCatName: sub?.name,
                })
              );
            }}
          >
            <MdEdit />
          </ButtonIcon>
        )}
        {userHasPermission(user, "faq-delete") && (
          <Modal>
            <Modal.Open opens="delete-category" stopPropagation={true}>
              <ButtonIcon>
                <MdDelete />
              </ButtonIcon>
            </Modal.Open>
            <Modal.Window name="delete-category">
              <ConfirmAction
                message="This category, all its subcategories, and associated questions will be deleted. Are you sure?"
                isPending={isDeleting}
                onSubmit={() => handleDeleteCategory(sub.id)}
              />
            </Modal.Window>
          </Modal>
        )}
      </div>
    </ListItem>
  );
}

export default SubCat;
