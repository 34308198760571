/* eslint-disable react/prop-types */
import styled from "styled-components";
import {
  detectDirection,
  formatDateTime,
  formatTimeMessages,
  userHasPermission,
} from "../../../utils/helper";
import { VscCheck, VscCheckAll } from "react-icons/vsc";
import ReactPlayer from "react-player";
import { FaFile } from "react-icons/fa";
import Modal from "./Modal";
import RepliedMessage from "./RepliedMessage";
import UserProfile from "./UserProfileImg";
import { useContext, useRef } from "react";
import { MdContentCopy, MdEdit, MdHistory } from "react-icons/md";

import EditedMessages from "./EditedMessages";
import UserContext from "../../UserContext/UserContext";

function getFileName(url) {
  // Split the URL by "/" and get the last part, which is the filename
  const parts = url.split("/");
  const fileName = parts.pop(); // Removes and returns the last element
  return fileName;
}

const StyledMessage = styled.div`
  display: flex;
  align-items: flex-end;
  ${(props) =>
    props.$sender !== "user" ? "flex-direction: row-reverse;" : ""};
  font-size: ${({ fontSize }) => {
    const clampedFontSize = Math.max(1, Math.min(fontSize || 1.4, 2));
    return `${clampedFontSize}rem`;
  }};

  .profile-pic {
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 50%;
    margin: ${(props) =>
      props.$sender !== "user" ? "0 0 0 0.6rem" : "0 0.6rem 0 0"};
    ${(props) => (props.$sender === "user" ? "cursor: pointer;" : "")};
  }

  .message-content {
    background-color: ${(props) =>
      props.$sender === "user"
        ? "var(--color-grey-300)"
        : "rgba(0, 128, 126, 0.297)"};
    color: ${(props) =>
      props.$sender === "user"
        ? "var(--color-grey-800)"
        : props.$type === "support"
        ? "var(--color-green-100)"
        : "var(--color-yellow-400)"};

    padding: 0.6rem 1rem 1.8rem 0.6rem;
    white-space: preserve-breaks;
    border-radius: var(--border-radius-lg);
    align-self: ${(props) =>
      props.$sender !== "user" ? "flex-end" : "flex-start"};
    max-width: ${(props) => {
      if (props.width < 0.5) return "70%";
      if (props.width < 0.55) return "65%";
      if (props.width < 0.6) return "60%";
      if (props.width < 0.65) return "55%";
      if (props.width > 0.7) return "40%";
      return "50%";
    }};
    min-width: 14rem;
    font-size: 0.8rem;
    position: relative;
    transition: none;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      ${(props) =>
        props.$sender !== "user" ? "right: -0.5rem;" : "left: -0.5rem;"}
      border-width: 10px;
      border-style: solid;
      transition: none;
      border-color: transparent transparent
        ${(props) =>
          props.$sender === "user"
            ? "var(--color-grey-300)"
            : "rgba(0, 128, 126, 0.297)"}
        transparent;
    }

    & .message-file {
      display: flex;
      gap: 2rem;

      & .file-icon {
        font-size: 3rem;
        padding: 0.5rem 1rem;
        background-color: var(--color-blue-200);
        border-radius: var(--border-radius-lg);
        cursor: pointer;
      }

      & .file-name-volume {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & .file-volume {
          font-weight: 100;
          font-size: 1.4rem;
          color: var(--color-blue-900);
        }
      }
    }
  }

  & .message-status {
    position: absolute;
    bottom: 2px;
    right: 4px;
    display: flex;
    gap: 1.2rem;
    /* align-items: center; */
    color: ${(props) =>
      props.$sender === "user"
        ? "var(--color-grey-600)"
        : "var(--color-grey-300)"};

    & .message-status--time {
      font-size: 0.8rem;
      font-weight: 100;
    }

    & .message-status--status {
      color: var(--color-green-200);
    }
  }

  & .message-action-btn {
    position: absolute;
    color: var(--color-grey-700);
    ${(props) => (props.$sender !== "user" ? "left: -2rem;" : "right: -2rem;")}
    cursor: pointer;
    outline: none;
    padding: 0.2rem 0.2rem;
    font-size: 1.2rem;
    border-radius: var(--border-radius-sm);
    backface-visibility: hidden;
    color: var(--color-grey-100);
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      /* background-color: var(--color-grey-700); */
      color: var(--color-grey-400);
    }

    &.message-action-btn--edit {
      bottom: 0rem;
    }
    &.message-action-btn--copy {
      bottom: 1.5rem;
    }
    &.message-action-btn--edited {
      bottom: 2.5rem;
    }
  }
`;

const StyledFileBubble = styled.div`
  display: flex;
  align-items: flex-end;
  ${(props) =>
    props.$sender !== "user" ? "flex-direction: row-reverse;" : ""};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}rem` : "1.4rem")};

  .profile-pic {
    width: 3.4rem;
    height: 3.4rem;
    border-radius: 50%;
    margin: ${(props) =>
      props.$sender !== "user" ? "0 0 0 0.6rem" : "0 0.6rem 0 0"};
  }
  border-radius: var(--border-radius-lg);

  align-self: ${(props) =>
    props.$sender !== "user" ? "flex-end" : "flex-start"};

  & img {
    max-width: 40%;
    object-fit: contain;
    border-radius: var(--border-radius-lg);
    cursor: pointer;
  }
`;

const playerStyles = {
  maxWidth: "100%",
  border: "2px solid #ccc",
  borderRadius: "10px",
  overflow: "hidden",
};

const convertTextToLinks = (text) => {
  // Regular expression to detect URLs (http/https/ftp)
  const urlRegex = /\b(https?:\/\/[^\s]+(?:\/[^\s]*)?)/gi;

  return text.split(urlRegex).map((part, index) => {
    // If this part is a URL, render it as an anchor tag
    if (urlRegex.test(part)) {
      return (
        <a
          style={{
            display: "inline-block",
            direction: "ltr",
            color: "var(--color-blue-500)",
          }}
          key={index}
          href={part}
          target="_blank"
        >
          {part}
        </a>
      );
    }
    // If it's not a URL, render the text as-is
    return part;
  });
};

function Message({ message, width, onReply, onEdit }) {
  const title = formatDateTime(message?.created_at);
  const { user } = useContext(UserContext);

  const messageRef = useRef(null);
  const type = message.message_type;

  const handleCopy = () => {
    if (message?.message) {
      navigator?.clipboard.writeText(message?.message);
    }
  };

  if (type === "image")
    return (
      <StyledFileBubble
        $sender={message.type}
        onDoubleClick={onReply}
        ref={messageRef}
        id={`message-${message.id}`}
      >
        {message.type !== "user" && <UserProfile message={message} />}

        <Modal>
          <Modal.Open opens={`image-${message.id}`}>
            <img src={message.message} alt="Attached" title={title} />
          </Modal.Open>
          <Modal.Window name={`image-${message.id}`}>
            <img
              src={message.message}
              alt="Attached"
              style={{
                padding: "4rem",
                maxHeight: "75vh",
              }}
            />
          </Modal.Window>
        </Modal>
      </StyledFileBubble>
    );
  if (type === "video")
    return (
      <StyledFileBubble
        $sender={message.type}
        onDoubleClick={onReply}
        ref={messageRef}
        id={`message-${message.id}`}
      >
        {message.type !== "user" && <UserProfile message={message} />}
        <div style={playerStyles} title={title}>
          <ReactPlayer
            url={message.message}
            controls
            width="100%"
            max-height="50%"
          />
        </div>
      </StyledFileBubble>
    );
  return (
    <StyledMessage
      $sender={message.type}
      width={width}
      onDoubleClick={onReply}
      ref={messageRef}
      id={`message-${message.id}`}
      $type={message.type}
    >
      {message.type !== "user" && <UserProfile message={message} />}
      <div className="message-content" title={title}>
        {type === "message" && (
          <div>
            {message?.reply_to !== null && message?.reply_to !== "null" && (
              <RepliedMessage message={message} $sender={message.type} />
            )}
            <div
              style={{
                minWidth: "8rem",
                textAlign:
                  detectDirection(message?.message) === "rtl"
                    ? "right"
                    : "left",
              }}
              dir="auto"
            >
              {convertTextToLinks(message?.message)}
            </div>
          </div>
        )}
        {(type === "zip" || type === "pdf") && (
          <div className="message-file">
            <a href={message.message} target="_blank" download>
              <div className="file-icon">
                <FaFile />
              </div>
            </a>
            <div className="file-name-volume">
              <p>{getFileName(message.message)}</p>

              <div>
                <p className="file-volume">62 kb</p>
              </div>
            </div>
          </div>
        )}
        <span className="message-status">
          <span className="message-status--time">
            <span style={{ display: "flex", gap: "1rem" }}>
              <span>{message.support_nickname}</span>

              <span>
                {message?.created_at && formatTimeMessages(message.created_at)}
              </span>
            </span>
          </span>
          {message.type !== "user" && (
            <span className="message-status--status">
              {message.read_status ? <VscCheckAll /> : <VscCheck />}
            </span>
          )}
        </span>

        <div
          className="message-action-btn message-action-btn--copy"
          onClick={() => handleCopy(message.message)}
        >
          <MdContentCopy />
        </div>
        {message.type !== "user" &&
          userHasPermission(user, "create chats") &&
          userHasPermission(user, "edit chats") && (
            <div
              className="message-action-btn message-action-btn--edit"
              onClick={() => onEdit(message.id, message.message)}
            >
              <MdEdit />
            </div>
          )}
        {message.type !== "user" && message.is_edited && (
          <Modal>
            <Modal.Open opens="edit-messages-modal">
              <div className="message-action-btn message-action-btn--edited">
                <MdHistory />
              </div>
            </Modal.Open>
            <Modal.Window name="edit-messages-modal">
              <EditedMessages id={message.id} />
            </Modal.Window>
          </Modal>
        )}
      </div>
    </StyledMessage>
  );
}

export default Message;
