import { useMutation, useQueryClient } from "@tanstack/react-query";
import useToken from "../messages/hooks/useToken";
import { createQuestion as createQuestionApi } from "../../../services/apiFAQ";

export function useCreateQuestion() {
  const queryClient = useQueryClient();
  const token = useToken();

  const { mutate: createQuestion, isPending: isCreating } = useMutation({
    mutationFn: (data) => createQuestionApi(data, token),
    onSuccess: () => {
      return queryClient.invalidateQueries(["categories"]);
    },
    onError: (err) => console.error(err.message),
  });

  return { createQuestion, isCreating };
}
