import React, { useContext } from "react";
import "./Notifications.css";
import UserContext from "../../UserContext/UserContext";

function Notifications() {
  const { user } = useContext(UserContext);

  // بررسی اگر اعلان‌ها وجود ندارد
  if (!user.dataes.notifications || user.dataes.notifications.length === 0) {
    return (
      <div className="NotificationsContainer">
        <h2 className="NotificationsTitle">اعلانات</h2>
        <p className="NoNotificationsMessage" style={{
              textAlign: "center",
              color: "#ff6f61",
              fontWeight: "bold",
            }}>هیچ اعلان جدیدی وجود ندارد.</p>
      </div>
    );
  }

  // مرتب‌سازی و انتخاب ۱۰ اعلان آخر
  const latestNotifications = user.dataes.notifications
    .sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at) // مرتب‌سازی نزولی بر اساس تاریخ
    )
    .slice(0, 10); // انتخاب ۱۰ اعلان اول

  return (
    <div className="NotificationsContainer">
      <h2 className="NotificationsTitle">اعلانات</h2>
      <table className="NotificationsTable">
        <thead>
          <tr>
            <th>عنوان</th>
            <th>پیام</th>
            <th>تاریخ</th>
          </tr>
        </thead>
        <tbody>
          {latestNotifications.map((notification, index) => (
            <tr key={notification.id}>
              <td>{notification.title}</td>
              <td>{notification.message}</td>
              <td>
                {new Date(notification.created_at).toLocaleString("fa-IR")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Notifications;
