import React, { useEffect } from "react";
import "./TopWithdrawals.css";
import AOS from "aos";
import "aos/dist/aos.css";

function TopWithdrawals({ Withdrawals }) {
  // useEffect(() => {
  //   AOS.init({
  //     duration: 1000,
  //     easing: "ease-in-out",
  //     once: true,
  //   });
  //   console.log("my payouts ::::", Withdrawals);
  // }, [Withdrawals]); // اضافه کردن Withdrawals به dependency array

  // مرتب‌سازی داده‌ها بر اساس تاریخ
  const sortedWithdrawals = (Withdrawals || []).sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  return (
    <div
      className="TopWithdrawalsContainer"
      data-aos={window.screen.width > 768 ? "zoom-in" : ""}
    >
      <h2 className="TopWithdrawalsTitle">برترین برداشت‌های اخیر</h2>
      <table className="WithdrawalsTable">
        <thead>
          <tr>
            <th>نام کاربر</th>
            <th>مبلغ </th>
          </tr>
        </thead>
        <tbody>
          {sortedWithdrawals.slice(0, 10).map((withdrawal, index) => (
            <tr key={index}>
              <td>
                {withdrawal.user_top.nickname
                  ? withdrawal.user_top.nickname
                  : withdrawal.user_top.first_name}
              </td>
              <td>
                {parseFloat(withdrawal.received_amount).toLocaleString()} $
              </td>{" "}
              {/* مبلغ */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TopWithdrawals;
