import styled from "styled-components";

const InputButtons = styled.div`
  position: absolute;
  right: 0.4rem;
  bottom: 1rem;
  display: flex;
  /* gap: 0%; */

  & .btn {
    font-size: 0.4rem;
    color: var(--color-grey-300);
    background: none;
    border: none;

    &:hover {
      color: var(--color-grey-400);
    }

    &:focus {
      outline: none;
    }
  }
`;
export default InputButtons;
