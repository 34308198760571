import { useSelector } from "react-redux";
import AllChats from "./AllChats";
import ChatForHistory from "./ChatForHistory";
import LeftSideWrapper from "./LeftSideWrapper";
// import Resizer from "./Resizer";
// import { useResizePane } from "./hooks/useResizePane";
import { useSpecificSupportChats } from "./useSpecificSupportChats";

function MessagesSupport() {
  // const { handleMouseDown } = useResizePane();
  const { historyActiveChatId, supportIdForChats } = useSelector(
    (state) => state.message
  );

  const { leftWidth, rightWidth } = useSelector((state) => state.layoutWidth);

  // Fetching conversations with infinite scroll
  const { data, isLoading } = useSpecificSupportChats({
    supportId: supportIdForChats,
    from: "12-01-2024",
    to: "12-10-2024",
  });

  return (
    <LeftSideWrapper $leftWidth={leftWidth} $rightWidth={rightWidth}>
      <div className="container-me">
        <div className="massage container-part chat-pane">
          <ChatForHistory key={historyActiveChatId} />
        </div>
        {/* <Resizer onMouseDown={handleMouseDown} /> */}
        <div className="massage-chat container-part chat-list-pane">
          <AllChats conversations={data?.data} isLoading={isLoading} />
        </div>
      </div>
    </LeftSideWrapper>
  );
}

export default MessagesSupport;
