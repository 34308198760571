import { useQuery } from "@tanstack/react-query";
import useToken from "../messages/hooks/useToken";
import { getCategories } from "../../../services/apiFAQ";

export function useCategories() {
  const token = useToken();

  const { isLoading, data, error } = useQuery({
    queryKey: ["categories"],
    queryFn: () => getCategories(token),
  });

  return { isLoading, data, error };
}
