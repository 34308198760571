/* eslint-disable eqeqeq */
/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from "react";
import {
  IoChevronBackCircle,
  IoArrowForwardCircleSharp,
} from "react-icons/io5";
import { FaCopy } from "react-icons/fa";
import { MdExpandMore } from "react-icons/md";
import Cookies from "js-cookie";
import axios from "axios";
import { MdAdsClick } from "react-icons/md";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import UserContext from "../UserContext/UserContext";
import Success from "../Success/Success";
import { RiShieldUserFill, RiLockPasswordFill } from "react-icons/ri";
import "./SubAcc.css";

// function formatToK(number) {
//   if (number >= 1000) {
//     return (number / 1000).toFixed(number % 1000 === 0 ? 0 : 1) + "k";
//   }
//   return number.toString();
// }
// function formatToK(number) {
//   if (number >= 1000) {
//     const result = +number / 1000; // Keep one decimal without rounding
//     return result.toFixed(2) + "k";
//   }
//   return number.toString();
// }
function formatToK(number) {
  if (number >= 1000) {
    const result = +number / 1000; // تقسیم بر هزار
    return result % 1 === 0 ? result.toFixed(0) + "k" : result.toFixed(2) + "k";
  }
  return number.toString();
}

export default function SubAcc() {
  const { load, url, user } = useContext(UserContext);
  const [auth, setAuth] = useState({ data: [], links: [], user: [] });
  const [id, setId] = useState({});
  const [time, setTime] = useState("");
  const [modal, setModal] = useState(false);
  const [btn, setBtn] = useState([]);
  const [radd, setRadd] = useState("");
  const [xol, setXol] = useState(0);
  const [m, setM] = useState([]);
  const [act, setAct] = useState({
    a1: "active",
    a2: "",
    a3: "",
    a4: "",
    a5: "",
    a6: "",
    a7: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStart] = useState("");
  const [modmod, setModMod] = useState(false);
  const [log, setLog] = useState("");
  const [pass, setPass] = useState("");
  const [passInv, setPassInv] = useState("");
  const [change, setChange] = useState([]);
  const [brok, setBroker] = useState("");
  const [servers, setServers] = useState([]);
  const [ser, serSer] = useState([""]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [startDateFilter, setStartDateFilter] = useState(""); // State برای تاریخ شروع
  const [endDateFilter, setEndDateFilter] = useState(""); // State برای تاریخ پایان
  const [selectedValue, setSelectedValue] = useState("");
  const [balance, setBalance] = useState("");
  const [expandedRow, setExpandedRow] = useState(null);

  const handleChange = (event) => {
    const inputValue = event.target.value;

    // بررسی اینکه فقط اعداد صحیح بین 1 و 1000 معتبر هستند
    if (/^\d*$/.test(inputValue) && inputValue >= 1 && inputValue <= 1000) {
      setBalance(inputValue);
    }
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    console.log("Selected Value:", event.target.value); // مقدار انتخابی در کنسول
  };
  useEffect(() => {
    setSearchTerm("");
  }, [act]);
  // const downloadExcel = () => {
  //   if (startDateFilter === "" || endDateFilter === "") {
  //     NotificationManager.warning(
  //       "لطفاً هر دو تاریخ شروع و پایان را وارد کنید.",
  //       "هشدار"
  //     );
  //     return;
  //   }

  //   const params = `type=${
  //     act.a2 === "active" ? "active" : act.a3 === "active" ? "delete" : "real"
  //   }&start_date=${startDateFilter}&end_date=${endDateFilter}`;
  //   load(true);
  //   console.log('params',params);
  //   axios
  //     .get(`${url}api/v1/panel/admin/accounts/export_excel?${params}`, {
  //       headers: { Authorization: `bearer ${Cookies.get("token")}` },
  //       responseType: "blob",
  //     })
  //     .then((res) => {
  //       load(false);
  //       const url = window.URL.createObjectURL(new Blob([res.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "accounts.xlsx"); // نام فایل اکسل
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //     .catch((err) => {
  //       load(false);
  //       NotificationManager.error(
  //         "دانلود اکسل انجام نشد. لطفاً دوباره تلاش کنید.",
  //         "خطا"
  //       );
  //       console.error(err);
  //     });
  // };

  const hasPermission = (permissionName) => {
    return user.dataes.permissions.some(
      (permission) => permission.name === permissionName
    );
  };

  const resetDate = () => {
    setStartDateFilter("");
    setEndDateFilter("");
    console.log(
      "my reset query is : ",
      `${url}api/v1/panel/admin/accounts/show?type=${
        act.a2 === "active" ? "active" : act.a3 === "active" ? "delete" : "real"
      }`
    );
    axios
      .get(
        `${url}api/v1/panel/admin/accounts/show?type=${
          act.a2 === "active"
            ? "active"
            : act.a3 === "active"
            ? "delete"
            : "real"
        }` /* به‌روزرسانی نام */,
        {
          headers: {
            Authorization: `bearer ${Cookies.get("token")}`,
          },
        }
      )
      .then((res) => {
        load(false);
        setAuth(res.data);
      })
      .catch((err) => {
        load(false);
        console.log(err);
      });
  };
  const downloadExcel = () => {
    // ساخت query string به صورت پویا
    const params = [];

    // اضافه کردن نوع (active, delete, real)
    if (act.a2 === "active") {
      params.push("type=active");
    } else if (act.a3 === "active") {
      params.push("type=delete");
    } else if (act.a7 === "active") {
      params.push("type=real");
    }

    // اضافه کردن تاریخ‌ها در صورت تنظیم بودن
    if (startDateFilter) {
      params.push(`start_date=${startDateFilter}`);
    }
    if (endDateFilter) {
      params.push(`end_date=${endDateFilter}`);
    }

    const query = params.length > 0 ? `?${params.join("&")}` : ""; // اگر پارامتری وجود نداشته باشد، کوئری خالی بماند
    console.log(
      "query",
      `${url}api/v1/panel/admin/accounts/export_excel${query}`
    );
    load(true);
    axios
      .get(`${url}api/v1/panel/admin/accounts/export_excel${query}`, {
        headers: { Authorization: `bearer ${Cookies.get("token")}` },
        responseType: "blob", // تنظیم برای دریافت فایل اکسل
      })
      .then((res) => {
        load(false);
        const fileURL = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", "accounts.xlsx"); // نام فایل دانلود
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        load(false);
        NotificationManager.error(
          "دانلود اکسل انجام نشد. لطفاً دوباره تلاش کنید.",
          "خطا"
        );
        console.error(err);
      });
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      sendSearchRequest(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);
  // useEffect(() => {
  //   if (selectedValue) {
  //     sendSearchAccType(selectedValue);
  //   }
  // }, [selectedValue]);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 2000);
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);
  const tab = () => {
    if (act.a2 === "active") {
      return "&&type=active";
    } else if (act.a3) {
      return "&&type=delete";
    } else if (act.a4) {
      return "&&type=rejected";
    } else if (act.a1) {
      return "&&type=inactive";
    } else if (act.a7) {
      return "";
    }
  };
  // const sendSearchRequest = (term) => {
  //   if (act.a7) {
  //     if (term.length > 1) {
  //       load(true);
  //       axios
  //         .get(url + "api/v1/panel/admin/accounts/real?query=" + term, {
  //           headers: {
  //             Authorization: `bearer ${Cookies.get("token")}`,
  //           },
  //         })
  //         .then((res) => {
  //           load(false);
  //           setAuth(res.data);
  //         })
  //         .catch((err) => {
  //           console.log(err);

  //           load(false);
  //         });
  //     } else {
  //       load(true);
  //       axios
  //         .get(url + "api/v1/panel/admin/accounts/real", {
  //           headers: {
  //             Authorization: `bearer ${Cookies.get("token")}`,
  //           },
  //         })
  //         .then((res) => {
  //           load(false);
  //           setAuth(res.data);
  //         })
  //         .catch((err) => {
  //           console.log(err);

  //           load(false);
  //         });
  //     }
  //   } else {
  //     if (term.length > 1) {
  //       load(true);
  //       axios
  //         .get(
  //           url + "api/v1/panel/admin/accounts/search?query=" + term + tab(),
  //           { headers: { Authorization: `bearer ${Cookies.get("token")}` } }
  //         )
  //         .then((res) => {
  //           load(false);
  //           setAuth(res.data.results);
  //           console.log(res.data.results);
  //         })
  //         .catch((err) => {
  //           load(false);
  //           console.log(err);
  //         });
  //     } else {
  //       load(true);
  //       axios
  //         .get(url + "api/v1/panel/admin/accounts/show?" + tab(), {
  //           headers: {
  //             Authorization: `bearer ${Cookies.get("token")}`,
  //           },
  //         })
  //         .then((res) => {
  //           load(false);
  //           setAuth(res.data);
  //           console.log(res.data);
  //         })
  //         .catch((err) => {
  //           load(false);
  //           console.log(err);
  //         });
  //     }
  //   }
  // };
  const sendSearchRequest = (term) => {
    load(true);

    // بررسی شرایط برای اضافه کردن تاریخ
    const shouldAddDates =
      act.a2 === "active" || act.a3 === "active" || act.a7 === "active";

    // ساخت query تاریخ‌ها فقط اگر شرایط برقرار باشد
    let filterDates = "";
    if (shouldAddDates && startDateFilter && endDateFilter) {
      filterDates = `&start_date=${startDateFilter}&end_date=${endDateFilter}`;
    }

    // حالا بر اساس act.a7 یا عدم آن، مسیرهای مختلف را داشته باشیم
    if (act.a7 === "active") {
      if (term.length > 1) {
        console.log(
          "my query : ",
          url + "api/v1/panel/admin/accounts/real?query=" + term + filterDates
        );
        axios
          .get(
            url +
              "api/v1/panel/admin/accounts/real?query=" +
              term +
              filterDates,
            {
              headers: {
                Authorization: `bearer ${Cookies.get("token")}`,
              },
            }
          )
          .then((res) => {
            load(false);
            setAuth(res.data);
          })
          .catch((err) => {
            load(false);
            console.log(err);
          });
      } else {
        console.log(
          "my query :",
          url + "api/v1/panel/admin/accounts/real?query=" + term + filterDates
        );
        axios
          .get(
            url +
              "api/v1/panel/admin/accounts/real?" +
              (filterDates ? filterDates.slice(1) : ""),
            {
              headers: {
                Authorization: `bearer ${Cookies.get("token")}`,
              },
            }
          )
          .then((res) => {
            load(false);
            setAuth(res.data);
          })
          .catch((err) => {
            load(false);
            console.log(err);
          });
      }
    } else {
      // اگر a7 فعال نباشد، از مسیر search یا show استفاده می‌کنیم
      if (term.length > 1) {
        console.log(
          "my query : ",
          url +
            "api/v1/panel/admin/accounts/search?query=" +
            term +
            tab() +
            filterDates
        );
        axios
          .get(
            url +
              "api/v1/panel/admin/accounts/search?query=" +
              term +
              tab() +
              filterDates,
            {
              headers: { Authorization: `bearer ${Cookies.get("token")}` },
            }
          )
          .then((res) => {
            load(false);
            setAuth(res.data.results);
            console.log(res.data.results);
          })
          .catch((err) => {
            load(false);
            console.log(err);
          });
      } else {
        console.log(
          "my query :",
          url + "api/v1/panel/admin/accounts/show?" + tab() + filterDates
        );
        axios
          .get(
            url + "api/v1/panel/admin/accounts/show?" + tab() + filterDates,
            {
              headers: {
                Authorization: `bearer ${Cookies.get("token")}`,
              },
            }
          )
          .then((res) => {
            load(false);
            setAuth(res.data);
            console.log(res.data);
          })
          .catch((err) => {
            load(false);
            console.log(err);
          });
      }
    }
  };

  useEffect(() => {
    user.dataes.permissions.map((d) => {
      setM((e) => [...e, d.name]);
    });
    load(true);
    axios
      .get(url + "api/v1/panel/admin/accounts/show?type=inactive", {
        headers: {
          Authorization: `bearer ${Cookies.get("token")}`,
        },
      })
      .then((res) => {
        load(false);
        setAuth(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        load(false);
        console.log(err);
      });
  }, []);
  const translator = (n) => {
    if (n === "First Step") {
      return "مرحله اول";
    } else if (n === "Second Step") {
      return "مرحله دوم";
    } else if (n === "Pro Trader") {
      return "پرو تریدر";
    } else if (n === "Real") {
      return "REAL";
    } else {
      return n;
    }
  };
  const translateType = (type) => {
    const types = {
      LoginInsertion: "درج اطلاعات حساب",
      AccountAction: "عملیات حساب",
      ChangeAccountDetails: "تغییر حساب",
      PassRequest: " بررسی پاس",
      Payout: "برداشت",
      PickAccount: "انتخاب حساب",
      AxiRevival:'تمدید axi'

      // Add more types here
    };
    return types[type] || type;
  };

  const translateUserType = (userType) => {
    const userTypes = {
      admin: "پشتیبان",
      user: "کاربر",
      // Add more user types here
    };
    return userTypes[userType] || userType;
  };
  const translateTitle = (title) => {
    const titles = {
      "Account Activated": "فعال سازی حساب",
      "Account Aproved": "تایید حساب",
      "Account Breached": "نقض حساب",
      "Account Rejected": "رد حساب",
      "Change Account Details": "تغییر حساب",

      "Insert Login Info": "درج اطلاعات حساب",
      "Insert Login Info For Real Account": "درج اطلاعات حساب Real",
      PassRequest: " بررسی پاس",
      "Payout Action": "بررسی برداشت",
      "Payout Request": "درخواست برداشت",
      "Pick Account Info": "مانیتور حساب",
      "Pick Account To Create": "ساخت حساب",
      "Handle Axi Revival":"تمدید axi"
      // سایر عنوان‌ها را اینجا اضافه کنید
    };
    return titles[title] || title; // اگر عنوان ترجمه نداشت، همان مقدار اصلی را برگردان
  };
  const translateDescription = (description) => {
    const descriptions = {
      Aproved: "تایید",
      "Change Account Details By Admin": "تغییر اطلاعات حساب توسط پشتیبان",
      "Insert Login Info By Admin": "  درج اطلاعات حساب توسط پشتیبان",
      "Insert Login Info By User": "درج اطلاعات حساب توسط کاربر",
      "Pick Account For Creating": "انتخاب حساب برای ساخت",
      "Pick Account For Monitoring": "انتخاب حساب برای مانیتور",
      "Re-Activated Account": "فعال سازی مجدد حساب",
      "User Requested for Payout": "ثبت درخواست برداشت توسط کاربر",
      "Axi Revival By Admin":"تمدید دستی axi"

      // سایر توضیحات را اینجا اضافه کنید
    };
    return descriptions[description] || description; // اگر توضیح ترجمه نداشت، مقدار اصلی را برگردان
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${hours}:${minutes}:${seconds}  ${year}-${month}-${day} `;
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  const openModal = (imageSrc) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage(null);
  };

  const authShow = () => {
    return (
      <>
        {auth.data.map((d) => {
          // console.log("my new dd: ", d);
          const asName = () => {
            const m = d.account_actions.find(
              (person) => person.title === "Account Aproved"
            );
            const x = d.account_actions.find(
              (person) => person.title === "Account Breached"
            );
            const r = d.account_actions.find(
              (person) => person.title === "Account Rejected"
            );

            if (m && act.a2 === "active") {
              return m.user.first_name + " " + m.user.last_name;
            } else if (x && act.a3 === "active") {
              return x.user.first_name + " " + x.user.last_name;
            } else if (r && act.a4 === "active") {
              return r.user.first_name + " " + r.user.last_name;
            }
          };

          return (
            <>
              <tr className="font">
                <th> {d.id} </th>
                <th> {d.user.username} </th>
                <th>
                  {d.order?.product
                    ? `${d.order.product.category?.title} ${d.order.product.title}`
                    : `${d.order.category?.title || "پرو تریدر "} ${
                        d.order.product_id == "13"
                          ? "2.5k"
                          : formatToK(d.order.product_id)
                      }`}
                </th>
                <th>{d.order.server + "/" + d.order.platform}</th>
                {act.a4 !== "active" ? (
                  <>
                    <th style={{ textAlign: "left", direction: "ltr" }}>
                      {" "}
                      {d.login}{" "}
                    </th>
                    <th
                      style={{ textAlign: "left", direction: "ltr" }}
                      className="transId"
                    >
                      {d.investor_password}
                    </th>
                  </>
                ) : null}
                <th> {translator(d.type)} </th>
                <th> {d.created_at} </th>
                <th> {d.updated_at} </th>
                {act.a1 === "active" || act.a5 === "active" ? null : (
                  <th> {asName()} </th>
                )}
                {!btn.includes(d.id) ? (
                  <th className="lastTh">
                    <MdAdsClick
                      onClick={() => {
                        if (act.a4 === "active") {
                          setModMod(true);
                          setId(d);
                          setModal(true);
                        } else {
                          if (act.a1 === "active") {
                            if (!m.includes("pass-requests-verify")) {
                              load(true);
                              axios
                                .post(
                                  url +
                                    "api/v1/panel/accounts/" +
                                    d.id +
                                    "/pick",
                                  {},
                                  {
                                    headers: {
                                      Authorization: `bearer ${Cookies.get(
                                        "token"
                                      )}`,
                                    },
                                  }
                                )
                                .then((res) => {
                                  load(false);
                                  if (res.data.success) {
                                    NotificationManager.success(
                                      "",
                                      "انتخاب شد",
                                      2000
                                    );
                                    setId(d);
                                    setModal(true);
                                  } else {
                                    NotificationManager.error(
                                      "قبلا توسط شخص دیگری انتخاب شده",
                                      "انتخاب نشد",
                                      4000
                                    );
                                  }
                                })
                                .catch((er) => {
                                  load(false);
                                  NotificationManager.error(
                                    "قبلا توسط شخص دیگری انتخاب شده",
                                    "انتخاب نشد",
                                    4000
                                  );
                                });
                            } else {
                              setId(d);
                              setModal(true);
                            }
                          } else if (act.a2 || act.a6) {
                            load(true);
                            axios
                              .get(
                                url + "api/v1/panel/change_account_details",
                                {
                                  headers: {
                                    Authorization: `bearer ${Cookies.get(
                                      "token"
                                    )}`,
                                  },
                                }
                              )
                              .then((res) => {
                                setChange(res.data.brokers);
                                load(false);
                                setId(d);
                                setModal(true);
                                console.log(res.data);
                              })
                              .catch((err) => {
                                load(false);
                                console.log(err);
                              });
                          } else {
                            setId(d);
                            setModal(true);
                          }
                        }
                      }}
                    />
                  </th>
                ) : null}
                <th
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MdExpandMore
                    className={`expand-icon ${
                      expandedRow === d.id ? "rotated" : ""
                    }`}
                    onClick={() => {
                      setExpandedRow(expandedRow === d.id ? null : d.id);
                      console.log("mu new d", d);
                      console.log("my expandeded rowww", expandedRow);
                    }}
                  />
                </th>
              </tr>
              {expandedRow === d.id && (
                <tr className="expanded-row">
                  <td colSpan="12">
                    <div
                      className="details"
                      style={{ display: "flex", gap: "1rem" }}
                    >
                      {/* Find and display the first valid image */}
                      {d.account_actions.find(
                        (action) => action.image !== null
                      ) && (
                        <>
                          <div
                            className="image-container"
                            style={{
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {(() => {
                              const actionWithImage = d.account_actions.find(
                                (action) => action.image !== null
                              );

                              const imageSrc = actionWithImage
                                ? `${url}storage${actionWithImage.image}`
                                : null;

                              console.log("my srccc:::", imageSrc);

                              return imageSrc ? (
                                <img
                                  src={imageSrc}
                                  alt="تصویر"
                                  style={{
                                    width: "80px", // تصویر کوچک‌تر
                                    height: "80px", // تصویر کوچک‌تر
                                    borderRadius: "50%", // گرد کردن تصویر
                                    cursor: "pointer",
                                  }}
                                  onClick={() => openModal(imageSrc)} // باز کردن مودال هنگام کلیک
                                />
                              ) : null;
                            })()}
                          </div>

                          {/* Modal for displaying the enlarged image */}
                          {isModalOpen && (
                            <div
                              className="modal-overlay"
                              style={{
                                position: "fixed",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.8)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: 1000,
                              }}
                              onClick={closeModal} // بستن مودال هنگام کلیک روی پس‌زمینه
                            >
                              <img
                                src={modalImage}
                                alt="Enlarged"
                                style={{
                                  maxWidth: "90%",
                                  maxHeight: "90%",
                                  borderRadius: "10px",
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}

                      {/* Table to display account actions */}
                      <table
                        className="actions-table"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th>نوع</th>
                            <th>عنوان</th>
                            <th>توضیحات</th>
                            <th>نوع کاربر</th>
                            <th>توسط</th>

                            <th>تاریخ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {d.account_actions.map((action) => {
                            // Translate type and user_type
                            const translatedType = translateType(action.type);
                            const translatedUserType = translateUserType(
                              action.user_type
                            );

                            return (
                              <tr key={action.id}>
                                <td>{translatedType}</td>
                                <td>{translateTitle(action.title)}</td>{" "}
                                {/* ترجمه عنوان */}
                                <td>
                                  {translateDescription(action.description)}
                                </td>{" "}
                                {/* ترجمه توضیحات */}
                                <td>{translatedUserType}</td>
                                <td>
                                  {action.user.first_name}{" "}
                                  {action.user.last_name}
                                </td>
                                <td>{formatDateTime(action.created_at)}</td>{" "}
                                {/* فرمت تاریخ */}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              )}
            </>
          );
        })}
        {(act.a2 === "active" || act.a3 === "active" || act.a7 === "active") &&
          hasPermission("accounts_export_excel") && (
            <button
              className="btn"
              onClick={downloadExcel}
              // style={{ padding: "0px", height: "2rem", margin: "10px" }}
              style={{
                padding: "0px 1rem",
                height: "2rem",
                margin: "10px",
              }}
            >
              دریافت فایل اکسل
            </button>
          )}
      </>
    );
  };
  const insides = () => {
    if (Object.keys(id).length !== 0) {
      return (
        <>
          <h6 className="bB p5"> اطلاعات حساب : </h6>
          <div className="orderDetail bb">
            <div className="fl"> نام کامل </div>
            <div>
              {" "}
              {id.user.first_name + " " + id.user.last_name}{" "}
              <FaCopy
                onClick={() => {
                  navigator.clipboard.writeText(
                    id.user.first_name + " " + id.user.last_name
                  );
                }}
                style={{
                  verticalAlign: "middle",
                  fontSize: "25px",
                  marginRight: "10px",
                }}
              />
            </div>
          </div>
          <div className="orderDetail bb">
            <div className="fl"> ایمیل </div>
            <div>
              {" "}
              {id.user.email}{" "}
              <FaCopy
                onClick={() => {
                  navigator.clipboard.writeText(id.user.email);
                }}
                style={{
                  verticalAlign: "middle",
                  fontSize: "25px",
                  marginRight: "10px",
                }}
              />{" "}
            </div>
          </div>
          <div className="orderDetail bb">
            <div className="fl"> شماره همراه </div>
            <div>
              {" "}
              {id.user.phone_number}{" "}
              <FaCopy
                onClick={() => {
                  navigator.clipboard.writeText(id.user.phone_number);
                }}
                style={{
                  verticalAlign: "middle",
                  fontSize: "25px",
                  marginRight: "10px",
                }}
              />{" "}
            </div>
          </div>
          <div className="orderDetail bb">
            <div className="fl"> نوع حساب </div>
            <div>
              {" "}
              {/* {id.order.product.category.title}{" "} */}
              <span>
                {id.order?.product
                  ? id.order.product.category?.title
                  : id.order.product_id === "13"
                  ? "pro trader"
                  : id.order.category?.title}
              </span>
              <FaCopy
                // onClick={() => {
                //   navigator.clipboard
                //     .writeText
                //     (id.order.product.category.title)
                //     ;
                // }}
                onClick={() => {
                  if (id.order?.product) {
                    navigator.clipboard.writeText(
                      id.order.product.category.title
                    );
                    NotificationManager.success("متن با موفقیت کپی شد!");
                  } else {
                    navigator.clipboard.writeText(id.order.category.title);
                    NotificationManager.success("متن با موفقیت کپی شد!");
                  }
                }}
                style={{
                  verticalAlign: "middle",
                  fontSize: "25px",
                  marginRight: "10px",
                }}
              />{" "}
            </div>
          </div>
          <div className="orderDetail bb">
            <div className="fl"> حساب </div>
            {/* <div>
              {" "}
              {id.order.product.title}{" "}
              <FaCopy
                onClick={() => {
                  navigator.clipboard.writeText(id.order.product.title);
                }}
                style={{
                  verticalAlign: "middle",
                  fontSize: "25px",
                  marginRight: "10px",
                }}
              />{" "}
            </div> */}
            <div>
              {id.order?.product ? (
                <>
                  {id.order.product.title}{" "}
                  <FaCopy
                    onClick={() => {
                      navigator.clipboard.writeText(id.order.product.title);
                      NotificationManager.success("متن با موفقیت کپی شد!");
                    }}
                    style={{
                      verticalAlign: "middle",
                      fontSize: "25px",
                      marginRight: "10px",
                    }}
                  />
                </>
              ) : (
                <>
                  {id.order?.product_id === "13"
                    ? "2.5k"
                    : formatToK(id.order?.product_id)}{" "}
                  <FaCopy
                    onClick={() => {
                      navigator.clipboard.writeText(
                        formatToK(id.order.product_id)
                      );
                      NotificationManager.success("متن با موفقیت کپی شد!");
                    }}
                    style={{
                      verticalAlign: "middle",
                      fontSize: "25px",
                      marginRight: "10px",
                    }}
                  />
                </>
                // <span style={{ color: "gray" }}>اطلاعات محصول موجود نیست</span>
              )}
            </div>
          </div>
          <div className="orderDetail bb">
            <div className="fl"> بروکر </div>
            <div>
              {" "}
              {id.order.broker && id.order.broker.name}{" "}
              <FaCopy
                onClick={() => {
                  navigator.clipboard.writeText(id.order.broker.name);
                }}
                style={{
                  verticalAlign: "middle",
                  fontSize: "25px",
                  marginRight: "10px",
                }}
              />{" "}
            </div>
          </div>
          <div className="orderDetail bb">
            <div className="fl"> پلتفرم </div>
            <div>
              {" "}
              {id.order.platform}{" "}
              <FaCopy
                onClick={() => {
                  navigator.clipboard.writeText(id.order.platform);
                }}
                style={{
                  verticalAlign: "middle",
                  fontSize: "25px",
                  marginRight: "10px",
                }}
              />{" "}
            </div>
          </div>

          <div className="orderDetail bb">
            <div className="fl"> سرور </div>
            <div>
              {" "}
              {id.order.server}{" "}
              <FaCopy
                onClick={() => {
                  navigator.clipboard.writeText(id.order.server);
                }}
                style={{
                  verticalAlign: "middle",
                  fontSize: "25px",
                  marginRight: "10px",
                }}
              />
            </div>
          </div>
          <div className="orderDetail bb">
            <div className="fl"> مرحله </div>
            <div> {translator(id.type)} </div>
          </div>
          {id.related_to !== null ? (
            <div className="orderDetail bb">
              <div className="fl" style={{ color: "#ffeb3b" }}>
                {" "}
                مرحله قبلی{" "}
              </div>
              <div style={{ color: "#ffeb3b" }}>
                {" "}
                {id.related_to.login}{" "}
                <FaCopy
                  onClick={() => {
                    navigator.clipboard.writeText(id.related_to.login);
                  }}
                  style={{
                    verticalAlign: "middle",
                    fontSize: "25px",
                    marginRight: "10px",
                  }}
                />{" "}
              </div>
            </div>
          ) : null}
          <div className="orderDetail bb">
            <div className="fl"> ورود </div>
            <div>
              {" "}
              {id.login}{" "}
              <FaCopy
                onClick={() => {
                  navigator.clipboard.writeText(id.login);
                }}
                style={{
                  verticalAlign: "middle",
                  fontSize: "25px",
                  marginRight: "10px",
                }}
              />{" "}
            </div>
          </div>
          <div className="orderDetail bb">
            <div className="fl"> گذرواژه </div>
            <div>
              {" "}
              {id.investor_password}{" "}
              <FaCopy
                onClick={() => {
                  navigator.clipboard.writeText(id.investor_password);
                }}
                style={{
                  verticalAlign: "middle",
                  fontSize: "25px",
                  marginRight: "10px",
                }}
              />{" "}
            </div>
          </div>
          <div className="orderDetail ">
            <div className="fl"> تلگرام آیدی </div>
            <div>
              {" "}
              {id.user.telegram_id}{" "}
              <FaCopy
                onClick={() => {
                  navigator.clipboard.writeText(id.user.telegram_id);
                }}
                style={{
                  verticalAlign: "middle",
                  fontSize: "25px",
                  marginRight: "10px",
                }}
              />{" "}
            </div>
          </div>
          {act.a6 === "active" ? (
            <>
              <div className="orderDetail ">
                <div className="fl"> تاریخ شروع </div>
                <div>
                  {" "}
                  {id.start_date}{" "}
                  <FaCopy
                    onClick={() => {
                      navigator.clipboard.writeText(id.start_date);
                    }}
                    style={{
                      verticalAlign: "middle",
                      fontSize: "25px",
                      marginRight: "10px",
                      width: "166px",
                    }}
                  />{" "}
                </div>
              </div>
              <div className="orderDetail ">
                <div className="fl"> تاریخ پایان </div>
                <div>
                  {" "}
                  {id.end_date}{" "}
                  <FaCopy
                    onClick={() => {
                      navigator.clipboard.writeText(id.end_date);
                    }}
                    style={{
                      verticalAlign: "middle",
                      fontSize: "25px",
                      marginRight: "10px",
                    }}
                  />{" "}
                </div>
              </div>
            </>
          ) : null}
          {console.log("id", id)}
          {(act.a2 === "active" || act.a7 === "active") &&
            (id.order.broker.id == 1) && (
              <button
                onClick={async () => {
                  load(true)
                  try {
                    const response = await axios.post(
                      url+`api/v1/panel/account/${id.id}/axi_revival`, // آدرس API
                      { }, // داده‌های ارسال
                      {
                        headers: {
                          Authorization: `bearer ${Cookies.get("token")}`,
                        },
                      }
                    );
                    NotificationManager.success('با موفقیت')
                    console.log("Response:", response.data); // مدیریت پاسخ موفق
                    load(false)
                  } catch (error) {
                    console.error("Error:", error); // مدیریت خطا
                    NotificationManager.warning("Error:", error)
                    load(false)
                  }
                }}
                className="logBtn mt "
                style={{
                  width:'100%'
                }}
              >
                تمدید axi
              </button>
            )}
        </>
      );
    }
  };
  const onSearch = () => {
    if (searchTerm.length > 2) {
      return "&&query=" + searchTerm;
    } else {
      return "";
    }
  };
  const xolShow = () => {
    if (xol === 1) {
      return (
        <>
          <textarea
            className="raddWhy"
            value={radd}
            onChange={(e) => {
              setRadd(e.target.value);
            }}
            placeholder="در صورت رد علت رد را وارد نمایید"
          />
          <div className="closeModal">
            <button
              style={{ width: "100%" }}
              className="btnreply mt"
              onClick={() => {
                if (radd === "") {
                  NotificationManager.error(
                    "علت رد خالی می باشد",
                    "رد حساب انجام نشد",
                    5000
                  );
                } else {
                  load(true);
                  axios
                    .post(
                      url + "api/v1/panel/accounts/action",
                      {
                        account_id: id.id,
                        status: 2,
                        description: radd,
                        title: "Account Rejected",
                        type: "AccountAction",
                      },
                      {
                        headers: {
                          Authorization: `bearer ${Cookies.get("token")}`,
                        },
                      }
                    )
                    .then((res) => {
                      load(false);
                      NotificationManager.success(
                        "حساب کاربر با موفقیت رد شد",
                        "انجام شد",
                        5000
                      );
                      setModal(false);
                      setBtn((e) => [...e, id.id]);
                      setRadd("");
                    })
                    .catch((err) => {
                      load(false);
                      NotificationManager.error(
                        "تایید حساب انجام نشد دوباره سعی کنید",
                        "انجام نشد",
                        5000
                      );
                      console.log(err);
                      setRadd("");
                    });
                }
              }}
            >
              {" "}
              رد نهایی حساب{" "}
            </button>
          </div>
        </>
      );
    } else if (xol === 2) {
      return (
        <>
          {act.a1 === "active" || act.a5 === "active" ? (
            <div className="orderDetail ">
              <div className="fl"> تاریخ شروع : </div>
              <input
                onChange={(e) => {
                  setStart(e.target.value);
                }}
                type="date"
              />
            </div>
          ) : null}
          <div className="closeModal">
            <button
              style={{ width: "100%" }}
              className="btnreply mt"
              onClick={() => {
                if (startDate !== "") {
                  load(true);
                  axios
                    .post(
                      url + "api/v1/panel/accounts/action",
                      {
                        start_date: startDate,
                        account_id: id.id,
                        status: 1,
                        description: "Aproved",
                        type: "AccountAction",
                        title: "Account Aproved",
                      },
                      {
                        headers: {
                          Authorization: `bearer ${Cookies.get("token")}`,
                        },
                      }
                    )
                    .then((res) => {
                      load(false);
                      NotificationManager.success(
                        "حساب با موفقیت تایید شد",
                        "انجام شد",
                        5000
                      );
                      setModal(false);
                      setBtn((e) => [...e, id.id]);
                      setRadd("");
                      setStart("");
                    })
                    .catch((err) => {
                      NotificationManager.error(
                        "تایید حساب انجام نشد دوباره سعی کنید",
                        "انجام نشد",
                        5000
                      );
                      load(false);
                      console.log(err);
                      setRadd("");
                    });
                } else {
                  NotificationManager.error(
                    "تاریخ شروع وارد نشده",
                    "نشد",
                    4000
                  );
                }

                setRadd("");
              }}
            >
              {" "}
              تایید نهایی حساب{" "}
            </button>
          </div>
        </>
      );
    }
  };
  const modalBtnShow = () => {
    if (act.a1 === "active" || act.a5 === "active") {
      return (
        <>
          <div style={{ marginBottom: "20px" }} className="closeModal">
            <button
              className="btnreply "
              onClick={() => {
                setXol(1);
              }}
            >
              {" "}
              رد حساب{" "}
            </button>
            <button
              className="btnreply"
              onClick={() => {
                setXol(2);
              }}
            >
              {" "}
              تایید حساب{" "}
            </button>
          </div>
          {xolShow()}
        </>
      );
    } else if (act.a2 === "active" || act.a6 === "active") {
      return (
        <>
          {" "}
          <div style={{ marginBottom: "20px" }} className="closeModal">
            <button
              className="btnreply mt"
              onClick={() => {
                if (act.a2 === "active" || act.a6 === "active") {
                  setXol(5);
                } else {
                  setModal(false);
                }
              }}
            >
              {" "}
              {act.a2 === "active" || act.a6 === "active"
                ? "تغییر حساب"
                : "خروج"}{" "}
            </button>
            <button
              className="btnreply mt"
              onClick={() => {
                setXol(4);
              }}
            >
              {" "}
              اکانت نقض شده{" "}
            </button>
          </div>
          {xol === 4 ? (
            <>
              <textarea
                className="raddWhy"
                value={radd}
                onChange={(e) => {
                  setRadd(e.target.value);
                }}
                placeholder="توضیحات و علت نقض را وارد نمایید"
              />
              <button
                onClick={() => {
                  if (radd === "") {
                    NotificationManager.error(
                      "علت نقض خالی می باشد",
                      "نقض حساب انجام نشد",
                      5000
                    );
                  } else {
                    load(true);
                    axios
                      .post(
                        url + "api/v1/panel/accounts/action",
                        {
                          account_id: id.id,
                          status: 3,
                          description: radd,
                          title: "Account Breached",
                          type: "AccountAction",
                        },
                        {
                          headers: {
                            Authorization: `bearer ${Cookies.get("token")}`,
                          },
                        }
                      )
                      .then((res) => {
                        load(false);
                        NotificationManager.success(
                          "حساب کاربر با موفقیت نقض شد",
                          "انجام شد",
                          5000
                        );
                        setModal(false);
                        setBtn((e) => [...e, id.id]);

                        setRadd("");
                      })
                      .catch((err) => {
                        load(false);
                        NotificationManager.error(
                          "نقض حساب انجام نشد دوباره سعی کنید",
                          "انجام نشد",
                          5000
                        );
                        console.log(err);
                        setRadd("");
                      });
                  }
                }}
                style={{ width: "100%" }}
                className="btnreply mt"
              >
                تایید نهایی نقض اکانت
              </button>
            </>
          ) : null}
          {xol === 5 ? (
            <>
              <div className="flex1">
                <div className="gg"> بروکر </div>
                <select
                  style={{ width: "166px" }}
                  value={brok}
                  onChange={(e) => {
                    setBroker(e.target.value);
                    if (e.target.value === "") {
                      setServers([]);
                      serSer([""]);
                    } else {
                      setServers(
                        change.find((m) => m.id == e.target.value).servers
                      );
                    }
                  }}
                  type="text"
                  className="loginInput"
                >
                  <option style={{ color: "black" }} value="">
                    {" "}
                    بروکر را انتخاب نمایید{" "}
                  </option>
                  {change.map((d) => {
                    return (
                      <option
                        style={{ color: "black" }}
                        key={d.id}
                        value={d.id}
                      >
                        {" "}
                        {d.name}{" "}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="flex1">
                <div className="gg"> سرور </div>
                <select
                  style={{ width: "166px" }}
                  value={ser[0]}
                  onChange={(e) => {
                    serSer([
                      e.target.value,
                      servers.find((m) => m.title === e.target.value)
                        .server_type,
                    ]);
                  }}
                  type="text"
                  className="loginInput"
                >
                  <option value=""> سرور را انتخاب نمایید </option>
                  {servers.map((d) => {
                    return (
                      <option
                        style={{ color: "black" }}
                        key={d.id}
                        value={d.title}
                      >
                        {" "}
                        {d.title + " | " + d.server_type}{" "}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="flex1">
                <div className="gg">
                  {" "}
                  <RiShieldUserFill /> شماره لاگین{" "}
                </div>
                <input
                  value={log}
                  onChange={(e) => {
                    setLog(e.target.value);
                  }}
                  type="text"
                  className="loginInput"
                />
              </div>
              <div className="flex1">
                <div className="gg">
                  {" "}
                  <RiLockPasswordFill /> رمز عبور{" "}
                </div>
                <input
                  type="text"
                  value={pass}
                  onChange={(e) => {
                    setPass(e.target.value);
                  }}
                  className="loginInput"
                />
              </div>
              <div className="flex1">
                <div className="gg">
                  {" "}
                  <RiLockPasswordFill /> INVESTOR{" "}
                </div>
                <input
                  type="text"
                  value={passInv}
                  onChange={(e) => {
                    setPassInv(e.target.value);
                  }}
                  className="loginInput"
                />
              </div>
              <div className="flex1">
                <div className="gg"> تاریخ شروع </div>
                <input
                  style={{ width: "166px" }}
                  type="date"
                  value={time}
                  onChange={(e) => {
                    setTime(e.target.value);
                  }}
                  className="loginInput"
                />
              </div>
              <textarea
                className="raddWhy"
                value={radd}
                onChange={(e) => {
                  setRadd(e.target.value);
                }}
                placeholder="توضیحات را وارد نمایید"
              />
              <button
                style={{ width: "100%" }}
                className="btnreply mt"
                onClick={() => {
                  if (
                    pass !== "" &&
                    passInv !== "" &&
                    log !== "" &&
                    brok !== "" &&
                    ser[0] !== "" &&
                    radd !== ""
                  ) {
                    load(true);
                    axios
                      .post(
                        url + "api/v1/panel/accounts/" + id.id + "/change",
                        {
                          login: log,
                          password: pass,
                          investor_password: passInv,
                          platform: ser[1],
                          broker: brok,
                          server: ser[0],
                          start_date: time,
                          description: radd,
                        },
                        {
                          headers: {
                            Authorization: `bearer ${Cookies.get("token")}`,
                          },
                        }
                      )
                      .then((res) => {
                        load(false);
                        NotificationManager.success(
                          "حساب کاربر تغییر یافت",
                          "انجام شد",
                          5000
                        );
                        setModal(false);
                        console.log(res.data);
                      })
                      .catch((err) => {
                        load(false);
                        NotificationManager.error(
                          " دوباره سعی کنید",
                          "انجام نشد",
                          5000
                        );
                        console.log(err);
                      });
                  } else {
                    NotificationManager.error(
                      "لطفا همه موارد را پر نمایید",
                      "انجام نشد",
                      4000
                    );
                  }
                }}
              >
                تغییر حساب
              </button>
            </>
          ) : null}
        </>
      );
    } else if (act.a3 === "active") {
      if (m.includes("reactive-account")) {
        return (
          <div className="closeModal">
            <button
              className="btnreply mt"
              onClick={() => {
                setModal(false);
              }}
            >
              {" "}
              خروج{" "}
            </button>
            <button
              className="btnreply mt"
              onClick={() => {
                load(true);
                axios
                  .post(
                    url + "api/v1/panel/accounts/action",
                    {
                      account_id: id.id,
                      status: 1,
                      description: "Re-Activated Account",
                      title: "Account Activated",
                      type: "AccountAction",
                    },
                    {
                      headers: {
                        Authorization: `bearer ${Cookies.get("token")}`,
                      },
                    }
                  )
                  .then((res) => {
                    load(false);
                    NotificationManager.success(
                      "حساب کاربر با موفقیت نقض شد",
                      "انجام شد",
                      5000
                    );
                    setModal(false);
                    setBtn((e) => [...e, id.id]);
                    setRadd("");
                  })
                  .catch((err) => {
                    load(false);
                    NotificationManager.error(
                      "نقض حساب انجام نشد دوباره سعی کنید",
                      "انجام نشد",
                      5000
                    );
                    console.log(err);
                    setRadd("");
                  });
              }}
            >
              {" "}
              فعال کردن اکانت{" "}
            </button>
          </div>
        );
      }
    }
  };
  // const liShows = () => {
  //   return (
  //     <>
  //       {auth.links.map((dd) => {
  //         let c = () => {
  //           if (dd.active) {
  //             return "ac";
  //           } else {
  //             return null;
  //           }
  //         };
  //         if (dd.label !== "&laquo; Previous" && dd.label !== "Next &raquo;") {
  //           return (
  //             <li
  //               key={dd.label}
  //               className={"page__numbers" + " " + c()}
  //               onClick={() => {
  //                 if (dd.url !== null) {
  //                   load(true);
  //                   console.log(dd.url + onSearch() + tab());

  //                   axios
  //                     .get(dd.url + onSearch() + tab(), {
  //                       headers: {
  //                         Authorization: `bearer ${Cookies.get("token")}`,
  //                       },
  //                     })
  //                     .then((res) => {
  //                       load(false);
  //                       console.log(res.data);
  //                       if (onSearch() === "") {
  //                         setAuth(res.data);
  //                       } else {
  //                         setAuth(res.data.results);
  //                       }
  //                     })
  //                     .catch((err) => {
  //                       load(false);
  //                       console.log(err);
  //                     });
  //                 }
  //               }}
  //             >
  //               {" "}
  //               {dd.label}{" "}
  //             </li>
  //           );
  //         }
  //       })}
  //     </>
  //   );
  // };
  const liShows = () => {
    return (
      <>
        {auth.links.map((dd) => {
          let c = () => (dd.active ? "ac" : null);

          if (dd.label !== "&laquo; Previous" && dd.label !== "Next &raquo;") {
            return (
              <li
                key={dd.label}
                className={`page__numbers ${c()}`}
                onClick={() => {
                  console.log(
                    "my query : ",
                    `${
                      dd.url
                    }${tab()}${onSearch()}&start_date=${startDateFilter}&end_date=${endDateFilter}`
                  );
                  if (dd.url !== null) {
                    load(true);
                    axios
                      .get(
                        `${
                          dd.url
                        }${tab()}${onSearch()}&start_date=${startDateFilter}&end_date=${endDateFilter}`,
                        {
                          headers: {
                            Authorization: `bearer ${Cookies.get("token")}`,
                          },
                        }
                      )
                      .then((res) => {
                        load(false);
                        if (onSearch() === "") {
                          setAuth(res.data);
                        } else {
                          setAuth(res.data.results);
                        }
                      })
                      .catch((err) => {
                        load(false);
                        console.error(err);
                      });
                  }
                }}
              >
                {dd.label}
              </li>
            );
          }
        })}
      </>
    );
  };

  return (
    <>
      <NotificationContainer />
      <Success
        show={modal}
        closeModal={() => {
          setModal(false);
          setXol(0);
          setRadd("");
          setBtn([]);
          setModMod(false);
          setBroker("");
          setServers([""]);
        }}
        name="inside23ghjda"
      >
        {!modmod ? (
          <div style={{ padding: "10px" }}>
            <div className="varizCon f12">{insides()}</div>
            <p className="mt f12">
              {" "}
              با توجه به بررسی های انجام شده آیا این حساب دارای اعتبار می باشد ؟
            </p>

            {modalBtnShow()}
          </div>
        ) : (
          <>
            {id.account_actions.map((d) => {
              if (d.title === "Account Rejected") {
                return (
                  <div key={d.title} className="des">
                    <p className="why">
                      {" "}
                      علت رد توسط {d.user.first_name +
                        " " +
                        d.user.last_name} :{" "}
                    </p>
                    <p> {d.description} </p>
                  </div>
                );
              }
            })}
          </>
        )}

        <button
          onClick={() => {
            if (user.dataes.roles[0].name === "admin") {
              load(true);
              axios
                .post(
                  url + "api/v1/auth/support/login",
                  { email: id.user.username },
                  {
                    headers: {
                      Authorization: `bearer ${Cookies.get("token")}`,
                    },
                  }
                )
                .then((res) => {
                  load(false);
                  Cookies.set("support_token", Cookies.get("token"));
                  Cookies.set("token", res.data.access_token);
                  Cookies.set("username", id.user.username);
                  window.location.reload();
                })
                .catch((err) => {
                  load(false);
                  console.log(err);
                });
            } else {
              load(true);
              axios
                .post(
                  url + "api/v1/panel/admin/login",
                  { id: id.user.id },
                  {
                    headers: {
                      Authorization: `bearer ${Cookies.get("token")}`,
                    },
                  }
                )
                .then((res) => {
                  load(false);
                  Cookies.set("support_token", Cookies.get("token"));
                  Cookies.set("token", res.data.access_token);
                  Cookies.set("username", id.user.username);
                  window.location.reload();
                })
                .catch((err) => {
                  load(false);
                  console.log(err);
                });
            }
          }}
          className="logBtn mt"
        >
          {" "}
          ورود به پنل کاربری{" "}
        </button>
      </Success>

      <div className="app-header-navigation">
        <div className="tabs">
          <a
            onClick={() => {
              setAct({
                a1: "active",
                a2: "",
                a3: "",
                a4: "",
                a5: "",
                a6: "",
                a7: "",
              });
              load(true);
              axios
                .get(url + "api/v1/panel/admin/accounts/show?type=inactive", {
                  headers: {
                    Authorization: `bearer ${Cookies.get("token")}`,
                  },
                })
                .then((res) => {
                  load(false);
                  setAuth(res.data);
                })
                .catch((err) => {
                  load(false);
                  console.log(err);
                });
            }}
            className={act.a1}
          >
            غیر فعال
          </a>
          <a
            onClick={() => {
              setAct({
                a1: "",
                a2: "",
                a3: "",
                a4: "",
                a5: "active",
                a6: "",
                a7: "",
              });
              load(true);
              axios
                .get(url + "api/v1/panel/admin/accounts/show/me", {
                  headers: {
                    Authorization: `bearer ${Cookies.get("token")}`,
                  },
                })
                .then((res) => {
                  load(false);
                  setAuth(res.data);
                  console.log(res.data);
                })
                .catch((err) => {
                  load(false);
                  console.log(err);
                });
            }}
            className={act.a5}
          >
            انتخاب شده
          </a>

          <a
            onClick={() => {
              setAct({
                a1: "",
                a2: "active",
                a3: "",
                a4: "",
                a5: "",
                a6: "",
                a7: "",
              });
              load(true);
              axios
                .get(url + "api/v1/panel/admin/accounts/show?type=active", {
                  headers: {
                    Authorization: `bearer ${Cookies.get("token")}`,
                  },
                })
                .then((res) => {
                  load(false);
                  setAuth(res.data);
                  console.log(res.data);
                })
                .catch((err) => {
                  load(false);
                  console.log(err);
                });
            }}
            className={act.a2}
          >
            فعال
          </a>

          <a
            onClick={() => {
              setAct({
                a1: "",
                a2: "",
                a3: "active",
                a4: "",
                a5: "",
                a6: "",
                a7: "",
              });
              load(true);
              axios
                .get(url + "api/v1/panel/admin/accounts/show?type=delete", {
                  headers: {
                    Authorization: `bearer ${Cookies.get("token")}`,
                  },
                })
                .then((res) => {
                  load(false);
                  setAuth(res.data);
                })
                .catch((err) => {
                  load(false);
                });
            }}
            className={act.a3}
          >
            منسوخ شده
          </a>
          <a
            onClick={() => {
              setAct({
                a1: "",
                a2: "",
                a3: "",
                a4: "active",
                a5: "",
                a6: "",
                a7: "",
              });
              setXol(5);
              load(true);
              axios
                .get(url + "api/v1/panel/admin/accounts/show?type=rejected", {
                  headers: {
                    Authorization: `bearer ${Cookies.get("token")}`,
                  },
                })
                .then((res) => {
                  load(false);
                  setAuth(res.data);
                })
                .catch((err) => {
                  load(false);
                });
            }}
            className={act.a4}
          >
            رد شده
          </a>
          <a
            onClick={() => {
              setAct({
                a1: "",
                a2: "",
                a3: "",
                a4: "",
                a5: "",
                a6: "active",
                a7: "",
              });
              setXol(5);
              load(true);
              axios
                .get(url + "api/v1/panel/admin/accounts/axi_revival", {
                  headers: {
                    Authorization: `bearer ${Cookies.get("token")}`,
                  },
                })
                .then((res) => {
                  load(false);
                  setAuth(res.data);
                })
                .catch((err) => {
                  load(false);
                });
            }}
            className={act.a6}
          >
            تمدید AXI
          </a>
          {user.dataes.permissions.find(
            (e) => e.name === "read-real-accounts"
          ) && (
            <a
              onClick={() => {
                setAct({
                  a1: "",
                  a2: "",
                  a3: "",
                  a4: "",
                  a5: "",
                  a6: "",
                  a7: "active",
                });
                setXol(5);
                load(true);
                axios
                  .get(url + "api/v1/panel/admin/accounts/real", {
                    headers: {
                      Authorization: `bearer ${Cookies.get("token")}`,
                    },
                  })
                  .then((res) => {
                    load(false);
                    setAuth(res.data);
                  })
                  .catch((err) => {
                    console.log(err);

                    load(false);
                  });
              }}
              className={act.a7}
            >
              REAL
            </a>
          )}
        </div>
        <div className="transAction">
          <div className="eachTicket">
            <p className="bb">
              با کلیک بر روی آیکون کلیک حساب کاربر را تایید یا رد نمایید :{" "}
            </p>
            {act.a5 !== "active" ? (
              <input
                value={searchTerm}
                style={{ width: "35%", margin: "10px" }}
                className="searchIn"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                type="text"
                placeholder="جستجو نام کاربری ..."
              />
            ) : null}

            {(act.a2 === "active" ||
              act.a3 === "active" ||
              act.a7 === "active") && (
              <div
                style={{ display: "flex", gap: "1rem", alignItems: "center" }}
              >
                <div style={{ display: "flex", gap: "1rem", margin: "10px" }}>
                  <label>
                    از تاریخ:
                    <input
                      style={{ width: "8rem" }}
                      className="searchIn"
                      type="date"
                      value={startDateFilter} /* تغییر نام به startDateFilter */
                      onChange={(e) =>
                        setStartDateFilter(e.target.value)
                      } /* به‌روزرسانی */
                    />
                  </label>
                  <label>
                    تا تاریخ:
                    <input
                      className="searchIn"
                      style={{ width: "8rem" }}
                      type="date"
                      value={endDateFilter} /* تغییر نام به endDateFilter */
                      onChange={(e) =>
                        setEndDateFilter(e.target.value)
                      } /* به‌روزرسانی */
                    />
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "0.2rem",
                    alignItems: "center",
                    margin: "10px",
                  }}
                >
                  <label htmlFor="step-select">انتخاب وضعیت: </label>
                  <select
                    id="step-select"
                    value={selectedValue}
                    onChange={handleSelectChange}
                    className="searchIn"
                    style={{
                      fontSize: "16px", // افزایش اندازه فونت
                      // padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",

                      width: "auto",
                    }}
                  >
                    <option value="" disabled>
                      انتخاب کنید...
                    </option>
                    <option style={{ color: "black" }} value="First Step">
                      First Step
                    </option>
                    <option style={{ color: "black" }} value="Second Step">
                      Second Step
                    </option>
                    <option style={{ color: "black" }} value="Pro Trader">
                      Pro Trader
                    </option>
                    <option style={{ color: "black" }} value="One Step">
                      One Step
                    </option>
                    <option style={{ color: "black" }} value="Real">
                      Real
                    </option>
                  </select>
                </div>

                <button
                  className="btn"
                  onClick={() => {
                    if (
                      !startDateFilter &&
                      !endDateFilter &&
                      !selectedValue &&
                      !balance
                    ) {
                      /* به‌روزرسانی نام state‌ها */
                      NotificationManager.warning(
                        "لطفا یکی از فیلتر ها را انتخاب نمایید"
                      );
                      return;
                    }
                    load(true);
                    console.log(
                      "my filter date query is : ",
                      `${url}api/v1/panel/admin/accounts/show?type=${
                        act.a2 === "active"
                          ? "active"
                          : act.a3 === "active"
                          ? "delete"
                          : "real"
                      }&start_date=${startDateFilter}&end_date=${endDateFilter}&account_type=${selectedValue}`
                    );
                    axios
                      .get(
                        `${url}api/v1/panel/admin/accounts/show?type=${
                          act.a2 === "active"
                            ? "active"
                            : act.a3 === "active"
                            ? "delete"
                            : "real"
                        }&start_date=${startDateFilter}&end_date=${endDateFilter}&account_type=${selectedValue}` /* به‌روزرسانی نام */,
                        {
                          headers: {
                            Authorization: `bearer ${Cookies.get("token")}`,
                          },
                        }
                      )
                      .then((res) => {
                        load(false);
                        setAuth(res.data);
                      })
                      .catch((err) => {
                        load(false);
                        console.log(err);
                      });
                  }}
                  style={{
                    padding: "0px 1rem",
                    height: "2rem",
                  }}
                >
                  اعمال فیلتر
                </button>
                <button
                  className="btn"
                  onClick={resetDate}
                  // style={{ margin: "10px" }}
                  style={{
                    padding: "0px 1rem",
                    height: "2rem",
                  }}
                >
                  لغو فیلتر
                </button>
              </div>
            )}

            <div className="table-responsive-md">
              <table border={1} className="table table-hover ">
                <thead>
                  <tr className="font">
                    <th> # </th>
                    <th> کاربر </th>
                    <th> محصول </th>
                    <th> سرور </th>
                    {act.a4 !== "active" ? (
                      <>
                        <th> لاگین </th>
                        <th> investor </th>
                      </>
                    ) : null}
                    <th> مرحله </th>
                    <th> تاریخ </th>
                    <th> بروزرسانی </th>
                    {act.a1 === "active" || act.a5 === "active" ? null : (
                      <th> بررسی توسط </th>
                    )}
                    {act.a4 !== "active" ? <th> </th> : null}
                    <th>عملیات</th>
                  </tr>
                </thead>
                <tbody>{authShow()}</tbody>
              </table>
              <ul class="page">
                <li
                  onClick={() => {
                    console.log(
                      "my query : ",
                      `${
                        auth.prev_page_url
                      }${tab()}${onSearch()}&start_date=${startDateFilter}&end_date=${endDateFilter}`
                    );
                    if (auth.prev_page_url !== null) {
                      load(true);
                      axios
                        .get(
                          `${
                            auth.prev_page_url
                          }${tab()}${onSearch()}&start_date=${startDateFilter}&end_date=${endDateFilter}`,
                          {
                            headers: {
                              Authorization: `bearer ${Cookies.get("token")}`,
                            },
                          }
                        )
                        .then((res) => {
                          load(false);
                          if (onSearch() === "") {
                            setAuth(res.data);
                          } else {
                            setAuth(res.data.results);
                          }
                        })
                        .catch((err) => {
                          load(false);
                          console.error(err);
                        });
                    }
                  }}
                  className="page__btn"
                >
                  <span class="material-icons">
                    <IoChevronBackCircle />
                  </span>
                </li>
                {liShows()}
                <li
                  onClick={() => {
                    console.log(
                      "my query : ",
                      `${
                        auth.next_page_url
                      }${tab()}${onSearch()}&start_date=${startDateFilter}&end_date=${endDateFilter}`
                    );
                    if (auth.next_page_url !== null) {
                      load(true);
                      axios
                        .get(
                          `${
                            auth.next_page_url
                          }${tab()}${onSearch()}&start_date=${startDateFilter}&end_date=${endDateFilter}`,
                          {
                            headers: {
                              Authorization: `bearer ${Cookies.get("token")}`,
                            },
                          }
                        )
                        .then((res) => {
                          load(false);
                          if (onSearch() === "") {
                            setAuth(res.data);
                          } else {
                            setAuth(res.data.results);
                          }
                        })
                        .catch((err) => {
                          load(false);
                          console.error(err);
                        });
                    }
                  }}
                  className="page__btn"
                >
                  <span class="material-icons">
                    <IoArrowForwardCircleSharp />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
