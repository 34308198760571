/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState , useContext } from 'react'
import "./Yekbar.css"
import logo from "../../photoes/logo.png"
import axios from 'axios';
import { IoMdBarcode } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import UserContext from "../UserContext/UserContext"
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import Cookies from 'js-cookie';
const Yekbar = (props) => {
 const navigate = useNavigate()
 const [yekNum , setYekNum] = useState("")
 const [time , setTime] = useState(0)
 const [barrasi , setBarrasi] = useState(true)
 const { login , load , url  } = useContext(UserContext);

const numChange = (e)=>{
	let pn = e.currentTarget.value
	if(isNaN(+pn)){
		e.currentTarget.value = ""
	}
	setYekNum(e.target.value)
}
 const barrasiShow = ()=>{
	let lastTime = ()=>{
		if(time !== 0){
			return time
		}else{
			return props.time
		}
	}
	if(barrasi){
		return <div className='flex'>
		<CountdownCircleTimer
		isPlaying
		duration={lastTime()}
		colors={['#fff', '#003e23', '#f05c03', '#f00']}
		colorsTime={[100, 55, 30, 0]}
		size={35}
		strokeWidth={3.5}
		onComplete={()=>{
			setBarrasi(false)
			setYekNum("")
		}}
		children={({ remainingTime }) => {
			const minutes = Math.floor(remainingTime / 60)
			const seconds = remainingTime % 60
		  
			return `${minutes}:${seconds}`
		  }}
		
	  >
		
	  </CountdownCircleTimer> 
	     <div className='barrasi' style={{marginRight:"5px"}} > بررسی کد  </div>
		</div>
	}else{
		return "ارسال مجدد"
	}
 }
  return (
    <div className={window.innerWidth < 800 ? "container2":"container1"}>
		<NotificationContainer/>
	<div className="screen">
		<div className="screen__content">
      <div className='logoCon'>
      <img className='logo' src={logo} alt=''/>
      </div> 
			<form onSubmit={(e)=>{e.preventDefault()}} className="login">
				<div className="login__field">
					<div className='ersal'> ارسال کد به {props.user[0]} </div>
					<input  value={yekNum} onChange={numChange} type="text" 
					className="login__input" placeholder=" کد یکبار مصرف "/>
				    <IoMdBarcode />
				</div>
				<button onClick={()=>{
					let user = props.user
					if(barrasi){
					  if(yekNum !== ""){
						load(true)				
					    let data = {"username": user[0] , "password" : user[1] , "code": yekNum}
					    axios.post(url+"api/v1/auth/verify",data ,).then(af=>{
						let acctok = af.data.access_token
						const now = new Date().getTime()
						Cookies.set('token', acctok, { expires: 7, secure: false });
						Cookies.set('time', now.toString(), { expires: 7, secure: false });
						axios.post(url+"api/v1/auth/me",{},
							{ headers: { "Authorization": `bearer ${af.data.access_token}` } }
							).then(res=>{
							login({dataes : res.data , at : acctok })
							load(false)
							navigate("/dashboard")
						    }).catch(err=>{
							load(false)
							NotificationManager.error(" عدم برقراری ارتباط با سرور ","ورود انجام نشد")
						    })
					    }).catch(er=>{
						load(false)
						console.log(er.response);
						if(er.response.data!==undefined && er.response.data.message === "Invalid Code" ){
							NotificationManager.error(" کد یکبار مصرف صحیح نمی باشد ","ورود انجام نشد")
						}else{

							NotificationManager.error(" عدم برقراری ارتباط با سرور ","ورود انجام نشد")
						}
					  })
						}else{
							NotificationManager.error(" کد وارد نشده است ","ورود انجام نشد")
						}
					}else{
						load(true)
						axios.post(url+"api/v1/auth/login",{"username": user[0] , "password" : user[1] , type: user[2]}).then(
							(res)=>{
								load(false)
								setBarrasi(true)
                                setTime(res.data.time)
								NotificationManager.success("رمز عبور یکبار مصرف مجدد ارسال شد ","ورود دو مرحله ای")
							}
						)
					}
				}} className="button login__submit">
					<span className="button__text"> {
					  barrasiShow()
						}</span>
					
				</button>
				{props.tfa?<button onClick={()=>{
                     let user = props.user
					 if(yekNum !== ""){
							load(true)				
					 let data = {"username": user[0] , "password" : user[1] ,
					 "code": yekNum , google2fa:"google2fa"}
					 axios.post(url+"api/v1/auth/verify",data ,).then(af=>{
						let acctok = af.data.access_token
						const now = new Date().getTime()
						Cookies.set('token', acctok, { expires: 7, secure: false });
						Cookies.set('time', now.toString(), { expires: 7, secure: false });
						axios.post(url+"api/v1/auth/me",{},
							{ headers: { "Authorization": `bearer ${af.data.access_token}` } }
							).then(res=>{
							login({dataes : res.data , at : acctok })
							load(false)
							navigate("/dashboard")
						}).catch(err=>{
							load(false)
							NotificationManager.error(" عدم برقراری ارتباط با سرور ","ورود انجام نشد")
						})
					  }).catch(er=>{
						load(false)
						console.log(er.response);
						if(er.response.data!==undefined && er.response.data.message === "Invalid Code" ){
							NotificationManager.error(" کد Google2Fa صحیح نمی باشد ","ورود انجام نشد")
						}else{

							NotificationManager.error(" عدم برقراری ارتباط با سرور ","ورود انجام نشد")
						}
					  })
						}else{
							NotificationManager.warning(" کد Google2Fa وارد نشده ","ورود انجام نشد")
						}
				}} className="button login__submit">
				 ورود با کد Google2Fa 
				</button>:null}				
			</form>
			<div className='forgotAndReg'>
        <a onClick={()=>{
			props.login()
			setTime(0)
		}} className='alog'> 
		تغییر شماره همراه /ایمیل
        </a>
      </div>
		</div>
		<div className="screen__background">
			
			<span className="screen__background__shape screen__background__shape3"></span>		
			<span className="screen__background__shape screen__background__shape2"></span>
			<span className="screen__background__shape screen__background__shape1"></span>
		</div>		
	</div>
</div>
  )
}

export default Yekbar