import React from "react";

function LeaderBoard({ competition }) {
  const prizePool = competition.prize_pool_distribution || [];

  // Define styles
  const tableStyle = {
    width: "100%",
    marginTop: "2rem",
    borderCollapse: "collapse",
    direction: "rtl",
    fontFamily: "'Vazir', sans-serif",
    backgroundColor: "#004d00", // Dark green
    borderRadius: "8px",
    overflow: "hidden",
  };

  const tableHeaderStyle = {
    background: "linear-gradient(90deg, #005500, #228B22)", // Dark to light green
    color: "#fff",
    fontWeight: "bold",
    fontSize: "1.2rem",
    textAlign: "center",
    borderBottom: "2px solid #333", // Darker line
  };

  const tableRowStyleEven = {
    backgroundColor: "#013220", // Darker green for even rows
    color: "#fff",
  };

  const tableRowStyleOdd = {
    backgroundColor: "#014d26", // Slightly lighter green for odd rows
    color: "#fff",
  };

  const tableCellHeaderStyle = {
    padding: "1rem",
    fontSize: "1.1rem",
    textAlign: "center",
  };

  const tableCellStyle = {
    padding: "1rem",
    fontSize: "1rem",
    textAlign: "center",
    color: "#fff",
  };

  const tableRowHoverStyle = {
    backgroundColor: "#02693e", // Lighter green for hover
    color: "#fff",
  };

  // Render rows
  const renderRows = () => {
    return prizePool.map((entry, index) => {
      const rank = Object.keys(entry)[0]; // Get the rank key
      const details = entry[rank]; // Get the details for the rank
      const rowStyle = index % 2 === 0 ? tableRowStyleEven : tableRowStyleOdd;

      return (
        <tr
          key={rank}
          style={rowStyle}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor =
              tableRowHoverStyle.backgroundColor)
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = rowStyle.backgroundColor)
          }
        >
          <td style={tableCellStyle}>{rank}</td>
          <td style={tableCellStyle}>{details["جایزه"]}</td>
        </tr>
      );
    });
  };

  return (
    <div>
      <h2 style={{ textAlign: "center", color: "#fff", marginBottom: "1rem" }}>
        جدول توزیع جوایز
      </h2>
      <table style={tableStyle}>
        <thead>
          <tr style={tableHeaderStyle}>
            <th style={tableCellHeaderStyle}>رتبه</th>
            <th style={tableCellHeaderStyle}>جایزه</th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
}

export default LeaderBoard;
