import { MdOutlineOndemandVideo } from "react-icons/md";
import styled from "styled-components";

/* eslint-disable react/prop-types */
const StyledContainer = styled.div`
  background-color: ${(props) =>
    props.$sender === "user"
      ? "var(--color-blue-200)"
      : "var(--color-grey-200)"};
  padding: 0.4rem 0.8rem 0.4rem;
  border-left: 3px solid var(--color-blue-500);
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-bottom: 0.8rem;
  width: 100%;
  min-width: 8rem;
  cursor: pointer;

  & .sender {
    color: var(--color-blue-500);
  }
`;

function getFileName(url) {
  // Split the URL by "/" and get the last part, which is the filename
  const parts = url.split("/");
  const fileName = parts.pop(); // Removes and returns the last element
  return fileName;
}

function RepliedMessage({ message, sender }) {
  const scrollToMessage = (messageId) => {
    const element = document.getElementById(`message-${messageId}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      element.style.backgroundColor = "var(--color-blue-10)";
      setTimeout(() => {
        element.style.backgroundColor = "inherit";
      }, 3000); // 3 seconds
    }
  };
  return (
    <StyledContainer
      $sender={sender}
      onClick={() => {
        scrollToMessage(message?.replied_to_message.id);
      }}
    >
      <div className="sender">
        {message?.replied_to_message?.type === "support" ? "You" : "User"}
      </div>
      {message?.replied_to_message?.message_type === "message" && (
        <span style={{ color: "var(--color-grey-700)" }}>
          {message?.replied_to_message?.message}
        </span>
      )}
      {message?.replied_to_message?.message_type === "video" && (
        <div
          style={{
            display: "flex",
            gap: "2rem",
          }}
        >
          {/* <img
            src={"http://" + message?.replied_to_message.message}
            alt="image"
            style={{
              width: "4rem",
              height: "4rem",
            }}
          /> */}
          <MdOutlineOndemandVideo
            style={{
              fontSize: "4rem",
            }}
          />
          <div>
            <span>
              {getFileName(message?.replied_to_message.message).length > 20
                ? getFileName(message?.replied_to_message.message).slice(
                    0,
                    20
                  ) + "..."
                : getFileName(message?.replied_to_message.message)}
            </span>
            <br />
            <span
              style={{
                color: "var(--color-blue-500)",
              }}
            >
              video
            </span>
          </div>
        </div>
      )}
      {message?.replied_to_message?.message_type === "image" && (
        <div
          style={{
            display: "flex",
            gap: "2rem",
          }}
        >
          <img
            src={message?.replied_to_message.message}
            alt="image"
            style={{
              width: "4rem",
              height: "4rem",
            }}
          />
          <div>
            <span>
              {getFileName(message?.replied_to_message.message).length > 20
                ? getFileName(message?.replied_to_message.message).slice(
                    0,
                    20
                  ) + "..."
                : getFileName(message?.replied_to_message.message)}
            </span>
            <br />
            <span
              style={{
                color: "var(--color-blue-500)",
              }}
            >
              image
            </span>
          </div>
        </div>
      )}
    </StyledContainer>
  );
}

export default RepliedMessage;
