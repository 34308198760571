/* eslint-disable react/prop-types */
import Modal from "./Modal";
import { MAIN_URL_STORE } from "../../../utils/constants";
// import UserInModal from "../users/UserInModal";

function UserProfileImg({ message }) {
  if (message.type === "support" || message.type === "vesper")
    return (
      <img
        src={
          message?.support_image
            ? `${MAIN_URL_STORE}${message?.support_image}`
            : "user-profile.svg"
        }
        alt={`${message.sender} profile`}
        className="profile-pic"
        title={message?.support_nickname}
      />
    );

  return (
    <Modal>
      <Modal.Open opens="profile">
        <img
          src={`/user-profile.svg`}
          alt={`${message.sender} profile`}
          className="profile-pic"
        />
      </Modal.Open>
      <Modal.Window name="profile">
        {/* <UserInModal clientId={message.sender_id} /> */}
        <div>test</div>
      </Modal.Window>
    </Modal>
  );
}

export default UserProfileImg;
