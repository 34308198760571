import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import "./UserDashboard.css";
import { FaWallet, FaChartLine } from "react-icons/fa";
import TopWithdrawals from "./userDashboard/TopWithdrawals";
import MarketInsights from "./userDashboard/MarketInsights";
import UserPerformance from "./userDashboard/UserPerformance";
import Notifications from "./userDashboard/Notifications";
import UserContext from "../UserContext/UserContext";
import AOS from "aos";
import "aos/dist/aos.css";
import RecentEvents from "./userDashboard/RecentEvents";

export default function UserDashboard(props) {
  console.log('my prop is : ', props);
  const [quote, setQuote] = useState(null); // استیت برای ذخیره داده‌های پاسخ

  // useEffect(() => {
  //   // درخواست برای دریافت داده‌های سخن
  //   const tok = "394867:66c5c69674f5c";
  //   axios
  //     .get("https://one-api.ir/sokhan/?token=" + tok + "&action=random")
  //     .then((res) => {
  //       setQuote(res.data); // ذخیره داده‌های پاسخ در استیت
  //       console.log("sokhans :::", res.data);
  //     })
  //     .catch((err) => {
  //       console.error("خطا در دریافت داده:", err);
  //     });
  // }, []); // فقط یک بار هنگام بارگذاری اجرا شود

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  const { user, url, load } = useContext(UserContext);

  // Fetch account data using react-query
  const fetchAccountData = async (userId) => {
    const response = await axios.get(`${url}api/v1/panel/dashboard/${userId}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  };

  const {
    data: accountData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["accountData", user.dataes.id],
    queryFn: () => fetchAccountData(user.dataes.id),
    staleTime: 300000,
    cacheTime: 600000,
  });
  // if (isLoading) {load(true)}else{load(false)};
  if (isLoading) return <div className="Loading">در حال بارگذاری...</div>;
  if (isError) return <div className="Error">خطا در دریافت اطلاعات</div>;

  return (
    <div className="UserDashboardContainer" data-aos="fade-up">
      {/* بخش نمای کلی حساب */}
      <div className="AccountOverviewContainer" data-aos="fade-up">
        <h2 className="AccountOverviewTitle">نمای کلی حساب</h2>
        <div className="AccountOverviewGrid">
          <div className="OverviewCard">
            <FaWallet className="CardIcon" />
            <h3>موجودی</h3>
            <p>{accountData.wallet} $</p>
          </div>
          <div className="OverviewCard">
            <FaChartLine className="CardIcon" />
            <h3>سود</h3>
            <p className={
          accountData.profit >= 0 ? "profit-positive" : "profit-negative"
        }>
              {accountData.profit} $
            </p>
          </div>
        </div>
      </div>
      <div
        className="FlexContainerDashboard"
        style={{
          display: "flex",
          gap: "2rem",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <RecentEvents changePage={props.changePage}   />
        {accountData.top_payouts && (
          <TopWithdrawals Withdrawals={accountData.top_payouts} />
        )}
      </div>

      {/* سایر بخش‌ها */}
      <div className="ResponsiveFlex">
        <div
          style={{
            flex: 1.2,
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <UserPerformance payouts={accountData.payouts} />
        </div>
        {/* <div className="QuoteContainer">
            {quote ? (
              <div>
                <p>{quote.result.text}</p>
                <p>{quote.result.author}</p>
              </div>
            ) : (
              <p>در حال بارگذاری سخن...</p>
            )}
          </div> */}
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <Notifications />
        </div>
      </div>

      {/* نمایش داده‌های سخن */}
    </div>
  );
}
