import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  setEndDateSupportChatHistory,
  setPageOfChatPaginate,
  setStartDateSupportChatHistory,
} from "./slices/messageSlice";
import { useQueryClient } from "@tanstack/react-query";
import ButtonIcon from "./ButtonIcon";

function FilterChats() {
  const dispatch = useDispatch();
  const {
    startDateSupportChatHistory,
    endDateSupportChatHistory,
    pageOfPaginate,
    supportIdForChats,
  } = useSelector((state) => state.message);
  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueryData([
    "support-chats-paginate",
    supportIdForChats,
    startDateSupportChatHistory,
    endDateSupportChatHistory,
    pageOfPaginate,
  ]);

  const totalPages = cachedData?.last_page || 1; // Total pages from API response
  const currentPage = cachedData?.current_page || 1; // Current page from API respons

  const handleStartDateChange = (event) => {
    dispatch(setStartDateSupportChatHistory(event.target.value));
  };

  const handleEndDateChange = (event) => {
    dispatch(setEndDateSupportChatHistory(event.target.value));
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      dispatch(setPageOfChatPaginate(currentPage - 1));
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      dispatch(setPageOfChatPaginate(currentPage + 1));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "0.8rem",
        alignItems: "center",
        marginTop: "1rem",
        direction: "ltr",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "0.6rem",
        }}
      >
        <input
          type="date"
          style={{
            padding: "0.2rem",
            fontSize: "10px",
            backgroundColor: "var(--color-green-800)",
            border: "none",
          }}
          value={startDateSupportChatHistory}
          onChange={handleStartDateChange}
        />
        <input
          type="date"
          style={{
            padding: "0.2rem",
            fontSize: "10px",
            backgroundColor: "var(--color-green-800)",
            border: "none",
          }}
          value={endDateSupportChatHistory}
          onChange={handleEndDateChange}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          flexGrow: "1",
          gap: "0.2rem",
        }}
      >
        <ButtonIcon
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          $color="var(--color-green-500)"
        >
          <MdKeyboardArrowLeft />
        </ButtonIcon>
        <p>
          {currentPage}/{totalPages}
        </p>
        <ButtonIcon
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          $color="var(--color-green-500)"
        >
          <MdKeyboardArrowRight />
        </ButtonIcon>
      </div>
    </div>
  );
}

export default FilterChats;
