import React, { useState, useContext, useEffect } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import { FaHatWizard } from "react-icons/fa";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Switch, FormControlLabel } from "@mui/material";
import { MdOutlineDone } from "react-icons/md";
import { LuCalendarDays } from "react-icons/lu";
import { LuArrowUpDown } from "react-icons/lu";
import { GiProfit } from "react-icons/gi";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { BsCalendarDate } from "react-icons/bs";
import { GiCycle } from "react-icons/gi";
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import { TiInfoLarge } from "react-icons/ti";
import UserContext from "../UserContext/UserContext";
import { VscPass } from "react-icons/vsc";

import { useQuery } from "@tanstack/react-query";
import { fetchAccountData } from "./fetchers/orderFetchers";

import { IoEllipsisHorizontalOutline } from "react-icons/io5";

import { MdOutlineSettingsBackupRestore } from "react-icons/md";

import { MdKeyboardBackspace } from "react-icons/md";

function Analysis({ analyise, analyiseNull }) {

  console.log("analyiiiissss: ", analyise);
  const chartData = [
    {
      name: " A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  function calculateTotalProfit(data) {
    if (Array.isArray(data)) {
      return data
        .map((position) => {
          // تبدیل profit به عدد (در صورت نبود profit مقدار 0 قرار می‌دهیم)
          const profit = position.Profit ? parseFloat(position.Profit) : 0;
          return isNaN(profit) ? 0 : profit; // اگر تبدیل به عدد نشد، 0 قرار می‌دهیم
        })
        .reduce((sum, profit) => sum + profit, 0); // جمع کردن تمام profit ها
    }
    return 0; // در صورت غیر معتبر بودن داده‌ها مقدار 0 برمی‌گرداند
  }

  const [isHovered, setIsHovered] = useState(false);

  // const data = [
  //   { key: "Key1", value: "Value1" },
  //   { key: "Key2", value: "Value2" },
  //   { key: "Key3", value: "Value3" },
  //   { key: "Key4", value: "Value4" },
  //   { key: "Key5", value: "Value5" },
  //   { key: "Key6", value: "Value6" },
  //   { key: "Key7", value: "Value7" },
  //   { key: "Key8", value: "Value8" },
  //   { key: "Key9", value: "Value9" },
  //   { key: "Key10", value: "Value10" },
  //   { key: "Key10", value: "Value10" },
  //   { key: "Key10", value: "Value10" },
  //   { key: "Key10", value: "Value10" },
  //   { key: "Key10", value: "Value10" },
  //   { key: "Key10", value: "Value10" },
  //   { key: "Key10", value: "Value10" },
  //   { key: "Key10", value: "Value10" },
  //   { key: "Key10", value: "Value10" },
  //   { key: "Key10", value: "Value10" },
  // ];

  // const tableData = [
  //   {
  //     openTime: "2024-12-12 08:30:00",
  //     openPrice: "1.12345",
  //     closeTime: "2024-12-12 10:45:00",
  //     closePrice: "1.12456",
  //     profit: "15.00",
  //     lots: "0.10",
  //     commission: "-2.00",
  //     swap: "-0.50",
  //     netProfit: "12.50",
  //     symbol: "EURUSD",
  //     type: "Buy",
  //   },
  //   {
  //     openTime: "2024-12-12 09:00:00",
  //     openPrice: "1.21567",
  //     closeTime: "2024-12-12 11:30:00",
  //     closePrice: "1.21890",
  //     profit: "32.00",
  //     lots: "0.20",
  //     commission: "-3.00",
  //     swap: "-1.00",
  //     netProfit: "28.00",
  //     symbol: "GBPUSD",
  //     type: "Buy",
  //   },
  //   {
  //     openTime: "2024-12-12 07:00:00",
  //     openPrice: "145.123",
  //     closeTime: "2024-12-12 09:00:00",
  //     closePrice: "146.456",
  //     profit: "140.00",
  //     lots: "0.50",
  //     commission: "-5.00",
  //     swap: "-1.50",
  //     netProfit: "133.50",
  //     symbol: "USDJPY",
  //     type: "Sell",
  //   },
  // ];

  // useEffect(()=>{
  //   console.log('analysis',analyise)
  //   console.log('account id : ',analyise.order.account.id)

  // },[])

  // function getUniqueTradingDays(positions) {
  //   if (!positions) {
  //     return;
  //   }
  //   const uniqueDays = new Set(
  //     positions.map((position) => {
  //       const openDate = new Date(position.open_time); // تبدیل به تاریخ
  //       return openDate.toISOString().split("T")[0]; // فقط تاریخ (YYYY-MM-DD)
  //     })
  //   );
  //   console.log('uniique dayssss:::',uniqueDays)

  //   return uniqueDays.size; // تعداد روزهای یکتای معامله
  // }
  function getUniqueTradingDays(positions) {
    if (!positions || positions.length === 0) {
      return 0;
    }
  
    const uniqueDays = new Set(
      positions.map((position) => {
        if (!position.open_time) {
          return null;
        }
  
        // استفاده از Date برای پردازش
        const openDate = new Date(position.open_time.replace(" ", "T") + "Z");
        if (isNaN(openDate.getTime())) {
          console.warn(`Invalid date: ${position.open_time}`);
          return null;
        }
  
        // فقط تاریخ
        const year = openDate.getUTCFullYear();
        const month = String(openDate.getUTCMonth() + 1).padStart(2, "0");
        const day = String(openDate.getUTCDate()).padStart(2, "0");
  
        return `${year}-${month}-${day}`;
      }).filter(Boolean) // حذف مقادیر null
    );
  
    console.log("Unique trading days:", Array.from(uniqueDays));
  
    return uniqueDays.size;
  }
  
  const { load, url, user } = useContext(UserContext);

  const useAccountData = (accountId) => {
    console.log("im innnn");
    return useQuery({
      queryKey: ["accountData", accountId], // کلید کوئری
      queryFn: () => fetchAccountData(accountId, url), // فانکشن fetch
      refetchInterval: 600000, // آپدیت خودکار هر 10 دقیقه (600000 میلی‌ثانیه)
    });
  };

  const {
    data: mydata,
    isLoading,
    isError,
  } = useAccountData(analyise.order.account.id);

  const [analyiseActiver, setAnalyiseActiver] = useState({
    a1: "active",
    a2: "",
  });
  const [isChecked, setIsChecked] = useState(true);
  const [page, setPage] = React.useState(1);

  const handleToggle = (event) => {
    setIsChecked(event.target.checked);
  };
  function formatToK(number) {
    if (number >= 1000) {
      return (number / 1000).toFixed(number % 1000 === 0 ? 0 : 1) + "k";
    }
    return number.toString();
  }

  const handleChangepage = (event, value) => {
    setPage(value);
    console.log("page :", value);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#CB9E52 !important", // رنگ متن هدر
      backgroundColor: "transparent !important", // پس‌زمینه شفاف
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: "white !important", // رنگ متن سلول‌ها
      backgroundColor: "transparent !important", // پس‌زمینه شفاف
    },
    "&:hover": {
      color: "#CB9E52 !important", // تغییر رنگ متن هنگام هاور
      cursor: "pointer", // نشانگر ماوس به دست
    },
  }));

  /////metrics////

  function convertObjectToArray(obj) {
    if (!obj) {
      return;
    }
    return Object.entries(obj).map(([key, value]) => ({
      key: key,
      value: value,
    }));
  }

  const divideArrayIntoChunks = (array, numChunks) => {
    if (!array?.length || !array) return;
    const result = [];
    let remainingItems = array.length;

    // برای محاسبه تعداد آیتم‌های هر ستون
    for (let i = 0; i < numChunks; i++) {
      const chunkSize = Math.ceil(remainingItems / (numChunks - i));
      result.push(array.slice(0, chunkSize));
      array = array.slice(chunkSize);
      remainingItems -= chunkSize;
    }

    return result;
  };

  const newColumns = convertObjectToArray(mydata?.positionsData.metrics);
  console.log("my new columns : ", newColumns);

  const columns = divideArrayIntoChunks(newColumns, 2);

  //////////table data//////

  const tableData = [...(mydata?.positionsData.positions || [])].reverse();

  const rowsPerPage = 10; // تعداد ردیف‌ها در هر صفحه
  const paginatedTableData = tableData.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );
  

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "transparent !important",
      // پس‌زمینه شفاف برای ردیف‌های فرد
    },
    "&:nth-of-type(even)": {
      // پس‌زمینه شفاف برای ردیف‌های زوج
    },
    "& td, & th": {
      color: "white !important", // رنگ متن‌ها
      padding: "16px !important", // فاصله داخلی
    },
    "&:last-child td, &:last-child th": {
      border: "none !important", // حذف خط مرزی آخر
      backgroundColor: "transparent !important", // پس‌زمینه شفاف برای آخرین ردیف
    },
    "&": {
      marginBottom: "10px !important", // فاصله بین ردیف‌ها
    },
    // اضافه کردن استایل‌ها برای هاور
    "&:hover": {
      // رنگ پس‌زمینه در هاور
      color: "#CB9E52 !important", // تغییر رنگ متن به طلایی هنگام هاور
      cursor: "pointer", // تغییر نشانگر ماوس به دست
    },
  }));

  console.log(
    "openPositionsData",
    calculateTotalProfit(mydata?.openPositionsData)
  );

  ///////////chartpart 2//////
  const [chartType, setChartType] = useState("Symbol Performance");

  const renderChart = () => {
    switch (chartType) {
      case "Symbol Performance":
        return <div>نمودار عملکرد نماد</div>;
      case "PnL by Weekday":
        return <div>نمودار PnL به تفکیک روز</div>;
      case "PnL by Hours":
        return <div>نمودار PnL به تفکیک ساعات</div>;
      default:
        return <div>نمودار دیگری</div>;
    }
  };
  //////nemoodare dayereyi  1 ////
  // پردازش داده‌ها برای شمارش تعداد پوزیشن‌های هر نماد
  const symbolCounts = mydata?.positionsData.positions.reduce(
    (acc, position) => {
      acc[position.symbol] = (acc[position.symbol] || 0) + 1;
      return acc;
    },
    {}
  );

  // تبدیل داده‌ها به فرمت مناسب برای نمودار دایره‌ای
  const pieChartData = Object.entries(symbolCounts || {}).map(
    ([symbol, count]) => ({
      name: symbol,
      value: count,
    })
  );

  const allSymbols = Object.keys(symbolCounts || {});

  const [selectedSymbols, setSelectedSymbols] = useState(allSymbols);

  const handleCheckboxChange = (symbol) => {
    if (selectedSymbols.includes(symbol)) {
      setSelectedSymbols(selectedSymbols.filter((item) => item !== symbol));
    } else {
      setSelectedSymbols([...selectedSymbols, symbol]);
    }
  };

  const filteredData = pieChartData.filter((item) =>
    selectedSymbols.includes(item.name)
  );

  // رنگ‌های دلخواه برای نمودار
  const COLORS = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#ff8042",
    "#8dd1e1",
    "#d0ed57",
    "#a4de6c",
  ];

  //////nemoodar 2//////
  // تابعی برای گرفتن نام روزهای هفته
  const getDayName = (dateString) => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const date = new Date(dateString);
    return days[date.getDay()];
  };

  // پردازش داده‌ها برای جمع کردن `net_profit` بر اساس روزهای هفته
  const weeklyData = mydata?.positionsData.positions
    ? mydata.positionsData.positions.reduce((acc, position) => {
        if (position.net_profit !== undefined && position.open_time) {
          const day = getDayName(position.open_time);
          acc[day] = (acc[day] || 0) + parseFloat(position.net_profit);
        }
        return acc;
      }, {})
    : {}; // مقدار پیش‌فرض

  console.log("weeklydata", weeklyData);

  // تبدیل داده‌ها به فرمت مناسب برای `Recharts`
  const chartData2 = Object.entries(weeklyData).map(([day, netProfit]) => ({
    day,
    netProfit,
  }));

  ////////////nemoodar 3//////
  const logPositionsInHour = (positions, targetHour) => {
    // فیلتر کردن موقعیت‌هایی که در ساعت مورد نظر هستند
    const filteredPositions = positions.filter((position) => {
      const openHour = new Date(position.open_time).getHours();
      return openHour === targetHour;
    });

    // نمایش موقعیت‌های فیلتر شده در لاگ
    console.log(
      `موقعیت‌های موجود در بازه ${targetHour}:00 تا ${targetHour}:59:`,
      filteredPositions
    );

    return filteredPositions; // برای استفاده احتمالی بعدی
  };

  // استفاده از تابع برای ساعت 18
  // logPositionsInHour(mydata.positionsData.positions, 13);

  const processHourlyPnL = (positions) => {
    const hourlyData = Array.from({ length: 24 }, (_, hour) => ({
      hour,
      positiveNetProfit: 0, // سود مثبت
      negativeNetProfit: 0, // سود منفی
    }));

    positions.forEach((position) => {
      const openHour = new Date(position.open_time).getHours();
      const netProfit = parseFloat(position.net_profit);

      if (netProfit >= 0) {
        hourlyData[openHour].positiveNetProfit += netProfit; // جمع مقادیر مثبت
      } else {
        hourlyData[openHour].negativeNetProfit += netProfit; // جمع مقادیر منفی
      }
    });

    return hourlyData.map(({ hour, positiveNetProfit, negativeNetProfit }) => ({
      hour: `${hour}:00 - ${hour}:59`,
      netProfit: positiveNetProfit + negativeNetProfit, // جمع نهایی
    }));
  };

  // داده‌ها برای نمودار سوم
  const hourlyPnLData = processHourlyPnL(mydata?.positionsData.positions || []);

  //////// nemoodare 4 om //////////

  const processPnLByOrderType = (positions) => {
    if (!positions) {
      return;
    }
    const pnlByType = positions?.reduce(
      (acc, position) => {
        const type = position.type; // نوع سفارش (Buy یا Sell)
        acc[type] += parseFloat(position.net_profit);
        return acc;
      },
      { Buy: 0, Sell: 0 } // مقدار اولیه برای هر نوع سفارش
    );

    // تبدیل به فرمت مناسب برای نمودار
    return Object.entries(pnlByType).map(([type, netProfit]) => ({
      type,
      netProfit,
    }));
  };

  // محاسبه بزرگترین مقدار مطلق

  // پردازش داده‌ها
  const orderTypeData = processPnLByOrderType(mydata?.positionsData.positions);

  const maxAbsoluteValue = orderTypeData
    ? Math.max(
        Math.abs(Math.min(...orderTypeData.map((d) => d.netProfit))),
        Math.abs(Math.max(...orderTypeData.map((d) => d.netProfit)))
      )
    : 0; // مقدار پیش‌فرض در صورت نبود داده

  //////////////nemoodare 5 om///////

  const calculateWinLoss = (positions) => {
    if (!positions || !Array.isArray(positions)) return { win: 0, loss: 0 };

    let winCount = 0;
    let lossCount = 0;

    positions.forEach((position) => {
      if (parseFloat(position.net_profit) > 0) {
        winCount += 1; // افزایش تعداد برد
      } else if (parseFloat(position.net_profit) < 0) {
        lossCount += 1; // افزایش تعداد باخت
      }
    });

    return { win: winCount, loss: lossCount };
  };
  const { win, loss } = calculateWinLoss(mydata?.positionsData.positions);

  const pieChartData2 = [
    { name: "Win", value: win, color: "#4caf50" }, // سبز برای برد
    { name: "Loss", value: loss, color: "#f44336" }, // قرمز برای باخت
  ];

  /////
  function convertToNumber(input) {
    if (typeof input === "string") {
      const unit = input.slice(-1).toLowerCase(); // گرفتن آخرین کاراکتر (k یا m)
      const number = parseFloat(input.slice(0, -1)); // حذف آخرین کاراکتر و تبدیل به عدد

      if (unit === "k") {
        return number * 1000; // ضرب در 1000 برای k
      } else if (unit === "m") {
        return number * 1000000; // ضرب در 1 میلیون برای m
      }
    }

    return parseFloat(input) || 0; // اگر عدد باشد یا مقدار معتبر نباشد
  }

  if (isLoading) {
    load(true);
  } else {
    load(false);
  }
  return (
    <div className="myAnalyise">
      <div
        className="tabs"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>
          <a
            onClick={() => {
              setAnalyiseActiver({ a1: "active", a2: "" });
            }}
            className={analyiseActiver.a1}
          >
            Account Overview
          </a>
          <a
            onClick={() => {
              setAnalyiseActiver({ a1: "", a2: "active" });
            }}
            className={analyiseActiver.a2}
          >
            Trading Overview
          </a>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginRight: "0px",
            alignItems: "center",
          }}
        >
          <button
            style={{
              background: "linear-gradient(to right, #1e7e34, #28a745)", // گرادیانت اولیه
              color: "white", // رنگ متن سفید
              padding: "5px 10px", // فاصله داخلی دکمه
              border: "none", // حذف حاشیه
              borderRadius: "5px", // گوشه‌های گرد
              cursor: "pointer", // تغییر نشانگر ماوس
              fontSize: "16px", // اندازه فونت
              display: "flex", // نمایش محتوای دکمه در کنار هم
              alignItems: "center", // تراز وسط برای محتوای عمودی
              justifyContent: "center", // تراز وسط برای افقی
              gap: "5px", // فاصله بین متن و آیکون
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // سایه‌ی زیبا
              transition: "background 0.5s ease, color 0.3s ease", // انیمیشن تغییرات
            }}
            onClick={analyiseNull}
            onMouseEnter={(e) => {
              const targetButton = e.currentTarget; // دسترسی به دکمه والد
              targetButton.style.background =
                "linear-gradient(to right, #ffffff, #dfffd6)"; // گرادیانت سفید به سبز روشن
              targetButton.style.color = "#28a745"; // تغییر رنگ متن به سبز
            }}
            onMouseLeave={(e) => {
              const targetButton = e.currentTarget; // دسترسی به دکمه والد
              targetButton.style.background =
                "linear-gradient(to right, #1e7e34, #28a745)"; // بازگشت به گرادیانت اصلی
              targetButton.style.color = "white"; // بازگشت به متن سفید
            }}
          >
            Account's <MdKeyboardBackspace style={{ margin: "0px" }} />
          </button>
        </div>
      </div>

      <div className="analyseContainer">
        {analyiseActiver.a1 == "active" ? (
          <>
            <div className="accountProfile">
              <div className="ProfileInfo">
                <h6 style={{ color: "#CB9E52" }}>
                  Hi <strong> {user.dataes.username} </strong>, this is you'r{" "}
                  <strong>{analyise.type}</strong> /{" "}
                  {formatToK(analyise.order.product_id)} account
                </h6>
                <div className="acc-info">
                  <div className="info">
                    <TiInfoLarge style={{ color: "#3498DB" }} />
                    <p>
                      you'r initial balance :
                      {analyise.order.product
                        ? analyise.order.product.title
                        : formatToK(analyise.order.product_id)}
                    </p>
                  </div>
                  <div className="info">
                    <TiInfoLarge style={{ color: "#3498DB" }} />
                    <p>
                      plan type :{" "}
                      {analyise.order.product
                        ? analyise.order.product.category.type
                        : analyise.order.category.type}
                    </p>
                  </div>
                  <div className="info">
                    <TiInfoLarge style={{ color: "#3498DB" }} />
                    <p>account type : {analyise.type}</p>
                  </div>
                </div>
              </div>
              <div className="separator"></div> {/* خط عمودی */}
              <div className="AccountCycle">
                <div style={{ color: "#CB9E52" }} className="info">
                  <GiCycle />
                  <h6>trading account cycle</h6>
                </div>
                <div className="acc-info">
                  <div className="info">
                    <BsCalendarDate style={{ color: "#4CAF50" }} />
                    <p>start Date : {analyise.order.account.start_date} </p>
                  </div>
                  <div className="info">
                    <BsCalendarDate style={{ color: "#E74C3C" }} />
                    <p>end Date : {analyise.order.account.end_date}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="statCon">
              <h4 style={{ textAlign: "center", marginTop: "1rem" }}>Stats</h4>

              <div class="separator-horizontal"></div>

              <div className="stats">
                <div
                  className="mycard "
                  style={{
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = "scale(1.1)"; // بزرگتر شدن کل div
                    e.currentTarget.style.boxShadow =
                      "0 8px 16px rgba(0, 0, 0, 0.2)"; // سایه‌ی عمیق‌تر
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "scale(1)"; // بازگشت به اندازه اولیه
                    e.currentTarget.style.boxShadow = "none"; // حذف سایه هنگام خروج
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    <RiMoneyDollarCircleLine
                      style={{ fontSize: "2rem", color: "#4CAF50" }}
                    />
                    <p style={{ opacity: "0.5", margin: 0 }}>Balance</p>
                  </div>

                  <strong>{mydata?.userDetails.original.data.Balance} $</strong>
                </div>

                <div
                  className="mycard "
                  style={{
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = "scale(1.1)"; // بزرگتر شدن کل div
                    e.currentTarget.style.boxShadow =
                      "0 8px 16px rgba(0, 0, 0, 0.2)"; // سایه‌ی عمیق‌تر
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "scale(1)"; // بازگشت به اندازه اولیه
                    e.currentTarget.style.boxShadow = "none"; // حذف سایه هنگام خروج
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    <GiProfit style={{ fontSize: "2rem", color: "#F1C40F" }} />
                    <p style={{ opacity: "0.5", margin: 0 }}>Profit</p>
                  </div>

                  <strong
                    style={{
                      color: analyise.order.product
                        ? mydata?.userDetails.original.data.Balance -
                            convertToNumber(analyise.order.product.title) >=
                          0
                          ? "#28a745" // سبز (رنگ مناسب برای مثبت)
                          : "#dc3545" // قرمز (رنگ مناسب برای منفی)
                        : mydata?.userDetails.original.data.Balance -
                            analyise.order.product_id >=
                          0
                        ? "#28a745"
                        : "#dc3545",
                    }}
                  >
                    {analyise.order.product
                      ? mydata?.userDetails.original.data.Balance -
                          convertToNumber(analyise.order.product.title) >=
                        0
                        ? `+${(
                            mydata?.userDetails.original.data.Balance -
                            convertToNumber(analyise.order.product.title)
                          ).toFixed(2)}`
                        : `${(
                            mydata?.userDetails.original.data.Balance -
                            convertToNumber(analyise.order.product.title)
                          ).toFixed(2)}`
                      : mydata?.userDetails.original.data.Balance -
                          analyise.order.product_id >=
                        0
                      ? `+${(
                          mydata?.userDetails.original.data.Balance -
                          analyise.order.product_id
                        ).toFixed(2)}`
                      : `${(
                          mydata?.userDetails.original.data.Balance -
                          analyise.order.product_id
                        ).toFixed(2)}`}{" "}
                    $
                  </strong>
                </div>
                <div
                  className="mycard "
                  style={{
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = "scale(1.1)"; // بزرگتر شدن کل div
                    e.currentTarget.style.boxShadow =
                      "0 8px 16px rgba(0, 0, 0, 0.2)"; // سایه‌ی عمیق‌تر
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "scale(1)"; // بازگشت به اندازه اولیه
                    e.currentTarget.style.boxShadow = "none"; // حذف سایه هنگام خروج
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    <LuArrowUpDown
                      style={{ fontSize: "2rem", color: "#E74C3C" }}
                    />
                    <p style={{ opacity: "0.5", margin: 0 }}>Equity</p>
                  </div>

                  <strong>
                    {(
                      (parseFloat(mydata?.userDetails.original.data.Balance) ||
                        0) + calculateTotalProfit(mydata?.openPositionsData)
                    ).toFixed(2)}
                  </strong>
                </div>
                <div
                  className="mycard "
                  style={{
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = "scale(1.1)"; // بزرگتر شدن کل div
                    e.currentTarget.style.boxShadow =
                      "0 8px 16px rgba(0, 0, 0, 0.2)"; // سایه‌ی عمیق‌تر
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "scale(1)"; // بازگشت به اندازه اولیه
                    e.currentTarget.style.boxShadow = "none"; // حذف سایه هنگام خروج
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    <LuCalendarDays
                      style={{ fontSize: "2rem", color: "#3498DB" }}
                    />
                    <p style={{ opacity: "0.5", margin: "0" }}>Trading-days</p>
                    {getUniqueTradingDays(mydata?.positionsData.positions) >=
                    5 ? (
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        onMouseEnter={() => setIsHovered(true)} // زمانی که ماوس وارد می‌شود
                        onMouseLeave={() => setIsHovered(false)} // زمانی که ماوس خارج می‌شود
                      >
                        <VscPass
                          style={{
                            color: "green",
                            fontSize: "2rem",
                            cursor: "pointer",
                          }}
                        />
                        {isHovered && ( // نمایش Tooltip فقط وقتی Hover شده است
                          <span
                            style={{
                              position: "absolute",
                              bottom: "125%", // بالای آیکون
                              left: "50%",
                              transform: "translateX(-50%)",
                              backgroundColor: "#555", // رنگ پس‌زمینه
                              color: "#fff", // رنگ متن
                              textAlign: "center",
                              borderRadius: "6px",
                              padding: "5px 10px",
                              whiteSpace: "nowrap",
                              fontSize: "0.9rem",
                              zIndex: 1,
                              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // سایه برای زیبایی
                            }}
                          >
                            Passed
                          </span>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        onMouseEnter={() => setIsHovered(true)} // زمانی که ماوس وارد می‌شود
                        onMouseLeave={() => setIsHovered(false)} // زمانی که ماوس خارج می‌شود
                      >
                        <IoEllipsisHorizontalOutline
                          style={{
                            color: "white",
                            fontSize: "2rem",
                            cursor: "pointer",
                          }}
                        />
                        {isHovered && ( // نمایش Tooltip فقط وقتی Hover شده است
                          <span
                            style={{
                              position: "absolute",
                              bottom: "115%", // بالای آیکون
                              left: "50%",
                              transform: "translateX(-50%)",
                              backgroundColor: "#555", // رنگ پس‌زمینه
                              color: "#fff", // رنگ متن
                              textAlign: "center",
                              borderRadius: "6px",
                              padding: "5px 10px",
                              whiteSpace: "nowrap",
                              fontSize: "0.9rem",
                              zIndex: 1,
                              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // سایه برای زیبایی
                            }}
                          >
                            going on
                          </span>
                        )}
                      </div>
                    )}
                  </div>

                  <strong>
                    {getUniqueTradingDays(mydata?.positionsData.positions)}
                  </strong>
                </div>
              </div>
            </div>
            <div className="trCon">
              <h4 style={{ textAlign: "center", marginTop: "1rem" }}>
                Trading History
              </h4>
              <div class="separator-horizontal"></div>
              <div className="trading-objective">
                <div className="col1">
                  <div
                    className="myyrow"
                    style={{
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = "scale(1.07)"; // بزرگتر شدن کل div
                      e.currentTarget.style.boxShadow =
                        "0 8px 16px rgba(0, 0, 0, 0.2)"; // سایه‌ی عمیق‌تر
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = "scale(1)"; // بازگشت به اندازه اولیه
                      e.currentTarget.style.boxShadow = "none"; // حذف سایه هنگام خروج
                    }}
                  >
                    <div className="myTop">
                      <div className="topLeft">
                        <MdOutlineDone />
                        <p
                          style={{
                            margin: 0,
                            fontWeight: "bold",
                            color: "#CB9E52",
                          }}
                        >
                          daily loss limit
                        </p>
                      </div>
                      <div className="topRight"></div>
                    </div>
                    <div className="myBot">
                      {/* {analyise.order.product ? (
                        analyise.order.product.category.type === "2-step" ? (
                          analyise.type === "First Step" ? (
                            // محتوای مربوط به حالت First Step در 2-step
                            <p style={{ marginBottom: 0 }}>
                              max loss limit :{" "}
                              {analyise.order.product
                                ? convertToNumber(
                                    analyise.order.product.title
                                  ) * 0.05
                                : analyise.order.product_id * 0.05}{" "}
                              $
                            </p>
                          ) : analyise.type === "Second Step" ? (
                            // محتوای مربوط به حالت Second Step در 2-step
                            <p style={{ marginBottom: 0 }}>
                             max loss limit :{" "}
                              {analyise.order.product
                                ? convertToNumber(
                                    analyise.order.product.title
                                  ) * 0.05
                                : analyise.order.product_id * 0.05}{" "}
                              $
                            </p>
                          ) : null
                        ) : analyise.order.product.category.type ===
                          "1-step" ? (
                          // محتوای مربوط به حالت 1-step در صورت موجود بودن product
                          <p style={{ marginBottom: 0 }}>
                            max loss limit :{" "}
                            {analyise.order.product
                              ? convertToNumber(analyise.order.product.title) *
                                0.04
                              : analyise.order.product_id * 0.04}{" "}
                            $
                          </p>
                        ) : analyise.order.product.category.type ===
                          "pro-trader" ? (
                          // محتوای مربوط به حالت pro-trader در صورت موجود بودن product
                          <p style={{ marginBottom: 0 }}>
                            max loss limit :{" "}
                            {analyise.order.product
                              ? convertToNumber(analyise.order.product.title) *
                                0.05
                              : analyise.order.product_id * 0.05}{" "}
                            $
                          </p>
                        ) : null
                      ) : analyise.order.category.type === "1-step" ? (
                        // محتوای مربوط به حالت 1-step در صورت عدم موجود بودن product
                        <p style={{ marginBottom: 0 }}>
                          max loss limit :{" "}
                          {analyise.order.product
                            ? convertToNumber(analyise.order.product.title) *
                              0.04
                            : analyise.order.product_id * 0.04}{" "}
                          $
                        </p>
                      ) : analyise.order.category.type === "2-step" ? (
                        // محتوای مربوط به حالت 2-step در صورت عدم موجود بودن product
                        analyise.type === "First Step" ? (
                          // محتوای مربوط به حالت First Step در 2-step
                          <p style={{ marginBottom: 0 }}>
                            max loss limit :{" "}
                            {analyise.order.product
                              ? convertToNumber(analyise.order.product.title) *
                                0.05
                              : analyise.order.product_id * 0.05}{" "}
                            $
                          </p>
                        ) : analyise.type === "Second Step" ? (
                          // محتوای مربوط به حالت Second Step در 2-step
                          <p style={{ marginBottom: 0 }}>
                            max loss limit :{" "}
                            {analyise.order.product
                              ? convertToNumber(analyise.order.product.title) *
                                0.05
                              : analyise.order.product_id * 0.05}{" "}
                            $
                          </p>
                        ) : null
                      ) : analyise.order.category.type === "pro-trader" ? (
                        // محتوای مربوط به حالت pro-trader در صورت عدم موجود بودن product
                        <p style={{ marginBottom: 0 }}>
                          max loss limit :{" "}
                          {analyise.order.product
                            ? convertToNumber(analyise.order.product.title) *
                              0.05
                            : analyise.order.product_id * 0.05}{" "}
                          $
                        </p>
                      ) : null} */}

                      {analyise.order.product ? (
                        analyise.order.product.category.type === "2-step" ? (
                          analyise.type === "First Step" ? (
                            // محتوای مربوط به حالت First Step در 2-step
                            <p style={{ marginBottom: 0 }}>
                              max loss limit :{" "}
                              {analyise.order.product
                                ? convertToNumber(
                                    analyise.order.product.title
                                  ) * 0.05
                                : analyise.order.product_id * 0.05}{" "}
                              $
                            </p>
                          ) : analyise.type === "Second Step" ? (
                            // محتوای مربوط به حالت Second Step در 2-step
                            <p style={{ marginBottom: 0 }}>
                              max loss limit :{" "}
                              {analyise.order.product
                                ? convertToNumber(
                                    analyise.order.product.title
                                  ) * 0.05
                                : analyise.order.product_id * 0.05}{" "}
                              $
                            </p>
                          ) : analyise.type === "Real" ? (
                            // محتوای مربوط به حالت Real در 2-step
                            <p style={{ marginBottom: 0 }}>
                              max loss limit :{" "}
                              {analyise.order.product
                                ? convertToNumber(
                                    analyise.order.product.title
                                  ) * 0.05
                                : analyise.order.product_id * 0.05}{" "}
                              $
                            </p>
                          ) : null
                        ) : analyise.order.product.category.type ===
                          "1-step" ? (
                          analyise.type === "Real" ? (
                            // محتوای مربوط به حالت Real در 1-step
                            <p style={{ marginBottom: 0 }}>
                              Real type content: max loss limit :{" "}
                              {analyise.order.product
                                ? convertToNumber(
                                    analyise.order.product.title
                                  ) * 0.04
                                : analyise.order.product_id * 0.04}{" "}
                              $
                            </p>
                          ) : (
                            // محتوای مربوط به حالت 1-step
                            <p style={{ marginBottom: 0 }}>
                              max loss limit :{" "}
                              {analyise.order.product
                                ? convertToNumber(
                                    analyise.order.product.title
                                  ) * 0.04
                                : analyise.order.product_id * 0.04}{" "}
                              $
                            </p>
                          )
                        ) : analyise.order.product.category.type ===
                          "pro-trader" ? (
                          // محتوای مربوط به حالت pro-trader در صورت موجود بودن product
                          <p style={{ marginBottom: 0 }}>
                            max loss limit :{" "}
                            {analyise.order.product
                              ? convertToNumber(analyise.order.product.title) *
                                0.05
                              : analyise.order.product_id * 0.05}{" "}
                            $
                          </p>
                        ) : null
                      ) : analyise.order.category.type === "2-step" ? (
                        analyise.type === "First Step" ? (
                          // محتوای مربوط به حالت First Step در 2-step
                          <p style={{ marginBottom: 0 }}>
                            max loss limit :{" "}
                            {analyise.order.product
                              ? convertToNumber(analyise.order.product.title) *
                                0.05
                              : analyise.order.product_id * 0.05}{" "}
                            $
                          </p>
                        ) : analyise.type === "Second Step" ? (
                          // محتوای مربوط به حالت Second Step در 2-step
                          <p style={{ marginBottom: 0 }}>
                            max loss limit :{" "}
                            {analyise.order.product
                              ? convertToNumber(analyise.order.product.title) *
                                0.05
                              : analyise.order.product_id * 0.05}{" "}
                            $
                          </p>
                        ) : analyise.type === "Real" ? (
                          // محتوای مربوط به حالت Real در 2-step
                          <p style={{ marginBottom: 0 }}>
                            Real type content: max loss limit :{" "}
                            {analyise.order.product
                              ? convertToNumber(analyise.order.product.title) *
                                0.05
                              : analyise.order.product_id * 0.05}{" "}
                            $
                          </p>
                        ) : null
                      ) : analyise.order.category.type === "1-step" ? (
                        analyise.type === "Real" ? (
                          // محتوای مربوط به حالت Real در 1-step
                          <p style={{ marginBottom: 0 }}>
                            max loss limit :{" "}
                            {analyise.order.product
                              ? convertToNumber(analyise.order.product.title) *
                                0.04
                              : analyise.order.product_id * 0.04}{" "}
                            $
                          </p>
                        ) : (
                          // محتوای مربوط به حالت 1-step
                          <p style={{ marginBottom: 0 }}>
                            max loss limit :{" "}
                            {analyise.order.product
                              ? convertToNumber(analyise.order.product.title) *
                                0.04
                              : analyise.order.product_id * 0.04}{" "}
                            $
                          </p>
                        )
                      ) : analyise.order.category.type === "pro-trader" ? (
                        // محتوای مربوط به حالت pro-trader در صورت عدم موجود بودن product
                        <p style={{ marginBottom: 0 }}>
                          max loss limit :{" "}
                          {analyise.order.product
                            ? convertToNumber(analyise.order.product.title) *
                              0.05
                            : analyise.order.product_id * 0.05}{" "}
                          $
                        </p>
                      ) : null}

                      <strong>today permitted loss : --</strong>
                    </div>
                  </div>
                  <div
                    className="myyrow"
                    style={{
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = "scale(1.07)"; // بزرگتر شدن کل div
                      e.currentTarget.style.boxShadow =
                        "0 8px 16px rgba(0, 0, 0, 0.2)"; // سایه‌ی عمیق‌تر
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = "scale(1)"; // بازگشت به اندازه اولیه
                      e.currentTarget.style.boxShadow = "none"; // حذف سایه هنگام خروج
                    }}
                  >
                    <div className="myTop">
                      <div className="topLeft">
                        <MdOutlineDone />
                        <p
                          style={{
                            margin: 0,
                            fontWeight: "bold",
                            color: "#CB9E52",
                          }}
                        >
                          overal loss limit
                        </p>
                      </div>
                      <div className="topRight"></div>
                    </div>
                    <div className="myBot">
                      {/* {analyise.order.product ? (
                        analyise.order.product.category.type === "2-step" ? (
                          analyise.type === "First Step" ? (
                            // محتوای مربوط به حالت First Step در 2-step
                            <p style={{ marginBottom: 0 }}>
                              max loss limit :{" "}
                              {analyise.order.product
                                ? convertToNumber(
                                    analyise.order.product.title
                                  ) * 0.12
                                : analyise.order.product_id * 0.12}{" "}
                              $
                            </p>
                          ) : analyise.type === "Second Step" ? (
                            // محتوای مربوط به حالت Second Step در 2-step
                            <p style={{ marginBottom: 0 }}>
                              max loss limit :{" "}
                              {analyise.order.product
                                ? convertToNumber(
                                    analyise.order.product.title
                                  ) * 0.12
                                : analyise.order.product_id * 0.12}{" "}
                              $
                            </p>
                          ) : null
                        ) : analyise.order.product.category.type ===
                          "1-step" ? (
                          // محتوای مربوط به حالت 1-step در صورت موجود بودن product
                          <p style={{ marginBottom: 0 }}>
                            max loss limit :{" "}
                            {analyise.order.product
                              ? convertToNumber(analyise.order.product.title) *
                                0.08
                              : analyise.order.product_id * 0.08}{" "}
                            $
                          </p>
                        ) : analyise.order.product.category.type ===
                          "pro-trader" ? (
                          // محتوای مربوط به حالت pro-trader در صورت موجود بودن product
                          <p style={{ marginBottom: 0 }}>
                            max loss limit :{" "}
                            {analyise.order.product
                              ? convertToNumber(analyise.order.product.title) *
                                0.05
                              : analyise.order.product_id * 0.05}{" "}
                            $
                          </p>
                        ) : null
                      ) : analyise.order.category.type === "1-step" ? (
                        // محتوای مربوط به حالت 1-step در صورت عدم موجود بودن product
                        <p style={{ marginBottom: 0 }}>
                          max loss limit :{" "}
                          {analyise.order.product
                            ? convertToNumber(analyise.order.product.title) *
                              0.08
                            : analyise.order.product_id * 0.08}{" "}
                          $
                        </p>
                      ) : analyise.order.category.type === "2-step" ? (
                        // محتوای مربوط به حالت 2-step در صورت عدم موجود بودن product
                        analyise.type === "First Step" ? (
                          // محتوای مربوط به حالت First Step در 2-step
                          <p style={{ marginBottom: 0 }}>
                            max loss limit :{" "}
                            {analyise.order.product
                              ? convertToNumber(analyise.order.product.title) *
                                0.12
                              : analyise.order.product_id * 0.12}{" "}
                            $
                          </p>
                        ) : analyise.type === "Second Step" ? (
                          // محتوای مربوط به حالت Second Step در 2-step
                          <p style={{ marginBottom: 0 }}>
                            max loss limit :{" "}
                            {analyise.order.product
                              ? convertToNumber(analyise.order.product.title) *
                                0.12
                              : analyise.order.product_id * 0.12}{" "}
                            $
                          </p>
                        ) : null
                      ) : analyise.order.category.type === "pro-trader" ? (
                        // محتوای مربوط به حالت pro-trader در صورت عدم موجود بودن product
                        <p style={{ marginBottom: 0 }}>
                          max loss limit :{" "}
                          {analyise.order.product
                            ? convertToNumber(analyise.order.product.title) *
                              0.05
                            : analyise.order.product_id * 0.05}{" "}
                          $
                        </p>
                      ) : null} */}
                      {analyise.order.product ? (
                        analyise.order.product.category.type === "2-step" ? (
                          analyise.type === "First Step" ? (
                            // محتوای مربوط به حالت First Step در 2-step
                            <p style={{ marginBottom: 0 }}>
                              max loss limit :{" "}
                              {convertToNumber(analyise.order.product.title) *
                                0.12}{" "}
                              $
                            </p>
                          ) : analyise.type === "Second Step" ? (
                            // محتوای مربوط به حالت Second Step در 2-step
                            <p style={{ marginBottom: 0 }}>
                              max loss limit :{" "}
                              {convertToNumber(analyise.order.product.title) *
                                0.12}{" "}
                              $
                            </p>
                          ) : analyise.type === "Real" ? (
                            // محتوای مربوط به حالت Real در 2-step
                            <p style={{ marginBottom: 0 }}>
                              max loss limit :{" "}
                              {convertToNumber(analyise.order.product.title) *
                                0.12}{" "}
                              $
                            </p>
                          ) : null
                        ) : analyise.order.product.category.type ===
                          "1-step" ? (
                          analyise.type === "Real" ? (
                            // محتوای مربوط به حالت Real در 1-step
                            <p style={{ marginBottom: 0 }}>
                              Real type content: max loss limit :{" "}
                              {convertToNumber(analyise.order.product.title) *
                                0.08}{" "}
                              $
                            </p>
                          ) : (
                            // محتوای مربوط به حالت 1-step
                            <p style={{ marginBottom: 0 }}>
                              max loss limit :{" "}
                              {convertToNumber(analyise.order.product.title) *
                                0.08}{" "}
                              $
                            </p>
                          )
                        ) : analyise.order.product.category.type ===
                          "pro-trader" ? (
                          // محتوای مربوط به حالت pro-trader در صورت موجود بودن product
                          <p style={{ marginBottom: 0 }}>
                            max loss limit :{" "}
                            {convertToNumber(analyise.order.product.title) *
                              0.05}{" "}
                            $
                          </p>
                        ) : null
                      ) : analyise.order.category.type === "2-step" ? (
                        analyise.type === "First Step" ? (
                          // محتوای مربوط به حالت First Step در 2-step
                          <p style={{ marginBottom: 0 }}>
                            max loss limit : {analyise.order.product_id * 0.12}{" "}
                            $
                          </p>
                        ) : analyise.type === "Second Step" ? (
                          // محتوای مربوط به حالت Second Step در 2-step
                          <p style={{ marginBottom: 0 }}>
                            max loss limit : {analyise.order.product_id * 0.12}{" "}
                            $
                          </p>
                        ) : analyise.type === "Real" ? (
                          // محتوای مربوط به حالت Real در 2-step
                          <p style={{ marginBottom: 0 }}>
                            Real type content: max loss limit :{" "}
                            {analyise.order.product_id * 0.12} $
                          </p>
                        ) : null
                      ) : analyise.order.category.type === "1-step" ? (
                        analyise.type === "Real" ? (
                          // محتوای مربوط به حالت Real در 1-step
                          <p style={{ marginBottom: 0 }}>
                            Real type content: max loss limit :{" "}
                            {analyise.order.product_id * 0.08} $
                          </p>
                        ) : (
                          // محتوای مربوط به حالت 1-step
                          <p style={{ marginBottom: 0 }}>
                            max loss limit : {analyise.order.product_id * 0.08}{" "}
                            $
                          </p>
                        )
                      ) : analyise.order.category.type === "pro-trader" ? (
                        // محتوای مربوط به حالت pro-trader در صورت عدم موجود بودن product
                        <p style={{ marginBottom: 0 }}>
                          max loss limit : {analyise.order.product_id * 0.05} $
                        </p>
                      ) : null}

                      <strong>max permitted loss : -- </strong>
                    </div>
                  </div>
                </div>
                <div className="col2">
                  <div
                    className="myyrow"
                    style={{
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = "scale(1.07)"; // بزرگتر شدن کل div
                      e.currentTarget.style.boxShadow =
                        "0 8px 16px rgba(0, 0, 0, 0.2)"; // سایه‌ی عمیق‌تر
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = "scale(1)"; // بازگشت به اندازه اولیه
                      e.currentTarget.style.boxShadow = "none"; // حذف سایه هنگام خروج
                    }}
                  >
                    <div className="myTop">
                      <div className="topLeft">
                        <MdOutlineDone />
                        <p
                          style={{
                            margin: 0,
                            fontWeight: "bold",
                            color: "#CB9E52",
                          }}
                        >
                          minimum trading days
                        </p>
                      </div>
                      <div className="topRight"></div>
                    </div>
                    <div className="myBot">
                      <p style={{ marginBottom: 0 }}>minimum : 5</p>
                      <strong>
                        days :{" "}
                        {getUniqueTradingDays(mydata?.positionsData.positions)}
                      </strong>
                    </div>
                  </div>
                  <div
                    className="myyrow"
                    style={{
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = "scale(1.07)"; // بزرگتر شدن کل div
                      e.currentTarget.style.boxShadow =
                        "0 8px 16px rgba(0, 0, 0, 0.2)"; // سایه‌ی عمیق‌تر
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = "scale(1)"; // بازگشت به اندازه اولیه
                      e.currentTarget.style.boxShadow = "none"; // حذف سایه هنگام خروج
                    }}
                  >
                    <div className="myTop">
                      <div className="topLeft">
                        <MdOutlineDone />
                        <p
                          style={{
                            margin: 0,
                            fontWeight: "bold",
                            color: "#CB9E52",
                          }}
                        >
                          profit target
                        </p>
                      </div>
                      <div className="topRight"></div>
                    </div>
                    <div className="myBot">
                      {/* {analyise.order.product ? (
                        analyise.order.product.category.type === "2-step" ? (
                          analyise.type === "First Step" ? (
                            // محتوای مربوط به حالت First Step در 2-step
                            <p style={{ marginBottom: 0 }}>
                              target :{" "}
                              {analyise.order.product
                                ? convertToNumber(
                                    analyise.order.product.title
                                  ) * 0.1
                                : analyise.order.product_id * 0.1}{" "}
                              $
                            </p>
                          ) : analyise.type === "Second Step" ? (
                            // محتوای مربوط به حالت Second Step در 2-step
                            <p style={{ marginBottom: 0 }}>
                              target :{" "}
                              {analyise.order.product
                                ? convertToNumber(
                                    analyise.order.product.title
                                  ) * 0.05
                                : analyise.order.product_id * 0.05}{" "}
                              $
                            </p>
                          ) : null
                        ) : analyise.order.product.category.type ===
                          "1-step" ? (
                          // محتوای مربوط به حالت 1-step در صورت موجود بودن product
                          <p style={{ marginBottom: 0 }}>
                            target :{" "}
                            {analyise.order.product
                              ? convertToNumber(analyise.order.product.title) *
                                0.12
                              : analyise.order.product_id * 0.12}{" "}
                            $
                          </p>
                        ) : analyise.order.product.category.type ===
                          "pro-trader" ? (
                          // محتوای مربوط به حالت pro-trader در صورت موجود بودن product
                          <p style={{ marginBottom: 0 }}>
                            target :{" "}
                            {analyise.order.product
                              ? convertToNumber(analyise.order.product.title) *
                                0.1
                              : analyise.order.product_id * 0.1}{" "}
                            $
                          </p>
                        ) : null
                      ) : analyise.order.category.type === "1-step" ? (
                        // محتوای مربوط به حالت 1-step در صورت عدم موجود بودن product
                        <p style={{ marginBottom: 0 }}>
                          target :{" "}
                          {analyise.order.product
                            ? convertToNumber(analyise.order.product.title) *
                              0.12
                            : analyise.order.product_id * 0.12}{" "}
                          $
                        </p>
                      ) : analyise.order.category.type === "2-step" ? (
                        // محتوای مربوط به حالت 2-step در صورت عدم موجود بودن product
                        analyise.type === "First Step" ? (
                          // محتوای مربوط به حالت First Step در 2-step
                          <p style={{ marginBottom: 0 }}>
                            target :{" "}
                            {analyise.order.product
                              ? convertToNumber(analyise.order.product.title) *
                                0.1
                              : analyise.order.product_id * 0.1}{" "}
                            $
                          </p>
                        ) : analyise.type === "Second Step" ? (
                          // محتوای مربوط به حالت Second Step در 2-step
                          <p style={{ marginBottom: 0 }}>
                            target :{" "}
                            {analyise.order.product
                              ? convertToNumber(analyise.order.product.title) *
                                0.05
                              : analyise.order.product_id * 0.05}{" "}
                            $
                          </p>
                        ) : null
                      ) : analyise.order.category.type === "pro-trader" ? (
                        // محتوای مربوط به حالت pro-trader در صورت عدم موجود بودن product
                        <p style={{ marginBottom: 0 }}>
                          target :{" "}
                          {analyise.order.product
                            ? convertToNumber(analyise.order.product.title) *
                              0.1
                            : analyise.order.product_id * 0.1}{" "}
                          $
                        </p>
                      ) : null} */}
                      {analyise.order.product ? (
                        analyise.order.product.category.type === "2-step" ? (
                          analyise.type === "First Step" ? (
                            // محتوای مربوط به حالت First Step در 2-step
                            <p style={{ marginBottom: 0 }}>
                              target :{" "}
                              {convertToNumber(analyise.order.product.title) *
                                0.1}{" "}
                              $
                            </p>
                          ) : analyise.type === "Second Step" ? (
                            // محتوای مربوط به حالت Second Step در 2-step
                            <p style={{ marginBottom: 0 }}>
                              target :{" "}
                              {convertToNumber(analyise.order.product.title) *
                                0.05}{" "}
                              $
                            </p>
                          ) : analyise.type === "Real" ? (
                            // محتوای مربوط به حالت Real در 2-step
                            <p style={{ marginBottom: 0 }}>target : --</p>
                          ) : null
                        ) : analyise.order.product.category.type ===
                          "1-step" ? (
                          analyise.type === "Real" ? (
                            // محتوای مربوط به حالت Real در 1-step
                            <p style={{ marginBottom: 0 }}>target : --</p>
                          ) : (
                            // محتوای مربوط به حالت 1-step
                            <p style={{ marginBottom: 0 }}>
                              target :{" "}
                              {convertToNumber(analyise.order.product.title) *
                                0.12}{" "}
                              $
                            </p>
                          )
                        ) : analyise.order.product.category.type ===
                          "pro-trader" ? (
                          // محتوای مربوط به حالت pro-trader در صورت موجود بودن product
                          <p style={{ marginBottom: 0 }}>
                            target :{" "}
                            {convertToNumber(analyise.order.product.title) *
                              0.1}{" "}
                            $
                          </p>
                        ) : null
                      ) : analyise.order.category.type === "2-step" ? (
                        analyise.type === "First Step" ? (
                          // محتوای مربوط به حالت First Step در 2-step
                          <p style={{ marginBottom: 0 }}>
                            target : {analyise.order.product_id * 0.1} $
                          </p>
                        ) : analyise.type === "Second Step" ? (
                          // محتوای مربوط به حالت Second Step در 2-step
                          <p style={{ marginBottom: 0 }}>
                            target : {analyise.order.product_id * 0.05} $
                          </p>
                        ) : analyise.type === "Real" ? (
                          // محتوای مربوط به حالت Real در 2-step
                          <p style={{ marginBottom: 0 }}>target : -- </p>
                        ) : null
                      ) : analyise.order.category.type === "1-step" ? (
                        analyise.type === "Real" ? (
                          // محتوای مربوط به حالت Real در 1-step
                          <p style={{ marginBottom: 0 }}>target :--</p>
                        ) : (
                          // محتوای مربوط به حالت 1-step
                          <p style={{ marginBottom: 0 }}>
                            target : {analyise.order.product_id * 0.12} $
                          </p>
                        )
                      ) : analyise.order.category.type === "pro-trader" ? (
                        // محتوای مربوط به حالت pro-trader در صورت عدم موجود بودن product
                        <p style={{ marginBottom: 0 }}>
                          target : {analyise.order.product_id * 0.1} $
                        </p>
                      ) : null}

                      <strong>
                        current :{" "}
                        <span
                          style={{
                            color: analyise.order.product
                              ? mydata?.userDetails.original.data.Balance -
                                  convertToNumber(
                                    analyise.order.product.title
                                  ) >=
                                0
                                ? "green"
                                : "red"
                              : mydata?.userDetails.original.data.Balance -
                                  analyise.order.product_id >=
                                0
                              ? "green"
                              : "red",
                          }}
                        >
                          {analyise.order.product
                            ? (
                                mydata?.userDetails.original.data.Balance -
                                convertToNumber(analyise.order.product.title)
                              ).toFixed(2)
                            : (
                                mydata?.userDetails.original.data.Balance -
                                analyise.order.product_id
                              ).toFixed(2)}
                          $
                        </span>
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : analyiseActiver.a2 == "active" ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                padding: "1rem",
              }}
            >
              <div
                style={{
                  borderRadius: "50%",
                  padding: "1rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                  backgroundColor: " rgba(203, 158, 82, 0.25)",
                }}
              >
                {/* برچسب سمت چپ */}
                <span>{"analytic"}</span>

                {/* کامپوننت Switch */}
                <FormControlLabel
                  style={{ margin: "0", marginRight: "24px" }}
                  control={
                    <Switch checked={isChecked} onChange={handleToggle} />
                  }
                  label=""
                />

                {/* برچسب سمت راست */}
                <span>{"stats"}</span>
              </div>
            </div>
            {isChecked ? (
              <>
                <div className="statsCon">
                  {columns?.map((col, colIndex) => (
                    <div
                      className="statcol"
                      style={{ minWidth: "50%" }}
                      key={colIndex}
                    >
                      {col.map((item, rowIndex) => (
                        <React.Fragment key={rowIndex}>
                          <div
                            className="mystatrow"
                            style={{
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.transform = "scale(1.03)"; // بزرگتر شدن کل div
                              e.currentTarget.style.boxShadow =
                                "0 8px 16px rgba(0, 0, 0, 0.2)"; // سایه‌ی عمیق‌تر
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.transform = "scale(1)"; // بازگشت به اندازه اولیه
                              e.currentTarget.style.boxShadow = "none"; // حذف سایه هنگام خروج
                            }}
                          >
                            <p>{item.key}</p>
                            <p>{item.value}</p>
                          </div>
                          {rowIndex !== col.length - 1 && (
                            <div className="separator-horizontal"></div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  ))}
                </div>

                <div className="statsTable">
                  <TableContainer
                    component={Paper}
                    sx={{ backgroundColor: "transparent" }}
                  >
                    <Table
                      sx={{
                        backgroundColor: "transparent", // پس‌زمینه شفاف
                        backdropFilter: "blur(10px)", // اعمال تاری
                        WebkitBackdropFilter: "blur(10px)", // برای پشتیبانی Safari
                        borderRadius: "10px", // گوشه‌های گرد (اختیاری)
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // سایه برای زیباتر کردن (اختیاری)
                      }}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Open Time</StyledTableCell>
                          <StyledTableCell align="left">
                            Open Price
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Close time
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            ClosePrice
                          </StyledTableCell>
                          <StyledTableCell align="left">Profit</StyledTableCell>
                          <StyledTableCell align="left">lots</StyledTableCell>
                          <StyledTableCell align="left">
                            commission
                          </StyledTableCell>
                          <StyledTableCell align="left">swap</StyledTableCell>
                          <StyledTableCell align="left">
                            Net Profit
                          </StyledTableCell>
                          <StyledTableCell align="left">symbol</StyledTableCell>
                          <StyledTableCell align="left">type</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      
                      <TableBody>
                        {paginatedTableData.map((row, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell>{row.open_time}</StyledTableCell>
                            <StyledTableCell align="left">
                              {row.open_price}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.close_time}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.close_price}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.profit}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.lots}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.commission}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.swap}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.net_profit}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.symbol}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.type}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Stack
                    spacing={2}
                    sx={{
                      width: "100%", // کل عرض صفحه را می‌گیرد
                      justifyContent: "center",
                      alignItems: "center", // برای قرار دادن دکمه‌ها در وسط
                      marginTop: "1rem",
                    }}
                  >
                    <Pagination
                      count={Math.ceil(tableData.length / rowsPerPage)}
                      page={page}
                      onChange={handleChangepage}
                      sx={{
                        width: "100%", // کل عرض ردیف را می‌گیرد
                        "& .MuiPagination-ul": {
                          justifyContent: "center", // قرار دادن دکمه‌ها در مرکز
                          width: "100%", // کل عرض ردیف برای دکمه‌ها
                        },
                        "& .MuiPaginationItem-root": {
                          color: "white", // رنگ متن اعداد
                        },
                      }}
                    />
                  </Stack>
                </div>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "3rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    padding: "0 1rem",

                    justifyContent: "space-between",
                    marginBottom: "20px",
                    gap: "10px", // فاصله بین دکمه‌ها
                  }}
                >
                  {[
                    {
                      label: "Symbol Performance",
                      value: "Symbol Performance",
                    },
                    { label: "PNL by Weekday", value: "PnL by Weekday" },
                    { label: "PNL by Hours", value: "PnL by Hours" },
                    { label: "PNL Order Type", value: "PnL order type" },
                    { label: "Win/Loss Ratio", value: "Win/Loss Ratio" },
                  ].map((button) => (
                    <button
                      className="chartBtns"
                      key={button.value}
                      onClick={() => setChartType(button.value)}
                      style={{
                        fontSize: "1rem",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        flex: "1", // اشغال فضای مساوی توسط دکمه‌ها
                        maxWidth: "18%", // حداکثر عرض هر دکمه (برای جلوگیری از خیلی بزرگ شدن)
                        minWidth: "17%",
                        // border: chartType === button.value ? "2px solid #3a3a3a" : "1px solid #ccc", // حاشیه خاکستری برای دکمه فعال
                        backgroundImage:
                          chartType === button.value
                            ? "linear-gradient(135deg, #3a3a3a, #1c1c1c)" // گرادیان خاکستری برای دکمه فعال
                            : "none", // بدون گرادیان برای دکمه‌های غیرفعال
                        backgroundColor:
                          chartType === button.value ? "#1c1c1c" : "#f0f0f0", // رنگ پس‌زمینه
                        color: chartType === button.value ? "#fff" : "#333", // رنگ متن دکمه فعال
                        cursor: "pointer",
                        transition: "all 0.3s ease",
                        boxShadow:
                          chartType === button.value
                            ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                            : "none", // سایه برای دکمه فعال
                      }}
                      onMouseOver={
                        (e) =>
                          (e.target.style.backgroundImage =
                            chartType === button.value
                              ? "linear-gradient(135deg, #4a4a4a, #2c2c2c)" // گرادیان هاور خاکستری تیره‌تر
                              : "linear-gradient(135deg, #e0e0e0, #cfcfcf)") // گرادیان خاکستری روشن برای هاور
                      }
                      onMouseOut={
                        (e) =>
                          (e.target.style.backgroundImage =
                            chartType === button.value
                              ? "linear-gradient(135deg, #3a3a3a, #1c1c1c)" // بازگشت به گرادیان اصلی
                              : "none") // بازگشت به حالت بدون گرادیان
                      }
                    >
                      {button.label}
                    </button>
                  ))}
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    padding: "20px",
                    borderRadius: "5px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {chartType === "Symbol Performance" && (
                    <div
                      style={{
                        marginTop: "20px",
                        padding: "20px",
                        borderRadius: "5px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        display: "flex",
                        justifyContent: "center",
                        gap: "2rem",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <h4 style={{ textAlign: "center" }}>
                          تعداد پوزیشن‌ها بر اساس نماد
                        </h4>
                        <ResponsiveContainer width="100%" height={400}>
                          <PieChart>
                            <Pie
                              data={filteredData}
                              cx="50%"
                              cy="50%"
                              labelLine={false}
                              outerRadius={150}
                              fill="#8884d8"
                              dataKey="value"
                            >
                              {filteredData.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              ))}
                            </Pie>
                            <Tooltip
                              formatter={(value) => {
                                const total = filteredData.reduce(
                                  (sum, entry) => sum + entry.value,
                                  0
                                ); // Calculate total inline
                                return `${((value / total) * 100).toFixed(1)}%`;
                              }}
                            />
                            <Legend />
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                      <div
                        style={{
                          width: "200px",
                          padding: "10px",
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",

                          borderRadius: "8px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <h4
                          style={{
                            textAlign: "center",
                            marginBottom: "10px",
                            color: "white",
                          }}
                        >
                          انتخاب نمادها
                        </h4>
                        {allSymbols.map((symbol) => (
                          <div
                            key={symbol}
                            style={{
                              display: "flex",

                              alignItems: "center",
                              marginBottom: "8px",
                              padding: "5px 10px",
                              borderRadius: "6px",
                              backgroundColor: "#ffffff",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                              transition: "all 0.3s ease",
                            }}
                            onMouseEnter={(e) =>
                              (e.currentTarget.style.backgroundColor =
                                "#f0f0f0")
                            }
                            onMouseLeave={(e) =>
                              (e.currentTarget.style.backgroundColor =
                                "#ffffff")
                            }
                          >
                            <input
                              type="checkbox"
                              checked={selectedSymbols.includes(symbol)}
                              onChange={() => handleCheckboxChange(symbol)}
                              style={{
                                marginRight: "10px",
                                width: "18px",
                                height: "18px",
                                cursor: "pointer",
                              }}
                            />
                            <label
                              style={{
                                color: "#333",
                                fontSize: "14px",
                                cursor: "pointer",
                              }}
                            >
                              {symbol}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {chartType === "PnL by Weekday" && (
                    <div>
                      <h4 style={{ textAlign: "center", marginBottom: "20px" }}>
                        PnL by Weekday
                      </h4>
                      <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                          data={chartData2}
                          margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="2 2" />
                          <XAxis dataKey="day" />
                          <YAxis />
                          <Tooltip
                            contentStyle={{
                              backgroundColor: "#1e1e2f", // رنگ پس‌زمینه‌ی دلخواه
                              borderRadius: "8px", // گوشه‌های گرد برای Tooltip
                              border: "1px solid #cccccc", // حاشیه‌ی ظریف برای زیبایی
                              color: "#ffffff", // رنگ متن
                              textAlign: "left",
                            }}
                            cursor={{
                              fill: "transparent", // حذف رنگ خاکستری روی میله‌ها هنگام هاور
                              color: "white",
                            }}
                            itemStyle={{
                              color: "#ffffff", // متن سفید
                              fontWeight: "bold", // متن ضخیم‌تر
                            }}
                            formatter={(value) => value.toFixed(2)}
                          />
                          <Bar
                            dataKey="netProfit"
                            barSize={30}
                            shape={(props) => {
                              const { x, y, width, value } = props;
                              const height = Math.abs(props.height); // ارتفاع مطلق
                              const fill = value >= 0 ? "#388e3c" : "#f44336"; // سبز برای مثبت، قرمز برای منفی
                              const adjustedY = value >= 0 ? y : y - height; // تنظیم نقطه شروع برای مقدار منفی

                              return (
                                <rect
                                  x={x}
                                  y={adjustedY}
                                  width={width}
                                  height={height}
                                  fill={fill}
                                />
                              );
                            }}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  )}
                  {chartType === "PnL by Hours" && (
                    <div>
                      <h4 style={{ textAlign: "center", marginBottom: "20px" }}>
                        PnL by Hours
                      </h4>
                      <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                          data={hourlyPnLData}
                          margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="2 2" />
                          <XAxis dataKey="hour" />
                          <YAxis />
                          <Tooltip
                            contentStyle={{
                              backgroundColor: "#1e1e2f", // رنگ پس‌زمینه‌ی دلخواه
                              borderRadius: "8px", // گوشه‌های گرد برای Tooltip
                              border: "1px solid #cccccc", // حاشیه‌ی ظریف برای زیبایی
                              color: "#ffffff", // رنگ متن
                              textAlign: "left",
                            }}
                            cursor={{
                              backgroundColor: "white",
                              fill: "transparent", // حذف رنگ خاکستری روی میله‌ها هنگام هاور
                            }}
                            itemStyle={{
                              color: "#ffffff", // متن سفید
                            }}
                            formatter={(value) => value.toFixed(2)}
                          />
                          <Bar
                            dataKey="netProfit"
                            barSize={30}
                            shape={(props) => {
                              const { x, y, width, value } = props;
                              const height = Math.abs(props.height); // ارتفاع مطلق
                              const fill = value >= 0 ? "#4caf50" : "#f44336"; // سبز برای مثبت، قرمز برای منفی
                              const adjustedY = value >= 0 ? y : y - height; // تنظیم نقطه شروع برای مقدار منفی

                              return (
                                <rect
                                  x={x}
                                  y={adjustedY}
                                  width={width}
                                  height={height}
                                  fill={fill}
                                />
                              );
                            }}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  )}
                  {chartType === "PnL order type" && (
                    <div>
                      <h4 style={{ textAlign: "center", marginBottom: "20px" }}>
                        PnL by Order Type
                      </h4>
                      <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                          data={orderTypeData}
                          margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="type" />
                          <YAxis
                            domain={[-maxAbsoluteValue, maxAbsoluteValue]} // تقارن محور Y حول صفر
                            tickFormatter={(value) => value.toLocaleString()} // نمایش اعداد خوانا
                            ticks={[-maxAbsoluteValue, 0, maxAbsoluteValue]} // نمایش خط 0 به همراه مقادیر حد بالا و پایین
                          />
                          <Tooltip
                            contentStyle={{
                              backgroundColor: "#1e1e2f", // رنگ پس‌زمینه‌ی دلخواه
                              borderRadius: "8px", // گوشه‌های گرد برای Tooltip
                              border: "1px solid #cccccc", // حاشیه‌ی ظریف برای زیبایی
                              color: "#ffffff", // رنگ متن
                              textAlign: "left",
                            }}
                            cursor={{
                              fill: "transparent",
                            }}
                            itemStyle={{
                              color: "#ffffff",
                              fontWeight: "bold",
                            }}
                            formatter={(value) => value.toFixed(2)}
                          />
                          <Bar
                            dataKey="netProfit"
                            barSize={50}
                            shape={(props) => {
                              const { x, y, width, value } = props;
                              const height = Math.abs(props.height); // محاسبه ارتفاع مطلق
                              const color =
                                props.payload.type === "Buy"
                                  ? "#4caf50"
                                  : "#f44336"; // سبز برای Buy و قرمز برای Sell
                              const adjustedY = value >= 0 ? y : y - height; // تنظیم y بر اساس مثبت یا منفی بودن مقدار

                              return (
                                <rect
                                  x={x}
                                  y={adjustedY}
                                  width={width}
                                  height={height}
                                  fill={color}
                                />
                              );
                            }}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  )}
                  {chartType === "Win/Loss Ratio" && (
                    <ResponsiveContainer width="100%" height={400}>
                      <PieChart>
                        <Pie
                          data={pieChartData2}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          outerRadius={120}
                          dataKey="value"
                        >
                          {pieChartData2.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Pie>
                        <Tooltip
                          formatter={(value) => {
                            const total = pieChartData2.reduce(
                              (sum, entry) => sum + entry.value,
                              0
                            ); // Inline total calculation
                            return `${((value / total) * 100).toFixed(1)}%`;
                          }}
                        />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  )}
                </div>
              </div>
            )}
          </>
        ) : null}
      </div>

      {/* <button
      onClick={() => {
        setAnalyise(null);
      }}
    >
      {" "}
      back{" "}
    </button> */}
    </div>
  );
}

export default Analysis;
