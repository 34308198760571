/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from "react-redux";
import { useAdminsList } from "./useAdminsList";
import { setSupportIdForChats } from "./slices/messageSlice";
import FilterChats from "./FilterChats";

function SelectUser({ supportChatData }) {
  const { data: admins, isLoading } = useAdminsList();
  const dispatch = useDispatch();
  const { supportIdForChats } = useSelector((state) => state.message);

  return (
    <div
      style={{
        padding: "1.2rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <select
        style={{
          padding: "0.4rem 1.2rem",
          width: "100%",
          backgroundColor: "var(--color-green-800)",
          fontSize: "12px",
        }}
        disabled={isLoading}
        value={supportIdForChats}
        onChange={(e) => dispatch(setSupportIdForChats(e.target.value))}
      >
        <option value={0}>All</option>
        {admins?.map((admin) => (
          <option key={admin.id} value={admin.id}>
            {admin?.first_name +
              " " +
              admin?.last_name +
              " (" +
              admin?.nickname +
              ")"}
          </option>
        ))}
      </select>
      <FilterChats supportChatData={supportChatData} />
    </div>
  );
}

export default SelectUser;
