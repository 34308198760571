import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  position: relative;
  /* Default font for English and other content */
  font-family: "Iran Sans";
  direction: ltr;

  /* Persian (Iran Sans) font for Persian language */
  /* &:lang(fa),
  &:lang(ar) {
    font-family: "Iran Sans", sans-serif;
  } */
`;

export default Container;
