import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { FaArrowLeft } from "react-icons/fa"; // آیکون بازگشت
import "./SupCompetition.css";
function SupCompList({ competitions }) {
  const [timeLeft, setTimeLeft] = useState({});
  const [selectedCompetition, setSelectedCompetition] = useState(null); // ذخیره مسابقه انتخاب‌شده

  // تابع برای درخواست جزئیات مسابقه
  const fetchCompetitionDetails = async () => {
    if (!selectedCompetition) return;
    const response = await axios.get(
      `/api/v1/competitions/${selectedCompetition.id}`
    );
    return response.data;
  };

  // کوئری برای جزئیات مسابقه
  // const {
  //   data: competitionDetails,
  //   isLoading,
  //   error,
  // } = useQuery({
  //   queryKey: ["competitionDetails", selectedCompetition?.id], // فقط زمانی درخواست ارسال می‌شود که selectedCompetition مقدار داشته باشد
  //   queryFn: fetchCompetitionDetails,
  //   enabled: !!selectedCompetition, // فعال شدن کوئری فقط وقتی selectedCompetition موجود باشد
  // });

  // مدیریت تایمر
  useEffect(() => {
    const timers = {};

    competitions.forEach((competition) => {
      if (competition.status === 2) return; // پایان‌یافته‌ها تایمر نیاز ندارند

      const padWithZero = (number) => (number < 10 ? `0${number}` : number);

      const calculateTimeLeft = () => {
        const now = new Date();
        const targetTime =
          competition.status === 1
            ? new Date(competition.start)
            : new Date(competition.end);
        const difference = targetTime - now;

        if (difference > 0) {
          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((difference / (1000 * 60)) % 60);
          const seconds = Math.floor((difference / 1000) % 60);

          setTimeLeft((prev) => ({
            ...prev,
            [competition.id]: {
              days: padWithZero(days),
              hours: padWithZero(hours),
              minutes: padWithZero(minutes),
              seconds: padWithZero(seconds),
            },
          }));
        } else {
          setTimeLeft((prev) => ({ ...prev, [competition.id]: null }));
        }
      };

      calculateTimeLeft();
      timers[competition.id] = setInterval(calculateTimeLeft, 1000);
    });

    return () => {
      Object.values(timers).forEach((timer) => clearInterval(timer));
    };
  }, [competitions]);

  // رندر جزئیات مسابقه
  const renderDetails = () => {
    // if (isLoading) return <div>در حال بارگذاری جزئیات...</div>;
    // if (error) return <div>خطایی رخ داد: {error.message}</div>;

    if ( selectedCompetition) {
      return (
        <div className="competition-details">
          <h2 className="competition-title">جزئیات مسابقه</h2>
          <div className="details-table">
            <div className="details-row odd">
              <div className="details-key">عنوان:</div>
              <div className="details-value">{selectedCompetition.title}</div>
            </div>
            <div className="details-row even">
              <div className="details-key">شروع:</div>
              <div className="details-value">{selectedCompetition.start}</div>
            </div>
            <div className="details-row odd">
              <div className="details-key">پایان:</div>
              <div className="details-value">{selectedCompetition.end}</div>
            </div>
            <div className="details-row even">
              <div className="details-key">جایزه:</div>
              <div className="details-value">{selectedCompetition.prize}</div>
            </div>
            <div className="details-row odd">
              <div className="details-key">شرکت‌کنندگان:</div>
              <div className="details-value">
                {selectedCompetition.contestants}
              </div>
            </div>
            <div className="details-row even">
              <div className="details-key">وضعیت محلی:</div>
              <div className="details-value">
                {selectedCompetition.status === 0 ? "در حال اجرا" : "آینده"}
              </div>
            </div>
            {timeLeft[selectedCompetition.id] && (
              <>
                <div className="details-row odd">
                  <div className="details-key">زمان باقی‌مانده:</div>
                  <div className="details-value">
                    {timeLeft[selectedCompetition.id].days} روز
                    {timeLeft[selectedCompetition.id].hours} ساعت،{" "}
                    {timeLeft[selectedCompetition.id].minutes} دقیقه،{" "}
                    {timeLeft[selectedCompetition.id].seconds} ثانیه
                  </div>
                </div>
                {/* <div className="details-row even">
                  <div className="details-key"></div>
                  <div className="details-value">
                    
                  </div>
                </div> */}
              </>
            )}
          </div>
        </div>

        // <div>
        //   <h2>جزئیات مسابقه</h2>
        //   <div>عنوان: {selectedCompetition.title}</div>
        //   <div>شروع: {selectedCompetition.start}</div>
        //   <div>پایان: {selectedCompetition.end}</div>
        //   <div>جایزه: {selectedCompetition.prize}</div>
        //   <div>شرکت‌کنندگان: {selectedCompetition.contestants}</div>
        //   <div>وضعیت محلی: {selectedCompetition.status === 0 ? "در حال اجرا" : "آینده"}</div>
        //   {timeLeft[selectedCompetition.id] && (
        //     <div>
        //       <h4>زمان باقی‌مانده:</h4>
        //       <div>{timeLeft[selectedCompetition.id].days} روز</div>
        //       <div>{timeLeft[selectedCompetition.id].hours} ساعت</div>
        //       <div>{timeLeft[selectedCompetition.id].minutes} دقیقه</div>
        //       <div>{timeLeft[selectedCompetition.id].seconds} ثانیه</div>
        //     </div>
        //   )}
        // </div>
      );
    }

    return null;
  };

  // رندر لیست مسابقات
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {selectedCompetition && (
        <button
          onClick={() => setSelectedCompetition(null)} // بازگشت به لیست مسابقات
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            marginBottom: "1rem",
            cursor: "pointer",
            background: "none",
            border: "none",
            color: "#007bff",
            fontSize: "1rem",
          }}
        >
          <FaArrowLeft size={20} />
          بازگشت
        </button>
      )}
      {selectedCompetition
        ? renderDetails() // نمایش جزئیات مسابقه انتخاب‌شده
        : competitions.map((competition) => (
            <div
              key={competition.id}
              className="cardStyle1"
              onClick={() => setSelectedCompetition(competition)} // ذخیره مسابقه انتخاب‌شده
              style={{ cursor: "pointer" }}
            >
              <div className="headerStyle1">
                <h6>{competition.title}</h6>
                <div className="statusStyle1">
                  {competition.status === 0
                    ? "در حال اجرا"
                    : competition.status === 1
                    ? "آینده"
                    : "پایان‌یافته"}
                </div>
              </div>
              <div className="contentStyle1">
                <div className="itemStyle1">
                  <div className="titleStyle1">شروع:</div>
                  <div>{competition.start}</div>
                </div>
                <div className="itemStyle1">
                  <div className="titleStyle1">پایان:</div>
                  <div>{competition.end}</div>
                </div>
                <div className="itemStyle1">
                  <div className="titleStyle1">جایزه:</div>
                  <div>{competition.prize}</div>
                </div>
              </div>
              <div>
                {competition.status === 2 ? (
                  <div style={{ color: "red", fontWeight: "bold" }}>
                    پایان یافت
                  </div>
                ) : (
                  timeLeft[competition.id] && (
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <div>{timeLeft[competition.id].days} روز</div>
                      <div>{timeLeft[competition.id].hours} ساعت</div>
                      <div>{timeLeft[competition.id].minutes} دقیقه</div>
                      <div>{timeLeft[competition.id].seconds} ثانیه</div>
                    </div>
                  )
                )}
              </div>
            </div>
          ))}
    </div>
  );
}

export default SupCompList;
