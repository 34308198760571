import React, { useState } from "react";

function CompetitionRules({ competition }) {
  const [openRuleIndex, setOpenRuleIndex] = useState(null);

  const tournamentRules = competition.rules?.["قوانین_مسابقه"] || {}; // دریافت قوانین از داده جدید

  const rules = [
    {
      question: "معیار انتخاب برندگان چیست؟",
      answer: `معیار: ${tournamentRules?.["برندگان"]?.["معیار"] || "مشخص نشده"}
      | تعداد برندگان: ${tournamentRules?.["برندگان"]?.["تعداد_برندگان"] || "مشخص نشده"}
      `,
    },
    {
      question: "حداکثر افت سرمایه روزانه چقدر است؟",
      answer: `${tournamentRules?.["حداکثر_افت_روزانه"] || "مشخص نشده"}`,
    },
    {
      question: "حداکثر افت سرمایه کل چقدر است؟",
      answer: `${tournamentRules?.["حداکثر_افت_کل"] || "مشخص نشده"}`,
    },
    {
      question: "حداقل روزهای معاملاتی مورد نیاز چیست؟",
      answer: `${tournamentRules?.["حداقل_روزهای_معاملاتی"] || "مشخص نشده"}`,
    },
  ];

  const toggleRule = (index) => {
    setOpenRuleIndex(openRuleIndex === index ? null : index);
  };

  const containerStyle = {
    padding: "2rem",
    borderRadius: "12px",
    backgroundColor: "transparent",
    fontFamily: "'Arial', sans-serif",
  };

  const ruleStyle = {
    marginBottom: "1rem",
    border: "1px solid rgba(255, 255, 255, 0.3)",
    borderRadius: "8px",
    overflow: "hidden",
    transition: "all 0.3s ease-in-out",
  };

  const ruleHeaderStyle = {
    padding: "1rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#fff",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    transition: "background-color 0.3s",
  };

  const ruleAnswerStyle = (isOpen) => ({
    padding: "1rem",
    color: "#fff",
    fontSize: "0.9rem",
    backgroundColor: "rgba(103, 90, 90, 0.1)",
    maxHeight: isOpen ? "200px" : "0",
    overflow: "hidden",
    transition: "max-height 0.5s ease-in-out, padding 0.3s ease-in-out",
    paddingTop: isOpen ? "1rem" : "0",
    paddingBottom: isOpen ? "1rem" : "0",
  });

  const iconStyle = {
    fontSize: "1.2rem",
    color: "#fff",
    transition: "transform 0.3s",
  };

  return (
    <div style={containerStyle}>
      <h2 style={{ marginBottom: "1.5rem", color: "#fff", textAlign: "center" }}>
        قوانین مسابقه
      </h2>
      {rules.map((rule, index) => (
        <div key={index} style={ruleStyle}>
          <div style={ruleHeaderStyle} onClick={() => toggleRule(index)}>
            {rule.question}
            <span
              style={{
                ...iconStyle,
                transform: openRuleIndex === index ? "rotate(45deg)" : "rotate(0deg)",
              }}
            >
              +
            </span>
          </div>
          <div style={ruleAnswerStyle(openRuleIndex === index)}>
            {rule.answer}
          </div>
        </div>
      ))}
    </div>
  );
}

export default CompetitionRules;
