import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  replyId: null,
  replyMessage: "",
  userTyping: "", //not used
  supportIsTyping: false, //not used
  showEmojiPicker: false,
  editId: null,
  isEditing: false,
  isSendingVesper: false,
  messageType: "support",
};

const inputSlice = createSlice({
  name: "inputs",
  initialState,
  reducers: {
    openReply: (state, action) => {
      state.replyId = action.payload.replyId;
      state.replyMessage = action.payload.replyMessage;
    },
    closeReply: (state) => {
      state.replyId = null;
      state.replyMessage = "";
    },
    openUserIsTyping: (state, action) => {
      state.userTyping = action.payload;
    },
    closeUserIsTyping: (state) => {
      state.userTyping = null;
    },
    openEdit: (state, action) => {
      state.editId = action.payload.editId;
      state.isEditing = true;
    },
    closeEdit: (state) => {
      state.editId = null;
      state.isEditing = false;
    },
    toggleEmojiPicker: (state) => {
      state.showEmojiPicker = !state.showEmojiPicker;
    },
    openSupportTyping: (state) => {
      state.supportIsTyping = true;
    },
    closeSupportTyping: (state) => {
      state.supportIsTyping = false;
    },
    toggleToVesper: (state) => {
      state.isSendingVesper = !state.isSendingVesper;
      state.messageType =
        state.messageType === "support" ? "vesper" : "support";
    },
    clearInputs: (state) => {
      state.replyId = null;
      state.replyMessage = "";
      state.userTyping = ""; //not used
      state.supportIsTyping = false; //not used
      state.showEmojiPicker = false;
      state.editId = null;
      state.isEditing = false;
      state.isSendingVesper = false;
      state.messageType = "support";
    },
  },
});

export const {
  openReply,
  closeReply,
  openUserIsTyping,
  closeUserIsTyping,
  openEdit,
  closeEdit,
  toggleEmojiPicker,
  openSupportTyping,
  closeSupportTyping,
  toggleToVesper,
  clearInputs,
} = inputSlice.actions;

export default inputSlice.reducer;
