/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import styled from "styled-components";
import { useContext, useState } from "react";
import ListItem from "./ListItem";
import { useCreateCategory } from "./useCreateCategory";
import toast from "react-hot-toast";
import ButtonIcon from "../messages/ButtonIcon";
import { MdDelete, MdEdit } from "react-icons/md";
import { useEditCategory } from "./useEditCategory";
import Modal from "../messages/Modal";
import ConfirmAction from "../messages/ConfirmAction";
import { useDeleteCategory } from "./useDeleteCategory";
import { userHasPermission } from "../../../utils/helper";
import UserContext from "../../UserContext/UserContext";

const Sidebar = styled.div`
  width: 20%;
  padding: 1rem;
  border-radius: 9px;
  

  overflow-y: auto;
  scrollbar-width: thin;
  box-shadow: 0 4px 8px rgba(240, 234, 234, 0.8);

  & .main-categories {
    display: flex;
    flex-direction: column;
    /* gap: 1.6rem; */
  }
  @media (max-width: 1280px) {
    width: 25%;
    h4 {
      font-size: 3rem;
    }
  }
  @media (max-width: 1080px) {
    width: 30%;
    h4 {
      font-size: 2.6rem;
    }
  }
  @media (max-width: 900) {
    width: 34%;
    h4 {
      font-size: 2rem;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;


function MainCat({ categories, handleCategorySelect }) {
  const [newMainCategory, setNewMainCategory] = useState("");
  const [editId, setEditId] = useState(null);
  const { createCategory, isCreating } = useCreateCategory();
  const { editCategory, iSEditing } = useEditCategory();
  const { deleteCategory, isDeleting } = useDeleteCategory();
  const { user } = useContext(UserContext);

  // Add a new main category
  const handleAddMainCategory = (e) => {
    e.preventDefault();
    if (!newMainCategory.trim()) return;

    if (!editId) {
      createCategory(
        { name: newMainCategory, parent_id: null },
        {
          onSuccess: (res) => {
            toast.success(
              res.message || "New category has ben inserted successfully."
            ),
              setNewMainCategory("");
          },
          onError: (err) =>
            toast.error(err.response.data.message || err.message),
        }
      );
    } else if (editId > 0) {
      editCategory(
        { name: newMainCategory, parent_id: null, id: editId },
        {
          onSuccess: (res) => {
            toast.success(
              res.message || "Category has ben updated successfully."
            ),
              setEditId(null);
            setNewMainCategory("");
          },
          onError: (err) =>
            toast.error(err.response.data.message || err.message),
        }
      );
    }
  };

  function handleDeleteCategory(id) {
    deleteCategory(id);
  }

  return (
    <Sidebar>
      <h5 style={{textAlign:'center'}}>Main categories</h5>
      <div className="main-categories" style={{ fontSize:'1rem' }}>
        {categories?.categories.map((category) => (
          <ListItem
            key={category.id}
            onClick={() => handleCategorySelect(category)}
          >
            <span>{category.name}</span>

            <div>
              {userHasPermission(user, "faq-update") && (
                <ButtonIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditId(category.id);
                    setNewMainCategory(category.name);
                  }}
                >
                  <MdEdit />
                </ButtonIcon>
              )}
              {userHasPermission(user, "faq-delete") && (
                <Modal>
                  <Modal.Open opens="delete-category" stopPropagation={true}>
                    <ButtonIcon>
                      <MdDelete />
                    </ButtonIcon>
                  </Modal.Open>
                  <Modal.Window name="delete-category">
                    <ConfirmAction
                      message="This category, all its subcategories, and associated questions will be deleted. Are you sure?"
                      isPending={isDeleting}
                      onSubmit={() => handleDeleteCategory(category.id)}
                    />
                  </Modal.Window>
                </Modal>
              )}
            </div>
          </ListItem>
        ))}
      </div>
      {userHasPermission(user, "faq") && (
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.6rem",
            margin: "2rem 0",
          }}
        >
          <input
          
            type="text"
            placeholder="New Main Category"
            value={newMainCategory}
            onChange={(e) => setNewMainCategory(e.target.value)}
            style={{ width: "100%",direction:"ltr" }}
            disabled={isCreating}
            className="form-control"
          />
          <div style={{ display: "flex", gap: "2rem" }}>
            <button
              onClick={handleAddMainCategory}
              disabled={isCreating}
              style={{ width: "100%" }}
              className="btn btn-primary"
            >
              {isCreating || iSEditing ? "Loading..." : " Submit"}
            </button>
            <button
              style={{ width: "100%" }}
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                setEditId(null);
                setNewMainCategory("");
              }}
              disabled={isCreating}
            >
              Cancel
            </button>
          </div>
        </form>
      )}
    </Sidebar>
  );
}

export default MainCat;
