import styled from "styled-components";

const TruncatedText = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  direction: ${(props) =>
    props.isRtl === "rtl" ? "rtl" : "ltr"}; /* Dynamic text direction */
  text-align: ${(props) =>
    props.isRtl === "rtl"
      ? "left"
      : "right"}; /* Align text for proper ellipsis */
`;
export default TruncatedText;
